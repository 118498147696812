import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Tree, DatePicker, Modal, Button, Spin, Layout, message, Tabs, Space, Row, Col, Tooltip } from 'antd';
import { MenuUnfoldOutlined, MenuFoldOutlined, ExpandAltOutlined, ShrinkOutlined, EditOutlined } from '@ant-design/icons';
import Header160 from '../header/header';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './Poa.css';
import PoaActividades from './PoaActividades';
import ExportPoa from './ExportPoa';
import { fetchPoaData, fetchPoaDataWithParams } from '../../services/poa/get_poa';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import { updatePoaCorteFecha, fetchPoaCorteData } from '../../services/poa/poaCorteService';
import FileManagerPOA from './FileManagerPOA';
import PlanPedagogicoI from './PlanPedagogicoTable';
const { Content, Sider } = Layout;

const Poa = () => {
    const [treeData, setTreeData] = useState([]);
    const [, setDataCompleta] = useState([]); // Estado para la data completa
    const [selectedItem, setSelectedItem] = useState(null);
    const [fechaCorte, setFechaCorte] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandAll, ] = useState(false);
    const [showCards, setShowCards] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newFechaCorte, setNewFechaCorte] = useState(dayjs(fechaCorte, 'YYYY-MM-DD'));
    const [controlExpandallC, setControlExpandallC] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (newExpandedKeys) => { setExpandedKeys(newExpandedKeys); setAutoExpandParent(true); };
    const { Mpoa } = useParams();
    const [, setParamValue] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [itemParams, setItemParams] = useState(false);
    const [planPedagogico, setPlanPedagogico] = useState(false);
    const [fileUploadMode, setFileUploadMode] = useState(false);
    const [customFlag, setCustomFlag] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (Mpoa) {
                setParamValue(Mpoa);
                // Si la actividad es exactamente "2.2.1", retorna null
                if (Mpoa === '2.2.1') {
                    return null;
                }

                // Función para determinar qué actividad es especial y cómo manejarla
                const manejarActividadEspecial = (actividad) => {
                    switch (actividad) {
                        case '2.2.1.1':
                            setPlanPedagogico(true);
                            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
                        case '2.2.1.2':
                            setFileUploadMode(true);
                            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
                        case '2.2.1.3':
                            setCustomFlag(true);
                            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
                        default:
                            return actividad; // Para actividades normales, retorna el valor original
                    }
                };

                const actividadBase = manejarActividadEspecial(Mpoa);

                try {
                    const dataCompleta = await fetchPoaData();
                    const dataPoa = dataCompleta.dataPoa;
                    const findNodePath = (node, path = []) => {
                        // Agregar el nodo actual a la ruta
                        path.push(node);

                        // Condición general para actividades no especiales
                        if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                            return path;
                        }

                        // Condición específica para 2.2.1.2 y 2.2.1.3
                        if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                            // Iterar sobre los hijos del nodo principal
                            if (node.children && node.children.length > 0) {
                                for (let child of node.children) {
                                    // Para 2.2.1.2, buscar el hijo con oid === 33
                                    if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                                        path.push(child);
                                        return path;
                                    }
                                    // Para 2.2.1.3, buscar el hijo con oid === 34
                                    if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                                        path.push(child);
                                        return path;
                                    }
                                }
                            }
                        }

                        // Recorrer los hijos de cualquier nodo
                        if (node.children && node.children.length > 0) {
                            for (let child of node.children) {
                                const result = findNodePath(child, [...path]); // Crear una copia de la ruta
                                if (result) return result;
                            }
                        }

                        return null; // Si no se encontró un nodo coincidente
                    };


                    let matchedPath = null;

                    for (let nodo of dataPoa) {
                        matchedPath = findNodePath(nodo);
                        if (matchedPath) break;
                    }

                    if (matchedPath) {
                        const initialData = extractData(matchedPath);
                        const actividadData = initialData[0].actividadData;
                        const tareaEncontrada = actividadData.find((item) => {
                            if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                                // Evaluar también el key para los casos especiales
                                return (
                                    item.codigo_tarea === actividadBase &&
                                    ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                                        (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
                                );
                            }
                            // Para casos normales, solo evaluar codigo_tarea
                            return item.codigo_tarea === actividadBase;
                        });
                        if (tareaEncontrada) {
                            setOpenModal(true);
                            setItemParams(tareaEncontrada);
                        } else {
                            setOpenModal(false);
                            setItemParams(null);
                        }
                    } else {
                        console.warn("No se encontró ningún nodo coincidente");
                    }
                } catch (error) {
                    console.error("Error al obtener los datos:", error);
                }
            } else {
                setOpenModal(false);
            }
        };

        fetchData();
    }, [Mpoa]);


    const extractData = (children) => {
        return children.flatMap(child => {
            if (child.children && child.tipo === 'Actividad') {
                const actividadData = child.children.flatMap(grandchild => {
                    if (grandchild.children && grandchild.tipo === 'Tarea') {
                        return grandchild.children.flatMap(greatGrandchild => {
                            if (greatGrandchild.tipo === 'Indicador') {
                                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                                const metaProyectada = greatGrandchild.meta_proyectada || 0;
                                const valorEjecutado = Math.floor((greatGrandchild.meta_e?.reduce((total, meta) => total + meta.valor, 0) || 0) * 100) / 100;
                                const porcentajeEjecucion = metaProyectada > 0 ? (valorEjecutado / metaProyectada) * 100 : 0;
                                const medioV = greatGrandchild.medioVerificacion || null;
                                return {
                                    key: greatGrandchild.key,
                                    codigo_tarea: grandchild.codigo_tarea,
                                    codActividad: grandchild.codActividad,
                                    nameActividad: child.actividad,
                                    tarea: grandchild.tarea,
                                    indicador: greatGrandchild.title,
                                    medioV: medioV,
                                    meta_proyectada: metaProyectada,
                                    valorEjecutado: valorEjecutado,
                                    porcentaje_ejecucion: porcentajeEjecucion % 1 === 0 ? porcentajeEjecucion.toFixed(0) : porcentajeEjecucion.toFixed(1),
                                    porcentaje_avance: greatGrandchild.meta_e?.[0]?.valor || 'N/A',
                                    fechas: fechaFormateada,
                                    responsables: 'N/A',
                                    soportes: 'N/A',
                                    datoIndicador: greatGrandchild,
                                };
                            }
                            return [];
                        });
                    }
                    return [];
                });

                const totalMetaProyectada = actividadData.reduce((total, data) => total + (data.meta_proyectada || 0), 0);
                const totalValorEjecutado = actividadData.reduce((total, data) => total + (data.meta_proyectada * (parseFloat(data.porcentaje_ejecucion) / 100) || 0), 0);
                const porcentajeEjecucionActividad = totalMetaProyectada > 0 ? (totalValorEjecutado / totalMetaProyectada) * 100 : 0;

                return [{
                    actividadTitle: child.title,
                    actividad: child.actividad,
                    porcentajeEjecucionActividad: porcentajeEjecucionActividad.toFixed(1),
                    actividadData: actividadData
                }];
            }
            return [];
        });
    };

    const hideModalMedios = () => {
        setOpenModal(false);
    };

    const appName = 'poa';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionExport = permissions.priv_export === 'Y';
    const toggleTreeVisibility = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                loadPoaCorteData();
                const dataCompleta = await fetchPoaData();
                const { dataPoa, fechaCorte } = dataCompleta;
                setDataCompleta(dataCompleta);
                const data = dataPoa;
                if (fechaCorte && fechaCorte.length > 0) {
                    const fecha = fechaCorte[0]?.fecha;
                    if (fecha) {
                        const formattedFechaCorte = formatDateToLocal(fecha);
                        setFechaCorte(formattedFechaCorte);
                        setNewFechaCorte(dayjs(fecha, 'YYYY-MM-DD'));
                    }
                }

                const filteredData = data.map(item => {
                    if (item.children && item.children.length > 0) {
                        item.children = item.children.map(child => {
                            if (child.children && child.children.length > 0) {
                                child.children = child.children.filter(grandchild => !grandchild.children);
                            }
                            return child;
                        });
                    }

                    return item;
                });
                setTreeData(filteredData);
                setExpandedKeys(keys => {
                    if (!keys.includes('0')) {
                        return [...keys, '0', 'producto_1'];
                    }
                    return [...keys, 'producto_1'];
                });
                if (!expandAll && data.length > 0) {
                    const initialKeysToExpand = data.slice(0, 9).map(item => item.key);
                    setExpandedKeys(initialKeysToExpand);
                }
            } catch (error) {
                console.error('Error fetching file tree:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [expandAll]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleDateChange = (date) => {
        if (date && date.isValid()) {
            setNewFechaCorte(date);
        } else {
            console.error('Fecha inválida');
            setNewFechaCorte(dayjs());
        }
    };

    const handleSelect = async (selectedKeys, info) => {
        const selectedNode = info.node;
        if (selectedNode.tipo === 'Producto') {
            try {
                const data = await fetchPoaDataWithParams(selectedNode.key);
                if (data.status === 'success') {
                    const nodeFromDataCompleta = findNodeInData(data.dataPoa, selectedNode.key);
                    if (nodeFromDataCompleta) {
                        setSelectedItem(nodeFromDataCompleta);
                    }
                    setShowCards(false);
                } else {
                    console.error('Error en la respuesta del backend:', data.message);
                    setSelectedItem(null);
                    setShowCards(false);
                }
            } catch (error) {
                console.error('Error en la solicitud al backend:', error);
                setSelectedItem(null);
                setShowCards(false);
            }
        } else {
            message.warning('Solo se pueden seleccionar elementos de tipo "Producto".');
        }
    };

    const actualizarSelectedItem = async () => {
        if (selectedItem) {
            try {
                const data = await fetchPoaDataWithParams(selectedItem.key);
                if (data.status === 'success') {
                    const nodeFromDataCompleta = findNodeInData(data.dataPoa, selectedItem.key);
                    if (nodeFromDataCompleta) {
                        setSelectedItem(nodeFromDataCompleta);
                    }
                } else {
                    console.error('Error en la respuesta del backend:', data.message);
                }
            } catch (error) {
                console.error('Error en la solicitud al backend:', error);
            }
        }
    };

    const findNodeInData = (data, key) => {
        for (let item of data) {
            if (item.key === key) {
                return item;
            } else if (item.children) {
                const found = findNodeInData(item.children, key);
                if (found) return found;
            }
        }
        return null;
    };

    const toggleShowCards = () => {
        setShowCards(!showCards);
    };

    const toggleExpandC = () => {
        setControlExpandallC(prevState => !prevState);
    };

    const titleRenderer = (node) => {
        const tooltipTitle = node?.producto || node?.actividad || node?.tarea || node?.indicador_meta || node?.title || 'Sin título';
        return (
            <Tooltip title={tooltipTitle} color='#87d068' placement="right">
                <span className={node.highlighted ? 'highlightedNode' : ''}>{node.title}</span>
            </Tooltip>
        );
    };

    const findPathToNode = (treeData, key) => {
        const path = [];

        const findNode = (nodes, key) => {
            for (const node of nodes) {
                path.push(node);
                if (node.key === key) {
                    return true;
                }
                if (node.children && findNode(node.children, key)) {
                    return true;
                }
                path.pop();
            }
            return false;
        };

        findNode(treeData, key);
        return path;
    };

    const pathToNode = selectedItem ? findPathToNode(treeData, selectedItem.key) : [];

    function formatDateToLocal(fechaUTC) {
        const fecha = new Date(fechaUTC);
        const dia = String(fecha.getUTCDate()).padStart(2, '0');
        const mes = String(fecha.getUTCMonth() + 1).padStart(2, '0');
        const año = fecha.getUTCFullYear();
        return `${dia}/${mes}/${año}`;
    }

    const loadPoaCorteData = async () => {
        try {
            const data = await fetchPoaCorteData();
            if (data.length > 0) {
                const registro = data.find((item) => item.oid === 1);
                if (registro) {
                    const formattedFechaCorte = formatDateToLocal(registro.fecha);
                    setFechaCorte(formattedFechaCorte);
                    setNewFechaCorte(dayjs(registro.fecha, 'YYYY-MM-DD'));
                } else {
                    message.error('No se encontró el registro con OID 1.');
                }
            } else {
                message.warning('No hay datos disponibles.');
            }
        } catch (error) {
            console.error('Error al obtener los datos de poaCorte:', error.message);
            message.error('Error al obtener los datos.');
        }
    };

    const handleSaveDate = async () => {
        if (!newFechaCorte) {
            message.warning('Por favor, selecciona una fecha.');
            return;
        }
        try {
            await updatePoaCorteFecha(1, newFechaCorte.format('YYYY-MM-DD'));
            message.success('Fecha de corte actualizada exitosamente');
            setIsModalVisible(false);
            loadPoaCorteData();
        } catch (error) {
            console.error('Error al actualizar la fecha de corte:', error.message);
            message.error('Error al actualizar la fecha.');
        }
    };

    return (
        <div className="container">
            <Layout className="container">
                {loading ? (
                    <div className="text-center">
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className="container py-3">
                        <div className="row mb-2 d-flex align-items-center">
                            <Header160 />
                            <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                                <h2 className="text-center mb-2">UTF COL 160 COL</h2>
                                <h4>Fecha del corte: <span>{fechaCorte}</span></h4>
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                    {permissionUpdate && (
                                        <Tooltip title="Editar la fecha de corte">
                                            <Button
                                                type="primary"
                                                icon={<EditOutlined />}
                                                onClick={showModal}
                                                shape="circle"
                                                size="large"
                                            />
                                        </Tooltip>
                                    )}

                                    {permissionExport && (<Tooltip title="Exportar en Excel el POA"><ExportPoa /></Tooltip>)}
                                </div>
                                <Modal
                                    title="Editar Fecha de Corte"
                                    open={isModalVisible}
                                    onOk={handleSaveDate}
                                    onCancel={handleCancel}
                                >
                                    <DatePicker
                                        format="YYYY-MM-DD"
                                        value={newFechaCorte}
                                        onChange={handleDateChange}
                                        style={{ width: '100%' }}
                                    />
                                </Modal>
                            </div>
                        </div>
                        <div style={{ marginBottom: 8, textAlign: 'left' }}>
                            <h6 onClick={toggleTreeVisibility} style={{ cursor: 'pointer' }}>
                                POA: UTF COL 160 |
                                {collapsed ? <MenuUnfoldOutlined style={{ color: '#1677ff', paddingLeft: '5px' }} /> : <MenuFoldOutlined style={{ color: '#1677ff', paddingLeft: '5px' }} />}
                            </h6>
                        </div>
                        <Layout style={{ minHeight: '100vh' }}>
                            <Sider
                                width={230}
                                collapsedWidth={0}
                                className="site-layout-background"
                                collapsed={collapsed}
                                collapsible
                                trigger={null}
                                onCollapse={toggleTreeVisibility}
                                style={{ transition: 'all 0.3s' }}
                            >
                                {!collapsed && (
                                    <Tree
                                        treeData={treeData}
                                        expandedKeys={expandedKeys}
                                        onExpand={onExpand}
                                        onSelect={handleSelect}
                                        autoExpandParent={autoExpandParent}
                                        titleRender={titleRenderer}
                                        showLine={true}
                                    />
                                )}
                            </Sider>
                            <Layout
                                style={{
                                    flex: 1,
                                    padding: collapsed ? '0px 0px 0px' : '0px 24px 24px',
                                    transition: 'padding 0.3s'
                                }}
                            >
                                <Content
                                    className="site-layout-background"
                                    style={{
                                        padding: 0,
                                        margin: 0,
                                        minHeight: 200,
                                    }}
                                >
                                    {selectedItem && (
                                        <Space direction="vertical" style={{ width: '100%', display: 'block !important' }} size="large">
                                            <Row gutter={8} justify="center" align="middle">
                                                <Col span={24}>
                                                    <div>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ flex: 1, fontSize: '16px', fontWeight: 'bold' }}>
                                                                {selectedItem ?
                                                                    (selectedItem.producto || selectedItem.actividad || selectedItem.tarea || selectedItem.indicador || selectedItem.title || 'Sin título')
                                                                    : 'Sin elemento seleccionado'}
                                                            </div>
                                                            <div style={{ flex: '0 0 auto' }}>
                                                                <button
                                                                    onClick={toggleShowCards}
                                                                    className="btn py-0 m-0"
                                                                    style={{ fontSize: '15px', color: '#8dc145' }}
                                                                >
                                                                    <FontAwesomeIcon icon={showCards ? faInfoCircle : faQuestionCircle} style={{ marginLeft: '2px' }} />
                                                                </button>
                                                                <button
                                                                    onClick={toggleExpandC}
                                                                    className="btn py-0 m-0"
                                                                    style={{ fontSize: '15px', color: '#1677ff', marginLeft: '5px' }}
                                                                >
                                                                    {controlExpandallC ? <ExpandAltOutlined /> : <ShrinkOutlined />}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            {showCards && pathToNode.map((node, index) => (
                                                                node && (
                                                                    <Card
                                                                        key={index}
                                                                        className={`m-2 ${showCards ? 'card-transition' : 'card-hidden'}`}
                                                                        style={{ width: '300px', overflowY: 'auto' }}
                                                                    >
                                                                        <Card.Body>
                                                                            <Card.Title style={{ fontSize: '14px' }}>{node.producto || node.actividad || node.tarea || node.indicador || node.title || 'Sin título'}</Card.Title>
                                                                            {node.tipo === 'Fase' && <Card.Text>Fase: {node.title}</Card.Text>}
                                                                            {node.producto && <Card.Text>Producto: {node.codigo_producto}</Card.Text>}
                                                                            {node.actividad && <Card.Text>Código Actividad: {node.codigo_actividad}</Card.Text>}
                                                                            {node.tarea && <Card.Text>Código Tarea: {node.codigo_tarea}</Card.Text>}
                                                                            {node.tipo === 'Indicador' && <Card.Text>Código Indicador: {node.key}</Card.Text>}
                                                                        </Card.Body>
                                                                    </Card>
                                                                )
                                                            ))}
                                                        </div>
                                                        <PoaActividades nodo={selectedItem} actualizar={actualizarSelectedItem} expandAllC={controlExpandallC} />
                                                    </div>

                                                </Col>
                                            </Row>
                                        </Space>
                                    )}
                                    {openModal && (
                                    <div className="container-fluid py-3">
                                        {planPedagogico ? (
                                            <Modal
                                                title={<span className="custom-modal-title">Medios de verificación</span>}
                                                open={openModal}
                                                onCancel={hideModalMedios}
                                                footer={null}
                                                width="95%"
                                                style={{ top: 20 }}
                                            >
                                                <PlanPedagogicoI codigo_tarea={itemParams} />
                                            </Modal>
                                        ) : (
                                            <div className="d-flex justify-content-center">
                                                <Modal
                                                    title={<span className="custom-modal-title">Medios de verificación</span>}
                                                    open={openModal}
                                                    onCancel={hideModalMedios}
                                                    footer={null}
                                                    width="95%"
                                                    style={{ top: 20 }}
                                                >
                                                    <FileManagerPOA codigo_tarea={itemParams} />
                                                </Modal>
                                            </div>
                                        )}
                                    </div>
                                )}
                                </Content>
                            </Layout>
                        </Layout>
                    </div>
                )}
            </Layout >
        </div>
    );
};

export default Poa;
