import React, { useEffect, useState } from "react";
import { Drawer, Spin, List, Avatar, Row, Col, Card, Button, Tooltip, Input, Layout, Modal, message } from "antd";
import { CloseOutlined, HomeOutlined, ArrowLeftOutlined, TableOutlined } from "@ant-design/icons";
import ImageComponent from '../imageMapping/ImageComponent';
import { getProyectosPO, getComponentesPO, getDataProyectos } from '../../services/plaOperativo/planOperativoProyectosServices';
import PoaPlanOperativoTB from './PoaPlanOperativoTable';
import { viewFile } from '../../services/planesPedagogicos/planPedagogicoDowService';
import { fetchPoaData } from '../../services/poa/get_poa';
import FileManagerPOAN from './FileManagerPOAN';
import Participantes from '../Participantes/Participantes';
import Ippta from '../Ippta/Ippta';
import Edah from '../EncuestaEdah/Edah';
import AcuerdosComerciales from '../../components/AcuerdosComerciales/AcuerdosComerciales';
import { getPOPeso, getPOPesoResumen } from '../../services/plaOperativo/headerPlanOperativoServices';
import DashboardPlanOperativo from './DashboardPlanOperativo';

import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';

import TablaAvanceJSX from './TablaResumenAvance.jsx'
import { useParams } from "react-router-dom";
import ExportPoa from './ExportablePOA';
import PoaResumen from './PoaResumen';

import RepositorioDocumento from '../Inicio/Repositorio/RepositorioDocumento_old2.jsx';

const { Meta } = Card;


const PoaPlanOperativo = () => {
    const { id } = useParams();
    const [banderaLink, setBanderaLink] = useState(false);
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectProject, setSelectedProject] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [componentes, setComponentes] = useState([]);
    const [error] = useState(null);
    const [isModalVisible2, setIsModalVisible2] = useState(false);
    const [selectedFase2, setSelectedFase2] = useState(null);
    const [codigoProyecto, setCodigoProyecto] = useState(null);
    const [codProyecto, setCodProyecto] = useState(null);
    const [isModalVisibleParticipantes, setIsModalVisibleParticipantes] = useState(false);
    const [openModalVisitasEcas, setOpenModalVisitasEcas] = useState(false);
    const [itemParams, setItemParams] = useState(false);
    const [opcionFiltro, setOpcionFiltro] = useState(null);
    const [, setLoadingComponentes] = useState(false);
    const [valoresJsonInicial, setValoresJsonInicial] = useState([]);
    const [isVisibleR, setIsVisibleR] = useState(false);
    const [currentView, setCurrentView] = useState('lista'); // 'lista' o 'fase'
    const [dataResumen, setDataResumen] = useState([]);
    const [dataResumenPOA, setDataResumenPOA] = useState([]);
    const [mostrarPorcentajes, setMostrarPorcentajes] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);

    //Control de Permisos
    const appName = 'planOperativo';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionPrint = permissions.priv_print === 'Y';
    const permissionExport = permissions.priv_export === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';


    const user = useSelector((state) => state.auth.user);
    const userLogin = user.username;

    const onCloseDrawable = () => {
        setOpenDrawer(false);
        regresarModal();
      };

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const { data } = await getProyectosPO();
                const dataJSONPesoResumen = await getPOPesoResumen();
                setDataResumenPOA(dataJSONPesoResumen.data);
                if (id) {
                    const proyectoEncontrado = data.find(proyecto => proyecto.codigo === id);
                    if (proyectoEncontrado) {
                        setProjects(data);
                        handleOpenModal(proyectoEncontrado);
                        setBanderaLink(true);
                    } else {
                        message.warning(`El proyecto ${id} no existe.`);
                        setProjects(data);
                        setBanderaLink(false);
                    }
                } else {
                    setProjects(data);
                }
            } catch (error) {
                console.error("Error fetching projects:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, [id]);

    useEffect(() => {
        const fetchData = async () => {
            if (!codProyecto) return;
            setLoadingComponentes(true);
            setSelectedComponent(null);
            try {
                const response = await getComponentesPO(codProyecto);
                if (id && banderaLink) {
                    const componenteEncontrado = response.data.find(componente => componente.oid === 1);
                    setSelectedComponent(componenteEncontrado);
                    setComponentes(response.data);
                } else {
                    setComponentes(response.data);
                }
            } catch (error) {
                console.error("Error fetching components:", error);
            } finally {
                setLoadingComponentes(false);
            }
        };
        fetchData();
    }, [banderaLink, codProyecto, id]);


    function logTotPesoPorMesAnioPorFase(dataJSONPeso) {
        // Objeto que guardará los acumuladores por fase, subdivididos en P y E.
        // Ej: acumuladoresPorFase[faseId] = { P: {}, E: {} }
        const acumuladoresPorFase = {};
        // Recorremos las "fases" (elementos del array dataJSONPeso)
        dataJSONPeso.forEach((fase) => {
            const faseId = fase.fase_id || "fase-sin-id";
            // Si no existe, inicializamos la estructura con P y E vacíos
            if (!acumuladoresPorFase[faseId]) {
                acumuladoresPorFase[faseId] = {
                    P: {},
                    E: {},
                };
            }
            if (!fase.children) return; // Sin actividades, skip
            fase.children.forEach((tarea) => {
                // Ajusta si tu "pesoPorcentual" es realmente un factor o un %.
                // Aquí supongo que lo usas "tal cual":
                const pesoEntero = parseFloat(tarea.pesoPorcentual) || 0;
                const peso = pesoEntero / 1; // o /100 si en BD 3 significa 3%
                if (!tarea.children) return; // Sin indicadores, skip
                tarea.children.forEach((indicador) => {
                    const meta = parseFloat(indicador.meta) || 0;
                    if (meta <= 0 || peso <= 0) return; // Evitar /0
                    // --- PROYECTADO (P) ---
                    if (indicador.cronogramasP) {
                        indicador.cronogramasP.forEach((crono) => {
                            const p = parseFloat(crono.avance) || 0;
                            const mes = crono.mes;
                            const ano = crono.ano;
                            const keyMesAnio = `${mes}-${ano}`;
                            const parcial = (p / meta) * peso;
                            if (!acumuladoresPorFase[faseId].P[keyMesAnio]) {
                                acumuladoresPorFase[faseId].P[keyMesAnio] = 0;
                            }
                            acumuladoresPorFase[faseId].P[keyMesAnio] += parcial;
                        });
                    }
                    // --- EJECUTADO (E) ---
                    if (indicador.cronogramasE) {
                        indicador.cronogramasE.forEach((crono) => {
                            const e = parseFloat(crono.avance) || 0;
                            const mes = crono.mes;
                            const ano = crono.ano;
                            const keyMesAnio = `${mes}-${ano}`;
                            const parcial = (e / meta) * peso;
                            if (!acumuladoresPorFase[faseId].E[keyMesAnio]) {
                                acumuladoresPorFase[faseId].E[keyMesAnio] = 0;
                            }
                            acumuladoresPorFase[faseId].E[keyMesAnio] += parcial;
                        });
                    }
                });
            });
        });
        // *** REDONDEO AL FINAL ***
        // Recorremos cada fase, y dentro de cada fase, cada tipo (P, E) y cada "mes-año"
        for (const faseId in acumuladoresPorFase) {
            for (const tipo of ["P", "E"]) {
                for (const keyMesAnio in acumuladoresPorFase[faseId][tipo]) {
                    const valorAcumulado = acumuladoresPorFase[faseId][tipo][keyMesAnio];
                    // Redondear a 2 decimales
                    acumuladoresPorFase[faseId][tipo][keyMesAnio] = Number(
                        valorAcumulado.toFixed(2)
                    );
                }
            }
        }
        // Ahora construimos un objeto JSON con el formato deseado
        // (ej. "fase" y luego "p" y "e" separadas)
        const resultado = { fases: [] };
        for (const [faseId, objPE] of Object.entries(acumuladoresPorFase)) {
            // objPE = { P: {...}, E: {...} }
            resultado.fases.push({
                fase: faseId,
                p: objPE.P,
                e: objPE.E,
            });
        }

        return resultado;
    }

    const toggleVisibilityDetalle = () => {
        setIsVisibleR(!isVisibleR); // Cambiar el estado entre visible y oculto
    };

    const filteredProjects = projects.filter((project) =>
        project.proyecto.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleOpenModal = async (project) => {
        setCodProyecto(project.oid);
        setSelectedProject(project);
        setIsModalVisible(true);
        const dataJSONPeso = await getPOPeso(0, project.oid, 'inicial');
        const dataDashboard = await getDataProyectos(project.oid);
        setLoading(true);
        try {
            const { status, data: body } = dataJSONPeso;
            if (status === 200 && body.status === 200 && Array.isArray(body.data)) {

                const pesoArray = body.data;

                // Procesa el JSON y asigna el resultado al estado
                const valoresProcesados = logTotPesoPorMesAnioPorFase(pesoArray);
                setValoresJsonInicial(valoresProcesados);
                setDataResumen(dataDashboard.data.dataProyecto);

            } else {
                throw new Error("Los datos de Peso no son válidos");
            }
        } catch (error) {
            console.error("Error al obtener datos de Peso:", error);
        } finally {
            // Se desactiva el estado de carga, sin importar si hubo error o no
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setIsVisibleR(false);
        setCurrentView('lista');
    };

    const regresarModal = () => {
        setIsModalVisible(true);
        //setSelectedComponent(null);
        setIsVisibleR(false);
        setCurrentView('lista');
    };

    useEffect(() => {
        if (selectProject) {
            console.log("Proyecto actualizado:", selectProject.codigo);
            setCodigoProyecto(selectProject.codigo);
        }
    }, [selectProject]);  // Se ejecuta cada vez que `selectProject` cambia

    const handleCardClick = (component) => {
        setCurrentView('lista');
        if (component.oid === 0) {
            downloadFileHandler();
        } else if (component.oid === 1) {
            setSelectedComponent(component);
            setCurrentView('fase');
        } else if (component.oid === 3) {
            setOpcionFiltro(1);
            setCodigoProyecto(selectProject.codigo);
            setIsModalVisibleParticipantes(true);
        } else if (component.oid === 4) {
            procesarMpoa("2.2.1.3") // Visitas
        } else if (component.oid === 5) {
            procesarMpoa("2.2.3") // Ecas
        } else if (component.oid === 6) {
            procesarMpoa("2.1.4") // Actas
        } else if (component.oid === 7) {
            setOpcionFiltro(2);
            setCodigoProyecto(selectProject.codigo);

            setIsModalVisibleParticipantes(true);
        } else if (component.oid === 8) {
            setOpcionFiltro(3);
            setCodigoProyecto(selectProject.codigo);
            setIsModalVisibleParticipantes(true);
        } else if (component.oid === 9) {
            setOpcionFiltro(4);
            setCodigoProyecto(selectProject.codigo);
            setIsModalVisibleParticipantes(true);
        } else if (component.oid === 13) {
            console.log("Repositorio de Documentos", selectProject.codigo);
            console.log(selectProject.codigo)
            setCodigoProyecto(selectProject.codigo);
            setOpenDrawer(true);
            handleCloseModal();
        }
    };

    const procesarMpoa = async (Mpoa) => {
        if (Mpoa) {
            if (Mpoa === '2.2.1') {
                return null;
            }
            const manejarActividadEspecial = (actividad) => {
                switch (actividad) {
                    case '2.2.1.1':
                        return '2.2.1';
                    case '2.2.1.2':
                        return '2.2.1';
                    case '2.2.1.3':
                        return '2.2.1';
                    default:
                        return actividad;
                }
            };
            const actividadBase = manejarActividadEspecial(Mpoa);
            try {
                const dataCompleta = await fetchPoaData();
                const dataPoa = dataCompleta.dataPoa;
                const findNodePath = (node, path = []) => {
                    path.push(node);
                    if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        return path;
                    }
                    if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        if (node.children && node.children.length > 0) {
                            for (let child of node.children) {
                                if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                                    path.push(child);
                                    return path;
                                }
                                if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                                    path.push(child);
                                    return path;
                                }
                            }
                        }
                    }

                    if (node.children && node.children.length > 0) {
                        for (let child of node.children) {
                            const result = findNodePath(child, [...path]);
                            if (result) return result;
                        }
                    }
                    return null;
                };
                let matchedPath = null;

                for (let nodo of dataPoa) {
                    matchedPath = findNodePath(nodo);
                    if (matchedPath) break;
                }
                if (matchedPath) {
                    const initialData = extractData(matchedPath);
                    const actividadData = initialData[0].actividadData;
                    const tareaEncontrada = actividadData.find((item) => {
                        if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                            return (
                                item.codigo_tarea === actividadBase &&
                                ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                                    (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
                            );
                        }
                        return item.codigo_tarea === actividadBase;
                    });
                    if (tareaEncontrada) {
                        tareaEncontrada.proyecto = selectProject.codigo;
                        setOpenModalVisitasEcas(true);
                        setItemParams(tareaEncontrada);
                    } else {
                        setOpenModalVisitasEcas(false);
                        setItemParams(null);
                    }
                } else {
                    console.warn("No se encontró ningún nodo coincidente");
                }
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            }
        } else {
            setOpenModalVisitasEcas(false);
        }
    };

    const extractData = (children) => {
        return children.flatMap(child => {
            if (child.children && child.tipo === 'Actividad') {
                const actividadData = child.children.flatMap(grandchild => {
                    if (grandchild.children && grandchild.tipo === 'Tarea') {
                        return grandchild.children.flatMap(greatGrandchild => {
                            if (greatGrandchild.tipo === 'Indicador') {
                                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                                const medioV = greatGrandchild.medioVerificacion || null;
                                return {
                                    key: greatGrandchild.key,
                                    codigo_tarea: grandchild.codigo_tarea,
                                    codActividad: grandchild.codActividad,
                                    nameActividad: child.actividad,
                                    tarea: grandchild.tarea,
                                    indicador: greatGrandchild.title,
                                    medioV: medioV,
                                    fechas: fechaFormateada,
                                    responsables: 'N/A',
                                    soportes: 'N/A',
                                    datoIndicador: greatGrandchild,
                                };
                            }
                            return [];
                        });
                    }
                    return [];
                });
                return [{
                    actividadTitle: child.title,
                    actividad: child.actividad,
                    actividadData: actividadData
                }];
            }
            return [];
        });
    };

    const hideModalVisitasEcas = () => {
        setOpenModalVisitasEcas(false);
    };

    const handleCancelParticipantes = () => {
        setIsModalVisibleParticipantes(false);
    };

    const downloadFileHandler = async () => {
        const loadingMessage = message.loading('Cargando archivo...', 0);
        try {
            const proyect = selectProject.codigo;
            const ruta = `/DocumentosActualizacion/${proyect}/Proyecto/Factibilidad Actualizada PDF/`;
            await viewFile(ruta);
            loadingMessage();
            message.success('Archivo cargado correctamente!', 2);
        } catch (error) {
            console.error('Error al abrir el archivo:', error);
            loadingMessage();
            message.error('No se encontró el archivo.', 2);
        }
    };

    const handleBackClick = () => {
        setSelectedComponent(null);
        setIsVisibleR(false)
        setCurrentView('lista');
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const handleFaseClick2 = (parentComponent, fase) => {
        setSelectedFase2(fase);
        setIsModalVisible2(true);
        handleCloseModal();
        setSelectedComponent(parentComponent);
        setCurrentView('fase'); // Cambia la vista actual
    };

    const handleCloseModal2 = () => {
        setIsModalVisible2(false);
        //setSelectedProject(null);
        setCurrentView('lista')
    };

    const renderComponent = () => {
        switch (opcionFiltro) {
            case 1:
                return <Participantes proyectoCodigo={codigoProyecto} />;
            case 2:
                return <Ippta proyectoCodigo={codigoProyecto} />;
            case 3:
                return <Edah proyectoCodigo={codigoProyecto} />;
            case 4:
                return <AcuerdosComerciales proyectoCodigo={codigoProyecto} />;
            default:
                return null;
        }
    };


    return (
        <div className="container">
            <Layout>
                <div className="row mb-2 d-flex align-items-center">
                    <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                        <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                            <p>PROYECTO UTF <br /> COL 160 COL</p>
                        </div>
                        <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                            <p>
                                Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
                                institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                        <h2 className="text-center mb-2">Implementación</h2>


                        {permissionExport && (
                            <Button
                                onClick={() => setMostrarPorcentajes(!mostrarPorcentajes)}
                                type="primary"
                                style={{ height: 40 }}
                            >
                                {mostrarPorcentajes ? "Volver a la Vista Principal" : "Porcentajes de Avance POA´s"}
                            </Button>
                        )}
                    </div>
                </div>

                {/* Vista condicional */}
                {mostrarPorcentajes ? (
                    <div>
                        {/* Aquí va la nueva vista de Porcentajes de Avance POA´s */}

                        <PoaResumen data={dataResumenPOA?.data} />

                    </div>
                ) : (

                    <>
                        <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                            <Col span={24}>
                                <Input
                                    placeholder="Buscar por nombre"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ width: 400, height: 40, marginRight: 8 }}
                                />
                                <Button
                                    onClick={() => setSearchTerm("")}
                                    type="primary"
                                    style={{ height: 40 }}
                                >
                                    Limpiar Filtro
                                </Button>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]} className="proyecto-card-unique-row">
                            {loading ? (
                                <div>Cargando proyectos...</div>
                            ) : (
                                filteredProjects.map((project) => (
                                    <Col
                                        key={project.oid}
                                        xs={24}
                                        sm={12}
                                        md={8}
                                        lg={8}
                                        xl={6}
                                        className="proyecto-card-unique-col"
                                    >
                                        <Card
                                            className="card-menu h-100 redondear card-header align-items-center text-center"
                                            style={{
                                                body: {
                                                    height: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                },
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: "60px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "10px",
                                                    textAlign: "center",
                                                    borderRadius: "5px",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <div >
                                                    <h6 className="card-title fw-bold fs-8 m-0" style={{ color: '#FFF !important' }}>{project.proyecto}</h6>
                                                    <p className="card-text"><small className="codproyecto">Código: {project.codigo}</small></p>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    flex: 1,
                                                    width: "100%",
                                                    overflow: "hidden",
                                                }}
                                            >
                                                <Row gutter={16} style={{ width: "100%", justifyContent: "center" }}>
                                                    <div className="col-md-12" style={{ maxHeight: '130px', width: '100%', height: '100%' }}>
                                                        <ImageComponent
                                                            projectId={project.oid} />
                                                    </div>
                                                </Row>
                                            </div>
                                            <div
                                                className="text-muted mt-auto"
                                                style={{
                                                    display: "flex",
                                                    backgroundColor: "#fff",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginTop: "auto",
                                                    padding: "10px",
                                                }}
                                            >
                                                <Tooltip title="Ingresar">
                                                    <Button
                                                        className="m-2 btn-primary-lis"
                                                        onClick={() => handleOpenModal(project)}
                                                        style={{
                                                            background: "none",
                                                            border: "none",
                                                            color: "inherit",
                                                        }}
                                                        icon={<HomeOutlined style={{ fontSize: 20, color: "#1890ff" }} />}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Card>
                                    </Col>
                                ))
                            )}
                        </Row>
                        {selectProject && (
                            <Drawer
                                title={
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <h4 style={{ margin: 0, fontWeight: 'bold' }}>
                                            {selectProject.codigo} - {selectProject.proyecto}
                                        </h4>

                                        <div>
                                            <Button
                                                icon={<CloseOutlined />}
                                                onClick={handleCloseModal}
                                                style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    border: 'none',
                                                    boxShadow: 'none',
                                                }}
                                            />
                                            {selectedComponent && (
                                                <Button
                                                    icon={<ArrowLeftOutlined />}
                                                    onClick={handleBackClick}
                                                    style={{
                                                        position: 'absolute',
                                                        right: 40,
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        border: 'none',
                                                        boxShadow: 'none',
                                                    }}
                                                >
                                                    Regresar
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                }
                                placement="right"
                                onClose={handleCloseModal}
                                open={isModalVisible}
                                width={'100%'}
                                destroyOnClose
                            >
                                <div style={{ paddingTop: '20px' }}>
                                    {currentView === 'lista' && (
                                        <div style={{ marginBottom: 24 }}>
                                            <h3 style={{ margin: 0, fontWeight: 500 }}>Selecciona una opción</h3>
                                            <p style={{ margin: 0, color: '#999' }}>
                                                Consulta toda la información disponible del proyecto: revisa el Plan Operativo Anual (POA), visualiza los soportes de las visitas, accede al listado completo de participantes y conoce en detalle las Sesiones ECA´s, entre otros recursos. Con esta sección, tendrás una visión integral del avance y la organización de tu proyecto.
                                            </p>
                                        </div>
                                    )}
                                    {currentView === 'lista' ? (

                                        <List
                                            style={{ padding: '16px' }}
                                            grid={{ gutter: 16, column: 4 }} // Se comporta como un grid
                                            dataSource={componentes}
                                            renderItem={(item) => {
                                                const isDisabled = item.estado === 2; // Condición para deshabilitar
                                                return (
                                                    <List.Item
                                                        style={{
                                                            cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                            transition: 'background-color 0.3s ease',
                                                            padding: '12px',
                                                            backgroundColor: isDisabled ? '#d3d3d3' : 'transparent',
                                                        }}
                                                        onMouseEnter={(e) => {
                                                            if (!isDisabled) {
                                                                e.currentTarget.style.backgroundColor = '#f0f0f0';
                                                            }
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            if (!isDisabled) {
                                                                e.currentTarget.style.backgroundColor = 'transparent';
                                                            }
                                                        }}
                                                        onClick={() => {
                                                            if (!isDisabled) {
                                                                handleCardClick(item);
                                                            }
                                                        }}
                                                    >
                                                        <Tooltip title={item.nombre_largo}>
                                                            <List.Item.Meta
                                                                avatar={
                                                                    <Avatar
                                                                        shape="square"
                                                                        style={{
                                                                            backgroundColor: item.color || '#d3d3d3',
                                                                            width: 64,
                                                                            height: 64,
                                                                            fontSize: 20,
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            color: '#000',
                                                                        }}
                                                                    >
                                                                        {item.sigla}
                                                                    </Avatar>
                                                                }
                                                                title={item.nombre_corto}
                                                                description={item.descripcion || 'Descripción breve...'}
                                                            />
                                                        </Tooltip>
                                                    </List.Item>
                                                );
                                            }}
                                        />

                                    ) : (
                                        // Aquí renderizas la vista específica de la fase seleccionada
                                        <div style={{ marginBottom: 24, width: '100%' }}>
                                            <div style={{ marginBottom: 24 }}>
                                                <h3 style={{ margin: 0, fontWeight: 500 }}>Plan Operativo Anual (POA)</h3>
                                                <p style={{ margin: 0, color: '#999' }}>
                                                    Accede a la información disponible del Plan Operativo Anual (POA)
                                                </p>
                                            </div>
                                            <Row gutter={[16, 16]}>
                                                {selectedComponent?.fases?.length > 0 ? (
                                                    selectedComponent.fases.map((fase) => (

                                                        <Col xs={24} sm={12} md={8} key={fase.oid}>
                                                            <Tooltip
                                                                overlayStyle={fase.estado === 2 ? { minWidth: "76em" } : {}}
                                                                title={fase.estado === 2 ? fase.comentario : fase.nombre_corto}
                                                                color={fase.estado === 2 ? "#000" : "blue"}
                                                            >
                                                                <Card
                                                                    hoverable={fase.estado !== 2}
                                                                    style={{
                                                                        borderRadius: "12px",
                                                                        textAlign: "center",
                                                                        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                                                        cursor: fase.estado === 2 ? "not-allowed" : "pointer",
                                                                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                                                        paddingTop: '15px',
                                                                    }}
                                                                    onClick={() =>
                                                                        fase.estado !== 2 && handleFaseClick2(selectedComponent, fase)
                                                                    }
                                                                    onMouseEnter={(e) => {
                                                                        if (fase.estado !== 2) {
                                                                            e.currentTarget.style.transform = "translateY(-4px)";
                                                                            e.currentTarget.style.boxShadow = "0 8px 16px rgba(0,0,0,0.2)";
                                                                        }
                                                                    }}
                                                                    onMouseLeave={(e) => {
                                                                        e.currentTarget.style.transform = "translateY(0)";
                                                                        e.currentTarget.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.1)";
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            width: "80px",
                                                                            height: "80px",
                                                                            borderRadius: "50%",
                                                                            margin: "0 auto 10px",
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            fontSize: "24px",
                                                                            fontWeight: "bold",
                                                                            backgroundColor: fase.estado === 2 ? "#d3d3d3" : fase.color,
                                                                            color: "#000",
                                                                        }}
                                                                    >
                                                                        {fase.sigla}
                                                                    </div>
                                                                    <h5 style={{ margin: "0 0 8px" }}>{fase.nombre_corto}</h5>
                                                                    <p style={{ color: "gray", margin: 0, fontSize: "14px" }}>
                                                                        {fase.nombre_largo}
                                                                    </p>
                                                                </Card>
                                                            </Tooltip>
                                                        </Col>

                                                    ))
                                                ) : (
                                                    <div>No hay fases disponibles</div>
                                                )}
                                            </Row>
                                            <Row justify="left" style={{ marginBottom: "20px", marginTop: '20px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <h3 style={{ margin: 0 }}>Resumen de avance</h3>
                                                    <Tooltip title="Mostrar u ocultar el resumen de avance en la ejecución.">
                                                        <TableOutlined
                                                            style={{
                                                                marginLeft: '16px',
                                                                color: toggleVisibilityDetalle ? 'green' : 'gray',
                                                                fontSize: '20px'
                                                            }}
                                                            onClick={toggleVisibilityDetalle}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                {isVisibleR && (
                                                    loading ? (
                                                        <Spin tip="Cargando..." />
                                                    ) : (
                                                        <div style={{ overflowX: 'auto' }}>
                                                            <TablaAvanceJSX valoresJsonPeso={valoresJsonInicial} modo="inicial" />
                                                        </div>
                                                    )
                                                )}
                                            </Row>
                                            <Row justify="center" style={{ marginBottom: "20px" }}>
                                                <Button
                                                    icon={<ArrowLeftOutlined />}
                                                    onClick={handleBackClick}
                                                    style={{ marginRight: "10px" }} // Agrega espacio a la derecha
                                                >
                                                    Regresar
                                                </Button>
                                                <ExportPoa selectedProject={codProyecto} valoresJsonPeso={valoresJsonInicial} />
                                            </Row>
                                        </div>
                                    )}
                                    {permissionPrint && (
                                        <>
                                            {/* Dashboard */}
                                            {dataResumen && Object.keys(dataResumen).length > 0 && currentView === 'lista' ? (
                                                <DashboardPlanOperativo dataProyecto={dataResumen} codigoProyecto={selectProject.oid} />
                                            ) : (
                                                <>
                                                    {currentView === 'lista' && (
                                                        <p>Dashboard.</p>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            </Drawer >
                        )}

                    </>)}

                <Modal
                    title=""
                    open={isModalVisible2}
                    onCancel={handleCloseModal2}
                    footer={null}
                    width="95%"
                    style={{ top: 10 }}
                >
                    <PoaPlanOperativoTB selectedFase={selectedFase2} selectedProject={selectProject} regresarAnterior={regresarModal} cerrarModal={handleCloseModal2} />
                </Modal>
                <Modal
                    title=""
                    open={isModalVisibleParticipantes}
                    onCancel={handleCancelParticipantes}
                    footer={null}
                    width="95%"
                    style={{ top: 10 }}
                >
                    {renderComponent()}
                </Modal>
                <div className="d-flex justify-content-center">
                    <Modal
                        title={<span className="custom-modal-title">Medios de verificación</span>}
                        open={openModalVisitasEcas}
                        onCancel={hideModalVisitasEcas}
                        footer={null}
                        width="95%"
                        style={{ top: 20 }}
                    >
                        <FileManagerPOAN codigo_tarea={itemParams} />
                    </Modal>
                </div>
                <div id="exportable-section">
                    {codigoProyecto && <RepositorioDocumento open={openDrawer} onCloseMetodo={onCloseDrawable} proyectoCodigo={codigoProyecto} />}
                </div>
            </Layout >
        </div >
    );
};
export default PoaPlanOperativo;
