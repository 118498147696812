import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin

// Registrar elementos de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const GraficaBarrasHorizontal = ({ titulo, etiquetas, datos, colores }) => {
    const data = {
        labels: etiquetas,
        datasets: datos.map((item, index) => ({
            label: item.label,
            data: item.valores,
            backgroundColor: colores[index % colores.length],
            borderRadius: 5,
            barThickness: "flex",
        })),
    };

    const options = {
        indexAxis: "y", // ✅ Esto hace que las barras sean **horizontales**
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
                labels: { font: { size: 14, weight: "bold" } },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.raw} visitas`;
                    },
                },
            },
            // ✅ Mostrar valores dentro de las barras
            datalabels: {
                display: true,
                color: "#000 !important", // Color del texto
                textStrokeColor: "rgba(171, 169, 169, 0.6)", // Agrega borde para mejorar visibilidad
                textStrokeWidth: 2,
                anchor: "end",
                align: "right",
                font: { weight: "bold", size: 12 },
                formatter: (value) => (value !== 0 ? value : ""),
                clip: false,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                ticks: { font: { size: 14 }, stepSize: 1 },
            },
            y: {
                ticks: {
                    font: { size: 12, weight: "bold" },
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
        },
    };

    return (
        <div style={{ textAlign: "center", width: "100%", height: "350px", padding: "10px" }}>
            <h4 style={{ fontWeight: "bold", marginBottom: "10px" }}>{titulo}</h4>
            <Bar data={data} options={options} />
        </div>
    );
};

export default GraficaBarrasHorizontal;
