import React, { useState } from 'react';
import { Input, DatePicker, message, Modal } from 'antd';
import moment from 'moment';

const EditableCell = ({ title, dataIndex, editable, children, value, record, handleSave, dataType, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [datavalue, setDataValue] = useState(false);

  const handleChange = (e) => {
    const input = e.target.value;
    setDataValue(true);
    if (/^\d*$/.test(input)) {
      setInputValue(input);
    } else {
      message.warning('Solo se permiten números.');
    }
  };

  const save = () => {
    const finalValue = /^\d+$/.test(inputValue) ? inputValue : '0';
    if (datavalue && finalValue !== inputValue) {
      message.info('El valor no era válido y se ha restablecido a 0.');
    }
    if (datavalue) {
      setEditing(false);
      handleSave({ ...record, [dataIndex]: finalValue });
    }
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : '';
    handleSave({ ...record, fechaFinReal: formattedDate });
    setEditing(false);
  };

  const confirmEdit = () => {
    Modal.confirm({
      title: 'Confirmar edición',
      content: '¿Estás seguro de que deseas modificar este dato?',
      okText: 'Sí, editar',
      cancelText: 'Cancelar',
      onOk() {
        setDataValue(false);
        setEditing(true);
      },
    });
  };

  return (
    <td {...restProps}>
      {editable ? (
        editing ? (
          dataType === 'date' ? (
            <DatePicker
              value={inputValue && moment(inputValue, 'YYYY-MM-DD', true).isValid() ? moment(inputValue, 'YYYY-MM-DD') : null}
              onChange={handleDateChange}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleDateChange(inputValue ? moment(inputValue, 'YYYY-MM-DD') : null);
                  setEditing(false);
                }
              }}
              open={true}
              autoFocus
            />
          ) : (
            <Input
              value={inputValue}
              onChange={handleChange}
              onBlur={save} // Solo guarda con Enter
              autoFocus
              inputMode="numeric"
              pattern="\d*"
            />
          )
        ) : (
          <div
            className="editable-cell-value-wrap"
            onClick={confirmEdit}
          >
            {children || 'Sin fecha'}
          </div>
        )
      ) : (
        children || 'Sin fecha'
      )}
    </td>
  );
};

export default EditableCell;
