import axios from "axios";
import config from "../../../config"; // Asegúrate de que la ruta sea correcta

const baseUrl = config.baseUrl;
const endpoint = `${baseUrl}/api/repositorioPOA`;

const RepositorioPOAProyecto = {
  getDato: async (proyectoID) => {
    try {
      const response = await axios.get(`${endpoint}/${proyectoID}`, {
        timeout: 5000, // 5 segundos de espera
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        console.error(
          `Error en la solicitud: ${error.response.status} - ${error.response.statusText}`,
          error.response.data
        );
      } else if (error.request) {
        console.error("Error: No se recibió respuesta del servidor.", error.request);
      } else {
        console.error("Error en la configuración de la solicitud:", error.message);
      }
      throw error;
    }
  },
};

export default RepositorioPOAProyecto;
