import React from "react";
import { Radar } from "react-chartjs-2";
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from "chart.js";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const AvanceProyectosRadar = ({ data }) => {
    // Extraer los valores
    const proyectos = data.map((item) => item.codigo);


    const avanceProgramado = data.map((item) => Math.round(parseFloat(item.avanceProgramado)));
    const avanceEjecutado = data.map((item) => Math.round(parseFloat(item.avanceEjecutado)));

    const conversionData = [
        { rango: "0% - 24%", color: "rgba(242, 0, 19, 0.3)", descripcion: "Bajo", min: 0, max: 24 },
        { rango: "25% - 64%", color: "rgba(255, 165, 0, 0.3)", descripcion: "Medio", min: 25, max: 64 },
        { rango: "65% - 100%", color: "rgba(25, 135, 84, 0.3)", descripcion: "Alto", min: 65, max: 100 }
    ];



    // Función para obtener el color según el avance ejecutado
    const getColorForValue = (value) => {
        const range = conversionData.find(({ min, max }) => value >= min && value <= max);
        return range ? range.color : "#198754"; // Default a negro si no hay coincidencias
    };


    // Definir datos para Chart.js
    const chartData = {
        labels: proyectos,
        datasets: [
            {
                label: "Avance Programado (%)",
                data: avanceProgramado,
                borderColor: "#118fe9",
                backgroundColor: "rgba(54, 162, 235, 0.3)",
                pointRadius: avanceProgramado.map((_, index) => (index % 2 === 0 ? 5 : 0)), // Alterna puntos visibles
                metadata: data.map((item) => item.fecha_actualizacion) // Agregamos fechas
            },
            {
                label: "Avance Ejecutado (%)",
                data: avanceEjecutado,
                borderColor: avanceEjecutado.map(value => getColorForValue(value)), // Borde de los puntos
                backgroundColor: avanceEjecutado.map(value => getColorForValue(value)), // Color de los puntos
                pointRadius: avanceEjecutado.map((_, index) => (index % 2 === 0 ? 5 : 0)), // Alterna puntos visibles
                metadata: data.map((item) => item.fecha_actualizacion) // Agregamos fechas
            },
        ],
    };

    // Opciones del gráfico
    const options = {
        responsive: true,
        scales: {
            r: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    font: {
                        size: 14, // Tamaño de fuente más grande

                    },
                    color: "#333", // Color oscuro para mejor contraste
                },
                pointLabels: {
                    font: {
                        size: 14, // Ajustar tamaño de etiquetas

                    },
                    color: "#333",
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
                labels: {
                    font: {
                        size: 14,
                    },
                    color: "#333",
                },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        const index = tooltipItem.dataIndex;
                        const fechaActualizacion = tooltipItem.dataset.metadata[index];
                        return [
                            `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(0)}%`,
                            `Actualizado: ${fechaActualizacion}`,
                        ];
                    },
                },
            },
        },
    };


    return (
        <div style={{ width: "80%", height: "80%", paddingBottom: "40px" }}>
            <Radar data={chartData} options={options} />
        </div>
    );
};

export default AvanceProyectosRadar;
