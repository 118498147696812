import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin

// Registrar elementos de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const GraficaBarras = ({ titulo, etiquetas, datos, colores }) => {
    const dynamicHeight = Math.max(300, etiquetas.length * 20);

    const data = {
        labels: etiquetas,
        datasets: datos.map((item, index) => ({
            label: item.label,
            data: item.valores,
            backgroundColor: colores[index % colores.length],
            borderRadius: 5,
            barThickness: "flex",
        })),
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
                labels: { font: { size: 14, weight: "bold" } },
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.raw} participantes`;
                    },
                },
            },
            // ✅ Habilitar DataLabels para mostrar los valores dentro de las barras
            datalabels: {
                display: true,
                color: "#FFF", // ✅ Asegurar que el texto sea blanco
                textStrokeColor: "rgba(0, 0, 0, 0.6)", // ✅ Agregar un borde oscuro para mejorar visibilidad
                textStrokeWidth: 2, // ✅ Grosor del borde
                anchor: "center", // Centrar el número dentro de la barra
                align: "center",
                font: { weight: "bold", size: 12 },
                formatter: Math.round, // ✅ Asegurar que el número sea entero
                clip: false, // ✅ Evitar que los números sean recortados
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                ticks: { font: { size: 14 }, stepSize: 10 },
            },
            x: {
                ticks: {
                    font: { size: 12, weight: "bold" },
                    autoSkip: false,
                    maxRotation: 45,
                    minRotation: 30,
                },
            },
        },
    };

    return (
        <div style={{ textAlign: "center", top: "0px" }}>
            <h3 style={{ fontWeight: "bold" }}>{titulo}</h3>
            <div style={{ textAlign: "center", width: "400px", height: `${dynamicHeight}px`, padding: "0px" }}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default GraficaBarras;
