import axios from 'axios';
import config from '../../../config'; // Configuración para obtener la URL base

// Obtener la URL base desde el archivo de configuración
const baseUrl = config.baseUrl;
const endpoint = '/api/subregionesmunicipios';
const endpointSet = '/api/registrarcohorte'; // Endpoint completo para las asistencias
const endpointSetAll = '/api/cohortes'; // Endpoint completo para las asistencias
const endpointUpdate = '/api/updatecohorte'; // Endpoint completo para las asistencias
const endpointDelete = '/api/eliminarcohorte'; // Endpoint completo para las asistencias

const API_URL = `${baseUrl}${endpoint}`;
const API_URLSET = `${baseUrl}${endpointSet}`;
const API_URLGetALL = `${baseUrl}${endpointSetAll}`;
const API_URL_Update = `${baseUrl}${endpointUpdate}`;

const API_URL_Delete = `${baseUrl}${endpointDelete}`;


// Función para obtener Subregiones
export const getRegiones = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data; // Retorna los datos de la respuesta

    } catch (error) {
        console.error('Error al obtener las Regiones:', error);
        throw error;
    }
};


// Función para registrar los datos de cohortes
export const setCohorte = async (userName, jsonDataCohorte) => {
    try {
        const payload = { userName, jsonDataCohorte };
        const response = await axios.post(API_URLSET, payload);
        return response.data;
    } catch (error) {
        console.error('Error al crear el registro:', error);
        throw error;
    }
};




// Función para obtener Cohorte
export const getCohorte = async () => {
    try {
        const response = await axios.get(API_URLGetALL);
        return response.data; // Retorna los datos de la respuesta

    } catch (error) {
        console.error('Error al obtener las asistencias:', error);
        throw error;
    }
};

// updateCohorte(userLogin, oid,registroActual, data)
export async function updateCohorte(userLogin, oid, registroActual, data) {

    const userName = typeof userLogin === 'object' ? userLogin.username : userLogin;

    const payload = {
        registroActual, // datos anteriores, para el log de cambios
        nuevosDatos: data,
        userName: userName
    };

    const response = await axios.put(`${API_URL_Update}/${oid}`, payload, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response.data;
}


export async function deleteCohorte(userLogin, oid, registroActual) {
    const userName = typeof userLogin === 'object' ? userLogin.username : userLogin;

    const payload = {
        registroActual, // datos anteriores, para el log de cambios
        userName: userName
    };

    try {
        const response = await axios.delete(`${API_URL_Delete}/${oid}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload // Enviar el payload dentro de "data"
        });

        return response.data;
    } catch (error) {
        console.error('Error eliminando cohorte:', error);
        throw error; // Lanzamos el error para que se maneje donde se llama el servicio
    }
}


