import axios from "axios";
import config from "../../config";

const baseUrl = `${config.baseUrl}/api/logsBodecom160`;

const logService = {
  /**
   * Obtiene la IP del usuario desde un servicio externo
   * @returns {Promise<string>} - IP del usuario
   */
  async getUserIp() {
    try {
      const response = await axios.get("https://api64.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error obteniendo la IP del usuario:", error);
      return "Desconocida"; // Si falla, registramos como "Desconocida"
    }
  },

  /**
   * Registra un nuevo log en la base de datos
   * @param {object} params - Datos del log
   * @param {string} params.action - Acción realizada
   * @param {string} params.description - Descripción de la acción
   * @param {string} params.username - Usuario que ejecutó la acción
   * @param {string} [params.level="INFO"] - Nivel del log
   */
  async createLog({ action, description, username, level = "INFO", application }) {
    try {
      const userIp = await this.getUserIp(); // Obtener la IP del usuario

      const logData = {
        action,
        description,
        username,
        level,
        ip_user: userIp,
        application,
        creator: username, // O cualquier otro valor correcto
        inserted_date: new Date().toISOString(),
      };
      

      return await axios.post(baseUrl, logData);
    } catch (error) {
      console.error("Error al enviar el log:", error);
    }
  },

  /**
   * Método genérico para registrar logs desde cualquier función
   * @param {Function} callback - Función que ejecuta la lógica principal
   * @param {string} action - Acción a registrar en el log
   * @param {string} description - Descripción detallada de la acción
   * @param {string} application - Aplicación donde ocurre la acción
   */
  async logAction(callback, action, description, application) {
    try {
      const result = await callback();
      await this.createLog({
        action,
        description,
        username: "userLogin", // Sustituir con el usuario real
        level: "INFO",
        application,
      });
      return result;
    } catch (error) {
      await this.createLog({
        action,
        description: `Error: ${error.message}`,
        username: "userLogin",
        level: "ERROR",
        application,
      });
      throw error;
    }
  },
};

export default logService;
