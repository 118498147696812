import React, { useState, useEffect, useCallback } from 'react';
import { Form, Input, Modal, Tabs, Rate, Progress, Row, Col, Select, Flex, message, Button, DatePicker } from 'antd';
import { getProyectos, getMunicipiosDepartamentos, fetchParticipantData, getEntidad } from '../../services/ippta/get_dataIppta'; // Asegúrate de que esta ruta sea correcta
import { UserOutlined, FundOutlined, ArrowLeftOutlined, ArrowRightOutlined, SendOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import './IpptaForm.css';
import { saveIpptaData } from '../../services/ippta/post_ippta';
import { updateIpptaData } from '../../services/ippta/update_ippta';
import PlanAccion from '../Ippta/PlanAccion';
import dayjs from 'dayjs';

const { TabPane } = Tabs;
const { Option } = Select;

const IpptaForm = ({ visible, onClose, formData, loadData }) => {
  const [form] = Form.useForm();
  const [ratings, setRatings] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [proyectos, setProyectos] = useState([]);
  const [entidadesService, setEntidadesService] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [filteredMunicipios, setFilteredMunicipios] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState('1');
  const twoColors = { '0%': '#108ee9', '100%': '#87d068', };
  const [ambientalRatings, setAmbientalRatings] = useState([0, 0, 0, 0, 0, 0]);
  const [socioeconomicoRatings, setSocioeconomicoRatings] = useState([0, 0, 0, 0, 0]);
  const [productivaRatings, setProductivaRatings] = useState([0, 0, 0, 0, 0, 0, 0]);
  const user = useSelector((state) => state.auth.user);
  const userLogin = user.username; 
  const [medicionData, setMedicionData] = useState("Inicial");
  const [oidRegistro, setOidRegistro] = useState(null);


  const resetFormAndRatings = useCallback(() => {
    form.resetFields();
    loadData();
    setOidRegistro(null);
    setAmbientalRatings(Array(6).fill(0));
    setSocioeconomicoRatings(Array(5).fill(0));
    setProductivaRatings(Array(7).fill(0));
    setRatings(Array(6).fill(0));
    setActiveTabKey('1');
  }, [form, loadData]);

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const proyectosData = await getProyectos();
      setProyectos(proyectosData.data || []);
      const municipiosData = await getMunicipiosDepartamentos();
      const departamentosData = municipiosData.data || [];
      setDepartamentos(departamentosData);
      setFilteredMunicipios([]);
      const entidad = await getEntidad();
      setEntidadesService(entidad || []);
    } catch (error) {
      console.error('Error al cargar datos:', error);
    }
  };


  const handleCancel = () => {
    const fieldsValue = form.getFieldsValue();
    const hasFilledFields = Object.values(fieldsValue).some(value => value !== undefined && value !== '');
    const hasSelectedRatings = ratings.some(rating => rating > 0);
    if (hasFilledFields || hasSelectedRatings) {
      Modal.confirm({
        title: '¿Está seguro de cerrar?',
        content: 'Hay campos que han sido diligenciados o calificaciones seleccionadas. Si cierra, se perderán los datos.',
        onOk: () => {
          resetFormAndRatings();
          onClose();
        },
      });
    } else {
      resetFormAndRatings();
      onClose();
    }
  };

  const handleDepartamentoChange = (value) => {
    const selectedDepartamento = departamentos.find(departamento => departamento.oid === value);
    if (selectedDepartamento) {
      setFilteredMunicipios(selectedDepartamento.municipios);
      form.setFieldsValue({ municipio: undefined });
    }
  };

  const handleTabChange = async (key) => {
    if (key === '2' || key === '3' || key === '4' || key === '5') {
      try {
        await form.validateFields();
        setActiveTabKey(key);
      } catch (error) {
        message.error('Por favor complete los campos obligatorios en el formulario antes de continuar.');
      }
    } else {
      setActiveTabKey(key);
    }
  };

  const handleAmbientalRateChange = (value, index) => {
    const newRatings = [...ambientalRatings];
    newRatings[index] = value;
    setAmbientalRatings(newRatings);

  };

  const handleSocioeconomicoRateChange = (value, index) => {
    const newRatings = [...socioeconomicoRatings];
    newRatings[index] = value;
    setSocioeconomicoRatings(newRatings);
  };

  const handleProductivaRateChange = (value, index) => {
    const newRatings = [...productivaRatings];
    newRatings[index] = value;
    setProductivaRatings(newRatings);
  }

  const handleIdentificacionChange = useCallback(async (value) => {
    if (value) {
      const identificacion = value.identificacion;
      const medicion = value.medicion;
      const data = await fetchParticipantData(identificacion, medicion);
      if (data && data.componente_general.length > 0) {
        const participantData = data.componente_general[0];
        setOidRegistro(participantData.oid);
        const selectedDepartamento = departamentos.find(dep => dep.oid === participantData.departamento);
        if (selectedDepartamento) {
          setFilteredMunicipios(selectedDepartamento.municipios);
        }
        form.setFieldsValue({
          proyecto: participantData.codigo_proyecto,
          medicion: participantData.medicion === 1 ? "Inicial" : "Final",
          fecha: participantData.fecha ? dayjs(participantData.fecha) : null,
          identificacion: participantData.identificacion,
          participante: participantData.participante,
          celular: participantData.celular,
          nombreFamiliar1: participantData.nombreFamiliar1,
          nombreFamiliar2: participantData.nombreFamiliar2,
          nombreFamiliar3: participantData.nombreFamiliar3,
          departamento: participantData.departamento,
          municipio: participantData.municipio,
          vereda: participantData.vereda,
          entidad: participantData.entidad,
          predio: participantData.predio,
          supervisor: participantData.supervisor_proyecto,
          responsableP: participantData.profesional_responsable,
        });

        if (data.componente_ambiental.length > 0) {
          const ambiental = data.componente_ambiental[0];
          setAmbientalRatings([
            ambiental.indicador1 || 0,
            ambiental.indicador2 || 0,
            ambiental.indicador3 || 0,
            ambiental.indicador4 || 0,
            ambiental.indicador5 || 0,
            ambiental.indicador6 || 0
          ]);
        }

        if (data.componente_socioeconomico.length > 0) {
          const socioeconomico = data.componente_socioeconomico[0];
          setSocioeconomicoRatings([
            socioeconomico.indicador1 || 0,
            socioeconomico.indicador2 || 0,
            socioeconomico.indicador3 || 0,
            socioeconomico.indicador4 || 0,
            socioeconomico.indicador5 || 0
          ]);
        }

        if (data.componente_producto.length > 0) {
          const productiva = data.componente_producto[0];
          setProductivaRatings([
            productiva.indicador1 || 0,
            productiva.indicador2 || 0,
            productiva.indicador3 || 0,
            productiva.indicador4 || 0,
            productiva.indicador5 || 0,
            productiva.indicador6 || 0,
            productiva.indicador7 || 0
          ]);
        }
      } else {
        form.setFieldsValue({
          participante: '',
          celular: '',
          nombreFamiliar1: '',
          nombreFamiliar2: '',
          nombreFamiliar3: '',
          departamento: '',
          municipio: '',
          vereda: '',
          entidad: '',
          predio: '',
          supervisor: '',
          responsableP: '',
        });
        setFilteredMunicipios([]);
        setAmbientalRatings(Array(6).fill(0));
        setSocioeconomicoRatings(Array(5).fill(0));
        setProductivaRatings(Array(7).fill(0));
      }
    }
  }, [form, departamentos]);

  useEffect(() => {
    if (formData) {
      handleIdentificacionChange(formData);
    }
  }, [formData, handleIdentificacionChange]);

  const validateRequiredFields = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      message.error('Por favor completa todos los campos obligatorios.');
      return false;
    }
  };

  const handleSave = async () => {
    const values = form.getFieldsValue();
    const fechaMoment = values.fecha; // Obtiene el objeto Moment del DatePicker
    const fechaFormateada = fechaMoment ? fechaMoment.format('YYYY-MM-DD') : null;

    const completeValues = {
      ...values,
      fecha: fechaFormateada,
      medicion: values.medicion ? values.medicion : "Inicial",
      ambientalRatings,
      socioeconomicoRatings,
      productivaRatings,
      user: userLogin,
    };

    try {
      const data = await saveIpptaData(completeValues);
      setOidRegistro(data.generalId);
      setActiveTabKey((prevKey) => (parseInt(prevKey) + 1).toString());

      if (activeTabKey === '5') {
        message.success('Datos enviados correctamente');
        resetFormAndRatings();
        onClose();
      }
    } catch (error) {
      if (error instanceof Error && error.message === 'Ya existen 2 registros con esta identificación') {
        message.warning(error.message); // Muestra advertencia específica
      } else {
        message.error('Error al guardar los datos.');
      }
    }
};


  const handleUpdate = async () => {
    const values = form.getFieldsValue();
    const fechaMoment = values.fecha; // Obtiene el objeto Moment del DatePicker
    const fechaFormateada = fechaMoment ? fechaMoment.format('YYYY-MM-DD') : null;

    const completeValues = {
      ...values,
      fecha: fechaFormateada,
      medicion: values.medicion ? values.medicion : "Inicial",
      ambientalRatings,
      socioeconomicoRatings,
      productivaRatings,
      user: userLogin,
    };
    try {
      await updateIpptaData(oidRegistro, completeValues);
      setActiveTabKey((prevKey) => (parseInt(prevKey) + 1).toString())
      if (activeTabKey === '5') {
        message.success('Datos eviado correctamente');
        resetFormAndRatings();
        onClose();
      }
    } catch (error) {
      console.error('Error al actualizar los datos:', error);
      message.error('Error al actualizar los datos.');
    }
  };

  const datos = async () => {
    const isValid = await validateRequiredFields();
    if (!isValid) {
      return;
    }
    if (oidRegistro) {
      handleUpdate();
    } else {
      handleSave();
    }
  }

  const goToPreviousTab = () => {
    setActiveTabKey((prevKey) => (parseInt(prevKey) - 1).toString());
  };

  const totalEnvironmentalStars = ambientalRatings.reduce((acc, curr) => acc + curr, 0);
  const maxEnvironmentalStars = 30;
  const percentDimension1 = Math.round((totalEnvironmentalStars / maxEnvironmentalStars) * 100);
  const totalSocioeconomicStars = socioeconomicoRatings.reduce((acc, curr) => acc + curr, 0);
  const maxSocioeconomicStars = 25;
  const percentDimension2 = Math.round((totalSocioeconomicStars / maxSocioeconomicStars) * 100);
  const totalProductivaStars = productivaRatings.reduce((acc, curr) => acc + curr, 0);
  const maxProductivaStars = 35;
  const percentDimension3 = Math.round((totalProductivaStars / maxProductivaStars) * 100);

  return (
    <Modal
      open={visible}
      title={<span style={{ fontSize: '24px', fontWeight: 'bold' }}>IPPTA</span>}
      cancelText="Cancelar"
      onCancel={handleCancel}
      width={1300}
      footer={null}
      style={{ textAlign: 'center' }}
    >
      <Tabs activeKey={activeTabKey} onChange={handleTabChange} animated>
        <TabPane tab={<span><UserOutlined /> Datos personales</span>} key="1">
          <Form form={form} layout="vertical" >
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="proyecto"
                  label="Proyecto:"
                  rules={[{ required: true, message: 'Por favor seleccione un proyecto!' }]}
                >
                  <Select
                    style={{ textAlign: 'left' }}
                    placeholder="Seleccione un proyecto"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {proyectos?.map(proyecto => (
                      <Option key={proyecto.proyecto_oid} value={proyecto.proyecto_oid}>
                        {`${proyecto.proyecto_codigo} - ${proyecto.nombre_proyecto}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="medicion" label="Medición:">
                  <Select
                    placeholder="Seleccione una medición"
                    value={medicionData}
                    onChange={(value) => setMedicionData(value)}
                    style={{ textAlign: 'left', width: '100%' }}
                  >
                    <Option value="Inicial">Inicial</Option>
                    <Option value="Final">Final</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="fecha"
                  label="Fecha:"
                  rules={[{ required: true, message: 'Por favor seleccione una fecha!' }]}
                >
                  <DatePicker style={{ width: '100%' }} placeholder="Seleccione una fecha" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {[
                {
                  name: "identificacion",
                  label: "Identificación:",
                  placeholder: "Escribe el número de identificación.",
                  rules: [
                    { required: true, message: "Por favor, ingresa el número de identificación." },
                  ],
                  type: "number",
                },
                {
                  name: "participante",
                  label: "Participante del proyecto:",
                  placeholder: "Escribe el nombre del participante.",
                  rules: [{ required: true, message: "Por favor, ingresa el nombre del participante." }],
                  type: "text",
                },
                {
                  name: "celular",
                  label: "Celular:",
                  placeholder: "Escribe el número de celular.",
                  rules: [
                    { required: true, message: "Por favor, ingresa el número de celular." },
                    {
                      pattern: /^\d{10}$/,
                      message: "El número de celular debe tener 10 dígitos.",
                    },
                  ],
                  type: "number",
                },
              ].map(({ name, label, placeholder, rules, type }) => (
                <Col span={8} key={name}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={rules}
                  >
                    <Input
                      placeholder={placeholder}
                      type={type}
                      onKeyPress={type === "number" ? (event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      } : undefined}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row gutter={16}>
              {Array.from({ length: 3 }, (_, index) => (
                <Col span={8} key={`familiar${index}`}>
                  <Form.Item
                    name={`nombreFamiliar${index + 1}`}
                    label="Nombre Familiar:"
                  >
                    <Input placeholder={`Escribe el nombre de ${index === 0 ? 'un' : index === 1 ? 'otro' : 'un tercer'} familiar`} />
                  </Form.Item>
                </Col>
              ))}
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  name="departamento"
                  label="Departamento:"
                  style={{ textAlign: 'left' }}
                  rules={[{ required: true, message: 'Por favor seleccione un departamento!' }]}
                >
                  <Select
                    placeholder="Seleccione un departamento"
                    showSearch
                    optionFilterProp="children"
                    onChange={handleDepartamentoChange}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {departamentos?.map(departamento => (
                      <Option key={departamento.oid} value={departamento.oid}>
                        {departamento.departamento}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="municipio"
                  label="Municipio:"
                  style={{ textAlign: 'left' }}
                  rules={[{ required: true, message: 'Por favor seleccione un municipio!' }]}
                >
                  <Select placeholder="Seleccione un municipio">
                    {filteredMunicipios.map(municipio => (
                      <Option key={municipio.oid} value={municipio.oid}>
                        {municipio.municipio}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="vereda"
                  label="Vereda:"
                  rules={[{ required: true, message: 'Por favor, ingresa el nombre de la vereda.' }]}
                >
                  <Input placeholder="Escribe el nombre de la vereda" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              {[
                { name: "entidad", label: "Entidad:" },
                { name: "predio", label: "Predio:" },
                { name: "supervisor", label: "Supervisor del proyecto:" },
                { name: "responsableP", label: "Profesional responsable:" },
              ].map(({ name, label }) => (
                <Col span={12} key={name}>
                  <Form.Item
                    name={name}
                    label={label}
                    rules={[
                      {
                        required: true,
                        message: name === "entidad"
                          ? "Por favor, seleccione la entidad." // Mensaje específico para entidad
                          : `Por favor, ingresa el nombre del ${label.toLowerCase()}.`,
                      },
                    ]}
                  >
                    {name === "entidad" ? (
                      <Select
                        style={{ textAlign: 'left' }}
                        placeholder="Seleccione una entidad"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().includes(input.toLowerCase())
                        }
                      >
                        {entidadesService?.map((entidad) => (
                          <Option key={entidad.entidad_oid} value={entidad.entidad_oid}>
                            {entidad.entidadd}
                          </Option>
                        ))}
                      </Select>
                    ) : (
                      <Input placeholder={`Escribe el nombre del ${label.toLowerCase()}`} />
                    )}
                  </Form.Item>
                </Col>
              ))}
            </Row>
          </Form>
        </TabPane>
        <TabPane tab={<span><FundOutlined /> Dimensión 1</span>} key="2">
          <Row
            gutter={16}
            style={{
              background: "#042956",
              textAlign: "center",
              padding: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '35px' }}>
              <h5 style={{ color: "#fff", textAlign: 'center' }}>Dimensión Ambiental</h5>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={12}>
                {[
                  { name: "fuentesAguaProteccion", label: "1. Protección de fuentes de agua." },
                  { name: "aguaConsumoManejo", label: "2. Manejo del agua para consumo humano." },
                  { name: "ahorroAguaHogar", label: "3. Ahorro de agua en el hogar y sistemas productivos." },
                  { name: "bosqueProteccion", label: "4. Protección del bosque en la finca." },
                  { name: "conectividadPaisaje", label: "5. Conectividad entre paisaje y agroecosistema." },
                  { name: "residuosSolidosManejo", label: "6. Manejo de residuos sólidos." },
                ].map(({ name, label }, index) => (
                  <Col key={name} span={20} style={{ marginBottom: '10px' }}> {/* span={24} para que ocupe todo el ancho */}
                    <div style={{ paddingLeft: '10px', display: 'flex', flexDirection: 'column' }}>
                      <span style={{ fontSize: '15px', marginBottom: '10px', textAlign: 'left' }}>{label}</span>
                      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Rate
                          allowHalf={false}
                          count={5}
                          style={{ fontSize: '25px', color: '#328BC7' }}
                          value={ambientalRatings[index]}
                          onChange={(value) => handleAmbientalRateChange(value, index)}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col span={10} style={{ padding: '16px', textAlign: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Calificación de la Dimensión
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension1} format={(percent) => `${totalEnvironmentalStars}`} />
              </Flex>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Nivel de transición Agroecológica
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension1} strokeColor={twoColors} format={(percent) => percent === 100 ? `${percent}%` : `${percent}%`} />
              </Flex>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={<span><FundOutlined /> Dimensión 2</span>} key="3">
          <Row
            gutter={16}
            style={{
              background: "#042956",
              textAlign: "center",
              padding: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>
              <h5 style={{ color: "#fff", textAlign: 'center' }}>Dimensión Socioeconómico</h5>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={12}>
                {[
                  { name: "generacionautoempleo", label: "7. Generación de ingresos e índice de autoempleo." },
                  { name: "organizacionComercializacion", label: "8. Organización para la comercialización de productos." },
                  { name: "autosuficienciaAlimentaria", label: "9. Autosuficiencia alimentaria familiar." },
                  { name: "participacionFamiliar", label: "10. Participación de la familia en procesos comunitarios." },
                  { name: "gestionConocimientoAgroecologico", label: "11. Gestión del conocimiento agroecológico." },
                ].map(({ name, label }, index) => (
                  <Col key={name}>
                    <div style={{ padding: '2px' }}>
                      <span style={{ fontSize: '15px', marginRight: '10px' }}>{label}</span>
                      <div style={{ display: 'flex', alignItems: 'flex-start', padding: '10px' }}>
                        <Rate
                          allowHalf={false}
                          count={5}
                          style={{ fontSize: '25px', color: '#328BC7' }}
                          value={socioeconomicoRatings[index]}
                          onChange={(value) => handleSocioeconomicoRateChange(value, index)}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={10} style={{ padding: '16px', textAlign: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Calificación de la Dimensión
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension2} format={(percent) => `${totalSocioeconomicStars}`} />
              </Flex>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Nivel de transición Agroecológica
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension2} strokeColor={twoColors} format={(percent) => percent === 100 ? `${percent}%` : `${percent}%`} />
              </Flex>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={<span><FundOutlined /> Dimensión 3</span>} key="4">
          <Row
            gutter={16}
            style={{
              background: "#042956",
              textAlign: "center",
              padding: "3px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Col>
              <h5 style={{ color: "#fff", textAlign: 'center' }}>Dimensión Productiva</h5>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row gutter={12}>
                {[
                  { name: "diversidadCultivos", label: "12. Diversidad de cultivos." },
                  { name: "diversidadAnimales", label: "13. Diversidad de animales (incluyendo peces)." },
                  { name: "integracionAnimalesCultivos", label: "14. Integración entre animales y cultivos." },
                  { name: "manejoSuelo", label: "15. Manejo del suelo." },
                  { name: "bienestarAnimal", label: "16. Bienestar animal (si aplica) a nivel de finca." },
                  { name: "produccionInsumos", label: "17. Producción de insumos en la finca." },
                  { name: "custodiaSemillas", label: "18. Producir y custodiar semillas en la finca." },
                ].map(({ name, label }, index) => (
                  <Col key={name} span={24} style={{ marginBottom: '10px' }}> {/* span={24} para que ocupe todo el ancho */}
                    <div style={{ padding: '2px', textAlign: 'left' }}>
                      <span style={{ fontSize: '15px', marginRight: '2px' }}>{label}</span>
                      <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', padding: '10px' }}>
                        <Rate
                          allowHalf={false}
                          count={5}
                          style={{ fontSize: '25px', color: '#328BC7' }}
                          value={productivaRatings[index]}
                          onChange={(value) => handleProductivaRateChange(value, index)}
                        />
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col span={10} style={{ padding: '16px', textAlign: 'center' }}>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Calificación de la Dimensión
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension3} format={(percent) => `${totalProductivaStars}`} />
              </Flex>
              <span style={{ fontSize: '16px', fontWeight: 'bold' }}>
                Nivel de transición Agroecológica
              </span>
              <Flex justify="center" align="center" style={{ height: '40%' }}>
                <Progress type="circle" percent={percentDimension3} strokeColor={twoColors} format={(percent) => percent === 100 ? `${percent}%` : `${percent}%`} />
              </Flex>
            </Col>
          </Row>
        </TabPane>
        <TabPane tab={<span><FundOutlined /> Plan de acción</span>} key="5">
          <Row>
            <Col span={24}>
              <PlanAccion oidRegistro={oidRegistro} />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: '16px', marginTop: 16 }}>
        <Button
          onClick={goToPreviousTab}
          disabled={activeTabKey === '1'}
          style={{ flex: 1 }}
        >
          <ArrowLeftOutlined /> {/* Ícono para Atrás */}
          <span style={{ marginLeft: 8 }}>Atrás</span>
        </Button>
        <Button
          onClick={datos}
          style={{ flex: 1 }}
        >
          {activeTabKey === '5' ? <><SendOutlined /> <span style={{ marginLeft: 8 }}>Enviar</span></> : <><ArrowRightOutlined /> <span style={{ marginLeft: 8 }}>Siguiente</span></>}
        </Button>
      </div>
    </Modal>
  );
};

export default IpptaForm;
