import React from "react";
import { Radar } from "react-chartjs-2";
import {
    Chart,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from "chart.js";

// Registrar componentes de Chart.js
Chart.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const RadarChart = ({ data }) => {
    // Etiquetas por tipo de sesión
    const labels = ["Virtual", "Autónomo", "Presencial"];

    // Extraer datos
    const {
        asistentesMujeresV,
        asistentesHombresV,
        asistentesMujeresA,
        asistentesHombresA,
        asistentesMujeresP,
        asistentesHombresP,
    } = data;

    // Obtener el valor máximo para ajustar la escala del radar
    const maxValue = Math.max(
        asistentesMujeresV,
        asistentesHombresV,
        asistentesMujeresA,
        asistentesHombresA,
        asistentesMujeresP,
        asistentesHombresP
    ) + 10; // Se agrega un pequeño margen

    // Datos del gráfico
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: "Mujeres",
                data: [asistentesMujeresV, asistentesMujeresA, asistentesMujeresP],
                borderColor: "rgba(255, 99, 132, 1)",
                backgroundColor: "rgba(255, 99, 132, 0.3)",
                borderWidth: 2,
                pointRadius: 6,
                pointHoverRadius: 8,
                pointBackgroundColor: "rgba(255, 99, 132, 1)",
            },
            {
                label: "Hombres",
                data: [asistentesHombresV, asistentesHombresA, asistentesHombresP],
                borderColor: "rgba(54, 162, 235, 1)",
                backgroundColor: "rgba(54, 162, 235, 0.3)",
                borderWidth: 2,
                pointRadius: 6,
                pointHoverRadius: 8,
                pointBackgroundColor: "rgba(54, 162, 235, 1)",
            },
        ],
    };

    // Configuración del gráfico
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            r: {
                beginAtZero: true,
                suggestedMax: maxValue,
                ticks: {
                    stepSize: Math.ceil(maxValue / 5), // Distribuye mejor los valores
                    color: "#555",
                    font: {
                        size: 14,
                    },
                    showLabelBackdrop: false,
                },
                angleLines: {
                    color: "rgba(0, 0, 0, 0.1)", // Líneas más suaves
                },
                grid: {
                    color: "rgba(0, 0, 0, 0.2)", // Mejor contraste
                },
            },
        },
        plugins: {
            legend: {
                position: "top",
                labels: {
                    font: {
                        size: 14,
                    },
                    usePointStyle: true,
                    padding: 20,
                },
            },
            tooltip: {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                titleFont: { size: 14 },
                bodyFont: { size: 14 },
                callbacks: {
                    label: (context) => `${context.dataset.label}: ${context.raw} asistentes`,
                },
            },
        },
    };

    return (
        <div style={{ width: "100%", maxWidth: "600px", height: "500px", margin: "0 auto" }}>
            <Radar data={chartData} options={options} />
        </div>
    );
};

export default RadarChart;
