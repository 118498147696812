import axios from "axios";
import config from "../../../../config"; // Configuración de la URL base

// Construcción de la URL base
const baseUrl = config.baseUrl;

//General
const endpoint1 = "/api/general_dhaa";
const endpoint2 = "/api/etareo_dhaa";
const endpoint3 = "/api/vereda_dhaa";
const endpoint4 = "/api/grupo_etnico_dhaa";
const endpoint5 = "/api/municipios_all_dhaa";
const endpoint6 = "/api/departamentos_all_dhaa";
const endpoint7 = "/api/organizacion_dhaa";

//Preguntas
const endpoint8 = "/api/preguntas_dhaa";

//Incidencias
const endpoint9 = "/api/incidencias_dhaa";
const endpoint10 = "/api/accion_social_dhaa";
const endpoint11 = "/api/accion_problematica_dhaa";

const endpoint12 = "/api/incidencias_general_dhaa";

const API_URL_1 = `${baseUrl}${endpoint1}`;
const API_URL_2 = `${baseUrl}${endpoint2}`;
const API_URL_3 = `${baseUrl}${endpoint3}`;
const API_URL_4 = `${baseUrl}${endpoint4}`;
const API_URL_5 = `${baseUrl}${endpoint5}`;
const API_URL_6 = `${baseUrl}${endpoint6}`;
const API_URL_7 = `${baseUrl}${endpoint7}`;
const API_URL_8 = `${baseUrl}${endpoint8}`;
const API_URL_9 = `${baseUrl}${endpoint9}`;
const API_URL_10 = `${baseUrl}${endpoint10}`;
const API_URL_11 = `${baseUrl}${endpoint11}`;
const API_URL_12 = `${baseUrl}${endpoint12}`;

const GeneralService = {
  // Obtener todos los registros
  getAllGeneral: async () => {
    try {
      const response = await axios.get(API_URL_1);

      return response.data;
    } catch (error) {
      console.error("Error al obtener los datos generales", error);
      throw error;
    }
  },

  // Obtener un registro por OID
  getGeneralById: async (oid) => {
    try {
      const response = await axios.get(`${API_URL_1}/${oid}`);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener el registro con OID ${oid}`, error);
      throw error;
    }
  },

  // Crear un nuevo registro
  createGeneral: async (data) => {
    try {
      const response = await axios.post(API_URL_1, data);
      return response.data;
    } catch (error) {
      console.error("Error al crear el registro", error);
      throw error;
    }
  },

  // Actualizar un registro por OID
  updateGeneral: async (oid, data) => {
    try {
      const response = await axios.put(`${API_URL_1}/${oid}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error al actualizar el registro con OID ${oid}`, error);
      throw error;
    }
  },

  // Eliminar un registro por OID
  deleteGeneral: async (oid) => {
    try {
      const response = await axios.delete(`${API_URL_1}/${oid}`);
      return response.data;
    } catch (error) {
      console.error(`Error al eliminar el registro con OID ${oid}`, error);
      throw error;
    }
  },

  getAllGrupoEtnico: async () => {
    try {
      const response = await axios.get(API_URL_4);
      return response.data; // Devuelve los datos recibidos
    } catch (error) {
      console.error("Error al obtener el grupo etnico: ", error);
      throw error;
    }
  },

  getAllGrupoEtareo: async () => {
    try {
      const response = await axios.get(API_URL_2);
      return response.data;
    } catch (error) {
      console.error("Error al obtener el grupo etareo: ", error);
      throw error;
    }
  },

  getAllVereda: async () => {
    try {
      const response = await axios.get(API_URL_3);
      return response.data;
    } catch (error) {
      console.error("Error al obtener la vereda: ", error);
      throw error;
    }
  },


  getMunicipios: async () => {
    try {
      const response = await axios.get(API_URL_5);
      return response.data;
    } catch (error) {
      console.error("Error al obtener los municipios: ", error);
      throw error;
    }
  },

  getDepartamentos: async () => {
    try {
      const response = await axios.get(API_URL_6);
      return response.data;
    } catch (error) {
      console.error("Error al obtener los departamentos: ", error);
      throw error;
    }
  },
  getOrganizacion: async () => {
    try {
      const response = await axios.get(API_URL_7);
      return response.data;
    } catch (error) {
      console.error("Error al obtener las organizaciones: ", error);
      throw error;
    }
  },

  // Crear un nuevo registro
  crearPregunta: async (data) => {
    try {
      const response = await axios.post(API_URL_8, data);
      return response.data;
    } catch (error) {
      console.error("Error las preguntas", error);
      throw error;
    }
  },
  updatePregunta: async (oid, data) => {
    try {
      const response = await axios.put(`${API_URL_8}/${oid}`, data); // Usar PUT en lugar de POST
      return response.data;
    } catch (error) {
      console.error("Error al actualizar las preguntas", error);
      throw error;
    }
  },
  getAllPreguntas: async (oid) => {
    try {
      const response = await axios.get(`${API_URL_8}/${oid}`);
      return response.data;
    } catch (error) {
      console.error("Error al obtener las preguntas: ", error);
      return [];
    }
  },
  getAllIncidencia: async () => {
    try {
      const response = await axios.get(API_URL_9);
      return response.data;
    } catch (error) {
      console.error("Error al obtener el grupo etareo: ", error);
      throw error;
    }
  },
  getAllSocial: async () => {
    try {
      const response = await axios.get(API_URL_10);
      return response.data;
    } catch (error) {
      console.error("Error al obtener el grupo etareo: ", error);
      throw error;
    }
  },
  getAllProblematica: async () => {
    try {
      const response = await axios.get(API_URL_11);
      return response.data;
    } catch (error) {
      console.error("Error al obtener el grupo etareo: ", error);
      throw error;
    }
  },

  // Crear un nuevo registro
  crearIncidencia: async (data) => {
    try {
      const response = await axios.post(API_URL_12, data);
      return response.data;
    } catch (error) {
      console.error("Error las preguntas", error);
      return [];
    }
  },
  updateIncidencia: async (oid, data) => {
    try {
      const response = await axios.put(`${API_URL_12}/${oid}`, data); // Usar PUT en lugar de POST
      return response.data;
    } catch (error) {
      console.error("Error al actualizar las preguntas", error);
      throw error;
    }
  },

  getAllIncidenciaGeneral: async (oid) => {
    try {
      const response = await axios.get(`${API_URL_12}/${oid}`);
      return response.data;
    } catch (error) {
      console.error("Error las preguntas", error);
      throw error;
    }
  },
};

export default GeneralService;
