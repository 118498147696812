import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Tooltip, Modal, message, Select } from "antd";
import { ArrowLeftOutlined, FolderOpenOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import FusionCharts from "fusioncharts";
import Chart from "fusioncharts/fusioncharts.charts";
import FusionChartsModule from "fusioncharts/fusioncharts.charts";
import FusionChartsTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import FileManagerPOA2 from "./FileAcompanamiento";
import EditableCell from "./EditableCellAcomp";
import "./AcompanamientoOrg.css";
import { selectAppPermissions } from "../../../../store/auth/selectors";
import { getAcompOrg, upsertAcompOrg, getProyectos, getDepartamentos, getMunicipios, getSubRegiones } from "../../../../services/Producto_3/Fort_Capacidades/AcompaOrganizaciones/acompOrgService";
import AcompanamientoOrgExport from "./AcompanamientoOrgExport.jsx";
import FileManagerPOAN from '../../../../components/poaReporte/FileManagerPOAN';
import { fetchPoaData } from '../../../../services/poa/get_poa';


FusionChartsModule(FusionCharts);
FusionChartsTheme(FusionCharts);
Chart(FusionCharts);
const { Option } = Select;
const AcompanamientoOrg = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [selectedMonth,] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const appName = 'curso-fortalecimiento';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const [filterSubSesion, setFilterSubSesion] = useState(null);
  const [subregionData, setSubregionData] = useState([]);

  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [proyectoOrg, setProyectoOrg] = useState([]);
  const [filterDepartamento, setFilterDepartamento] = useState(null);
  const [filterMunicipio, setFilterMunicipio] = useState(null);
  const [filterProyecto, setFilterProyecto] = useState(null);

  const [itemParams, setItemParams] = useState(false);
  const [openModalUsuario, setOpenModalUsuario] = useState(false)

  const permissionInsert = permissions.priv_insert === 'Y';
  const permissionExport = permissions.priv_export === 'Y';
  const permissionUpdate = permissions.priv_update === 'Y';
  const permissionDelete = permissions.priv_delete === 'Y';

  const mesesEnEspañol = React.useMemo(() => [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ], []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAcompOrg();
        setData(result);
        setDataFilter(result);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    let totaldearchivos = 0;
    const transformedData = dataFilter.flatMap((org, orgIndex) => {
      const totalPorRegistro = org.fechas.reduce((acumulador, item) => {
        const acompanamiento = item.archivos.Memoria_Acompanamiento || 0;
        const capacitacion = item.archivos.Memoria_Capacitacion || 0;
        return acumulador + acompanamiento + capacitacion;
      }, 0);

      totaldearchivos = totaldearchivos + totalPorRegistro;
      setTotalRegistros(totaldearchivos);
      const sortedFechas = org.fechas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      return sortedFechas.map((item, index) => {
        const isFirstRow = index === 0;
        const rowSpanValue = isFirstRow ? sortedFechas.length : 0;
        const monthNumber = moment(item.fecha).month();
        const year = moment(item.fecha).year();
        const month = mesesEnEspañol[monthNumber];
        const currentMonthYear = `${month} ${year}`;
        const previousMonthYear =
          index > 0
            ? `${mesesEnEspañol[moment(sortedFechas[index - 1].fecha).month()]} ${moment(sortedFechas[index - 1].fecha).year()}`
            : null;
        const isFirstMonthRow = index === 0 || currentMonthYear !== previousMonthYear;
        const monthRowSpanValue = isFirstMonthRow
          ? sortedFechas.filter(
            (f) => {
              const fMonth = mesesEnEspañol[moment(f.fecha).month()];
              const fYear = moment(f.fecha).year();
              return `${fMonth} ${fYear}` === currentMonthYear;
            }
          ).length
          : 0;
        if (selectedMonth && month !== selectedMonth) return null;
        const tematica = item.datos && item.datos.length > 0 ? item.datos[0].tematica : "";
        const plan_acompa_obs = item.datos && item.datos.length > 0 ? item.datos[0].plan_acompa_obs : "";
        const plan_acompa_num = item.datos && item.datos.length > 0 ? item.datos[0].plan_acompa_num : "";
        const reunion_socializa_obs = item.datos && item.datos.length > 0 ? item.datos[0].reunion_socializa_obs : "";
        const reunion_socializa_num = item.datos && item.datos.length > 0 ? item.datos[0].reunion_socializa_num : "";
        const plan_compras_obs = item.datos && item.datos.length > 0 ? item.datos[0].plan_compras_obs : "";
        const plan_compras_num = item.datos && item.datos.length > 0 ? item.datos[0].plan_compras_num : "";
        const oid = item.datos && item.datos.length > 0 ? item.datos[0].oid : "";
        const fechaORG = item.datos && item.datos.length > 0 ? item.datos[0].fecha : "";
        return {
          key: `${orgIndex}-${index}`,
          oid_acomorg: org.oid_acomorg,
          proyecto: org.codigo_proyecto,
          subregion: org.sub_region,
          departamento: org.departamento,
          municipio: org.municipio,
          zona: org.zona,
          linea_p: org.linea_p,
          organizacion: isFirstRow ? (org.oid_org === 53 ? "UNIPA" : org.sigla_org) : "",
          mes: isFirstMonthRow ? currentMonthYear : "",
          dia: moment(item.fecha).format("D"),
          rowSpan: rowSpanValue,
          monthRowSpan: monthRowSpanValue,
          acompanamiento: item.archivos.Memoria_Acompanamiento,
          capacitacion: item.archivos.Memoria_Capacitacion,
          totalPorRegistro: isFirstRow ? totalPorRegistro : "",
          tematica: tematica,
          plan_acompa_obs: plan_acompa_obs,
          plan_acompa_num: plan_acompa_num,
          reunion_socializa_obs: reunion_socializa_obs,
          reunion_socializa_num: reunion_socializa_num,
          plan_compras_obs: plan_compras_obs,
          plan_compras_num: plan_compras_num,
          oid: oid,
          fechaORG: fechaORG,
        };
      }).filter((item) => item !== null);
    });
    setFilteredData(transformedData);

  }, [dataFilter, mesesEnEspañol, selectedMonth]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Llamar a los servicios en paralelo
        const [departamentosData, municipiosData, subregionesData, proyectoOrg] = await Promise.all([
          getDepartamentos(),
          getMunicipios(),
          getSubRegiones(),
          getProyectos(),
        ]);

        // Actualizar el estado con los datos obtenidos
        setDepartamentos(departamentosData);
        setMunicipios(municipiosData);
        setSubregionData(subregionesData);
        setProyectoOrg(proyectoOrg);

      } catch (error) {
        console.error('Error al cargar los datos:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filterSubSesion, filterDepartamento, filterMunicipio, filterProyecto, data]);

  const handleClearFilters = () => {
    setDataFilter(data);
    setFilterDepartamento(null);
    setFilterMunicipio(null);
    setFilterProyecto(null);
    setFilterSubSesion(null);
  };


  const convertirFecha = (fecha) => {
    const meses = {
      enero: '01',
      febrero: '02',
      marzo: '03',
      abril: '04',
      mayo: '05',
      junio: '06',
      julio: '07',
      agosto: '08',
      septiembre: '09',
      octubre: '10',
      noviembre: '11',
      diciembre: '12'
    };
    const [mes, anio] = fecha.toLowerCase().split(' ');
    const mesNumero = meses[mes];
    const anioNumero = anio;
    return `${anioNumero}-${mesNumero}`;
  };

  const handleSave = async (updatedRecord) => {
    const updatedData = {
      oid: updatedRecord.oid,
      tematica: updatedRecord.tematica,
      plan_acompa_obs: updatedRecord.plan_acompa_obs,
      plan_acompa_num: updatedRecord.plan_acompa_num,
      plan_compras_obs: updatedRecord.plan_compras_obs,
      plan_compras_num: updatedRecord.plan_compras_num,
      reunion_socializa_obs: updatedRecord.reunion_socializa_obs,
      reunion_socializa_num: updatedRecord.reunion_socializa_num,
      oid_acomorg: updatedRecord.oid_acomorg,
      fecha: convertirFecha(updatedRecord.mes),
    };
    try {
      await upsertAcompOrg(updatedData);
      const newData = [...filteredData];
      const index = newData.findIndex((item) => item.key === updatedRecord.key);
      if (index > -1) {
        newData[index] = { ...newData[index], ...updatedRecord };
        setFilteredData(newData);
      }
      message.success('Registro guardado exitosamente');
    } catch (error) {
      console.error('Error al guardar el registro:', error);
      message.error('Error al guardar el registro');
    }
  };

  const columns = [
    {
      title: "Proyecto",
      dataIndex: "proyecto",
      key: "proyecto",
      fixed: '',
      width: 100,
      align: 'center',
      render: (_, record) => record.proyecto,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Sub-Región",
      dataIndex: "subregion",
      key: "subregion",
      width: 100,
      align: 'center',
      render: (_, record) => {
        const capitalizeWords = (text) =>
          text
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
        return capitalizeWords(record.subregion);
      },
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
        style: {
          whiteSpace: 'nowrap', // Mantiene el texto en una sola línea
          overflow: 'hidden',    // Oculta el texto si excede el ancho
          textOverflow: 'ellipsis' // Agrega "..." si el texto es muy largo
        },
      }),
    },

    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "departamento",
      width: 100,
      align: 'center',
      render: (_, record) => record.departamento,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
        style: {
          whiteSpace: 'nowrap', // Mantiene el texto en una sola línea
          overflow: 'hidden',    // Oculta el texto si excede el ancho
          textOverflow: 'ellipsis' // Agrega "..." si el texto es muy largo
        },
      }),
    },
    {
      title: "Municipio",
      dataIndex: "municipio",
      key: "municipio",
      width: 100,
      align: 'center',
      render: (_, record) => record.municipio,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
        style: {
          whiteSpace: 'nowrap', // Mantiene el texto en una sola línea
          overflow: 'hidden',    // Oculta el texto si excede el ancho
          textOverflow: 'ellipsis' // Agrega "..." si el texto es muy largo
        },
      }),
    },
    {
      title: "Zona",
      dataIndex: "zona",
      key: "zona",
      width: 100,
      align: 'center',
      render: (_, record) => record.zona,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Linea productiva",
      dataIndex: "linea_p",
      key: "linea_p",
      width: 200,
      align: 'center',
      render: (_, record) => record.linea_p,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
        style: {
          whiteSpace: 'nowrap', // Mantiene el texto en una sola línea
          overflow: 'hidden',    // Oculta el texto si excede el ancho
          textOverflow: 'ellipsis' // Agrega "..." si el texto es muy largo
        },
      }),
    },
    {
      title: "Organización",
      dataIndex: "organizacion",
      key: "organizacion",
      width: 100,
      align: 'center',
      render: (_, record) => record.organizacion,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Mes / Año",
      dataIndex: "mes",
      key: "mes",
      width: 100,
      align: 'center',
      onCell: (record) => ({
        rowSpan: record.monthRowSpan,
      }),
      render: (_, record) => record.mes,
    },
    {
      title: "Día",
      dataIndex: "dia",
      key: "dia",
      width: 100,
      align: 'center',
    },
    {
      title: "Acompañamiento Técnico",
      dataIndex: "acompanamiento",
      key: "acompanamiento",
      width: 200,
      align: 'center',

    },
    {
      title: "Capacitación",
      dataIndex: "capacitacion",
      key: "capacitacion",
      width: 200,
      align: 'center',

    },
    {
      title: "Total",
      dataIndex: "totalPorRegistro",
      key: "totalPorRegistro",
      width: 200,
      align: 'center',
      render: (_, record) => record.totalPorRegistro,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Temáticas",
      dataIndex: "tematica",
      key: "tematica",
      width: 500,
      className: "tematica-col",
      ellipsis: false,
      render: (_, record) => (
        <Tooltip title={record.tematica}>
          <div className="tematica-content">{record.tematica}</div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: permissionUpdate,
        title: "tematica",
        text: record.tematica,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title:(
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          Plan de acompañamiento
          <Tooltip title="Abrir carpeta">
            <FolderOpenOutlined
              style={{ cursor: "pointer",  fontSize: "24px",}}
              onClick={() => handleNoInfoClickSoportes(2)}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "plan_acompa_obs",
      key: "plan_acompa_obs",
      width: 500,
      className: "tematica-col",
      ellipsis: false,
      render: (_, record) => (
        <Tooltip title={record.plan_acompa_obs}>
          <div className="tematica-content">{record.plan_acompa_obs}</div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: permissionUpdate,
        title: "plan_acompa_obs",
        text: record.plan_acompa_obs,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title: "Número de planes de acompañamiento",
      dataIndex: "plan_acompa_num",
      key: "plan_acompa_num",
      width: 500,
      className: "tematica-col",
      ellipsis: false,
      render: (_, record) => (
        <Tooltip title={record.plan_acompa_num}>
          <div className="tematica-content">{record.plan_acompa_num}</div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: permissionUpdate,
        title: "plan_acompa_num",
        text: record.plan_acompa_num,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    }, {
      title: (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          Reunión de socialización
          <Tooltip title="Abrir carpeta">
            <FolderOpenOutlined
              style={{ cursor: "pointer",  fontSize: "24px",}}
              onClick={() => handleNoInfoClickSoportes(3)}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "reunion_socializa_obs",
      key: "reunion_socializa_obs",
      width: 500,
      className: "tematica-col",
      ellipsis: false,
      render: (_, record) => (
        <Tooltip title={record.reunion_socializa_obs}>
          <div className="tematica-content">{record.reunion_socializa_obs}</div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: permissionUpdate,
        title: "reunion_socializa_obs",
        text: record.reunion_socializa_obs,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    }, {
      title: "Número de reuniones de socialización",
      dataIndex: "reunion_socializa_num",
      key: "reunion_socializa_num",
      width: 500,
      className: "tematica-col",
      ellipsis: false,
      render: (_, record) => (
        <Tooltip title={record.reunion_socializa_num}>
          <div className="tematica-content">{record.reunion_socializa_num}</div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: permissionUpdate,
        title: "reunion_socializa_num",
        text: record.reunion_socializa_num,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          Plan de compras
          <Tooltip title="Abrir carpeta">
            <FolderOpenOutlined
              style={{ cursor: "pointer",  fontSize: "24px",}}
              onClick={() => handleNoInfoClickSoportes(4)}
            />
          </Tooltip>
        </div>
      ),
      dataIndex: "plan_compras_obs",
      key: "plan_compras_obs",
      width: 500,
      className: "tematica-col",
      ellipsis: false,
      render: (_, record) => (
        <Tooltip title={record.plan_compras_obs}>
          <div className="tematica-content">{record.plan_compras_obs}</div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: permissionUpdate,
        title: "plan_compras_obs",
        text: record.plan_compras_obs,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title: "Número de planes de compras",
      dataIndex: "plan_compras_num",
      key: "plan_compras_num",
      width: 500,
      className: "tematica-col",
      ellipsis: false,
      render: (_, record) => (
        <Tooltip title={record.plan_compras_num}>
          <div className="tematica-content">{record.plan_compras_num}</div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: permissionUpdate,
        title: "plan_compras_num",
        text: record.plan_compras_num, // Pasa el valor de 'tematica' para que se edite
        handleSave, // Función para guardar los cambios
        rowSpan: record.monthRowSpan, // Incluye el rowSpan aquí
      }),
    },
  ];

  const handleCardClick = () => {
    navigate(`/menu-curso-fortalecimiento`);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleNoInfoClick = () => {
    setIsModalVisible(true);
  };

  const applyFilters = () => {
    let filtered = dataFilter;
    if (filterSubSesion) filtered = filtered.filter((item) => item.cod_sub_region === filterSubSesion);
    if (filterDepartamento) filtered = filtered.filter((item) => item.oid_departamento === filterDepartamento);
    if (filterMunicipio) filtered = filtered.filter((item) => item.oid_municipio === filterMunicipio);
    if (filterProyecto) filtered = filtered.filter((item) => item.oid_proyecto === filterProyecto);
    setDataFilter(filtered);
  };

  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const handleNoInfoClickSoportes = (opcion) => {
    let tarea = "";
    switch (opcion) {
      case 1:
        tarea = "3.3.2";
        break;
      case 2:
        tarea = "3.3.1";
        break;
      case 3:
        tarea = "3.3.3";
        break;
      case 4:
        tarea = "3.3.4";
        break;
      default:
        return; // Si la opción no es válida, termina la función
    }
    // Llamar a procesarMpoa solo si hay una tarea válida
    procesarMpoa(tarea);
  };
  

  const hideModalUsuario = () => {
    setOpenModalUsuario(false);
  };

  const procesarMpoa = async (Mpoa) => {
    if (Mpoa) {
      if (Mpoa === '2.2.1') {
        return null;
      }
      const manejarActividadEspecial = (actividad) => {
        switch (actividad) {
          case '2.2.1.1':
            return '2.2.1';
          case '2.2.1.2':
            return '2.2.1';
          case '2.2.1.3':
            return '2.2.1';
          default:
            return actividad;
        }
      };
      const actividadBase = manejarActividadEspecial(Mpoa);
      try {
        const dataCompleta = await fetchPoaData();
        const dataPoa = dataCompleta.dataPoa;
        const findNodePath = (node, path = []) => {
          path.push(node);
          if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
            return path;
          }
          if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
            if (node.children && node.children.length > 0) {
              for (let child of node.children) {
                if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                  path.push(child);
                  return path;
                }
                if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                  path.push(child);
                  return path;
                }
              }
            }
          }

          if (node.children && node.children.length > 0) {
            for (let child of node.children) {
              const result = findNodePath(child, [...path]);
              if (result) return result;
            }
          }
          return null;
        };
        let matchedPath = null;

        for (let nodo of dataPoa) {
          matchedPath = findNodePath(nodo);
          if (matchedPath) break;
        }
        if (matchedPath) {
          const initialData = extractData(matchedPath);
          const actividadData = initialData[0].actividadData;
          const tareaEncontrada = actividadData.find((item) => {
            if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
              return (
                item.codigo_tarea === actividadBase &&
                ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                  (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
              );
            }
            return item.codigo_tarea === actividadBase;
          });
          if (tareaEncontrada) {
            setOpenModalUsuario(true);
            setItemParams(tareaEncontrada);
          } else {
            setOpenModalUsuario(false);
            setItemParams(null);
          }
        } else {
          console.warn("No se encontró ningún nodo coincidente");
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    } else {
      setOpenModalUsuario(false);
    }
  };

  const extractData = (children) => {
    return children.flatMap(child => {
      if (child.children && child.tipo === 'Actividad') {
        const actividadData = child.children.flatMap(grandchild => {
          if (grandchild.children && grandchild.tipo === 'Tarea') {
            return grandchild.children.flatMap(greatGrandchild => {
              if (greatGrandchild.tipo === 'Indicador') {
                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                const medioV = greatGrandchild.medioVerificacion || null;
                return {
                  key: greatGrandchild.key,
                  codigo_tarea: grandchild.codigo_tarea,
                  codActividad: grandchild.codActividad,
                  nameActividad: child.actividad,
                  tarea: grandchild.tarea,
                  indicador: greatGrandchild.title,
                  medioV: medioV,
                  fechas: fechaFormateada,
                  responsables: 'N/A',
                  soportes: 'N/A',
                  datoIndicador: greatGrandchild,
                };
              }
              return [];
            });
          }
          return [];
        });
        return [{
          actividadTitle: child.title,
          actividad: child.actividad,
          actividadData: actividadData
        }];
      }
      return [];
    });
  };

  return (
    <div className="container-fluid" style={{ paddingLeft: '3rem', paddingRight: '3rem' }}>
      <div className="row mb-2 d-flex align-items-center">
        <div className="col-md-7 linea_separador mb-2 d-flex align-items-center">
          <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
            <p>PROYECTO UTF <br /> COL 160 COL</p>
          </div>
          <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
            <p>
              Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
              institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
            </p>
          </div>
        </div>
        <div className="col-md-5 d-flex justify-content-end align-items-center flex-column">
          <h4 className="text-center">Acompañamiento Organizaciones</h4>

          {permissionExport && (
            <Row gutter={24} className="mb-3">
              <AcompanamientoOrgExport dataSource={filteredData} />
            </Row>
          )}

          {/* Botón Regresar */}
          <Row justify="center" align="middle" gutter={16} className="mb-2">
            <Col span={4} style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={handleCardClick}
                icon={<ArrowLeftOutlined />}
                
              >
                Regresar
              </Button>
            </Col>
          </Row>

          {/* Botones adicionales */}
          {permissionInsert && (
  <Row justify="center" align="middle" gutter={[16, 16]}>
    {/* Primera fila */}
    <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
      <Button icon={<FolderOpenOutlined />} onClick={handleNoInfoClick}>
        Caja herramientas
      </Button>
    </Col>
    <Col span={12} style={{ display: "flex", justifyContent: "center" }}>
      <Tooltip title="Soportes">
        <Button icon={<FolderOpenOutlined />} onClick={() => handleNoInfoClickSoportes(1)}>
          Soporte
        </Button>
      </Tooltip>
    </Col>
    </Row>

)}
        </div>

      </div>

      <div className="mb-3 d-flex align-items-center">
        <Row align="middle" justify="space-between" style={{ width: "100%" }}>
          {/* Contenedor de los filtros y botones */}
          <Col span={24}>
            <Row gutter={[10, 0]} wrap={false} justify="space-between" align="middle">
              {/* Filtros */}
              <Col span={4}>
                <Select
                  showSearch
                  placeholder="Filtrar por Sub-Región"
                  value={filterSubSesion || undefined}
                  onChange={(value) => setFilterSubSesion(value)}
                  className="filtro-item"
                  style={{ width: '100%' }}
                >
                  {subregionData.map(subRegion => (
                    <Option key={subRegion.oid} value={subRegion.oid}>
                      {capitalizeWords(subRegion.nombre_subregion_pdet)}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col span={4}>
                <Select
                  showSearch
                  placeholder="Filtrar por Departamento"
                  value={filterDepartamento || undefined}
                  onChange={(value) => setFilterDepartamento(value)}
                  className="filtro-item"
                  style={{ width: '100%' }}
                >
                  {departamentos.map(depto => (
                    <Option key={depto.oid} value={depto.oid}>
                      {depto.departamento}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col span={4}>
                <Select
                  showSearch
                  placeholder="Filtrar por Municipio"
                  value={filterMunicipio || undefined}
                  onChange={(value) => setFilterMunicipio(value)}
                  className="filtro-item"
                  style={{ width: '100%' }}
                >
                  {municipios.map(mun => (
                    <Option key={mun.oid} value={mun.oid}>
                      {mun.municipio}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col span={4}>
                <Select
                  showSearch
                  placeholder="Filtrar por Proyecto"
                  value={filterProyecto || undefined}
                  onChange={(value) => setFilterProyecto(value)}
                  className="filtro-item"
                  style={{ width: '100%' }}
                >
                  {proyectoOrg.map(proyect => (
                    <Option key={proyect.oid} value={proyect.oid}>
                      {capitalizeWords(proyect.proyecto)}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col span={4}>
                <Tooltip title="Limpiar filtros">
                  <Button
                    icon={<SearchOutlined />}
                    onClick={handleClearFilters}
                    style={{
                      backgroundColor: '#1677FF',
                      color: '#ffffff',
                      width: '100%'
                    }}
                  >
                    Limpiar Filtros
                  </Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          {/* 20% - Total de archivos */}
          <Col span={24} className="col-total-archivos" style={{ textAlign: "right" }}>
            <div className="participant-count-container">
              <h1 className="participant-count-title">
                Total de archivos: <span className="participant-count-number">{totalRegistros}</span>
              </h1>
            </div>
          </Col>

        </Row>
      </div>


      <div className="mb-5" style={{ overflowX: 'auto' }}> {/* Agrega scroll horizontal si es necesario */}
        <Table
          className="tablaAcompanamiento"
          columns={columns.map((col) => {
            if (!col.editable) {
              return col;
            }
            return {
              ...col,
              onCell: (record) => ({
                record,
                editable: col.editable,
                value: record[col.dataIndex],
                title: col.dataIndex,
                handleSave,
              }),
            };
          })}
          dataSource={filteredData}
          bordered
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          pagination={false}
          summary={() => {
            const totalAcompanamiento = filteredData.reduce(
              (sum, row) => sum + (row.acompanamiento || 0),
              0
            );
            const totalCapacitacion = filteredData.reduce(
              (sum, row) => sum + (row.capacitacion || 0),
              0
            );
            const totalPorRegistro = filteredData.reduce(
              (sum, row) => sum + (row.totalPorRegistro || 0),
              0
            );
            return (
              <Table.Summary.Row style={{ backgroundColor: '#042956', color: '#ffffff' }}>
                <Table.Summary.Cell index={0} colSpan={9} align="center">
                  <b>Total Registros</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="center">
                  <b>{totalAcompanamiento}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="center">
                  <b>{totalCapacitacion}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="center">
                  <b>{totalPorRegistro}</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} colSpan={9} align="center">
                  <b></b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </div>


      <div className="d-flex justify-content-center">
        <Modal
          title={<span className="custom-modal-title">Repositorio de Acompañamiento Organizacional</span>}
          open={isModalVisible}
          onCancel={hideModal}
          footer={null}
          width="95%"
          style={{ top: 20 }}
        >
          <FileManagerPOA2 />
        </Modal>
        {
          openModalUsuario && itemParams && (
            <div className="d-flex justify-content-center">
              <Modal
                title={<span className="custom-modal-title">Medios de verificación</span>}
                open={openModalUsuario}
                onCancel={hideModalUsuario}
                footer={null}
                width="95%"
                style={{ top: 20 }}
              >
                <FileManagerPOAN codigo_tarea={itemParams} />
              </Modal>
            </div>
          )
        }
      </div>
    </div >


  );
};

export default AcompanamientoOrg;
