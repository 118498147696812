import React, { useState, useEffect, useRef } from "react";
import { Badge, Checkbox, Space, Typography, Row, Col, Card, Table, Progress, Tooltip, Modal, Button, Divider } from "antd";
import dayjs from "dayjs";
import ExportarExcelPoa from "./ExportarExcelPoa"; // Importamos el componente de exportación
import { SearchOutlined, FileImageOutlined, FolderOpenOutlined, BarChartOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import './TablaResumenAvance.css';
import AvanceProyectosChart from './Graficos/AvanceProyectosChart';
import AvanceProyectosChartBarras from './Graficos/AvanceProyectosChartBarras';
import html2canvas from "html2canvas";
import config from '../../config';


const PoaResumen = ({ data }) => {
    // Estado de la tabla
    const [filteredData, setFilteredData] = useState([]);
    const tableRef = useRef(null);
    const [visible, setVisible] = useState(false);
    const [proyectoSeleccionado, setProyectoSeleccionado] = useState(null);

    const [visibleGrafico, setVisibleGrafico] = useState(false);
    const [mostrarFechas, setMostrarFechas] = useState(true);

    const [visibleModal, setVisibleModal] = useState(false);
    const [selectedChart, setSelectedChart] = useState(null);




    const { Text } = Typography;
    const baseUrl = config.baseUrl;

    // Función para obtener el mes en texto
    const obtenerNombreMes = (numeroMes) => {
        const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
        return meses[numeroMes - 1];
    };

    const conversionData = [
        { rango: "0% - 24%", color: "#f20013", descripcion: "Bajo" },
        { rango: "25% - 64%", color: "#ffa500", descripcion: "Medio" },
        { rango: "65% - 100%", color: "#198754", descripcion: "Alto" }
    ];

    // Función para alternar la visibilidad
    const toggleFechas = () => {
        setMostrarFechas(!mostrarFechas);
    };


    // Agregar dentro del useEffect para ver cómo se está procesando `data`
    useEffect(() => {

        if (!data || !Array.isArray(data) || data.length === 0) {
            console.warn("⚠️ No hay datos válidos en `data`.");
            return;
        }

        const nuevosDatos = data.map((proyecto) => {
            if (!proyecto.fecha_actualizacion) {
                console.warn(`⚠️ Proyecto sin fecha_actualizacion:`, proyecto);
                return null;
            }

            const fechaActualizacion = dayjs(proyecto.fecha_actualizacion);
            const mesFiltro = obtenerNombreMes(fechaActualizacion.month() + 1);
            const anoFiltro = fechaActualizacion.year().toString();

            const avanceP = proyecto.P?.find(item => item.ano === anoFiltro && item.mes === mesFiltro);
            const avanceE = proyecto.E?.find(item => item.ano === anoFiltro && item.mes === mesFiltro);

            return {
                key: proyecto.oidProyecto,
                oidProyecto: proyecto.oidProyecto,
                codigo: proyecto.codigo,
                nombreProyecto: proyecto.nombreProyecto,
                fecha_inicio: proyecto.fecha_inicio,
                fecha_terminacion: proyecto.fecha_terminacion,
                fecha_actualizacion: proyecto.fecha_actualizacion,
                mes: mesFiltro,
                ano: anoFiltro,
                avanceProgramado: avanceP ? avanceP.Total_Avance : "0.000000",
                avanceEjecutado: avanceE ? avanceE.Total_Avance : "0.000000",
            };
        }).filter(Boolean);

        setFilteredData(nuevosDatos);
    }, [data]);


    //Funcion para ebrir el POA
    const verPOA = (record) => {
        if (!record?.codigo) {
            console.error("Código no disponible para ver POA");
            return;
        }

        const url = `${baseUrl}/planOperativo/${record.codigo}`;
        window.open(url, "_blank");
    };


    // Función para abrir el modal y setear los datos
    const verDetalles = (record) => {

        // Buscar el proyecto completo en los datos originales
        const proyectoCompleto = data.find(p => p.oidProyecto === record.oidProyecto);

        if (!proyectoCompleto) {
            console.warn("⚠️ No se encontró el proyecto completo en los datos originales.");
            return;
        }

        // Extraer toda la historia de avances (sin filtrar por mes/año)
        const historialAvances = [];

        if (proyectoCompleto.P) {
            proyectoCompleto.P.forEach(item => {
                historialAvances.push({
                    mes: item.mes,
                    ano: item.ano,
                    avanceProgramado: item.Total_Avance,
                    avanceEjecutado: "0.000000" // Se asignará después si hay dato en `E`
                });
            });
        }

        if (proyectoCompleto.E) {
            proyectoCompleto.E.forEach(item => {
                // Buscar si ya existe el mes/año en el historial
                const registroExistente = historialAvances.find(av => av.mes === item.mes && av.ano === item.ano);
                if (registroExistente) {
                    registroExistente.avanceEjecutado = item.Total_Avance;
                } else {
                    historialAvances.push({
                        mes: item.mes,
                        ano: item.ano,
                        avanceProgramado: "0.000000", // Si no había en P, se asigna 0
                        avanceEjecutado: item.Total_Avance
                    });
                }
            });
        }

        // Guardar la información completa en el estado
        setProyectoSeleccionado({
            ...record,
            historialAvances, // Se usa para mostrar en la tabla del modal
        });

        setVisible(true);
    };

    // Función para cerrar el modal
    const cerrarModal = () => {
        setVisible(false);
        setProyectoSeleccionado(null);
    };


    // Columnas de la tabla
    const columns = [

        ...(mostrarFechas
            ? [
                {
                    title: "",
                    key: "detalle",
                    align: "center",
                    render: (_, record) => (
                        <Tooltip title="Ver detalles">
                            <SearchOutlined
                                className="icono-lupa"
                                style={{ fontSize: "18px", color: "#1677ff", cursor: "pointer" }}
                                onClick={() => verDetalles(record)}
                            />
                        </Tooltip>
                    ),
                },
                {
                    title: "",
                    key: "detallePOA",
                    align: "center",
                    render: (_, record) => (
                        <Tooltip title="Ver Plan Operativo del Proyecto">
                            <FolderOpenOutlined
                                className="icono-lupa"
                                style={{ fontSize: "18px", color: "#1677ff", cursor: "pointer" }}
                                onClick={() => verPOA(record)}
                            />
                        </Tooltip>
                    ),
                }
            ]
            : []),

        {
            title: (
                <span>
                    Proyecto &nbsp;
                    <Checkbox checked={mostrarFechas} onChange={toggleFechas} />
                </span>
            ),
            key: "codigoProyecto",
            align: "left",
            render: (text, record) => `${record.codigo} - ${record.nombreProyecto}`,
        },
        ...(mostrarFechas
            ? [
                {
                    title: "Fecha Inicio POA",
                    dataIndex: "fecha_inicio",
                    key: "fecha_inicio",
                    align: "center",
                    render: (text) => text ? dayjs(text).format("DD-MM-YYYY") : "",
                },
                {
                    title: "Fecha Terminación POA",
                    dataIndex: "fecha_terminacion",
                    key: "fecha_terminacion",
                    align: "center",
                    render: (text) => text ? dayjs(text).format("DD-MM-YYYY") : "",
                },
                {
                    title: "Fecha de actualización de Avances",
                    dataIndex: "fecha_actualizacion",
                    key: "fecha_actualizacion",
                    align: "center",
                    render: (text) => text ? dayjs(text).format("DD-MM-YYYY") : "",
                },
                {
                    title: "Mes de Corte",
                    key: "mes_ano",
                    align: "center",
                    render: (_, record) => record.mes && record.ano ? `${record.mes}-${record.ano}` : ""
                },
            ]
            : []),
        {
            title: "Avance Programado (P)",
            dataIndex: "avanceProgramado",
            key: "avanceProgramado",
            align: "center", // Alineación centrada
            render: (text) => (
                <Progress
                    percent={100} // La barra siempre estará llena
                    format={() => (
                        <span style={{ fontWeight: "bold", fontSize: "14px", color: "#FFF" }}>
                            {`${Math.round(parseFloat(text))}%`}
                        </span>
                    )} // Muestra el valor real
                    percentPosition={{ align: 'right', type: 'inner' }}
                    size={[150, 20]}
                    strokeColor={{ '0%': '#108ee9', '100%': '#108ee9' }} // Degradado de colores
                />
            )
        },

        {
            title: "Avance Ejecutado (E)",
            dataIndex: "avanceEjecutado",
            key: "avanceEjecutado",
            align: "center", // Alineación centrada
            render: (text, record) => {
                const ejecutado = parseFloat(text);
                const programado = parseFloat(record.avanceProgramado);

                // Evitar divisiones por cero
                const porcentajeEjecucion = programado > 0 ? (ejecutado / programado) * 100 : 0;

                // Determinar color según el porcentaje de ejecución
                let color = "#f20013";
                if (porcentajeEjecucion > 24 && porcentajeEjecucion <= 64) {
                    color = "#ffa500";
                } else if (porcentajeEjecucion > 65) {
                    color = "#198754";
                }

                return (
                    <Progress
                        percent={Math.round(porcentajeEjecucion)}


                        format={() => (
                            <span style={{ fontWeight: "bold", fontSize: "14px", color: "#FFF" }}>
                                {`${Math.round(ejecutado)}%`}
                            </span>
                        )}

                        percentPosition={{ align: 'right', type: 'inner' }}
                        size={[150, 20]}
                        strokeColor={color}
                    />
                );
            }
        }

    ];

    // Definición de columnas para la tabla dentro del modal
    const columnasModal = [
        {
            title: "Mes de Corte",
            key: "mes_ano",
            align: "center",
            render: (_, record) =>
                record.mes && record.ano ? `${record.mes} - ${record.ano}` : ""
        },
        {
            title: "Avance Programado (P)",
            dataIndex: "avanceProgramado",
            key: "avanceProgramado",
            align: "center", // Alineación centrada
            render: (text) => (
                <Progress
                    percent={100} // La barra siempre estará llena
                    format={() => (
                        <span style={{ fontWeight: "bold", fontSize: "14px", color: "#FFF" }}>
                            {`${Math.round(parseFloat(text))}%`}
                        </span>
                    )} // Muestra el valor real
                    percentPosition={{ align: 'right', type: 'inner' }}
                    size={[150, 20]}
                    strokeColor={{ '0%': '#108ee9', '100%': '#108ee9' }} // Degradado de colores
                />
            )
        },
        {
            title: "Avance Ejecutado (E)",
            dataIndex: "avanceEjecutado",
            key: "avanceEjecutado",
            align: "center", // Alineación centrada
            render: (text, record) => {
                const ejecutado = parseFloat(text);
                const programado = parseFloat(record.avanceProgramado);

                // Evitar divisiones por cero
                const porcentajeEjecucion = programado > 0 ? (ejecutado / programado) * 100 : 0;

                // Determinar color según el porcentaje de ejecución
                let color = "#f20013";
                if (porcentajeEjecucion > 24 && porcentajeEjecucion <= 64) {
                    color = "#ffa500";
                } else if (porcentajeEjecucion > 65) {
                    color = "#198754";
                }

                return (
                    <Progress
                        percent={Math.round(porcentajeEjecucion)}
                        format={() => (
                            <span style={{ fontWeight: "bold", fontSize: "14px", color: "#FFF" }}>
                                {`${Math.round(ejecutado)}%`}
                            </span>
                        )}

                        percentPosition={{ align: 'right', type: 'inner' }}
                        size={[150, 20]}
                        strokeColor={color}
                    />
                );
            }
        }


    ];


    const capturarTabla = async () => {
        if (tableRef.current) {
            const canvas = await html2canvas(tableRef.current);
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = "Porcentajes_de_Avance_POAs.png";
            link.click();
        }
    };


    // Función para abrir el modal y establecer qué gráfico se maximiza
    const handleOpenModal = (chartType) => {
        setSelectedChart(chartType);
        setVisibleModal(true);
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 16 }}>
                <h2 style={{ margin: 0 }}>Porcentajes de Avance POA´s
                    <br />
                    {/* <Space size="large">
                        {conversionData.map((item) => (
                            <Badge
                                key={item.rango}
                                color={item.color}
                                text={`${item.rango} (${item.descripcion})`}
                            />
                        ))}
                    </Space> */}
                </h2>


                <div style={{ display: "flex", gap: 8 }}> {/* Contenedor para los botones con espacio reducido */}

                    {!mostrarFechas ? (
                        <Button type="primary" icon={<FileImageOutlined />} onClick={capturarTabla} style={{ backgroundColor: "#28a745", borderColor: "#218838", color: "white", marginBottom: "10px" }}>
                            Imagen Tabla
                        </Button>
                    ) : null}


                    <ExportarExcelPoa dataSource={filteredData} todosLosDatos={data} />


                    <Button
                        onClick={() => setVisibleGrafico(!visibleGrafico)}
                        type="primary"
                        icon={visibleGrafico ? <EyeInvisibleOutlined /> : <BarChartOutlined />}
                        style={{ backgroundColor: "#28a745", borderColor: "#218838", color: "white" }}

                        onMouseLeave={(e) => (e.target.style.backgroundColor = "#28a745")}
                    >
                        {visibleGrafico ? "Ocultar" : "Mostrar"}
                    </Button>
                </div>
            </div>

            {visibleGrafico ? (
                <div className="container-fluid">
                    <div className="row">
                        {/* Gráfico de Barras */}
                        <div className="col-12 col-md-6 d-flex flex-column align-items-center chart-container mb-5 pt-2"
                            style={{
                                width: "100%",
                                maxWidth: "49%", // Permite que se acomode bien en mobile
                                boxShadow: "none",
                                cursor: "default"
                            }}>
                            <Button type="primary" onClick={() => handleOpenModal("barras")} style={{ padding: "5px" }}>
                                Maximizar
                            </Button>
                            <AvanceProyectosChartBarras data={filteredData} />
                        </div>

                        {/* Gráfico Radar */}
                        <div className="col-12 col-md-6 d-flex flex-column align-items-center chart-container mb-5 pt-2"
                            style={{
                                width: "100%",
                                maxWidth: "49%", // Permite que se acomode bien en mobile
                                boxShadow: "none",
                                cursor: "default"
                            }}>
                            <Button type="primary" onClick={() => handleOpenModal("radar")} style={{ padding: "5px" }}>
                                Maximizar
                            </Button>
                            <AvanceProyectosChart data={filteredData} />
                        </div>
                    </div>
                </div>

            ) : null}

            <Divider />


            <div
                ref={tableRef}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    overflowX: "auto" // Permite desplazamiento horizontal si es necesario
                }}
            >
                <Table
                    columns={columns}
                    dataSource={filteredData}
                    pagination={false}
                    style={{
                        marginBottom: "2em",
                        width: mostrarFechas ? "100%" : "60%", // Ajuste dinámico del ancho
                        maxWidth: "100%", // Evita que se desborde
                        minWidth: "600px", // Evita que sea demasiado pequeña en pantallas grandes
                        transition: "width 0.3s ease-in-out", // Animación suave
                    }}
                />
            </div>

            <Modal
                title="Porcentajes de Avance POA´s"
                open={visibleModal}
                onCancel={() => setVisibleModal(false)}
                footer={null}
                width={"90vw"}
                style={{ top: 30 }}
            >
                <div
                    style={{
                        width: "100%",
                        height: "80vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start", // Alinea arriba
                        overflow: "hidden",
                        paddingTop: "20px", // Espaciado superior opcional
                    }}
                >
                    <div
                        style={{
                            width: "80%",
                            height: "80%",
                            maxWidth: selectedChart === "radar" ? "600px" : "100%",
                            maxHeight: "500px",
                            marginTop: "0", // Asegura que la gráfica esté en la parte superior
                        }}
                    >
                        {selectedChart === "barras" ? (
                            <AvanceProyectosChartBarras data={filteredData} />
                        ) : selectedChart === "radar" ? (
                            <AvanceProyectosChart data={filteredData} />
                        ) : null}
                    </div>
                </div>
            </Modal>




            {/* Modal con la tabla de detalles */}
            <Modal
                title={`Detalles del Proyecto: ${proyectoSeleccionado?.codigo || ""} - ${proyectoSeleccionado?.nombreProyecto || ""}`}
                open={visible}
                onCancel={cerrarModal}
                modalStyle={{ top: 30 }} // Ajusta la posición
                style={{ top: 30 }} // Ajusta la posición
                footer={[
                    <Button key="close" onClick={cerrarModal}>
                        Cerrar
                    </Button>,
                ]}
                width={'80%'}
            >
                <div>
                    <Card style={{ background: "#f5f5f5", borderRadius: 10, padding: "15px" }}>
                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <Badge.Ribbon text="Inicio" color="blue">
                                    <Card size="small">
                                        <Text strong>Fecha Inicio POA: </Text>
                                        {proyectoSeleccionado?.fecha_inicio || "N/A"}
                                    </Card>
                                </Badge.Ribbon>
                            </Col>
                            <Col span={24}>
                                <Badge.Ribbon text="Terminación" color="red">
                                    <Card size="small">
                                        <Text strong>Fecha Terminación POA: </Text>
                                        {proyectoSeleccionado?.fecha_terminacion || "N/A"}
                                    </Card>
                                </Badge.Ribbon>
                            </Col>
                            <Col span={24}>
                                <Badge.Ribbon text="Última actualización" color="green">
                                    <Card size="small">
                                        <Text strong>Fecha de actualización de Avances: </Text>
                                        {proyectoSeleccionado?.fecha_actualizacion || "N/A"}
                                    </Card>
                                </Badge.Ribbon>
                            </Col>
                        </Row>
                    </Card>
                </div>


                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginBottom: 16 }}>
                    <Space size="large">
                        {conversionData.map((item) => (
                            <Badge
                                key={item.rango}
                                color={item.color}
                                text={`${item.rango} (${item.descripcion})`}
                            />
                        ))}
                    </Space>
                </div>


                <Divider />

                <Table
                    columns={columnasModal}
                    dataSource={proyectoSeleccionado?.historialAvances || []}
                    rowKey={(record) => `${record.mes}-${record.ano}`} // Clave única
                    rowClassName={(record) =>
                        record.mes === proyectoSeleccionado?.mes && record.ano === proyectoSeleccionado?.ano
                            ? "resaltado" // Aplicar clase CSS si coincide con la última actualización
                            : ""
                    }
                    pagination={false}
                />

            </Modal>


        </div>
    );
};

export default PoaResumen;
