import React from 'react';

const Header160 = () => {
    return (

        <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
            <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                <p>PROYECTO UTF <br /> COL 160 COL</p>
            </div>
            <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                <p>
                    Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de
                    capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la
                    transformación de los territorios PDET
                </p>
            </div>
        </div>

    );
};

export default Header160;
