import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/guardar-ippta';

export const saveIpptaData = async (data) => {
  try {
    const response = await axios.post(`${baseUrl}${endpoint}`, data);
    return response.data; // Retornar la respuesta del backend
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw new Error(error.response.data.message || 'Error en el servidor');
    }
    throw new Error('Error al conectar con el servidor');
  }
};
