import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint1 = '/api/proyectosPO';
const endpoint2 = '/api/componentesPO';
const endpoint3 = '/api/serviciomenuPOA';

const endpoint4 = '/api/dashboard-poa';





const getProyectosPO = async () => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint1}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los proyectos:', error);
    throw error;
  }
};

const getComponentesPO = async (codigo_proyecto) => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint2}`, {
      params: { codigo_proyecto }, // Envía el parámetro como query string
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los componentes:', error);
    throw error;
  }
};


const getDataProyectos = async (codigo_proyecto) => {

  if (!codigo_proyecto) {
    console.warn("El código de proyecto es inválido o no fue proporcionado.");
    return { status: 400, data: [] }; // 🔹 En lugar de null, retorna un objeto válido
  }

  try {
    const oid_proyecto = { codigo_proyecto };

    const response = await axios.get(`${baseUrl}${endpoint3}/${codigo_proyecto}`, {
      params: { oid_proyecto }, // Envía el parámetro como query string
    });


    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error("Error al obtener los componentes:", error);
    return { status: 500, data: [] }; // 🔹 Evita errores devolviendo un objeto vacío
  }
};


const dataIndicadores = async (codigo_proyecto) => {

  if (!codigo_proyecto) {
    console.warn("El código de proyecto es inválido o no fue proporcionado.");
    return { status: 400, data: [] };
  }

  try {
    const body = JSON.stringify({ proyecto: codigo_proyecto }); // 🔥 Formato esperado
    const response = await axios.post(`${baseUrl}${endpoint4}`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error("Error al obtener los indicadores:", error);
    return { status: 500, data: [] };
  }
};



export { getProyectosPO, getComponentesPO, getDataProyectos, dataIndicadores };