import React from "react";

const mapMes = {
    ENE: 1,
    FEB: 2,
    MAR: 3,
    ABR: 4,
    MAY: 5,
    JUN: 6,
    JUL: 7,
    AGO: 8,
    SEP: 9,
    OCT: 10,
    NOV: 11,
    DIC: 12,
};

function TablaFaseJSX({ faseObj, index, modo }) {
    const { p, e, fase } = faseObj; // 'fase' puede ser un número o etiqueta
    if (!p || !e) {
        return <p key={index}>No se encontraron valores P/E en la fase {fase || index + 1}</p>;
    }

    // Extraemos las claves de P y E (por ej. "AGO-2024", "SEP-2025", etc.)
    const clavesP = Object.keys(p);
    const clavesE = Object.keys(e);
    const allKeysSet = new Set([...clavesP, ...clavesE]);
    let allKeys = Array.from(allKeysSet);

    // Ordenamos las claves por año y mes
    allKeys.sort((a, b) => {
        const [mesA, yearA] = a.split("-");
        const [mesB, yearB] = b.split("-");
        const mesNumA = mapMes[mesA] || 0;
        const mesNumB = mapMes[mesB] || 0;
        const yearNumA = parseInt(yearA, 10) || 0;
        const yearNumB = parseInt(yearB, 10) || 0;
        if (yearNumA === yearNumB) {
            return mesNumA - mesNumB;
        }
        return yearNumA - yearNumB;
    });

    const getTitleFase = (faseObj, index) => {


        if (faseObj.fase === '1') {
            return 'Fase de alistamiento';
        } else if (faseObj.fase === '2') {
            return 'Fase de ejecución';
        } else {
            // Si no es 1 o 2, usa el valor de fase o el índice + 1
            return `Fase ${faseObj.fase || index + 1}`;
        }
    };

    return (
        <div key={index} style={{ marginBottom: '32px' }}>
            <hr />
            {modo && <h4>{getTitleFase(faseObj, index)}</h4>}

            <div style={{ overflowX: 'auto' }}>
                <table
                    title="Resumen avance en la ejecución"
                    cellSpacing="0"
                    cellPadding="4"
                    className="TablaHover"
                    style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                        marginTop: '16px',
                        border: '0px solid #ccc !important',
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                rowSpan={2}
                                border={0}
                                style={{
                                    position: 'sticky',
                                    left: 0,
                                    top: 0, // Fija el encabezado en la parte superior
                                    backgroundColor: '#FFF',
                                    padding: '6px 12px',
                                    textAlign: 'left',
                                    border: '0px solid #ccc !important',
                                    zIndex: 4, // Asegúrate de un zIndex alto para que se sobreponga a las celdas del cuerpo
                                }}
                            >
                                {/* Puedes dejarlo vacío o colocar un título */}

                            </th>
                            {allKeys.map((mesAnio) => (
                                <th
                                    key={mesAnio}
                                    colSpan={2}
                                    style={{
                                        position: 'sticky',
                                        top: 0, // Fija este encabezado
                                        backgroundColor: '#042956',
                                        padding: '8px 12px',
                                        textAlign: 'center',
                                        border: '1px solid #ccc',
                                        color: '#fff',
                                        fontSize: 12,
                                        zIndex: 3,
                                    }}
                                >
                                    {mesAnio.replace('-', ' ')}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {allKeys.map((mesAnio) => (
                                <React.Fragment key={`${mesAnio}-sub`}>
                                    <th
                                        style={{
                                            backgroundColor: '#408cc85c',
                                            textAlign: 'center',
                                            border: '1px solid #ccc',
                                        }}
                                    >
                                        P
                                    </th>
                                    <th
                                        style={{
                                            backgroundColor: '#19875463',
                                            textAlign: 'center',
                                            border: '1px solid #ccc',
                                        }}
                                    >
                                        E
                                    </th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    position: 'sticky',
                                    left: 0,
                                    fontWeight: 'bold',
                                    backgroundColor: '#042956',
                                    border: '1px solid #ccc',
                                    color: '#fff',
                                    textAlign: 'left',
                                    padding: '8px 12px',
                                    zIndex: 1,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                META MENSUAL DE AVANCE EN LA EJECUCIÓN
                            </td>
                            {allKeys.map((mesAnio) => {
                                const valorP = p[mesAnio] ?? 0;
                                const valorE = e[mesAnio] ?? 0;
                                return (
                                    <React.Fragment key={`${mesAnio}-vals`}>
                                        <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>

                                            {Math.trunc(valorP)}%

                                        </td>
                                        <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                                            {Math.trunc(valorE)}%
                                        </td>
                                    </React.Fragment>
                                );
                            })}
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td
                                style={{
                                    position: 'sticky',
                                    left: 0,
                                    fontWeight: 'bold',
                                    backgroundColor: '#042956',
                                    border: '1px solid #ccc',
                                    textAlign: 'left',
                                    padding: '8px 12px',
                                    color: '#fff',
                                    zIndex: 1,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                TOTAL AVANCE
                            </td>
                            {(() => {
                                let totalP = 0;
                                let totalE = 0;
                                return allKeys.map((mesAnio) => {
                                    totalP += p[mesAnio] ?? 0;
                                    totalE += e[mesAnio] ?? 0;
                                    return (
                                        <React.Fragment key={`${mesAnio}-total`}>
                                            <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                                                {totalP.toFixed(0)}%
                                            </td>
                                            <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                                                {totalE.toFixed(0)}%
                                            </td>
                                        </React.Fragment>
                                    );
                                });
                            })()}
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
}

function TablaConsolidado({ valoresJsonPeso }) {

    if (!valoresJsonPeso?.fases?.length) {
        return <p>No hay datos de avance disponibles para consolidado</p>;
    }

    // Obtenemos todas las claves únicas de todas las fases
    const allKeysSet = new Set();
    valoresJsonPeso.fases.forEach((faseObj) => {
        const { p, e } = faseObj;
        if (p) Object.keys(p).forEach((key) => allKeysSet.add(key));
        if (e) Object.keys(e).forEach((key) => allKeysSet.add(key));
    });
    let allKeys = Array.from(allKeysSet);

    // Ordenamos las claves
    allKeys.sort((a, b) => {
        const [mesA, yearA] = a.split("-");
        const [mesB, yearB] = b.split("-");
        const mesNumA = mapMes[mesA] || 0;
        const mesNumB = mapMes[mesB] || 0;
        const yearNumA = parseInt(yearA, 10) || 0;
        const yearNumB = parseInt(yearB, 10) || 0;
        if (yearNumA === yearNumB) {
            return mesNumA - mesNumB;
        }
        return yearNumA - yearNumB;
    });

    // Inicializamos los acumuladores para cada clave
    const consolidatedP = {};
    const consolidatedE = {};
    allKeys.forEach((key) => {
        consolidatedP[key] = 0;
        consolidatedE[key] = 0;
    });

    // Sumamos los valores de cada fase para cada clave
    valoresJsonPeso.fases.forEach((faseObj) => {
        const { p, e } = faseObj;
        allKeys.forEach((key) => {
            if (p && typeof p[key] === 'number') {
                consolidatedP[key] += p[key];
            }
            if (e && typeof e[key] === 'number') {
                consolidatedE[key] += e[key];
            }
        });
    });

    return (
        <>
            <h4>Total Avance Proyecto (Fase de Alistamiento y Fase de Ejecución).</h4>
            <div style={{ overflowX: 'auto' }}>
                <table
                    title="Consolidado avance en la ejecución"
                    cellSpacing="0"
                    cellPadding="4"
                    border={0}
                    className="TablaHover"
                    style={{
                        borderCollapse: 'collapse',
                        width: '100%',
                        marginTop: '16px',
                        border: '0px solid #ccc',
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                rowSpan={2}
                                style={{
                                    position: 'sticky',
                                    left: 0,
                                    backgroundColor: '#FFF',
                                    padding: '6px 12px',
                                    textAlign: 'left',
                                    border: '0px solid #ccc !important',
                                    zIndex: 2,
                                }}
                            >
                                {/* Título opcional */}
                            </th>
                            {allKeys.map((mesAnio) => (
                                <th
                                    key={mesAnio}
                                    colSpan={2}
                                    style={{
                                        backgroundColor: '#042956',
                                        padding: '8px 12px',
                                        textAlign: 'center',
                                        border: '1px solid #ccc',
                                        color: '#fff',
                                        fontSize: 12,
                                    }}
                                >
                                    {mesAnio.replace('-', ' ')}
                                </th>
                            ))}
                        </tr>
                        <tr>
                            {allKeys.map((mesAnio) => (
                                <React.Fragment key={`${mesAnio}-sub`}>
                                    <th
                                        style={{
                                            backgroundColor: '#408cc85c',
                                            textAlign: 'center',
                                            border: '0px solid #ccc !important',
                                        }}
                                    >
                                        P
                                    </th>
                                    <th
                                        style={{
                                            backgroundColor: '#19875463',
                                            textAlign: 'center',
                                            border: '0px solid #ccc !important',
                                        }}
                                    >
                                        E
                                    </th>
                                </React.Fragment>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td
                                style={{
                                    position: 'sticky',
                                    left: 0,
                                    fontWeight: 'bold',
                                    backgroundColor: '#042956',
                                    border: '0px solid #ccc !importan',
                                    color: '#fff',
                                    textAlign: 'left',
                                    padding: '8px 12px',
                                    zIndex: 1,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                META MENSUAL DE AVANCE EN LA EJECUCIÓN
                            </td>
                            {allKeys.map((mesAnio) => (

                                <React.Fragment key={`${mesAnio}-vals`}>
                                    <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>

                                        {Math.trunc(consolidatedP[mesAnio])}%

                                    </td>
                                    <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                                        {Math.trunc(consolidatedE[mesAnio])}%
                                    </td>
                                </React.Fragment>
                            ))}
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td
                                style={{
                                    position: 'sticky',
                                    left: 0,
                                    fontWeight: 'bold',
                                    backgroundColor: '#042956',
                                    border: '1px solid #ccc',
                                    textAlign: 'left',
                                    padding: '8px 12px',
                                    color: '#fff',
                                    zIndex: 1,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                TOTAL AVANCE
                            </td>
                            {(() => {
                                let totalP = 0;
                                let totalE = 0;
                                return allKeys.map((mesAnio) => {
                                    totalP += consolidatedP[mesAnio];
                                    totalE += consolidatedE[mesAnio];
                                    return (
                                        <React.Fragment key={`${mesAnio}-total`}>
                                            <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                                                {totalP.toFixed(0)}%
                                            </td>
                                            <td style={{ textAlign: 'center', border: '1px solid #ccc' }}>
                                                {totalE.toFixed(0)}%
                                            </td>
                                        </React.Fragment>
                                    );
                                });
                            })()}
                        </tr>
                    </tfoot>
                </table>

            </div>
        </>
    );
}

export default function TablaAvanceConsolidado({ valoresJsonPeso, modo }) {

    if (
        !valoresJsonPeso?.fases?.length ||
        valoresJsonPeso.fases.every((fase) => {
            const pVacio = !fase.p || Object.keys(fase.p).length === 0;
            const eVacio = !fase.e || Object.keys(fase.e).length === 0;
            return pVacio && eVacio;
        })
    ) {
        return <p>No hay datos de avance disponibles</p>;
    }

    const tablaRvisible = (modo === 'inicial');

    return (
        <div style={{ overflowX: 'auto' }}>
            {valoresJsonPeso.fases.map((faseObj, index) => (

                <TablaFaseJSX key={index} faseObj={faseObj} index={index} modo={tablaRvisible} />
            ))}
            {valoresJsonPeso.fases.length > 1 && (

                <TablaConsolidado valoresJsonPeso={valoresJsonPeso} />
            )}
        </div>
    );
}

