import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { message, InputNumber, Select, Button, Form, Popconfirm, Input, Col, DatePicker, Drawer, Table, ConfigProvider, Row, Tooltip } from "antd";
import { PlusOutlined, QuestionCircleOutlined, CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import esES from "antd/es/locale/es_ES";
import moment from "moment";
import "moment/locale/es"; // Importar moment en español
import { getRegiones, setCohorte, updateCohorte } from "../../../services/Producto_3/Uniminuto/uniminutosServices"
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../../store/auth/selectors';
import { v4 as uuidv4 } from 'uuid';


moment.locale("es"); // Configurar moment en español
const { Option } = Select;

const FormularioCohorte = forwardRef((props, ref) => {


    const [modo, setModo] = useState("crear"); // "crear" o "editar"
    const [registroActual, setRegistroActual] = useState(null);

    //Control de Permisos
    const appName = 'uniminuto';
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionDelete = permissions.priv_delete === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionPrint = permissions.priv_print === 'Y';

    const user = useSelector((state) => state.auth.user);
    const userLogin = user.username;


    // Exponer funciones al componente padre
    useImperativeHandle(ref, () => ({
        showModal,
        editRecord,
        permissionDelete,
        permissionUpdate,
        permissionInsert,
        permissionPrint,
        userLogin

    }));


    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const [subregiones, setSubregiones] = useState([]);
    const [municipiosPorFila, setMunicipiosPorFila] = useState({});



    const [sesiones, setSesiones] = useState([]);
    const [contadorSesiones, setContadorSesiones] = useState(1);


    // Cargar subregiones (con sus municipios) al montar el componente
    useEffect(() => {
        const fetchDataSubregiones = async () => {
            try {
                const dataSubregion = await getRegiones();
                // Verifica que dataSubregion existe y tenga success true
                if (dataSubregion && dataSubregion.success && Array.isArray(dataSubregion.data)) {
                    setSubregiones(dataSubregion.data);
                } else {
                    // Si no se cumple, establece un array vacío o muestra un mensaje de error
                    setSubregiones([]);
                    console.error("La respuesta de subregiones no tiene el formato esperado.");
                }
            } catch (error) {
                console.error("Error al obtener las subregiones:", error);
            }
        };
        fetchDataSubregiones();
    }, []);


    // Función que se llama cuando cambia la subregión en una fila
    const handleSubregionChange = (rowKey, subregionOid, tabla) => {
        // Buscamos la subregión seleccionada
        const selected = subregiones.find(
            (sub) => sub.subregion_oid === subregionOid
        );
        if (selected) {
            setMunicipiosPorFila((prev) => ({
                ...prev,
                [rowKey]: selected.municipios,
            }));
            // Reiniciamos el valor del campo municipio en la fila, usando el mismo patrón de nombre
            form.setFieldsValue({ [`municipio_${tabla}_${rowKey}`]: undefined });
        } else {
            setMunicipiosPorFila((prev) => ({
                ...prev,
                [rowKey]: [],
            }));
        }
    };


    // Mostrar modal
    // Función para abrir en modo crear
    const showModal = () => {

        setModo("crear");
        setRegistroActual(null);
        form.resetFields();
        setVisible(true);
    };

    // Ocultar drawer
    const handleCancel = () => {
        form.resetFields();
        setSesiones([]);
        // Volver a 1 los contadores si quieres
        setContadorSesiones(1);
        setVisible(false);
    };

    // Función para abrir en modo editar y cargar datos
    const editRecord = (registroCompleto) => {

        if (userLogin === undefined) {
            message.warning("Debe iniciar sesión nuevamente.");
            return;
        }
        setModo("editar");
        setRegistroActual(registroCompleto);

        // 1. Cargar datos generales del formulario
        form.setFieldsValue({
            cohorte: registroCompleto.JSONDataCohorte.cohorte,
            fechaInicio: moment(registroCompleto.JSONDataCohorte.fechaInicio, "YYYY-MM-DD"),
            fechaFinal: moment(registroCompleto.JSONDataCohorte.fechaFinal, "YYYY-MM-DD"),
            resumen: registroCompleto.JSONDataCohorte.resumen,
        });

        // 2. Transformar las sesiones a un array que el Table muestre
        const sesionesEdit = (registroCompleto.JSONDataCohorte.detalleSesiones || []).map((sesion) => {
            // Determinamos una key única; si ya existe, se reutiliza
            const rowKey = sesion.key || `sesiones-${sesion.numRegistro}`;
            return {
                ...sesion,
                key: rowKey,
            };
        });

        // 2B. Asignar al estado para que se muestre en la tabla
        setSesiones(sesionesEdit);

        // 2C. Para cada sesión, llamamos a form.setFieldsValue con los nombres que usan tus columnas

        sesionesEdit.forEach((ses) => {

            const rowKey = ses.key;
            form.setFieldsValue({
                [`numSesion_sesiones_${rowKey}`]: ses.numSesion,
                [`fechaSesion_sesiones_${rowKey}`]: ses.fechaSesion ? moment(ses.fechaSesion, "YYYY-MM-DD") : null,
                [`subregion_sesiones_${rowKey}`]: ses.subregion && ses.subregion.oid ? ses.subregion.oid : "",
                [`municipio_sesiones_${rowKey}`]: ses.municipio && ses.municipio.oid ? ses.municipio.oid : "",
                [`asistentes_sesiones_${rowKey}`]: ses.asistentes ?? "",
                [`mujeres_sesiones_${rowKey}`]: ses.mujeres ?? "",
                [`pertenenciaEtnica_sesiones_${rowKey}`]: ses.pertenenciaEtnica ?? "",

                // 🔹 Agregar Modalidad Sesión, Módulo y Periodo
                [`modalidadSesion_sesiones_${rowKey}`]: ses.modalidadSesion || "", // Puede ser Virtual, Presencial, Autónomo
                [`modulo_sesiones_${rowKey}`]: ses.modulo || null, // Aseguramos que sea un número
                [`periodo_sesiones_${rowKey}`]: Array.isArray(ses.periodo) ? ses.periodo : [], // Aseguramos que sea un array

            });

            if (ses.subregion?.oid) {
                const foundSubregion = subregiones.find(
                    (sub) => sub.subregion_oid === ses.subregion.oid
                );
                if (foundSubregion) {
                    setMunicipiosPorFila((prev) => ({
                        ...prev,
                        [rowKey]: foundSubregion.municipios, // array de municipios de esa subregión
                    }));
                }
            }

        });

        // 4. Finalmente, abrimos el Drawer/Modal
        setVisible(true);
    };


    // Agregar registro a Sesiones
    const agregarSesion = () => {

        const newKey = `sesion-${contadorSesiones}-${uuidv4()}`;

        setSesiones((prev) => [
            ...prev,
            {

                key: newKey,
                numRegistro: prev.length + 1,
                fechaSesion: "",
                subregion: "",
                municipio: "",
                asistentes: "",
                mujeres: "",
                porcentajeMujeres: "",
                hombres: "",
                porcentajeHombres: "",
                pertenenciaEtnica: "",
                porcentajeEtnico: "",
                modalidadSesion: "",
                modulo: "",
                periodo: [],

            },
        ]);

        form.setFieldsValue({
            [`numSesion_sesiones_${newKey}`]: "",
            [`fechaSesion_sesiones_${newKey}`]: null,
            [`subregion_sesiones_${newKey}`]: "",
            [`municipio_sesiones_${newKey}`]: "",
            [`asistentes_sesiones_${newKey}`]: "",
            [`mujeres_sesiones_${newKey}`]: "",
            [`pertenenciaEtnica_sesiones_${newKey}`]: "",
        });

        setContadorSesiones((old) => old + 1); // incrementa el contador para la próxima fila
    };


    // Eliminar sesión
    const eliminarSesion = (key) => {
        setSesiones((prev) => prev.filter((item) => item.key !== key));
    };

    // Guardar datos y mostrar en JSON
    const handleSave = async () => {
        try {
            const values = await form.validateFields();

            // Verifica que al menos una de las tablas tenga datos
            if (sesiones.length === 0) {
                message.warning("Debe registrar datos en las Sesiones");
                return;
            }

            // Actualizamos las filas de sesiones para incluir la información de subregión y municipio
            const updatedSesiones = sesiones.map((row) => {
                // Recupera los valores seleccionados de cada fila
                const subregionValue = form.getFieldValue(`subregion_sesiones_${row.key}`);
                const municipioValue = form.getFieldValue(`municipio_sesiones_${row.key}`);
                const fechaSesionValue = form.getFieldValue(`fechaSesion_sesiones_${row.key}`);
                const numSesionValue = form.getFieldValue(`numSesion_sesiones_${row.key}`);

                // 🔹 Recuperar los nuevos valores de Modalidad Sesión, Módulo y Periodo
                const modalidadSesionValue = form.getFieldValue(`modalidadSesion_sesiones_${row.key}`);
                const moduloValue = form.getFieldValue(`modulo_sesiones_${row.key}`);
                const periodoValue = form.getFieldValue(`periodo_sesiones_${row.key}`); // Este será un array


                // Busca el objeto de subregión según el OID seleccionado

                const subObj = subregiones.find((s) => s.subregion_oid === subregionValue);
                // Busca el objeto de municipio en la lista asignada para esa fila
                const muniObj = (municipiosPorFila[row.key] || []).find((m) => m.municipio_oid === municipioValue);

                return {
                    ...row,
                    // Guarda la fecha en formato "YYYY-MM-DD"
                    fechaSesion: fechaSesionValue ? fechaSesionValue.format("YYYY-MM-DD") : "",
                    // Agrega el número de sesión obtenido del formulario
                    numSesion: numSesionValue !== undefined ? numSesionValue : "",
                    // Agrega un objeto con el OID y nombre de la subregión
                    subregion: subObj ? { oid: subObj.subregion_oid, nombre: subObj.nombre_subregion_pdet } : "",
                    // Agrega un objeto con el OID y nombre del municipio
                    municipio: muniObj ? { oid: muniObj.municipio_oid, nombre: muniObj.municipio } : "",


                    // 🔹 Agregar los nuevos campos al JSON
                    modalidadSesion: modalidadSesionValue || "",
                    modulo: moduloValue !== undefined ? moduloValue : null,
                    periodo: Array.isArray(periodoValue) ? periodoValue : [], // Se guarda como array


                };
            });

            const data = {
                cohorte: values.cohorte,
                fechaInicio: values.fechaInicio ? values.fechaInicio.format("YYYY-MM-DD") : null,
                fechaFinal: values.fechaFinal ? values.fechaFinal.format("YYYY-MM-DD") : null,
                resumen: values.resumen || "",
                detalleSesiones: updatedSesiones,
            };

            let result;

            if (modo === "editar") {
                // Llamas a tu servicio de actualización
                result = await updateCohorte(userLogin, registroActual.oid, registroActual, data);
            } else {
                // De lo contrario, estás creando un nuevo registro
                result = await setCohorte(userLogin, data);
            }

            if (result && result.oid) {
                message.success("Registro guardado con éxito");
                // Reinicia el formulario y vacía los estados

                // Aquí se notifica al padre
                if (props.onRegistroGuardado) {
                    props.onRegistroGuardado();
                }

                form.resetFields();
                setSesiones([]);

                setMunicipiosPorFila({});

                setContadorSesiones(1);
                setVisible(false);
            } else {
                message.error("Error al guardar el registro, por favor intente nuevamente");
            }
        } catch (error) {

            message.error("Error al guardar el registro, verifique los campos requeridos");
        }
    };

    // Columnas de la tabla de sesiones
    const actualizarValores = (key, campo, valor, tabla) => {
        const actualizarTabla = (setSesiones) => {
            setSesiones((prev) =>
                prev.map((item) => {
                    if (item.key === key) {
                        const nuevoItem = { ...item, [campo]: valor };

                        // Parseamos los valores a número (si no es número, toma 0)
                        const totalAsistentes = parseInt(nuevoItem.asistentes, 10) || 0;
                        const mujeres = parseInt(nuevoItem.mujeres, 10);
                        const pertenenciaEtnica = parseInt(nuevoItem.pertenenciaEtnica, 10);

                        // -------------------------------------
                        // VALIDACIONES y CÁLCULOS
                        // -------------------------------------

                        // 1) Si "Mujeres" es mayor que "Asistentes", ajustamos:
                        if (!isNaN(mujeres) && mujeres > totalAsistentes) {
                            nuevoItem.mujeres = totalAsistentes;
                        }

                        // 2) Calculamos solo si Asistentes > 0 y Mujeres es un número válido >= 0
                        if (totalAsistentes > 0 && !isNaN(mujeres) && mujeres >= 0) {
                            // Hombres = Asistentes - Mujeres
                            const hombres = totalAsistentes - nuevoItem.mujeres;
                            nuevoItem.hombres = hombres;

                            // % Mujeres
                            nuevoItem.porcentajeMujeres = ((nuevoItem.mujeres / totalAsistentes) * 100).toFixed(2);
                            // % Hombres
                            nuevoItem.porcentajeHombres = ((hombres / totalAsistentes) * 100).toFixed(2);
                        } else {
                            // Si no cumple la condición, puedes dejar en blanco o poner "0.00"
                            nuevoItem.hombres = "";
                            nuevoItem.porcentajeMujeres = "";
                            nuevoItem.porcentajeHombres = "";
                        }

                        // 3) Pertenencia Étnica solo la calculamos si Asistentes > 0 y pertenencia > 0
                        if (totalAsistentes > 0 && !isNaN(pertenenciaEtnica) && pertenenciaEtnica >= 0) {
                            // Tampoco puede ser mayor que Asistentes
                            if (pertenenciaEtnica > totalAsistentes) {
                                nuevoItem.pertenenciaEtnica = totalAsistentes;
                            }
                            nuevoItem.porcentajeEtnico = ((nuevoItem.pertenenciaEtnica / totalAsistentes) * 100).toFixed(2);
                        } else {
                            // Si no cumple, lo ponemos vacío o cero
                            nuevoItem.porcentajeEtnico = "";
                        }

                        return nuevoItem;
                    }
                    return item;
                })
            );
        };

        // Se aplica según la tabla: 
        if (tabla === "sesiones") {
            actualizarTabla(setSesiones);
        }
    };

    const getColumns = (tabla, onDelete, subregiones, municipiosPorFila, handleSubregionChange) => ([

        ...(permissionDelete ? [ // 🔹 Si `permissionDelete` es `true`, agrega la columna
            {
                title: "",
                key: `acciones_${tabla}`,
                align: "center",
                render: (_, record) => (
                    <Popconfirm
                        title="¿Está segur@ de eliminar este registro?"
                        description="No podrá revertir esta acción."
                        onConfirm={() => onDelete(record.key)}  // 🔹 Solo se ejecuta si el usuario confirma
                        okText="Sí, eliminar"
                        cancelText="Cancelar"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                    >
                        <Button
                            danger
                            icon={<DeleteOutlined />}
                        />
                    </Popconfirm>
                ),
            }

        ] : []),
        {

            title: (
                <div style={{ height: '30px', lineHeight: '30px', overflow: 'hidden' }}>
                    # ID
                </div>
            ),
            dataIndex: "numRegistro",
            key: "numRegistro",
            align: "center",
            fixed: 'left',
            width: 40,
            render: (text) => (
                <span style={{ whiteSpace: "nowrap" }}>
                    {text}
                </span>
            ),
        },
        {
            title: "# Sesión",
            dataIndex: "numSesion",
            key: "numSesion",
            align: "center",
            width: 70,
            render: (text, record) => (
                <Form.Item
                    name={`numSesion_${tabla}_${record.key}`}
                    style={{ marginBottom: 0 }}
                    rules={[
                        {
                            validator(_, value) {
                                if (value === undefined || value === null || value === "") {
                                    return Promise.reject(new Error("Campo requerido"));
                                }
                                if (!Number.isInteger(value) || value < 1 || value > 500) {
                                    return Promise.reject(new Error("Ingrese un número entero entre 1 y 100"));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}

                >
                    <InputNumber min={1} max={500} style={{ width: "50px", maxWidth: "50px" }} />
                </Form.Item>
            ),
        },
        {
            title: "Modalidad *",
            dataIndex: "modalidadSesion",
            key: `modalidadSesion_${tabla}`,
            align: "center",
            fixed: 'left',
            width: 150,
            render: (value, record) => (
                <Form.Item
                    name={`modalidadSesion_${tabla}_${record.key}`}
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: "Campo requerido" }]}
                >
                    <Select
                        placeholder="Seleccione modalidad"
                        style={{ width: "140px", textAlign: "center" }}
                        onChange={(selectedValue) =>
                            actualizarValores(record.key, "modalidadSesion", selectedValue, tabla)
                        }
                    >
                        <Option value="Virtual">Virtual</Option>
                        <Option value="Presencial">Presencial</Option>
                        <Option value="Autónomo">Autónomo</Option>
                    </Select>
                </Form.Item>
            ),
        },
        {
            title: "Módulo *",
            dataIndex: "modulo",
            key: `modulo_${tabla}`,
            align: "center",
            fixed: 'left',
            width: 100,
            render: (value, record) => (
                <Form.Item
                    name={`modulo_${tabla}_${record.key}`}
                    style={{ marginBottom: 0 }}
                    rules={[
                        { required: true, message: "Campo requerido" },
                        {
                            validator(_, value) {
                                if (value === undefined || value === null || value === "") {
                                    return Promise.reject(new Error("Campo requerido"));
                                }
                                if (!Number.isInteger(value) || value < 1 || value > 30) {
                                    return Promise.reject(new Error("Ingrese un número entero entre 1 y 30"));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <InputNumber
                        min={1}
                        max={30}
                        style={{ width: "80px", textAlign: "center" }}
                        onChange={(value) => actualizarValores(record.key, "modulo", value, tabla)}
                    />
                </Form.Item>
            ),
        },

        {
            title: "Fecha *",
            dataIndex: "fechaSesion",
            key: `fechaSesion_${tabla}`,
            align: "center",
            width: 120,
            render: (value, record, index) => (
                <Form.Item
                    // 🔹 Nombre con prefijo para no chocar 
                    name={`fechaSesion_${tabla}_${record.key}`}
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: "Campo requerido" }]}
                >
                    <DatePicker format="DD/MM/YYYY" style={{ width: "120px", maxWidth: "120px" }} />
                </Form.Item>
            ),
        },
        {
            title: "Periodo *",
            dataIndex: "periodo",
            key: `periodo_${tabla}`,
            align: "center",
            width: 200,
            render: (value, record) => (
                <Form.Item
                    name={`periodo_${tabla}_${record.key}`}
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: "Campo requerido" }]}
                >
                    <Select
                        mode="multiple"  // 🔹 Permite selección múltiple
                        placeholder="Seleccione meses"
                        style={{ width: "200px", textAlign: "center" }}
                        maxTagCount="responsive"
                        onChange={(selectedValues) =>
                            actualizarValores(record.key, "periodo", selectedValues, tabla)
                        }
                    >
                        {[
                            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
                        ].map((mes) => (
                            <Select.Option key={mes} value={mes}>
                                {mes}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            ),
        },
        {
            title: "Subregión *",
            dataIndex: "subregion",
            key: `subregion_${tabla}`,
            align: "center",
            width: 100,
            ellipsis: true,
            render: (value, record) => (
                <Form.Item
                    name={`subregion_${tabla}_${record.key}`}
                    style={{
                        marginBottom: 0,
                        height: "32px",
                        maxHeight: "32px",
                    }}
                    rules={[{ required: true, message: "Campo requerido" }]}
                >
                    <Select
                        showSearch
                        placeholder="Seleccione subregión"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        popupMatchSelectWidth={false}  // Permite que el dropdown tenga un ancho diferente al del select
                        style={{
                            width: "150px",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                        onChange={(selectedValue) =>
                            handleSubregionChange(record.key, selectedValue, tabla)
                        }
                    >
                        {(subregiones || []).map((sub) => (
                            <Option key={sub.subregion_oid} value={sub.subregion_oid}>
                                {sub.nombre_subregion_pdet}
                            </Option>
                        ))}

                    </Select>
                </Form.Item>
            ),
        },
        {
            title: "Municipio *",
            dataIndex: "municipio",
            key: `municipio_${tabla}`,
            align: "center",
            width: 150,
            ellipsis: true,
            render: (value, record) => (
                <Form.Item
                    name={`municipio_${tabla}_${record.key}`}
                    style={{
                        marginBottom: 0,
                        height: "32px",
                        maxHeight: "32px",
                    }}
                    rules={[{ required: true, message: "Campo requerido" }]}
                >
                    <Select
                        showSearch
                        placeholder="Seleccione municipio"
                        popupMatchSelectWidth={false}
                        style={{
                            width: "150px",
                            maxWidth: "150px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().includes(input.toLowerCase())
                        }
                        optionLabelProp="children"  // <-- Clave para mostrar el nombre en lugar del OID
                    >
                        {(municipiosPorFila[record.key] || []).map((muni) => (
                            <Option key={muni.municipio_oid} value={muni.municipio_oid}>
                                {muni.municipio}
                            </Option>
                        ))}
                    </Select>

                </Form.Item>
            ),
        },
        {
            title: "Asistentes *",
            dataIndex: "asistentes",
            key: `asistentes_${tabla}`,
            align: "center",
            width: 100,
            render: (value, record, index) => (
                <Form.Item
                    name={`asistentes_${tabla}_${record.key}`} // Nombre único
                    style={{ marginBottom: 0 }}
                    rules={[{ required: true, message: "Campo requerido" }]}
                >
                    <Input
                        type="number"
                        min={1}
                        onChange={(e) => {
                            actualizarValores(record.key, "asistentes", e.target.value, tabla);
                            // Forzamos la revalidación del campo "Mujeres"


                            // 🔹 Solo revalida "Mujeres" si ya tiene un valor
                            if (form.getFieldValue(`mujeres_${tabla}_${record.key}`) !== undefined) {
                                form.validateFields([`mujeres_${tabla}_${record.key}`]);
                            }

                        }}
                        style={{ width: "80px", maxWidth: "80px", textAlign: "center" }}
                    />
                </Form.Item>
            ),
        },
        {
            title: "Mujeres *",
            dataIndex: "mujeres",
            key: `mujeres_${tabla}`,
            align: "center",
            width: 100,
            render: (value, record, index) => (
                <Form.Item
                    name={`mujeres_${tabla}_${record.key}`}
                    style={{ marginBottom: 0 }}
                    dependencies={[`asistentes_${tabla}_${record.key}`]}
                    rules={[
                        { required: true, message: "Campo requerido" },
                        ({ getFieldValue }) => ({
                            validator(_, val) {
                                const totalAsistentes = Number(
                                    getFieldValue(`asistentes_${tabla}_${record.key}`)
                                ) || 0;

                                if (val === undefined || val === null || val === "") {
                                    return Promise.resolve();
                                }


                                if (totalAsistentes === 0) {
                                    return Promise.reject(
                                        new Error("Ingrese primero el número de Asistentes")
                                    );
                                }
                                if (val < 0) {
                                    return Promise.reject(
                                        new Error("El número de Mujeres no puede ser negativo")
                                    );
                                }
                                if (val > totalAsistentes) {
                                    return Promise.reject(
                                        new Error("No puede ser mayor que Asistentes")
                                    );
                                }
                                return Promise.resolve();
                            },
                        }),


                    ]}
                >
                    <Input
                        type="number"
                        min={0}
                        onChange={(e) => actualizarValores(record.key, "mujeres", e.target.value, tabla)}
                        style={{ width: "80px", maxWidth: "80px", textAlign: "center" }}
                    />
                </Form.Item>
            ),
        },
        {
            title: "% Mujeres",
            dataIndex: "porcentajeMujeres",
            key: `porcentajeMujeres_${tabla}`,
            align: "center",
            width: 100,
            render: (_, record) => {
                const valor = record.porcentajeMujeres;
                return (
                    <Input
                        value={valor !== "" && valor !== undefined ? `${valor}%` : ""}
                        disabled
                        style={{ textAlign: "center", backgroundColor: "#f5f5f5", width: "100%" }}
                    />
                );
            },
        },
        {
            title: "Hombres",
            dataIndex: "hombres",
            key: `hombres_${tabla}`,
            align: "center",
            width: 100,
            render: (_, record) => (
                <Input
                    value={record.hombres}
                    disabled
                    style={{ textAlign: "center", backgroundColor: "#f5f5f5", width: "100%" }}
                />
            ),
        },
        {
            title: "% Hombres",
            dataIndex: "porcentajeHombres",
            key: `porcentajeHombres_${tabla}`,
            align: "center",
            width: 100,
            render: (_, record) => {
                const valor = record.porcentajeHombres;
                return (
                    <Input
                        value={valor !== "" && valor !== undefined ? `${valor}%` : ""}
                        disabled
                        style={{ textAlign: "center", backgroundColor: "#f5f5f5", width: "100%" }}
                    />
                );
            },
        },
        {
            title: "Pert. Étnica NARP*",
            dataIndex: "pertenenciaEtnica",
            key: `pertenenciaEtnica_${tabla}`,
            align: "center",
            width: 150,
            render: (value, record, index) => (
                <Form.Item
                    name={`pertenenciaEtnica_${tabla}_${record.key}`}
                    style={{ marginBottom: 0 }}
                    dependencies={[`asistentes_${tabla}_${record.key}`]}
                    rules={[
                        { required: true, message: "Campo requerido" },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                // Obtenemos el número de Asistentes de la misma fila
                                const totalAsistentes = Number(
                                    getFieldValue(`asistentes_${tabla}_${record.key}`)
                                ) || 0;

                                if (value === undefined || value === null || value === "") {
                                    // Si está vacío, permitimos que otra regla (required) se encargue
                                    return Promise.resolve();
                                }

                                if (value < 0) {
                                    return Promise.reject(
                                        new Error("El valor no puede ser negativo")
                                    );
                                }

                                if (value > totalAsistentes) {
                                    return Promise.reject(
                                        new Error("No puede superar el número de Asistentes")
                                    );
                                }

                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <Input
                        type="number"
                        min={0}
                        style={{ textAlign: "center", width: '80px', maxWidth: '80px' }}
                        onChange={(e) => actualizarValores(record.key, "pertenenciaEtnica", e.target.value, tabla)}
                    />
                </Form.Item>
            ),
        },
        {
            title: "% P.É",
            dataIndex: "porcentajeEtnico",
            key: `porcentajeEtnico_${tabla}`,
            align: "center",
            width: 70,
            render: (_, record) => {
                const valor = record.porcentajeEtnico;
                return (
                    <Input
                        value={valor !== "" && valor !== undefined ? `${valor}%` : ""}
                        disabled
                        style={{ textAlign: "center", backgroundColor: "#f5f5f5", width: "70px" }}
                    />
                );
            },
        },

    ]);


    // Dentro de tu componente:
    const columnsSesiones = getColumns("sesiones", eliminarSesion, subregiones, municipiosPorFila, handleSubregionChange);


    return (
        <div className="container">
            <Drawer
                title={
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', // Centra horizontalmente el contenido
                            width: '100%',
                            position: 'relative',     // Permite posicionar el botón con absolute si quieres
                        }}
                    >
                        {/* Título centrado */}
                        <h4 style={{ margin: 0, fontWeight: 'bold' }}>
                            {modo === "crear" ? "Registrar Cohorte" : "Editar Cohorte"}
                        </h4>

                        <div>


                            <Tooltip title="Salir del formulario.">
                                <Popconfirm
                                    title='Desea salir del formulario sin guardar los datos'
                                    description="¿Segur@ que quieres salir del formulario sin guardar los datos? No podrás revertir esta operación."
                                    onConfirm={handleCancel} // 🔹 Solo aquí se ejecuta la acción al confirmar
                                    okText="Sí"
                                    cancelText="No"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                >
                                    <Button
                                        icon={<CloseOutlined />}
                                        // 🔹 Eliminamos onClick aquí para que no cierre el modal antes de la confirmación
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            border: 'none',
                                            boxShadow: 'none',
                                        }}
                                    />
                                </Popconfirm>
                            </Tooltip>


                        </div>
                    </div>
                }
                placement="right"
                closable={false}
                getContainer={false}
                open={visible}
                onCancel={handleCancel}
                onOk={handleSave}
                width={'100%'}
                rootStyle={{
                    position: "absolute",
                    top: 0,
                    height: "100vh", // Ocupa el 100% del alto de la pantalla
                    right: 0
                }}
                styles={{
                    body: {
                        overflow: "auto",
                        height: "100%"
                    }
                }}

                destroyOnClose
                position="absolute"
                footer={
                    <div
                        style={{
                            textAlign: 'right',
                            padding: '10px 16px',
                            borderTop: '1px solid #f0f0f0',
                            background: '#fff',
                            marginRight: '20px'
                        }}
                    >
                        <Tooltip title="Salir del formulario.">
                            <Popconfirm
                                title='Desea salir del formulario sin guardar los datos'
                                description="¿Segur@ que quieres salir del formaulario sin guardar los datos, no podras revertir esta operación?"
                                onConfirm={() => handleCancel()}
                                okText="Sí"
                                cancelText="No"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                            >
                                <Button
                                    type="primary"
                                    danger
                                    icon={<CloseOutlined />}
                                    className='me-2 p-2'
                                >
                                    Cerrar
                                </Button>
                            </Popconfirm>
                        </Tooltip>

                        <Button type="primary" onClick={handleSave}>
                            Guardar y Enviar
                        </Button>

                    </div>
                }

            >
                <ConfigProvider locale={esES}>
                    <Form form={form} layout="vertical">
                        <Row gutter={16}>
                            {/* Cohorte */}
                            <Col span={5}>
                                <Form.Item
                                    name="cohorte"
                                    label="Cohorte"
                                    rules={[{ required: true, message: "Campo requerido" }]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            {/* Fecha de Inicio */}
                            <Col span={5}>
                                <Form.Item
                                    name="fechaInicio"
                                    label="Fecha de Inicio"
                                    rules={[{ required: true, message: "Campo requerido" }]}
                                >
                                    <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            {/* Fecha Final con validación */}
                            <Col span={5}>
                                <Form.Item
                                    name="fechaFinal"
                                    label="Fecha Final"
                                    dependencies={["fechaInicio"]}
                                    // Eliminamos la regla separada "required: true" y lo manejamos en el validator
                                    rules={[{ required: true, message: "Campo requerido" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            // Validar "required"
                                            if (!value) {
                                                return Promise.reject(new Error("El campo Fecha Final es obligatorio"));
                                            }

                                            const fechaInicio = getFieldValue("fechaInicio");
                                            if (!fechaInicio) {
                                                // Si no hay fechaInicio, no hacemos comparación
                                                return Promise.resolve();
                                            }

                                            // -- USANDO .diff PARA COMPARAR DÍAS --
                                            // Si el resultado es >= 0, significa que "fechaFinal" es igual o posterior
                                            // a "fechaInicio" (en días).
                                            const diff = value.diff(fechaInicio, "day");
                                            if (diff >= 0) {
                                                return Promise.resolve();
                                            }

                                            // Si es < 0, es porque "fechaFinal" es menor que "fechaInicio"
                                            return Promise.reject(
                                                new Error("La Fecha Final no puede ser menor que la Fecha de Inicio")
                                            );
                                        },
                                    }),
                                    ]}
                                >
                                    <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                                </Form.Item>



                            </Col>


                        </Row>


                        {/* Resumen */}
                        <Form.Item name="resumen" label="Resumen" rules={[{ required: true, message: "Campo requerido" }]}>
                            <Input.TextArea rows={4} />
                        </Form.Item>


                        {/* Tabla Sesiones */}
                        <h4>Asistencia en las Sesiones</h4>
                        <Button type="dashed" onClick={agregarSesion} icon={<PlusOutlined />} className="mb-2">
                            Añadir Sesión
                        </Button>
                        <Table
                            columns={columnsSesiones}
                            dataSource={sesiones}
                            pagination={false}
                            align="middle"
                            tableLayout="fixed"        // 🔑 Evita que el ancho se ajuste al contenido
                            scroll={{ x: "max-content" }}
                        />

                    </Form>
                </ConfigProvider>


            </Drawer >

        </div >
    );
});

export default FormularioCohorte;
