import React, { useState } from 'react';
import { Button, Form, Input, Upload, Spin, message, Modal, Typography, Tooltip, Dropdown, Space, Divider } from 'antd';
import { FolderOpenOutlined, InboxOutlined, UploadOutlined, FolderAddOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { createFolder, uploadFiles, uploadFolderFile } from '../../../../services/inicio/repositorio/repositorioDescargarVerService';

const { Text } = Typography;
const { Dragger } = Upload;


const CrearCarpeta = ({
    parentId,           // ID de la carpeta padre (ubicación actual)
    diseno,            // 1 -> icono+texto, 2 -> botón con tooltip
    onFolderCreated,   // Callback opcional para refrescar lista al crear
    currentPath,        // String con la ruta actual
    username,
    pathActual,
    onFileUploaded
}) => {
    const [folderName, setFolderName] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [openModalFile, setOpenModalFile] = useState(false);
    const [openModalFolder, setOpenModalFolder] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm(); // Para controlar el Form si deseas

    //Producción
    //finalPathActual = "/var/www/html/backend/DocumentosFAO/Repositorio/";
    //Desarrollo
    //finalPathActual = "/Volumes/bodecom/bodecom_160/bodecom_160/backend/DocumentosFAO/Repositorio/";

    const finalPathActual = (parentId === null || parentId === 1)
        ? "/Volumes/bodecom/bodecom_160/bodecom_160/backend/DocumentosFAO/Repositorio/"
        : pathActual;

    // Aquí defines tu “menu de acciones”
    const itemsAcciones = [
        {
            key: "createFolder",
            label: "Crear carpeta",
            icon: <FolderAddOutlined />,
            onClick: () => handleOpen()
        },
        { key: "divider1", type: "divider" },
        {
            key: "uploadFolder",
            label: "Subir carpeta",
            icon: <CloudUploadOutlined />,
            onClick: () => handleOpenFolder()

        },
        { key: "divider2", type: "divider" },
        {
            key: "uploadFile",
            label: "Subir archivo",
            icon: <UploadOutlined />,
            onClick: () => handleOpenFile()
        },
        { key: "divider3", type: "divider" },
    ];

    // Abrir/cerrar modal
    const handleOpen = () => setOpenModal(true);
    const handleOpenFile = () => setOpenModalFile(true);
    const handleOpenFolder = () => setOpenModalFolder(true);

    const handleClose = () => {
        setOpenModal(false);
        setFolderName(''); // limpiar input
        form.resetFields(); // Limpiar el formulario
        setLoading(false); // Reinicia el estado de carga
    };

    const handleCloseFile = () => {
        setOpenModalFile(false);
        setFolderName('');
        setLoading(false); // Reinicia el estado de carga
    };


    const handleCloseFolder = () => {
        setOpenModalFolder(false);
        setFolderName('');
        setLoading(false); // Reinicia el estado de carga
    };



    const uploadProps = {
        name: "file",
        multiple: true,
        customRequest: async (options) => {
            // Extraemos lo necesario de options
            const { file, onSuccess, onError } = options;
            const formData = new FormData();
            formData.append("file", file);
            formData.append("parentPath", finalPathActual);
            formData.append("parentId", parentId);
            formData.append("username", username);

            try {
                const result = await uploadFiles(formData);
                console.log("formData: ", formData);
                // ✅ Notificar al padre
                if (onFileUploaded && result.files && result.files.length > 0) {
                    const uploadedFile = result.files[0];
                    onFileUploaded({
                        ...uploadedFile,
                        type: "file",
                    });
                }

                // Llamamos a onSuccess para notificar al componente Upload
                onSuccess(result, file);
                message.success(`${file.name} subido correctamente`);
            } catch (err) {
                onError(err);
                message.error(`${file.name} error al subir.`);
            }
        },
        onChange(info) {
            const { status } = info.file;
            if (status === "uploading") {
                setLoading(true);
            } else if (status === "done") {
                setLoading(false);
            } else if (status === "error") {
                setLoading(false);
            }
        },
        onDrop(e) {
            console.log("Archivos arrastrados:", e.dataTransfer.files);
        },
    };



    const uploadPropsFolder = ({ parentId, parentPath, username, onFileUploaded }) => {
        return {
            name: 'file',
            directory: true,
            multiple: true,
            customRequest: async (options) => {
                const { file, onSuccess, onError } = options;

                const formData = new FormData();
                // Usar Blob para asegurar la codificación correcta
                const fileBlob = new Blob([file], { type: file.type });
                formData.append('file', fileBlob, file.name);

                formData.append('relativePath', file.webkitRelativePath || file.name);
                formData.append('parentId', parentId);
                formData.append('parentPath', parentPath);
                formData.append('username', username);
                try {
                    const result = await uploadFolderFile(formData);
                    console.log("resultado:", result);
                    if (onFileUploaded && result.data.files) {
                        result.data.files.forEach(f => onFileUploaded(f));
                    }

                    onSuccess(result.data, file);
                    message.success('Carpeta subida correctamente');
                } catch (err) {
                    console.error('Error detallado:', err.response?.data || err.message);
                    onError(err);
                    message.error('Error al subir la carpeta');
                }
            }
        };
    };





    // Crear carpeta
    // Suponiendo que tienes un 'form' creado con: const [form] = Form.useForm();
    const handleCreateFolder = async () => {
        try {
            setLoading(true); // activa el spinner
            // 1) Valida el campo "folderName"
            const values = await form.validateFields(["folderName"]);

            // 2) Obtiene y limpia "folderName"
            const name = values.folderName.trim();

            // 3) Llama a tu servicio para crear la carpeta

            const result = await createFolder({ parentId, folderName: name, username });
            message.success(`Carpeta "${name}" creada con éxito`);


            // 4) Refrescar o notificar al componente padre si aplica
            const newFolder = result.folder;

            // Llama al callback pasando el nuevo folder para que el padre actualice su árbol
            if (onFolderCreated) onFolderCreated(newFolder);

            // 5) Cierra el modal y limpia el formulario
            handleClose();
            form.resetFields(["folderName"]);
        } catch (error) {
            // Si es un error de validación, AntD ya lo muestra
            if (error.errorFields) {
                return;
            }
            // Si es error del servidor, personaliza el mensaje según el status code
            if (error.response) {
                const status = error.response.status;
                const msg = error.response.data.message;
                switch (status) {
                    case 401:
                        message.error(msg || "Carpeta padre no encontrada");
                        break;
                    case 402:
                        message.error(msg || "La carpeta ya existe");
                        break;
                    default:
                        message.error(msg || "No se pudo crear la carpeta.");
                }
            } else {
                message.error("No se pudo crear la carpeta.");
            }
        } finally {
            setLoading(false); // desactiva el spinner
        }
    };


    // Botón “create folder” en 2 variantes (según 'diseno')
    const renderCreateButton = () => {
        if (diseno === 1) {
            return (
                <Dropdown menu={{ items: itemsAcciones }} trigger={["click"]}>
                    <Button>Acciones</Button>
                </Dropdown>
            );
        } else if (diseno === 2) {
            // Versión 2: botón con tooltip
            return (
                <>
                    <Tooltip title="Crear carpeta">
                        <Button
                            type="primary"
                            icon={<FolderAddOutlined />}
                            onClick={handleOpen}
                            style={{ fontSize: 20, marginRight: 10 }}
                        />
                    </Tooltip>

                    <Tooltip title="Subir carpeta">
                        <Button
                            type="primary"
                            icon={<CloudUploadOutlined />}
                            onClick={() => handleOpenFolder(true)}
                            style={{ fontSize: "20px" }}
                        />
                    </Tooltip>
                </>
            );
        } else {
            return (
                <Tooltip title="Subir archivo">
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => handleOpenFile(true)}
                        style={{ fontSize: "20px" }}
                    />
                </Tooltip>
            )

        }
    };

    // Subcomponente o función que muestra la “Ruta actual”
    const RutaActual = () => {
        return (
            <div
                style={{
                    backgroundColor: "#fafafa",
                    padding: "16px",
                    borderRadius: "6px",
                    marginBottom: "16px",
                    border: "1px solid #d9d9d9",
                }}
            >
                {/* Divider con el icono + texto en el título */}
                <Divider orientation="left" orientationMargin={0}>
                    <Space>

                        <FolderOpenOutlined style={{ color: "#1677ff", fontSize: 16 }} />

                        <Text strong style={{ margin: 0 }}>
                            Ruta actual
                        </Text>
                    </Space>
                </Divider>

                {/* Ahora mostramos el "currentPath" debajo del Divider */}
                <Text style={{ display: "block", marginTop: "8px" }}>{currentPath}</Text>
            </div>
        );
    };


    return (
        <>
            {/* Botón o icono+texto para abrir modal */}
            {renderCreateButton()}

            {/* Modal para ingresar el nombre de la nueva carpeta */}
            <Modal
                title="Crear Carpeta"
                open={openModal}
                onCancel={handleClose}
                onOk={handleCreateFolder}
                okText="Crear"
                cancelText="Cancelar"
                destroyOnClose
                width={"50%"}
            >
                <Spin spinning={loading} tip="Creando carpeta..." size="large">


                    <Form
                        layout="vertical"
                        form={form}
                    // Puedes inicializar valores si deseas:
                    // initialValues={{ folderName: '' }}
                    >
                        <Form.Item
                            label="Nombre de la nueva carpeta"
                            name="folderName"
                            // `rules` -> validaciones (mostrar asterisco y error si vacío)
                            rules={[
                                { required: true, message: "Por favor ingresa un nombre." },
                                {
                                    pattern: /^[^\\\/:*?"<>|]+$/,
                                    message: 'No usar / \\ : * ? " < > |',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Ej: Mi carpeta"
                            />
                        </Form.Item>
                    </Form>

                    <Divider />
                    <RutaActual />

                </Spin>

            </Modal>


            <Modal
                title="Subir archivo"
                open={openModalFile}
                onCancel={handleCloseFile}
                icon={<UploadOutlined />}
                cancelText="Cancelar"
                destroyOnClose
                width="50%"
                footer={null}
            >
                <Spin spinning={loading} tip="Subiendo archivo..." size="large">

                    <div style={{ width: "100%" }}>
                        <Divider />

                        {/* Drag & Drop para subir archivos */}
                        <Dragger {...uploadProps} style={{ marginTop: 16, width: "80%", margin: "0 auto" }}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined style={{ fontSize: 48, color: "#999" }} />
                            </p>
                            <p className="ant-upload-text">
                                Haz clic o arrastra tu archivo a esta área para subir
                            </p>
                            <p className="ant-upload-hint">
                                Soporta subir uno o varios archivos simultáneamente.
                            </p>
                        </Dragger>
                    </div>

                    <Divider />
                    {/* Tu componente que muestra la ruta actual */}
                    <RutaActual currentPath={currentPath} />
                </Spin>
                <div style={{ marginTop: 10, textAlign: "right" }}>
                    <Button type="primary" onClick={handleCloseFile}>Cerrar</Button>
                </div>

            </Modal>


            <Modal
                title="Subir carpeta"
                open={openModalFolder}
                onCancel={handleCloseFolder}
                icon={<UploadOutlined />}
                cancelText="Cancelar"
                destroyOnClose
                width="50%"
                footer={null}
            >
                <Spin spinning={loading} tip="Subiendo carpeta..." size="large">
                    <Divider />

                    <div style={{ width: "100%" }}>

                        {/* Drag & Drop para subir carpetas */}
                        <Dragger
                            {...uploadPropsFolder({
                                parentId,
                                parentPath: finalPathActual,
                                username,
                                onFileUploaded
                            })}
                            directory
                            style={{ marginTop: 16, width: "80%", margin: "0 auto" }}
                        >


                            <p className="ant-upload-drag-icon">
                                <InboxOutlined style={{ fontSize: 48, color: "#999" }} />
                            </p>
                            <p className="ant-upload-text">
                                Haz clic o arrastra tu carpeta a esta área para subir
                            </p>
                            <p className="ant-upload-hint">
                                Soporta subir una sola carpeta simultáneamente.
                            </p>
                        </Dragger>
                    </div>

                    <Divider />
                    {/* Tu componente que muestra la ruta actual */}
                    <RutaActual currentPath={currentPath} />
                </Spin>
                <div style={{ marginTop: 10, textAlign: "right" }}>
                    <Button type="primary" onClick={handleCloseFolder}>Cerrar</Button>
                </div>

            </Modal >
        </>
    );
};

export default CrearCarpeta;
