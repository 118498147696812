import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const depmunEndpoint = '/api/view_file/';

// Función para visualizar o descargar un archivo
export const viewOrDownloadFile = async (ruta, nombre) => {
  try {
    const response = await axios.post(
      `${baseUrl}${depmunEndpoint}`,
      { ruta },
      { responseType: 'blob' } // Para manejar archivos binarios
    );
    const contentDisposition = response.headers['content-disposition'];
    const contentType = response.headers['content-type'];

    const blob = new Blob([response.data], { type: contentType });

    // Verifica si el archivo es visualizable o debe descargarse
    if (contentType.startsWith('application/pdf') || contentType.startsWith('image/')) {
      // Si es visualizable (PDF, imagen), lo mostramos
      const viewUrl = window.URL.createObjectURL(blob);
      window.open(viewUrl);
    } else {
      // Si no es visualizable, lo descargamos
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;

      // Limpia el nombre eliminando el prefijo hasta el primer guion
      const cleanedName = nombre.replace(/^\d+-/, '');

      // Si tiene un nombre en el Content-Disposition, úsalo
      if (contentDisposition) {
        link.setAttribute('download', cleanedName);
      } else {
        link.setAttribute('download', cleanedName);
      }

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (error) {
    console.error('Error viewing or downloading file', error);
    throw error;
  }
};
