import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AvanceProyectosChartBarras = ({ data }) => {
    // Extraer los valores
    const proyectos = data.map((item) => item.codigo);

    const avanceProgramado = data.map((item) => Math.round(parseFloat(item.avanceProgramado)));
    const avanceEjecutado = data.map((item) => Math.round(parseFloat(item.avanceEjecutado)));


    // Definir datos para Chart.js
    const chartData = {
        labels: proyectos,
        datasets: [
            {
                label: "% Avance Programado (P)",
                data: avanceProgramado,
                backgroundColor: "rgba(54, 162, 235, 0.7)", // Azul
                metadata: data.map((item) => item.fecha_actualizacion) // Agregamos fechas

            },
            {
                label: "% Avance Ejecutado (E)",
                data: avanceEjecutado,
                backgroundColor: "rgba(255, 165, 0, 0.6)", // Rojo

                metadata: data.map((item) => item.fecha_actualizacion) // Agregamos fechas

            },
        ],
    };

    // Opciones del gráfico
    const options = {
        responsive: true,
        indexAxis: "y", // Barras horizontales
        scales: {
            x: {
                beginAtZero: true,
                max: 100, // Asumiendo que los valores son en porcentaje
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        // Obtener el índice del dato dentro del dataset
                        const index = tooltipItem.dataIndex;

                        // Obtener la fecha de actualización desde metadata
                        const fechaActualizacion = tooltipItem.dataset.metadata[index];

                        // Formatear el texto del tooltip
                        return [
                            `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(0)}%`,
                            `Fecha de actualización: ${fechaActualizacion}`
                        ];
                    },
                },
            },

        },
    };

    return (
        <div style={{ width: "100%", height: "100%", padding: "40px" }}>
            <Bar data={chartData} options={{ ...options, maintainAspectRatio: false }} />
        </div>
    );

};

export default AvanceProyectosChartBarras;
