import React, { useRef, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

// Registrar los elementos en ChartJS
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const GraficaTorta3D = ({ titulo, datos, colores }) => {
    const chartRef = useRef(null);

    // Configuración de los datos
    const total = datos.reduce((acc, item) => acc + item.valor, 0);
    const data = {
        labels: datos.map((item) => item.name),
        datasets: [
            {
                data: datos.map((item) => item.valor),
                backgroundColor: colores, // Colores dinámicos
                borderWidth: 2,
                borderColor: "#66D3FA", // Borde para el efecto 3D
                hoverOffset: 4,

            },
        ],
    };

    // Opciones de configuración
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "50%", // Hace que parezca más un gráfico 3D
        plugins: {
            legend: {
                position: "bottom",
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        let porcentaje = ((tooltipItem.raw / total) * 100).toFixed(1);
                        return `${tooltipItem.raw} (${porcentaje}%)`;
                    },
                },
            },
            datalabels: {
                color: "#fff", // Texto en blanco dentro de la torta
                anchor: "center",
                align: "center",
                textStrokeColor: "rgba(0, 0, 0, 0.6)", // ✅ Agregar un borde oscuro para mejorar visibilidad
                textStrokeWidth: 2, // ✅ Grosor del borde
                font: {
                    weight: "bold",
                    size: 14,
                },
                formatter: (value) => `${(value / total * 100).toFixed(1)}%`,
            },
        },
    };

    return (


        <div style={{ textAlign: "center", width: "100%", padding: "0px" }}>
            <h3 style={{ fontWeight: "bold" }}>{titulo}</h3>
            <div style={{ width: "100%", height: "300px", margin: "auto" }}>
                <Doughnut ref={chartRef} data={data} options={options} />
            </div>
        </div>


    );
};

export default GraficaTorta3D;
