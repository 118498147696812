import React, { useEffect, useState, useMemo } from "react";
import { Table, Layout, Descriptions, Card, Collapse, Typography, message, Tooltip, Modal, Button, DatePicker, Space } from 'antd';
import { getHeader, getPOA, getPOPeso, updateAvance, updateFecha, updatePeso, getPOPesoResumen } from '../../services/plaOperativo/headerPlanOperativoServices';
import { ColumnHeightOutlined, EyeOutlined, TableOutlined, LinkOutlined, InfoCircleOutlined, MenuOutlined, MoreOutlined, ArrowLeftOutlined, WarningOutlined } from '@ant-design/icons';
import { fetchPoaData } from '../../services/poa/get_poa';
import FileManagerPOA2 from './FileManagerPP';
import FileManagerPOAN from './FileManagerPOAN';
import EditableCell from './EditableCell';
import './PoaPlanOperativoTable.css';
import moment from 'moment';
import dayjs from 'dayjs';
import Ippta from '../Ippta/Ippta';
import Edah from '../EncuestaEdah/Edah';
import TablaAvanceJSX from './TablaResumenAvance.jsx'
import { selectAppPermissions } from '../../store/auth/selectors.js';
import { useSelector } from "react-redux";

const { Title } = Typography;

const PoaPlanOperativoTB = ({ selectedFase, selectedProject, regresarAnterior, cerrarModal }) => {


  const [headerPO, setHeaderPO] = useState({});
  const [loading, setLoading] = useState(true);
  const [actividades, setActividades] = useState([]);
  const [error, setError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actividadSelect, setActividadSelect] = useState(null);
  const [openModal1, setOpenModal1] = useState(false);
  const [itemParams, setItemParams] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [mesInicial2024, setMesInicial2024] = useState('');
  const [meses2024, setMeses2024] = useState([]);
  const [mesInicial2025, setMesInicial2025] = useState('');
  const [meses2025, setMeses2025] = useState([]);
  const [tareasSubtareas, setTareasSubtareas] = useState([]);
  const [columnasDinamicas2024, setColumnasDinamicas2024] = useState([]);
  const [columnasDinamicas2025, setColumnasDinamicas2025] = useState([]);
  const [editingDate, setEditingDate] = useState(false);
  const [opcionFiltro, setOpcionFiltro] = useState(null);
  const [codigoProyecto, setCodigoProyecto] = useState(null);
  const [isModalVisibleParticipantes, setIsModalVisibleParticipantes] = useState(false);
  const [banderaProyecto, setBanderaProyecto] = useState([]);
  const [filaSeleccionada, setFilaSeleccionada] = useState(null);
  const [valoresJsonPeso, setValoresJsonPeso] = useState([]);
  const [rawPesoData, setRawPesoData] = useState([]);
  const [mostrarTablaResumen, setMostrarTablaResumen] = useState(true);
  const [mostrarTablaCronograma, setMostrarTablaCronograma] = useState(true);
  const [isVisibleTarea, setIsVisibleTarea] = useState(true);
  const [pesoTotal, setPesoTotal] = useState(0);
  const [tipoFase, setTipoFase] = useState(false);

  const appName = 'poa_plan_operativo';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));

  const permissionInsert = permissions.priv_insert === 'Y'; //Tabla y fecha Actualizacion
  //const permissionDelete = permissions.priv_delete === 'Y';
  const permissionUpdate = permissions.priv_update === 'Y';  //Soportes
  //const permissionExport = permissions.priv_export === 'Y';
  const permissionView = permissions.priv_print === 'Y'; // Peso Prcentual
  const permissionPrint = permissions.priv_print === 'Y';


  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { data } = await getHeader(selectedProject.oid);
        setHeaderPO(data?.encabezado[0]);
        setError(false);
      } catch (error) {
        setError(true);
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, [selectedProject.oid]);



  useEffect(() => {
    const fetchPOA = async () => {
      setLoading(true);
      try {
        // Reiniciamos los estados
        setActividades([]);
        setValoresJsonPeso([]);
        setTareasSubtareas([]);
        // Llamada al primer servicio
        const { data: poaData } = await getPOA(selectedFase.oid, selectedProject.oid);
        // Verificamos que poaData sea un array (o tenga la estructura que esperamos)
        if (!Array.isArray(poaData)) {
          throw new Error("Los datos de POA no son válidos");
        }
        // Si el primer servicio fue exitoso, entonces ejecutamos el segundo:

        const dataJSONPeso = await getPOPeso(selectedFase.oid, selectedProject.oid, 'filtrado');
        const esFase3 = selectedFase.oid === 3;
        setTipoFase(esFase3);
        if (!tipoFase) {
          const { status, data: body } = dataJSONPeso;
          if (status === 200 && body.status === 200 && Array.isArray(body.data)) {

            const pesoArray = dataJSONPeso.data.data;

            const valoresJsonPeso = logTotPesoPorMesAnioPorFase(pesoArray);

            const pesoTotal = parseFloat(pesoArray[0].totalPeso)
            setPesoTotal(pesoTotal);
            setValoresJsonPeso(valoresJsonPeso);
            setRawPesoData(pesoArray);

          } else {
            throw new Error("Los datos de Peso no son válidos");
          }
        }

        // Actualizamos estados con los datos obtenidos
        actualizarMeses(poaData);
        setActividades(poaData);
        setTareasSubtareas(mapTareasSubtareas(poaData));

      } catch (error) {
        console.error("Error fetching POA:", error);
        setActividades([]);
        setValoresJsonPeso([]);
      } finally {
        setLoading(false);
      }
    };

    if (selectedFase?.oid && selectedProject?.oid) {
      fetchPOA();
    }
  }, [selectedFase.oid, selectedProject.oid]);

  useEffect(() => {
    if (rawPesoData) {
      const nuevosTotales = logTotPesoPorMesAnioPorFase(rawPesoData);
      setValoresJsonPeso(nuevosTotales);
    }
  }, [rawPesoData]);


  useEffect(() => {
    if (meses2024 && mesInicial2024 && valoresJsonPeso) {
      const columnas2024 = generarColumnasDinamicas(meses2024, mesInicial2024, '2024', valoresJsonPeso);
      const columnas2025 = generarColumnasDinamicas(meses2025, mesInicial2025, '2025', valoresJsonPeso);

      setColumnasDinamicas2024(columnas2024);
      setColumnasDinamicas2025(columnas2025);
    }
  }, [meses2024, mesInicial2024, valoresJsonPeso]);


  const totalPesoFase = useMemo(() => {
    return actividades.reduce((totalActividad, actividad) => {
      if (actividad.children && Array.isArray(actividad.children)) {
        // Sumamos el pesoPorcentual de cada tarea de la actividad.
        const sumaTareas = actividad.children.reduce((sumTarea, tarea) => {

          return sumTarea + Number(tarea.pesoPorcentual || 0);
        }, 0);
        return totalActividad + sumaTareas;
      }
      return totalActividad;
    }, 0);
  }, [actividades]);



  function capitalizarPrimeraLetra(texto) {
    return texto.toLowerCase().charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
  }

  function getIdByMonthAndYear(month, year, cronogramas, id_indicador) {
    const cronograma = cronogramas.find(c => c.mes === month && c.ano === year && c.indicador_id === id_indicador);
    return cronograma ? cronograma.id : null; // Retorna el id si lo encuentra, de lo contrario null
  }

  function logTotPesoPorMesAnioPorFase(dataJSONPeso) {

    // Objeto que guardará los acumuladores por fase, subdivididos en P y E.
    // Ej: acumuladoresPorFase[faseId] = { P: {}, E: {} }
    const acumuladoresPorFase = {};

    // Recorremos las "fases" (elementos del array dataJSONPeso)
    dataJSONPeso.forEach((fase) => {
      const faseId = fase.fase_id || "fase-sin-id";

      // Si no existe, inicializamos la estructura con P y E vacíos
      if (!acumuladoresPorFase[faseId]) {
        acumuladoresPorFase[faseId] = {
          P: {},
          E: {},
        };
      }

      if (!fase.children) return; // Sin actividades, skip

      fase.children.forEach((tarea) => {
        // Ajusta si tu "pesoPorcentual" es realmente un factor o un %.
        // Aquí supongo que lo usas "tal cual":
        const pesoEntero = parseFloat(tarea.pesoPorcentual) || 0;
        const peso = pesoEntero / 1; // o /100 si en BD 3 significa 3%

        if (!tarea.children) return; // Sin indicadores, skip

        tarea.children.forEach((indicador) => {
          const meta = parseFloat(indicador.meta) || 0;
          if (meta <= 0 || peso <= 0) return; // Evitar /0

          // --- PROYECTADO (P) ---
          if (indicador.cronogramasP) {
            indicador.cronogramasP.forEach((crono) => {
              const p = parseFloat(crono.avance) || 0;
              const mes = crono.mes;
              const ano = crono.ano;
              const keyMesAnio = `${mes}-${ano}`;

              const parcial = (p / meta) * peso;

              if (!acumuladoresPorFase[faseId].P[keyMesAnio]) {
                acumuladoresPorFase[faseId].P[keyMesAnio] = 0;
              }
              acumuladoresPorFase[faseId].P[keyMesAnio] += parcial;
            });
          }

          // --- EJECUTADO (E) ---
          if (indicador.cronogramasE) {
            indicador.cronogramasE.forEach((crono) => {
              const e = parseFloat(crono.avance) || 0;
              const mes = crono.mes;
              const ano = crono.ano;
              const keyMesAnio = `${mes}-${ano}`;

              const parcial = (e / meta) * peso;

              if (!acumuladoresPorFase[faseId].E[keyMesAnio]) {
                acumuladoresPorFase[faseId].E[keyMesAnio] = 0;
              }
              acumuladoresPorFase[faseId].E[keyMesAnio] += parcial;
            });
          }
        });
      });
    });

    // *** REDONDEO AL FINAL ***
    // Recorremos cada fase, y dentro de cada fase, cada tipo (P, E) y cada "mes-año"
    for (const faseId in acumuladoresPorFase) {
      for (const tipo of ["P", "E"]) {
        for (const keyMesAnio in acumuladoresPorFase[faseId][tipo]) {
          const valorAcumulado = acumuladoresPorFase[faseId][tipo][keyMesAnio];
          // Redondear a 2 decimales
          acumuladoresPorFase[faseId][tipo][keyMesAnio] = Number(
            valorAcumulado.toFixed(2)
          );
        }
      }
    }

    // Ahora construimos un objeto JSON con el formato deseado
    // (ej. "fase" y luego "p" y "e" separadas)
    const resultado = { fases: [] };

    for (const [faseId, objPE] of Object.entries(acumuladoresPorFase)) {
      // objPE = { P: {...}, E: {...} }
      resultado.fases.push({
        fase: faseId,
        p: objPE.P,
        e: objPE.E,
      });
    }

    // Devolver el objeto JSON para usarlo en otra parte
    return resultado;
  }

  // Función para alternar la visibilidad de la tabla de resumen
  const toggleTablaResumen = () => {
    setIsVisible(false);
    // Si actualmente la tabla resumen está visible y la de cronograma está oculta,
    // evitar ocultar la de resumen porque así ambas quedarían ocultas.
    if (mostrarTablaResumen && !mostrarTablaCronograma) {
      return;
    }
    setMostrarTablaResumen(prev => !prev);
  };

  // Función para alternar la visibilidad de la tabla de cronograma
  const toggleTablaCronograma = () => {
    setIsVisible(false);
    if (mostrarTablaCronograma && !mostrarTablaResumen) {
      return;
    }
    setMostrarTablaCronograma(prev => !prev);
  };

  const toggleVisibilityTarea = () => {
    setIsVisibleTarea(!isVisibleTarea); // Cambiar el estado entre visible y oculto
  };



  const updateAvanceInRaw = (jsonArray, key, newValue) => {
    return jsonArray.map((actividad) => ({
      ...actividad,
      children: actividad.children?.map((tarea) => ({
        ...tarea,
        children: tarea.children?.map((indicador) => ({
          ...indicador,
          cronogramasP: indicador.cronogramasP?.map((crono) =>
            crono.id === Number(key) ? { ...crono, avance: newValue } : crono
          ),
          cronogramasE: indicador.cronogramasE?.map((crono) =>
            crono.id === Number(key) ? { ...crono, avance: newValue } : crono
          ),
        })),
      })),
    }));
  };



  const generarColumnasDinamicas = (meses, mesInicial, ano, valoresJsonPeso) => {
    const mesInicialIndex = meses.indexOf(mesInicial);
    if (mesInicialIndex === -1) return [];
    return meses.slice(mesInicialIndex).map((mes) => {
      // Construir la clave en formato "MES-ANO", por ejemplo: "AGO-2024"
      const keyMesAno = `${mes.toUpperCase()}-${ano}`;
      // Extraer valor para tipo "P"
      let valorP = "-";
      if (
        valoresJsonPeso &&
        valoresJsonPeso.fases &&
        valoresJsonPeso.fases.length > 0 &&
        valoresJsonPeso.fases[0].p
      ) {
        const tmp = valoresJsonPeso.fases[0].p[keyMesAno];
        if (tmp !== undefined) {
          valorP = tmp;
        } else {
          valorP = 0;
        }
      }
      // Extraer valor para tipo "E"
      let valorE = "-";
      if (
        valoresJsonPeso &&
        valoresJsonPeso.fases &&
        valoresJsonPeso.fases.length > 0 &&
        valoresJsonPeso.fases[0].e
      ) {
        const tmpE = valoresJsonPeso.fases[0].e[keyMesAno];
        if (tmpE !== undefined) {
          valorE = tmpE;
        } else {
          valorE = 0;
        }
      }
      return [
        // Columna para tipo "P"

        {
          title: (
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
              <span>{mes}</span>
              {!tipoFase && (
                <span
                  style={{
                    marginTop: '4px',
                    paddingTop: '4px',
                    borderTop: '1px solid #ccc',
                    fontSize: 12,
                    color: '#ffc107',
                  }}
                >
                  {valorP}%
                </span>
              )}
            </div>
          ),
          dataIndex: `${mes.toLowerCase()}${ano}`, // ej.: "ago2024"
          key: `key${capitalizarPrimeraLetra(mes)}${ano}`,
          width: 100,
          align: 'center',
          render: (text) => {
            // Intentamos convertir a número
            const numValue = parseFloat(text);
            // Si no es un número, se muestra tal cual
            if (isNaN(numValue)) {
              return text;
            }
            // Extraemos la parte decimal
            const decimalPart = numValue % 1;
            // Si los decimales son >= 0.1, mostramos con decimales, de lo contrario solo el entero
            const formattedValue = decimalPart >= 0.1 ? numValue.toFixed(1) : Math.trunc(numValue);
            return (
              <div
                style={{
                  backgroundColor: numValue >= 1 ? '#96BEEF' : 'transparent',
                  color: numValue >= 1 ? '#FFFFFF' : 'inherit',
                }}
              >
                {formattedValue}
              </div>
            );
          },
          onCell: (record, rowIndex) => ({
            record,
            dataIndex: `${mes.toLowerCase()}${ano}`, // la clave que se actualizará
            editable: permissionInsert && rowIndex % 2 !== 0,
            handleSave: (updatedRecord) =>
              handleSave(updatedRecord, record[`key${capitalizarPrimeraLetra(mes)}${ano}`], `${mes.toLowerCase()}${ano}`),
          }),
        },
        // Columna para tipo "E"
        {
          title: (
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
              <span>% Avance</span>
              {!tipoFase && (
                <span
                  style={{
                    marginTop: '4px',
                    paddingTop: '4px',
                    borderTop: '1px solid #ccc',
                    fontSize: 12,
                    color: '#ffc107',
                  }}
                >
                  {valorE}%
                </span>
              )}
            </div>
          ),
          dataIndex: `avance${capitalizarPrimeraLetra(mes)}${ano}`, // ej.: "avanceAgo2024"
          key: `avance${capitalizarPrimeraLetra(mes)}${ano}`,
          width: 100,
          align: 'center',
          editable: true,
          render: (text, record, rowIndex) => {
            // Puedes ajustar este render según cómo quieras mostrar los datos de E
            return <div>{text}</div>;
          },
          onCell: (record, rowIndex) => ({
            rowSpan: rowIndex % 2 === 0 ? 2 : 0,
          }),
        },
      ];
    }).flat();
  };

  const generarMeses = (mesInicio, mesFin) => {
    const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    const indiceInicio = meses.indexOf(mesInicio);
    const indiceFin = meses.indexOf(mesFin);
    if (indiceInicio === -1 || indiceFin === -1) {
      return [];
    }
    return meses.slice(indiceInicio, indiceFin + 1);
  };

  const actualizarMeses = (data) => {
    let encontrado2024 = false;
    let encontrado2025 = false;
    data.forEach(actividad => {
      if (encontrado2024 && encontrado2025) return;
      actividad.children.forEach(tarea => {
        if (encontrado2024 && encontrado2025) return;
        tarea.children.forEach(item => {
          if (encontrado2024 && encontrado2025) return;
          item.cronogramaconfig.forEach(config => {
            if (config.anio === '2024' && !encontrado2024) {
              setMesInicial2024(config.mes_inicio);
              setMeses2024(generarMeses(config.mes_inicio, config.mes_final));
              encontrado2024 = true;
            } else if (config.anio === '2025' && !encontrado2025) {
              setMesInicial2025(config.mes_inicio);
              setMeses2025(generarMeses(config.mes_inicio, config.mes_final));
              encontrado2025 = true;
            }
          });
        });
      });
    });
  };

  const formatDate = (date) => {
    if (!date) return "Sin fecha";

    try {
      const localDate = new Date(date + "T00:00:00"); // Forzar la hora a medianoche
      return localDate.toLocaleDateString('es-ES', { year: 'numeric', month: '2-digit', day: '2-digit' });
    } catch {
      return "Formato inválido";
    }
  };

  const calcularAvance = (avanceP, avanceE) => {
    if (avanceP && avanceE) {
      if (avanceP === 0 || avanceP === '-' || avanceE === 0) return '0%';
      const sumaTotalP = parseFloat(avanceP);
      const sumaTotalE = parseFloat(avanceE);
      if (sumaTotalP === 0) return '0%';
      return `${((sumaTotalE / sumaTotalP) * 100).toFixed(0)}%`;
    }
    return '0%';
  };

  const handleCancelParticipantes = () => {
    setIsModalVisibleParticipantes(false);
  };

  const handleClickBasedOnURL = (component, record) => {

    if (component.includes("sistematizacionIppta")) {
      setOpcionFiltro(1);
      setCodigoProyecto(record.proyecto);
      setIsModalVisibleParticipantes(true);
    } else if (component.includes("encuestaEdah")) {
      setOpcionFiltro(2);
      setCodigoProyecto(record.proyecto);
      setIsModalVisibleParticipantes(true);
    } else {

      procesarMpoa(component, record);
    }
  };

  const renderComponent = () => {
    switch (opcionFiltro) {
      case 1:
        return <Ippta proyectoCodigo={codigoProyecto} />;
      case 2:
        return <Edah proyectoCodigo={codigoProyecto} />;
      default:
        return null;
    }
  };


  const mapTareasSubtareas = (actividades) => {
    return actividades.flatMap((actividad) => {
      if (actividad.children && Array.isArray(actividad.children)) {
        return actividad.children.flatMap((tarea) =>
          tarea.children ? tarea.children.map((subtarea) => {
            const rowData = {
              fechaFinReal: formatDate(subtarea.medios_verificacion[0]?.fecha_fin_real),
              medioVerificacion: subtarea.medios_verificacion[0]?.medio_verificacion || '-',
              observacion: subtarea.medios_verificacion[0]?.observacion || '-',
              ruta: subtarea.medios_verificacion[0]?.ruta || '-',
            };
            const cronogramasP = subtarea.cronogramasP || [];
            const cronogramasE = subtarea.cronogramasE || [];
            const getAvance = (mes, cronogramas, ano) => {
              const cronograma = cronogramas.find(cronograma => cronograma.mes === mes && cronograma.ano === ano);
              return cronograma ? cronograma.avance : '-';
            };
            const sumarAvances = (datos) => {
              const totalAvance = datos.reduce((sum, cronograma) => {
                const avance = parseFloat(cronograma.avance);
                return sum + (isNaN(avance) ? 0 : avance);
              }, 0);
              return totalAvance;
            };
            const sumaP2024_2025 = sumarAvances(cronogramasP);
            const sumaE2024_2025 = sumarAvances(cronogramasE);
            const avanceAcu = sumaP2024_2025 !== 0 ? ((sumaE2024_2025 / sumaP2024_2025) * 100).toFixed(0) + '%' : '0%';
            const dataTable = [
              {
                key: `subtarea-${subtarea.id}-1`,
                proyecto: selectedProject.codigo,
                oidActividad: actividad.id,
                codActividad: actividad.codigo_actividad,
                oidTarea: tarea.id,
                codTarea: tarea.codigo_tarea,
                codIndicador: subtarea.id,
                codMV: subtarea.medio_verificacion_id,
                tarea: `${tarea.codigo_tarea} - ${tarea.descripcion}`,
                indicador: subtarea.indicador,
                meta: subtarea.meta,
                pesoPorcentual: tarea.pesoPorcentual,
                fechaInicio: formatDate(subtarea.fecha_inicio),
                fechaFin: formatDate(subtarea.fecha_fin),
                pe: 'P',
                ene2024: getAvance('ENE', cronogramasP, '2024'),
                keyEne2024: getIdByMonthAndYear('ENE', '2024', cronogramasP, subtarea.id),
                feb2024: getAvance('FEB', cronogramasP, '2024'),
                keyFeb2024: getIdByMonthAndYear('FEB', '2024', cronogramasP, subtarea.id),
                mar2024: getAvance('MAR', cronogramasP, '2024'),
                keyMar2024: getIdByMonthAndYear('MAR', '2024', cronogramasP, subtarea.id),
                abr2024: getAvance('ABR', cronogramasP, '2024'),
                keyAbr2024: getIdByMonthAndYear('ABR', '2024', cronogramasP, subtarea.id),
                may2024: getAvance('MAY', cronogramasP, '2024'),
                keyMay2024: getIdByMonthAndYear('MAY', '2024', cronogramasP, subtarea.id),
                jun2024: getAvance('JUN', cronogramasP, '2024'),
                keyJun2024: getIdByMonthAndYear('JUN', '2024', cronogramasP, subtarea.id),
                jul2024: getAvance('JUL', cronogramasP, '2024'),
                keyJul2024: getIdByMonthAndYear('JUL', '2024', cronogramasP, subtarea.id),
                ago2024: getAvance('AGO', cronogramasP, '2024'),
                keyAgo2024: getIdByMonthAndYear('AGO', '2024', cronogramasP, subtarea.id),
                sep2024: getAvance('SEP', cronogramasP, '2024'),
                keySep2024: getIdByMonthAndYear('SEP', '2024', cronogramasP, subtarea.id),
                oct2024: getAvance('OCT', cronogramasP, '2024'),
                keyOct2024: getIdByMonthAndYear('OCT', '2024', cronogramasP, subtarea.id),
                nov2024: getAvance('NOV', cronogramasP, '2024'),
                keyNov2024: getIdByMonthAndYear('NOV', '2024', cronogramasP, subtarea.id),
                dic2024: getAvance('DIC', cronogramasP, '2024'),
                keyDic2024: getIdByMonthAndYear('DIC', '2024', cronogramasP, subtarea.id),

                ene2025: getAvance('ENE', cronogramasP, '2025'),
                keyEne2025: getIdByMonthAndYear('ENE', '2025', cronogramasP, subtarea.id),
                feb2025: getAvance('FEB', cronogramasP, '2025'),
                keyFeb2025: getIdByMonthAndYear('FEB', '2025', cronogramasP, subtarea.id),
                mar2025: getAvance('MAR', cronogramasP, '2025'),
                keyMar2025: getIdByMonthAndYear('MAR', '2025', cronogramasP, subtarea.id),
                abr2025: getAvance('ABR', cronogramasP, '2025'),
                keyAbr2025: getIdByMonthAndYear('ABR', '2025', cronogramasP, subtarea.id),
                may2025: getAvance('MAY', cronogramasP, '2025'),
                keyMay2025: getIdByMonthAndYear('MAY', '2025', cronogramasP, subtarea.id),
                jun2025: getAvance('JUN', cronogramasP, '2025'),
                keyJun2025: getIdByMonthAndYear('JUN', '2025', cronogramasP, subtarea.id),
                jul2025: getAvance('JUL', cronogramasP, '2025'),
                keyJul2025: getIdByMonthAndYear('JUL', '2025', cronogramasP, subtarea.id),
                ago2025: getAvance('AGO', cronogramasP, '2025'),
                keyAgo2025: getIdByMonthAndYear('AGO', '2025', cronogramasP, subtarea.id),
                sep2025: getAvance('SEP', cronogramasP, '2025'),
                keySep2025: getIdByMonthAndYear('SEP', '2025', cronogramasP, subtarea.id),
                oct2025: getAvance('OCT', cronogramasP, '2025'),
                keyOct2025: getIdByMonthAndYear('OCT', '2025', cronogramasP, subtarea.id),
                nov2025: getAvance('NOV', cronogramasP, '2025'),
                keyNov2025: getIdByMonthAndYear('NOV', '2025', cronogramasP, subtarea.id),
                dic2025: getAvance('DIC', cronogramasP, '2025'),
                keyDic2025: getIdByMonthAndYear('DIC', '2025', cronogramasP, subtarea.id),


                metaAcu: sumaP2024_2025,
                avanceAcu: avanceAcu,
                fechaFinReal: formatDate(subtarea.fecha_f_real),
                medioVerificacion: rowData.medioVerificacion,
                observacion: rowData.observacion,
                ruta: subtarea.ruta,
                actividad: actividad.descripcion,
              },
              {
                key: `subtarea-${subtarea.id}-2`,
                oidActividad: actividad.id,
                pesoPorcentual: tarea.pesoPorcentual,
                proyecto: '',
                codIndicador: '',
                codActividad: '',
                codTarea: '',
                oidTarea: tarea.id,
                codMV: '',
                tarea: '',
                indicador: '',
                meta: '',
                fechaInicio: '',
                fechaFin: '',
                pe: 'E',
                ene2024: getAvance('ENE', cronogramasE, '2024'),
                keyEne2024: getIdByMonthAndYear('ENE', '2024', cronogramasE, subtarea.id),
                feb2024: getAvance('FEB', cronogramasE, '2024'),
                keyFeb2024: getIdByMonthAndYear('FEB', '2024', cronogramasE, subtarea.id),
                mar2024: getAvance('MAR', cronogramasE, '2024'),
                keyMar2024: getIdByMonthAndYear('MAR', '2024', cronogramasE, subtarea.id),
                abr2024: getAvance('ABR', cronogramasE, '2024'),
                keyAbr2024: getIdByMonthAndYear('ABR', '2024', cronogramasE, subtarea.id),
                may2024: getAvance('MAY', cronogramasE, '2024'),
                keyMay2024: getIdByMonthAndYear('MAY', '2024', cronogramasE, subtarea.id),
                jun2024: getAvance('JUN', cronogramasE, '2024'),
                keyJun2024: getIdByMonthAndYear('JUN', '2024', cronogramasE, subtarea.id),
                jul2024: getAvance('JUL', cronogramasE, '2024'),
                keyJul2024: getIdByMonthAndYear('JUL', '2024', cronogramasE, subtarea.id),
                ago2024: getAvance('AGO', cronogramasE, '2024'),
                keyAgo2024: getIdByMonthAndYear('AGO', '2024', cronogramasE, subtarea.id),
                sep2024: getAvance('SEP', cronogramasE, '2024'),
                keySep2024: getIdByMonthAndYear('SEP', '2024', cronogramasE, subtarea.id),
                oct2024: getAvance('OCT', cronogramasE, '2024'),
                keyOct2024: getIdByMonthAndYear('OCT', '2024', cronogramasE, subtarea.id),
                nov2024: getAvance('NOV', cronogramasE, '2024'),
                keyNov2024: getIdByMonthAndYear('NOV', '2024', cronogramasE, subtarea.id),
                dic2024: getAvance('DIC', cronogramasE, '2024'),
                keyDic2024: getIdByMonthAndYear('DIC', '2024', cronogramasE, subtarea.id),

                ene2025: getAvance('ENE', cronogramasE, '2025'),
                keyEne2025: getIdByMonthAndYear('ENE', '2025', cronogramasE, subtarea.id),
                feb2025: getAvance('FEB', cronogramasE, '2025'),
                keyFeb2025: getIdByMonthAndYear('FEB', '2025', cronogramasE, subtarea.id),
                mar2025: getAvance('MAR', cronogramasE, '2025'),
                keyMar2025: getIdByMonthAndYear('MAR', '2025', cronogramasE, subtarea.id),
                abr2025: getAvance('ABR', cronogramasE, '2025'),
                keyAbr2025: getIdByMonthAndYear('ABR', '2025', cronogramasE, subtarea.id),
                may2025: getAvance('MAY', cronogramasE, '2025'),
                keyMay2025: getIdByMonthAndYear('MAY', '2025', cronogramasE, subtarea.id),
                jun2025: getAvance('JUN', cronogramasE, '2025'),
                keyJun2025: getIdByMonthAndYear('JUN', '2025', cronogramasE, subtarea.id),
                jul2025: getAvance('JUL', cronogramasE, '2025'),
                keyJul2025: getIdByMonthAndYear('JUL', '2025', cronogramasE, subtarea.id),
                ago2025: getAvance('AGO', cronogramasE, '2025'),
                keyAgo2025: getIdByMonthAndYear('AGO', '2025', cronogramasE, subtarea.id),
                sep2025: getAvance('SEP', cronogramasE, '2025'),
                keySep2025: getIdByMonthAndYear('SEP', '2025', cronogramasE, subtarea.id),
                oct2025: getAvance('OCT', cronogramasE, '2025'),
                keyOct2025: getIdByMonthAndYear('OCT', '2025', cronogramasE, subtarea.id),
                nov2025: getAvance('NOV', cronogramasE, '2025'),
                keyNov2025: getIdByMonthAndYear('NOV', '2025', cronogramasE, subtarea.id),
                dic2025: getAvance('DIC', cronogramasE, '2025'),
                keyDic2025: getIdByMonthAndYear('DIC', '2025', cronogramasE, subtarea.id),
                metaAcu: sumaE2024_2025,
                avanceAcu: avanceAcu,
                fechaFinReal: rowData.fechaFinReal,
                medioVerificacion: '',
                observacion: '',
                ruta: '',
                actividad: '',
              },
            ];

            dataTable.forEach(item => {
              if (item.pe === 'P') {
                item.avanceEne2024 = calcularAvance(item.ene2024, dataTable[1].ene2024);
                item.avanceFeb2024 = calcularAvance(item.feb2024, dataTable[1].feb2024);
                item.avanceMar2024 = calcularAvance(item.mar2024, dataTable[1].mar2024);
                item.avanceAbr2024 = calcularAvance(item.abr2024, dataTable[1].abr2024);
                item.avanceMay2024 = calcularAvance(item.may2024, dataTable[1].may2024);
                item.avanceJun2024 = calcularAvance(item.jun2024, dataTable[1].jun2024);
                item.avanceJul2024 = calcularAvance(item.jul2024, dataTable[1].jul2024);
                item.avanceAgo2024 = calcularAvance(item.ago2024, dataTable[1].ago2024);
                item.avanceSep2024 = calcularAvance(item.sep2024, dataTable[1].sep2024);
                item.avanceOct2024 = calcularAvance(item.oct2024, dataTable[1].oct2024);
                item.avanceNov2024 = calcularAvance(item.nov2024, dataTable[1].nov2024);
                item.avanceDic2024 = calcularAvance(item.dic2024, dataTable[1].dic2024);
                item.avanceEne2025 = calcularAvance(item.ene2025, dataTable[1].ene2025);
                item.avanceFeb2025 = calcularAvance(item.feb2025, dataTable[1].feb2025);
                item.avanceMar2025 = calcularAvance(item.mar2025, dataTable[1].mar2025);
                item.avanceAbr2025 = calcularAvance(item.abr2025, dataTable[1].abr2025);
                item.avanceMay2025 = calcularAvance(item.may2025, dataTable[1].may2025);
                item.avanceJun2025 = calcularAvance(item.jun2025, dataTable[1].jun2025);
                item.avanceJul2025 = calcularAvance(item.jul2025, dataTable[1].jul2025);
                item.avanceAgo2025 = calcularAvance(item.ago2025, dataTable[1].ago2025);
                item.avanceSep2025 = calcularAvance(item.sep2025, dataTable[1].sep2025);
                item.avanceOct2025 = calcularAvance(item.oct2025, dataTable[1].oct2025);
                item.avanceNov2025 = calcularAvance(item.nov2025, dataTable[1].nov2025);
                item.avanceDic2025 = calcularAvance(item.dic2025, dataTable[1].dic2025);
              }
              if (item.pe === 'E') {
                item.avanceEne2024 = calcularAvance(item.ene2024, dataTable[0].ene2024);
                item.avanceFeb2024 = calcularAvance(item.feb2024, dataTable[0].feb2024);
                item.avanceMar2024 = calcularAvance(item.mar2024, dataTable[0].mar2024);
                item.avanceAbr2024 = calcularAvance(item.abr2024, dataTable[0].abr2024);
                item.avanceMay2024 = calcularAvance(item.may2024, dataTable[0].may2024);
                item.avanceJun2024 = calcularAvance(item.jun2024, dataTable[0].jun2024);
                item.avanceJul2024 = calcularAvance(item.jul2024, dataTable[0].jul2024);
                item.avanceAgo2024 = calcularAvance(item.ago2024, dataTable[0].ago2024);
                item.avanceSep2024 = calcularAvance(item.sep2024, dataTable[0].sep2024);
                item.avanceOct2024 = calcularAvance(item.oct2024, dataTable[0].oct2024);
                item.avanceNov2024 = calcularAvance(item.nov2024, dataTable[0].nov2024);
                item.avanceDic2024 = calcularAvance(item.dic2024, dataTable[0].dic2024);
                item.avanceEne2025 = calcularAvance(item.ene2025, dataTable[0].ene2025);
                item.avanceFeb2025 = calcularAvance(item.feb2025, dataTable[0].feb2025);
                item.avanceMar2025 = calcularAvance(item.mar2025, dataTable[0].mar2025);
                item.avanceAbr2025 = calcularAvance(item.abr2025, dataTable[0].abr2025);
                item.avanceMay2025 = calcularAvance(item.may2025, dataTable[0].may2025);
                item.avanceJun2025 = calcularAvance(item.jun2025, dataTable[0].jun2025);
                item.avanceJul2025 = calcularAvance(item.jul2025, dataTable[0].jul2025);
                item.avanceAgo2025 = calcularAvance(item.ago2025, dataTable[0].ago2025);
                item.avanceSep2025 = calcularAvance(item.sep2025, dataTable[0].sep2025);
                item.avanceOct2025 = calcularAvance(item.oct2025, dataTable[0].oct2025);
                item.avanceNov2025 = calcularAvance(item.nov2025, dataTable[0].nov2025);
                item.avanceDic2025 = calcularAvance(item.dic2025, dataTable[0].dic2025);
              }
            });

            return dataTable;
          }) : []
        );
      }
      return [];
    }).flat();
  };

  const procesarMpoa = async (url, valor) => {
    if (url) {
      const Mpoa = url.split("/poa/")[1];

      if (Mpoa === '2.2.1') {
        return null;
      }
      // Función para determinar qué actividad es especial y cómo manejarla
      const manejarActividadEspecial = (actividad) => {
        switch (actividad) {
          case '2.2.1.1':
            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
          case '2.2.1.2':
            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
          case '2.2.1.3':
            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
          default:
            return actividad; // Para actividades normales, retorna el valor original
        }
      };
      const actividadBase = manejarActividadEspecial(Mpoa);

      try {
        const dataCompleta = await fetchPoaData();
        const dataPoa = dataCompleta.dataPoa;
        const findNodePath = (node, path = []) => {
          path.push(node);
          if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
            return path;
          }
          if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
            if (node.children && node.children.length > 0) {
              for (let child of node.children) {
                if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                  path.push(child);
                  return path;
                }
                if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                  path.push(child);
                  return path;
                }
              }
            }
          }
          if (node.children && node.children.length > 0) {
            for (let child of node.children) {
              const result = findNodePath(child, [...path]);
              if (result) return result;
            }
          }
          return null;
        };
        let matchedPath = null;

        for (let nodo of dataPoa) {
          matchedPath = findNodePath(nodo);
          if (matchedPath) break;
        }
        if (matchedPath) {
          const initialData = extractData(matchedPath);
          const actividadData = initialData[0].actividadData;
          const tareaEncontrada = actividadData.find((item) => {
            if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
              return (
                item.codigo_tarea === actividadBase &&
                ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                  (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
              );
            }
            return item.codigo_tarea === actividadBase;
          });

          if (tareaEncontrada) {
            tareaEncontrada.proyecto = valor.proyecto;
            setBanderaProyecto(selectedProject.codigo);
            setOpenModal1(true);
            setItemParams(tareaEncontrada);
          } else {
            setOpenModal1(false);
            setItemParams(null);
          }
        } else {
          console.warn("No se encontró ningún nodo coincidente");
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    } else {
      setOpenModal1(false);
    }
  };

  const extractData = (children) => {
    return children.flatMap(child => {
      if (child.children && child.tipo === 'Actividad') {
        const actividadData = child.children.flatMap(grandchild => {
          if (grandchild.children && grandchild.tipo === 'Tarea') {
            return grandchild.children.flatMap(greatGrandchild => {
              if (greatGrandchild.tipo === 'Indicador') {
                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                const metaProyectada = greatGrandchild.meta_proyectada || 0;
                const valorEjecutado = Math.floor((greatGrandchild.meta_e?.reduce((total, meta) => total + meta.valor, 0) || 0) * 100) / 100;
                const porcentajeEjecucion = metaProyectada > 0 ? (valorEjecutado / metaProyectada) * 100 : 0;
                const medioV = greatGrandchild.medioVerificacion || null;
                return {
                  key: greatGrandchild.key,
                  codigo_tarea: grandchild.codigo_tarea,
                  codActividad: grandchild.codActividad,
                  nameActividad: child.actividad,
                  tarea: grandchild.tarea,
                  indicador: greatGrandchild.title,
                  medioV: medioV,
                  meta_proyectada: metaProyectada,
                  valorEjecutado: valorEjecutado,
                  porcentaje_ejecucion: porcentajeEjecucion % 1 === 0 ? porcentajeEjecucion.toFixed(0) : porcentajeEjecucion.toFixed(1),
                  porcentaje_avance: greatGrandchild.meta_e?.[0]?.valor || 'N/A',
                  fechas: fechaFormateada,
                  responsables: 'N/A',
                  soportes: 'N/A',
                  datoIndicador: greatGrandchild,
                };
              }
              return [];
            });
          }
          return [];
        });

        const totalMetaProyectada = actividadData.reduce((total, data) => total + (data.meta_proyectada || 0), 0);
        const totalValorEjecutado = actividadData.reduce((total, data) => total + (data.meta_proyectada * (parseFloat(data.porcentaje_ejecucion) / 100) || 0), 0);
        const porcentajeEjecucionActividad = totalMetaProyectada > 0 ? (totalValorEjecutado / totalMetaProyectada) * 100 : 0;

        return [{
          actividadTitle: child.title,
          actividad: child.actividad,
          porcentajeEjecucionActividad: porcentajeEjecucionActividad.toFixed(1),
          actividadData: actividadData
        }];
      }
      return [];
    });
  };

  const hideModalMedios = () => {
    setBanderaProyecto(null);
    setOpenModal1(false);
    setItemParams(null);
  };

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>El proyecto no existe o no se encontró información.</div>;
  }

  const formatDateToText = (dateString) => {
    if (!dateString) return ''; // Evita errores si la fecha es nula o indefinida

    // Descomponer la fecha "YYYY-MM-DD"
    const [year, month, day] = dateString.split('-');

    // Crear objeto Date en formato local
    const date = new Date(Number(year), Number(month) - 1, Number(day));

    return date.toLocaleDateString('es-CO', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const items = [
    {
      key: '1',
      span: 3,
      label: (
        <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
          {headerPO?.titulo || 'Nombre del Proyecto no disponible'}
        </div>
      ),
    },
    {
      key: '2',
      span: 3,
      label: (
        <div style={{ textAlign: 'left' }}>
          {headerPO?.convenio || 'Convenio no disponible'}
        </div>
      ),
    }, {
      key: '3',
      span: 3,
      label: (
        <div style={{ textAlign: 'left' }}>
          {headerPO?.factibilidad || 'Factibilidad no disponible'}
        </div>
      ),
    }, {
      key: '4',
      span: 3,
      label: (
        <div style={{ textAlign: 'left' }}>
          {headerPO?.territorio_zona || 'Territorio / Zona PDET no disponible'}
        </div>
      ),
    },
    {
      key: '5',
      span: 1,
      label: (
        <div style={{ textAlign: 'left' }}>
          <strong>Tiempo de Duración:</strong>{' '}
          {headerPO?.duracion ?? 'Duración no disponible'}
        </div>
      ),
    },
    {
      key: '6',
      span: 1,
      label: (
        <div style={{ textAlign: 'left' }}>
          <strong>Fecha Inicio:</strong>{' '}
          {formatDateToText(headerPO?.fecha_inicio)}
        </div>
      ),
    },
    {
      key: '7',
      span: 1,
      label: (
        <div style={{ textAlign: 'left' }}>
          <strong>Fecha Terminación:</strong>{' '}
          {formatDateToText(headerPO?.fecha_terminacion)}
        </div>
      ),
    },
  ];

  const formatFechaToISO = (fecha) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(fecha)) {
      const [year, month, day] = fecha.split('-');
      const formattedDate = new Date(year, month - 1, day);
      if (isNaN(formattedDate)) {
        throw new Error(`Fecha no válida: ${fecha}`);
      }
      return formattedDate.toISOString();
    }

    // Detecta si la fecha está en formato DD/MM/YYYY
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(fecha)) {
      const [day, month, year] = fecha.split('/');
      const formattedDate = new Date(year, month - 1, day);
      if (isNaN(formattedDate)) {
        throw new Error(`Fecha no válida: ${fecha}`);
      }
      return formattedDate.toISOString();
    }

    // Si no coincide con ninguno de los formatos, lanza un error
    throw new Error(`Formato de fecha inválido: ${fecha}`);
  };

  const handleSaveDate = async (value, record) => {
    const codIndicador = record.codIndicador;
    const newfechaFinReal = value.fechaFinReal;
    const tipo = 'medio_verificacion';
    const newData = [...actividades];
    newData.forEach((actividad) => {
      actividad.children.forEach((tarea) => {
        tarea.children.forEach((cronograma) => {
          if (cronograma.id === codIndicador) {
            cronograma.fecha_f_real = newfechaFinReal;
          }
        });
      });
    });
    setActividades(newData);
    setTareasSubtareas(mapTareasSubtareas(newData));
    try {
      const response = await updateFecha(codIndicador, newfechaFinReal, tipo);

      if (response.status === 200) {
        message.success('Avance actualizado correctamente');
      } else {
        message.error('No se pudo actualizar el avance');
      }
    } catch (error) {
      console.error('Error en handleSaveDate:', error);
      message.error('Error al actualizar el avance');
    }
  };

  const handleSave = async (value, key, dataIndex) => {

    const valorActualizado = value[dataIndex];
    if (valorActualizado === undefined || valorActualizado === null) {
      return; // No hacer nada si es undefined o null
    }

    const newData = [...actividades];
    newData.forEach(actividad => {
      actividad.children.forEach(tarea => {
        tarea.children.forEach(cronograma => {
          const cronogramaP = cronograma.cronogramasP.find(item => item.id === Number(key));
          const cronogramaE = cronograma.cronogramasE.find(item => item.id === Number(key));
          if (cronogramaP) {

            cronogramaP.avance = valorActualizado;
          }
          if (cronogramaE) {

            cronogramaE.avance = valorActualizado;
          }
        });
      });
    });
    setActividades(newData);
    setTareasSubtareas(mapTareasSubtareas(newData));

    try {
      const response = await updateAvance(key, valorActualizado);
      if (response.status === 200) {
        message.success('Avance actualizado correctamente');

        // Actualizamos el JSON raw para recalcular totales sin volver a consumir el servicio.
        setRawPesoData(prevRaw => {
          const newRaw = updateAvanceInRaw(prevRaw, key, Number(valorActualizado));
          return newRaw;
        });


      }
    } catch (error) {
      message.error('Error al actualizar el avance: '); // Muestra un mensaje de error
    }
  };


  const handleSavePeso = async (updatedRecord) => {

    const valorActualizado = updatedRecord.pesoPorcentual;
    if (valorActualizado === undefined || valorActualizado === null) {
      return; // No hacer nada si es undefined o null
    }

    // Supongamos que identificamos la tarea por tarea.id o record.oidTarea.
    // Por ejemplo, usaremos "oidTarea" (asegúrate de que cada tarea tenga este campo único).
    const rowId = updatedRecord.oidTarea;
    let oldValue = null;

    // Creamos una copia del array de actividades
    const newData = [...actividades];

    // Recorremos las actividades
    newData.forEach((actividad) => {
      if (!actividad.children) return;

      actividad.children.forEach((tarea) => {
        // Si la tarea coincide con el id, actualizamos su pesoPorcentual
        if (tarea.oidTarea === rowId || tarea.id === rowId) {

          oldValue = Number(tarea.pesoPorcentual || 0);
          tarea.pesoPorcentual = valorActualizado;
          return { ...tarea, pesoPorcentual: Number(valorActualizado) };
        }
      });
    });

    // Actualizamos el estado con la nueva data
    setActividades(newData);
    setTareasSubtareas(mapTareasSubtareas(newData));

    // Si hemos obtenido el valor anterior, calculamos el delta y ajustamos pesoTotal
    if (oldValue !== null) {

      const delta = Number(valorActualizado) - oldValue;

      const nuevoTotal = pesoTotal + delta;

      setPesoTotal(nuevoTotal);


      if (nuevoTotal > 100) {
        Modal.confirm({
          title: 'Advertencia: Peso Excedido',
          content: (
            <div>
              El peso porcentual ingresado excede la sumatoria del 100% para la Fase I y Fase II.
              Ajuste el valor ingresado para evitar errores en el cálculo de avance de las actividades.
            </div>
          ),
          centered: true,
          okText: 'Cerrar',
          cancelText: 'Cancelar',
          onOk() {
            // Acciones a realizar cuando se presiona "Cerrar"
          },
          onCancel() {
            // Acciones a realizar cuando se presiona "Cancelar"
          },
        });
      }
    }

    try {
      const response = await updatePeso(rowId, valorActualizado); // Asegúrate de tener updatePeso definido
      if (response.status === 200) {
        message.success('Peso actualizado correctamente');

        setRawPesoData((prevRaw) => {

          const newJson = updatePesoInJson(prevRaw, rowId, Number(valorActualizado));
          return newJson;
        });

      }
    } catch (error) {
      message.error('Error al actualizar el peso');
    }
  };

  // Función para actualizar el peso en el JSON "raw" (que es un arreglo de actividades)
  const updatePesoInJson = (jsonArray, rowId, newValue) => {

    if (!jsonArray) return []; // Retorna un arreglo vacío si jsonArray es null o undefined

    // Recorremos cada actividad
    return jsonArray.map((actividad) => ({
      ...actividad,
      // Si la actividad tiene tareas ("children"), las actualizamos
      children: actividad.children
        ? actividad.children.map((tarea) => {
          // Si la tarea coincide con el rowId (puedes usar tarea.id o tarea.oidTarea)
          if (tarea.id === rowId || tarea.oidTarea === rowId) {
            // Actualizamos la propiedad "pesoPorcentual"
            return { ...tarea, pesoPorcentual: newValue };
          }
          // Si no, la dejamos igual
          return tarea;
        })
        : actividad.children,
    }));
  };

  const handleNoInfoClick = (datos) => {
    setBanderaProyecto(selectedProject.codigo);
    setActividadSelect(datos);
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setBanderaProyecto(null)
    setActividadSelect(null);
    setIsModalVisible(false);
  };

  const cellStyle = { padding: '2px 5px' };

  const columnss = [

    ...(isVisibleTarea
      ? [
        {
          title: 'Tarea',
          dataIndex: 'tarea',
          key: 'tarea',
          width: 300,
          ellipsis: false,
          onCell: (record, rowIndex) => ({
            rowSpan: rowIndex % 2 === 0 ? 2 : 0, style: cellStyle
          }),
          render: (text, record, rowIndex) =>
            rowIndex % 2 === 0 ? (
              <div
                style={{
                  width: '482px',
                  whiteSpace: 'pre-line',
                  wordWrap: 'break-word',
                  textAlign: 'justify',
                }}
              >
                {text}
              </div>
            ) : null,
        },
      ]
      : []),

    {
      title: 'Indicador',
      dataIndex: 'indicador',
      key: 'indicador',
      fixed: 'left',
      width: 50,
      render: (text) => (
        <Tooltip title={text}>
          <span
            style={{
              display: 'block', // o 'inline-block'
              whiteSpace: 'nowrap', // Impide saltos de línea
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '300px',
              minWidth: '300px',
              maxWidth: '300px',
              lineHeight: .9,
            }}
          >
            {text}
          </span>
        </Tooltip>
      ),
      onCell: (record, rowIndex) => ({
        rowSpan: rowIndex % 2 === 0 ? 2 : 0,
      }),
    },

    {
      title: 'Meta',
      dataIndex: 'meta',
      key: 'meta',
      width: 100,
      fixed: 'left',
      align: 'center',
      onCell: (record, rowIndex) => ({
        rowSpan: rowIndex % 2 === 0 ? 2 : 0,
      }),
      render: (value) => {
        // Asegurar que el valor sea un número
        const numValue = Number(value); // Convertimos a número si viene como string

        if (isNaN(numValue)) {
          return '-'; // Valor por defecto si no es un número
        }

        // Extraemos la parte decimal
        const decimalPart = numValue % 1;

        // Si la parte decimal es menor o igual a 0.1, mostrar solo el entero
        return decimalPart <= 0.1 ? Math.trunc(numValue) : numValue.toFixed(1);
      },
    },



    { title: 'Fecha Inicio', dataIndex: 'fechaInicio', key: 'fechaInicio', width: 150, onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) },
    { title: 'Fecha Fin', dataIndex: 'fechaFin', key: 'fechaFin', width: 150, onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) },
    { title: 'P/E', dataIndex: 'pe', key: 'pe', width: 50, align: 'center', fixed: 'left' },
    {
      title: 'Cronograma 2024',
      className: 'color2024',
      children: columnasDinamicas2024,
    },
    {
      title: 'Cronograma 2025',
      className: 'color2025',
      children: columnasDinamicas2025,
    },

    {
      title: 'Seguimiento Acumulado', children: [
        { title: 'Meta y Avance Acumulado', dataIndex: 'metaAcu', key: 'metaAcu', width: 150, render: (value) => value || '-', align: 'center' },
        { title: '% de Avance Acumulado', dataIndex: 'avanceAcu', key: 'avanceAcu', width: 100, render: (text) => { const progreso = parseFloat(text); const { color, backgroundColor } = getAvanceStyle(progreso); return (<div style={{ color, backgroundColor }}>{text}</div>); }, align: 'center', onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) }
      ]
    },

    ...(!tipoFase
      ? [
        {
          title: (
            <Tooltip
              title={`*Asignar el peso porcentual según la relevancia de la actividad en el proyecto.`}
            >
              <div
                style={{
                  display: 'block', // o 'inline-block'
                  whiteSpace: 'nowrap', // Impide saltos de línea
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '200px',
                  minWidth: '200px',
                  maxWidth: '200px',
                  lineHeight: .9,
                  textAlign: 'center',
                }}
              >
                <div style={{ textAlign: 'center' }}>
                  PESO PORCENTUAL <br />
                  (
                  <span style={{ fontSize: '1em' }}>
                    {pesoTotal.toFixed(0)}%
                  </span>
                  )
                </div>
                <hr />
                <div style={{ textAlign: 'center' }}>
                  PESO PORCENTUAL <br />
                  {selectedFase.nombre_corto} <br />
                  (
                  <span style={{ fontSize: '1em' }}>
                    {totalPesoFase.toFixed(0)}%
                  </span>
                  )
                </div>
              </div>
            </Tooltip>
          ),
          dataIndex: 'pesoPorcentual',
          key: 'pesoPorcentual',
          width: 200,
          align: 'center',
          render: (text) => `${text}%`,
          onCell: (record, rowIndex) => ({
            record,
            editable: permissionView,
            dataIndex: 'pesoPorcentual',  // <-- PASAR el dataIndex
            dataType: 'integer',
            handleSave: (updatedRecord) => {
              // Este "updatedRecord" es el objeto que envía EditableCell
              // Llamas tu función handleSavePeso
              handleSavePeso(updatedRecord);
            },
            rowSpan: rowIndex % 2 === 0 ? 2 : 0
          }),
        },
      ] : []),


    {
      title: 'FECHA FIN REAL',
      dataIndex: 'fechaFinReal',
      key: 'fechaFinReal',
      width: 150,
      align: 'center',
      editable: permissionInsert,
      render: (text, record) => {
        if (!text || text === 'Sin fecha') {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                margin: '2px',
              }}
            >
              <span>Sin fecha</span>
              <Tooltip title="Por favor diligenciar este campo, con la fecha en la que se realizó la tarea, en formato dd/mm/año">
                <WarningOutlined
                  style={{ fontSize: '16px', color: 'red', cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
          );
        }
        return text;
      },
      onCell: (record, rowIndex) => ({
        record,
        editable: permissionInsert,
        dataType: 'date',
        handleSave: (value) =>
          handleSaveDate(value, record),
        rowSpan: rowIndex % 2 === 0 ? 2 : 0,
      }),

    },
    {
      title: 'MEDIO DE VERIFICACIÓN',
      dataIndex: 'medioVerificacion',
      key: 'medioVerificacion',
      width: 250,
      ellipsis: false,
      onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }),
      render: (text) => (
        <div style={{ width: '400px', whiteSpace: 'pre-line', wordWrap: 'break-word', textAlign: 'justify' }}>
          {text}
        </div>
      ),
    },
    ...(permissionUpdate
      ? [
        {
          title: "Ruta Soportes",
          dataIndex: "ruta",
          align: "center",
          key: "ruta",
          width: 150,
          onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0 }),
          render: (text, record) => {
            //SIN RUTA POA, PLAN OPERATIVO
            if (!text) {
              return (
                <Tooltip title="No hay información disponible">
                  <InfoCircleOutlined
                    style={{ fontSize: "16px", color: "#8ABF45", cursor: "pointer" }}
                    onClick={() => handleNoInfoClick(record)}
                  />
                </Tooltip>
              );
            }

            const contentRegex = /{([^{}]+)}/g;
            let matches = [...text.matchAll(contentRegex)];
            //RUTA POA
            if (matches.length === 0) {
              return text.startsWith("https://") ? (
                <Tooltip title={text}>
                  <LinkOutlined
                    style={{ fontSize: "16px", color: "#317EDA", cursor: "pointer" }}
                    onClick={() => handleClickBasedOnURL(text, record)}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={text}>
                  <InfoCircleOutlined
                    style={{ fontSize: "16px", color: "#8ABF45", cursor: "pointer" }}
                    onClick={() => handleNoInfoClick(record)}
                  />
                </Tooltip>
              );
            }

            let elements = matches.map((match, index) => {
              let content = match[1].trim();
              return content.startsWith("https://") ? (
                <Tooltip key={`url-${index}`} title={content}>
                  <LinkOutlined
                    style={{ fontSize: "16px", color: "#317EDA", cursor: "pointer" }}
                    onClick={() => handleClickBasedOnURL(content, record)}
                  />
                </Tooltip>
              ) : (
                <Tooltip key={`text-${index}`} title={content}>
                  <InfoCircleOutlined
                    style={{ fontSize: "16px", color: "#8ABF45", cursor: "pointer" }}
                    onClick={() => handleNoInfoClick(record)}
                  />
                </Tooltip>
              );
            });

            return <Space>{elements}</Space>;
          },
        },
      ]
      : []),
  ];



  const getAvanceStyle = (progreso) => {
    const color = progreso < 30 ? 'red' : progreso < 60 ? 'orange' : 'green';
    const backgroundColor = progreso < 30 ? 'rgba(255, 0, 0, 0.1)' : progreso < 60 ? 'rgba(255, 165, 0, 0.1)' : 'rgba(0, 128, 0, 0.1)';
    return { color, backgroundColor };
  };


  const panelItems = actividades.map((actividad) => ({
    key: actividad.codigo_actividad,
    label: (

      <span style={{ color: 'white', marginRight: '8px' }}>
        Actividad: {actividad.codigo_actividad} - {actividad.descripcion}
      </span>

    ),
    style: {
      backgroundColor: '#042956',
      color: 'white',
      margin: '0px',
    },
    children: (
      <div className="tabla-container">

        {mostrarTablaCronograma && (
          <div className="custom-ant-table-wrapper">
            <Table
              bordered
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              width="100%"
              className="table-curso-fortalecimiento"
              style={{ tableLayout: 'fixed' }}
              columns={columnss}
              dataSource={tareasSubtareas.filter((tarea) => tarea.oidActividad === actividad.id)}
              pagination={false}
              loading={loading}
              scroll={{
                x: 'max-content',
                y: 600,
              }}
              size="small"
              rowClassName={(record, index) =>
                index === filaSeleccionada ? 'fila-seleccionada' : 'fila-normal'
              }
              onRow={(record, index) => ({
                onClick: () => setFilaSeleccionada(index),
              })}

            />
          </div>
        )}

        {permissionPrint && (
          <>
            {mostrarTablaResumen && !tipoFase && (
              <div style={{ width: '100%' }}>
                {/* Título fijo fuera del contenedor con scroll */}
                <div className="titleTable">
                  <hr />
                  <h3 className="titleResumen">Resumen avance en la ejecución</h3>
                </div>

                <div style={{ overflowX: 'auto' }}>
                  <TablaAvanceJSX valoresJsonPeso={valoresJsonPeso} modo='filtro' />
                </div>

              </div>
            )}
          </>)}

      </div>
    ),
  }));



  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Cambiar el estado entre visible y oculto
  };

  const regresar = () => {
    regresarAnterior();
    setEditingDate(false);
    cerrarModal();
  };

  const handleEditDateClick = () => {
    setEditingDate(true);
  };

  const handleDateChange = async (date, dateString, headerPO) => {
    if (date && headerPO) {
      const fecha = dayjs(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const oid = headerPO?.children?.[0]?.id;
      const tipo = "detalle";
      const updatedHeaderPO = { ...headerPO };
      updatedHeaderPO.fecha_actualizacion = formatFechaToISO(fecha);
      if (updatedHeaderPO.children && updatedHeaderPO.children.length > 0) {
        updatedHeaderPO.children[0].fecha_actualizacion = formatFechaToISO(fecha);
      }
      try {
        await updateFecha(oid, fecha, tipo);
        setHeaderPO(updatedHeaderPO);
        setEditingDate(false);
        message.success('Fecha actualizada correctamente');
      } catch (error) {
        message.error('Hubo un error al actualizar la fecha');
        console.error('Error al actualizar la fecha:', error);
      }
    }
  };


  return (
    <div className="">
      <Layout>
        <div className="d-flex justify-content-center">
          <div className="d-flex justify-content-center" style={{ gap: "10px" }}>
            <Tooltip title="Regresar">
              <Button
                type="primary"
                shape="circle"
                icon={<ArrowLeftOutlined />}
                onClick={regresar}
              />
            </Tooltip>
            <Tooltip title={isVisible ? "Cerrar contenido" : "Abrir contenido"}>
              <Button
                type="primary"
                shape="circle"
                icon={isVisible ? <MoreOutlined /> : <MenuOutlined />}
                onClick={toggleVisibility}
              />
            </Tooltip>
          </div>
        </div>

        <div className="d-flex justify-content-center gap-3">

          {isVisible && (

            <>
              <Card style={{ marginTop: 16, width: '50%' }}>
                <Descriptions title="" layout="vertical" bordered items={items} />

              </Card>

              <div style={{ paddingTop: '15px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                <Title
                  level={4}
                  className="gradient-text"
                  style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {selectedProject.codigo} - {selectedProject.proyecto}
                </Title>
                <p style={{ fontSize: '16px', fontWeight: 'bold', margin: 0 }}>
                  Fecha de actualización:
                </p>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    fontSize: '16px',
                    border: '1px solid #A19E9E',
                    fontWeight: 'bold',
                    padding: '5px',
                    borderRadius: '4px',
                    margin: 0,
                    width: '270px',
                    cursor: permissionInsert ? 'pointer' : 'not-allowed',
                    backgroundColor: permissionInsert ? 'white' : '#f8f9fa',
                  }}
                  onClick={() => {
                    if (permissionInsert) {
                      handleEditDateClick();
                    }
                  }}
                >
                  {editingDate ? (
                    <DatePicker
                      style={{ width: '270px', }}
                      onChange={(date, dateString) => handleDateChange(date, dateString, headerPO)}
                      value={
                        headerPO?.fecha_actualizacion
                          ? dayjs(headerPO.fecha_actualizacion)
                          : null
                      }
                      format="DD/MM/YYYY"
                    />
                  ) : headerPO?.fecha_actualizacion ? (
                    <span>{moment(headerPO.fecha_actualizacion).format('DD/MM/YYYY')}</span>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        padding: '8px 12px',
                        backgroundColor: '#f8f9fa',
                        borderRadius: '4px',
                        maxWidth: '100%',
                        boxSizing: 'border-box',
                      }}
                    >
                      <span
                        style={{
                          color: '#575757',
                          fontSize: '14px',
                          textAlign: 'center',
                        }}
                      >
                        Haz clic para registrar la fecha
                      </span>
                      <Tooltip title="*Por favor diligenciar este campo, con la fecha de corte en la que se actualizaron las metas ejecutadas del POA.">
                        <WarningOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
                      </Tooltip>
                    </div>

                  )}

                </div>
                {headerPO?.fecha_actualizacion ? (
                  null
                ) : (

                  <Tooltip title="**Por favor diligenciar este campo, con la fecha de corte en la que se actualizaron las metas ejecutadas del POA.">
                    <WarningOutlined style={{ fontSize: '20px', color: 'red', cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </div>
            </>


          )}

          {!isVisible && (
            <div
              style={{
                paddingTop: '15px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Title
                level={4}
                className="gradient-text"
                style={{ margin: 0 }}
              >
                {selectedProject.codigo} - {selectedProject.proyecto}
              </Title>

              <p style={{ fontSize: '16px', fontWeight: 'bold', margin: 0 }}>
                Fecha de actualización:
              </p>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  fontSize: '16px',
                  border: '1px solid #A19E9E',
                  fontWeight: 'bold',
                  padding: '5px',
                  borderRadius: '4px',
                  margin: 0,
                  width: '270px',
                }}
                onClick={handleEditDateClick}
              >
                {editingDate ? (
                  <DatePicker
                    style={{ width: '270px' }}
                    onChange={(date, dateString) => handleDateChange(date, dateString, headerPO)}
                    value={
                      headerPO?.fecha_actualizacion
                        ? dayjs(headerPO.fecha_actualizacion)
                        : null
                    }
                    format="DD/MM/YYYY"
                  />
                ) : headerPO?.fecha_actualizacion ? (
                  <span>
                    {moment(headerPO.fecha_actualizacion).format('DD/MM/YYYY')}
                  </span>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <span style={{ color: '#575757' }}>
                      Haz clic para registrar la fecha
                    </span>
                    <Tooltip title="*Por favor diligenciar este campo, con la fecha de corte en la que se actualizaron las metas ejecutadas del POA.">
                      <WarningOutlined
                        style={{ fontSize: '16px', color: 'red', cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>

              {!headerPO?.fecha_actualizacion && (



                <Tooltip title="*Por favor diligenciar este campo, con la fecha de corte en la que se actualizaron las metas ejecutadas del POA.">
                  <WarningOutlined
                    style={{ fontSize: '16px', color: 'red', cursor: 'pointer' }}
                  />
                </Tooltip>


              )}
            </div>
          )}




        </div>

        <div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title
              level={4}
              className="justified-title"
              style={{ marginTop: '10px', marginRight: '16px' }} // separa el título del icono
            >
              {selectedFase.nombre_corto}
            </Title>

            <div style={{ display: 'flex', alignItems: 'center' }}>

              {!tipoFase && (
                <>
                  <span
                    onClick={toggleTablaResumen}
                    style={{ color: 'red', cursor: 'pointer', fontSize: '20px', marginRight: '16px' }} // aumenta el tamaño del icono
                  >

                    <Tooltip title={`Mostrar u ocultar el resumen de avance en la ejecución.`}>
                      <TableOutlined style={{ color: mostrarTablaCronograma ? 'green' : 'gray', fontSize: '20px' }} />

                    </Tooltip>

                  </span>
                  <span
                    onClick={toggleTablaCronograma}
                    style={{ color: 'red', cursor: 'pointer', fontSize: '20px' }} // aumenta el tamaño del icono
                  >

                    <Tooltip title={`Mostrar u ocultar cronograma.`}>
                      <EyeOutlined style={{ color: mostrarTablaResumen ? 'green' : 'gray', fontSize: '20px', marginRight: '16px' }} />
                    </Tooltip>

                  </span>
                </>
              )}

              <span
                onClick={toggleVisibilityTarea}
                style={{ color: 'red', cursor: 'pointer', fontSize: '20px', marginRight: '16px' }} // aumenta el tamaño del icono
              >
                <Tooltip title={`Mostrar u ocultar la columna Tarea.`}>
                  <ColumnHeightOutlined style={{ color: toggleVisibilityTarea ? 'green' : 'gray', fontSize: '20px' }} />

                </Tooltip>

              </span>


            </div>
          </div>

          <Collapse accordion items={panelItems} />


        </div>



        <div className="d-flex justify-content-center">
          <Modal
            title={<span className="custom-modal-title">Medios de verificación</span>}
            open={isModalVisible}
            onCancel={hideModal}
            footer={null}
            width="95%"
            style={{ top: 20 }}
          >
            <FileManagerPOA2 codigo_tarea={actividadSelect} select_proyect={banderaProyecto} />
          </Modal>
        </div>


        <div className="d-flex justify-content-center">
          <Modal
            title={<span className="custom-modal-title">Medios de verificación</span>}
            open={openModal1}
            onCancel={hideModalMedios}
            footer={null}
            width="95%"
            style={{ top: 20 }}
          >
            <FileManagerPOAN codigo_tarea={itemParams} />
          </Modal>
        </div>
        <div>
          <Modal
            title=""
            open={isModalVisibleParticipantes}
            onCancel={handleCancelParticipantes}
            footer={null}
            width="95%"
            style={{ top: 10 }}
          >
            {renderComponent()}
          </Modal>
        </div>
      </Layout>
    </div>
  );
};

export default PoaPlanOperativoTB;
