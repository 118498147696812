import axios from "axios";
import config from "../../../config";

const baseUrl = config.baseUrl;

/**
 * Descarga un archivo dado su OID
 * @param {string} oid - Identificador del archivo
 * @returns {Promise<Blob>} - Archivo descargado en formato Blob
 */
export const downloadFile = async (oid) => {
  try {
    const response = await axios.get(`${baseUrl}/api/repositorio/downldoad_repo/${oid}`, {
      responseType: 'blob', // Permite manejar archivos binarios
    });

    return {
      status: response.status,
      data: response.data, // Archivo en formato Blob
    };
  } catch (error) {
    console.error('Error al descargar el archivo:', error);
    throw error;
  }
};

/**
 * Obtiene la URL de visualización de un archivo
 * @param {string} oid - Identificador del archivo
 * @returns {string} - URL para visualizar el archivo
 */
export const getViewFileUrl = (oid) => {
  return `${baseUrl}/api/repositorio/view_repo/${oid}`;
};



/**
 * Crea una carpeta en el repositorio (en la ubicación actual)
 * @param {Object} params - Datos necesarios para crear la carpeta
 * @param {string|number} params.parentId - Identificador de la carpeta padre (ubicación actual). Puede ser null o 0 si estás en raíz
 * @param {string} params.folderName - Nombre de la nueva carpeta
 * @returns {Promise<Object>} - Respuesta del servidor con info de la carpeta creada
 */
export const createFolder = async ({ parentId, folderName, username }) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/create_folder`, {
      parentId,
      folderName,
      username
    });

    return response.data; // Podrías retornar la data con { success: true, folder: {...} }
  } catch (error) {
    console.error("Error al crear carpeta:", error);
    throw error;
  }
};


export const renameFolder = async ({ folderId, newName, username }) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/rename_folder`, {
      folderId,
      newName,
      username
    });
    return response.data;
  } catch (error) {
    console.error("Error renombrando carpeta:", error);
    throw error;
  }
};

export const deleteFolder = async ({ folderId, username }) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/delete_folder`, { folderId, username });
    return response.data;
  } catch (error) {
    console.error("Error al eliminar carpeta:", error);
    throw error;
  }
};


//Trabajo con archivos

export const uploadFiles = async (formData) => {

  try {

    const response = await axios.post(`${baseUrl}/api/repositorio/upload_file`, formData);
    return response.data;
  } catch (error) {
    console.error("Error al subir archivos:", error);
    throw error;
  }
};

export const deleteFile = async ({ fileId, username }) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/delete_file`, {
      fileId,
      username
    });
    return response.data;
  } catch (error) {
    console.error("Error al eliminar archivo:", error);
    throw error;
  }
};

export const downloadZip = async (fileIds) => {
  const response = await axios.post(`${baseUrl}/api/repositorio/download-zip`, { fileIds }, {
    responseType: 'blob'
  });
  return response;
};


export const uploadFolderFile = async (formData) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/upload_folder`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8'
      },
    });
    console.log("Respuesta del servidor:", response); // Para depuración
    return response;
  } catch (error) {
    console.error("Error al subir carpeta. :", error);
    throw error;
  }
};


/**
 * Obtiene la lista de documentos del repositorio
 * @returns {Promise<Array>} - Lista de documentos [{ oid, nombre, link, origen }]
 */
export const getListaDocumentos = async () => {
  try {
    const response = await axios.get(`${baseUrl}/api/repositorio/lista-documentos`);
    console.log("Respuesta servidor: ", response)
    return response.data; // { status: 200, data: [...] }
  } catch (error) {
    console.error('Error al obtener la lista de documentos:', error);
    throw error;
  }
};



/**
 * Actualiza un documento existente por su OID
 * @param {Object} documento - Objeto con campos: oid, nombre, link, origen
 * @returns {Promise}
 */
export const updateDocumento = async (documento) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/update_documento`, documento);
    return response.data;
  } catch (error) {
    console.error('Error al actualizar el documento:', error);
    throw error;
  }
};


export const createDocumento = async ({ nombre, link }) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/create_documento`, {
      nombre,
      link
    });

    return response.data;
  } catch (error) {
    console.error("Error al crear documento:", error);
    throw error;
  }
};


export const deleteDocumento = async (oid) => {
  try {
    const response = await axios.post(`${baseUrl}/api/repositorio/delete_documento`, { oid });
    return response.data;
  } catch (error) {
    console.error("Error al eliminar documento:", error);
    throw error;
  }
};


