import axios from 'axios';
import config from '../../../config'; // Configuración de la URL base

const baseUrl = config.baseUrl;
const endpoint = '/api/graficasEnfoqueGeneral';
const API_URL = `${baseUrl}${endpoint}`;

const EnfoqueGrafico = {
  getData: async () => {
    try {
      const response = await axios.get(API_URL);
      return response.data; // Retorna los datos obtenidos
    } catch (error) {
      console.error("Error obteniendo los datos:", error);
      throw error; // Lanza el error para que pueda ser manejado donde se llame
    }
  }
};

export default EnfoqueGrafico;
