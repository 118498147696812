import axios from "axios";
import config from "../../../config"; // Asegúrate de que la ruta sea correcta

const baseUrl = config.baseUrl;
const endpoint = `${baseUrl}/api/repositorioDocumento`; // Concatena correctamente

const RepositorioService = {
  getDocuments: async (user) => {
    try {
      const response = await axios.get(`${endpoint}/${user}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching documents:", error);
      throw error;
    }
  },

  getDocumentById: async (id) => {
    try {
      const response = await axios.get(`${endpoint}/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching document with ID ${id}:`, error);
      throw error;
    }
  },

  createDocument: async (data) => {
    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error("Error creating document:", error);
      throw error;
    }
  },

  uploadFile: async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(`${endpoint}/upload`, formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  },

  deleteDocument: async (id) => {
    try {
      const response = await axios.delete(`${endpoint}/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting document with ID ${id}:`, error);
      throw error;
    }
  }
};

export default RepositorioService;
