import React from 'react';
import * as ExcelJS from 'exceljs';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

const ExcelDetalleCohorte = ({ cohorte }) => {

    const obtenerMeses = (fechaInicio, fechaFinal) => {
        if (!fechaInicio || !fechaFinal) return '';

        // Convertir fechas en formato local
        const fechaIni = new Date(`${fechaInicio}T00:00:00`);
        const fechaFin = new Date(`${fechaFinal}T00:00:00`);

        // Lista de nombres de meses
        const meses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        // Obtener el número de mes correctamente (suma +1)
        const mesInicio = fechaIni.getMonth() + 1; // Septiembre (9)
        const mesFinal = fechaFin.getMonth() + 1; // Octubre (10)

        // Construir el periodo de meses correctos
        const periodo = [];
        for (let i = mesInicio; i <= mesFinal; i++) {
            periodo.push(meses[i - 1]); // Ajustar índice al array
        }

        return periodo.join("_"); // Concatenar meses con "_"
    };

    const handleExport = async () => {
        if (!cohorte || !cohorte.rawData || !cohorte.rawData.JSONDataCohorte) {
            console.error("No hay datos de cohorte para exportar");
            return;
        }

        const jsonData = cohorte.rawData.JSONDataCohorte;
        const fileExtension = '.xlsx';
        const fileName = `Detalle_Cohorte_${cohorte.cohorte}` + fileExtension;


        const periodo = obtenerMeses(jsonData.fechaInicio, jsonData.fechaFinal);

        // Crear un nuevo libro y una hoja de trabajo
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(`Cohorte ${cohorte.cohorte}`);

        // **Definir anchos de columnas**
        worksheet.columns = [
            { header: "# Registro", key: "numRegistro", width: 15 },
            { header: "# Sesión", key: "numSesion", width: 15 },
            { header: "Modalidad Sesión", key: "modalidad", width: 20 },
            { header: "Fecha", key: "fechaSesion", width: 18 },
            { header: "Periodo", key: "periodo", width: 25 },
            { header: "Modulo", key: "modulo", width: 25 },  // Nueva columna agregada
            { header: "Subregión", key: "subregion", width: 25 },
            { header: "Municipio", key: "municipio", width: 25 },
            { header: "Asistentes", key: "asistentes", width: 10 },
            { header: "Mujeres", key: "mujeres", width: 10 },
            { header: "% Mujeres", key: "porcentajeMujeres", width: 12 },
            { header: "Hombres", key: "hombres", width: 10 },
            { header: "% Hombres", key: "porcentajeHombres", width: 12 },
            { header: "Pertenencia Étnica", key: "pertenenciaEtnica", width: 16 },
            { header: "% Pertenencia Étnica", key: "porcentajeEtnico", width: 17 },
        ];

        // **Título principal**
        worksheet.mergeCells('A1:O1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = `DETALLE DE COHORTE - ${jsonData.cohorte}`;
        titleCell.font = { size: 16, bold: true, color: { argb: 'FFFFFFFF' } };
        titleCell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        titleCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '042956' } };

        // **Información general del cohorte**
        console.log("jsonData:", jsonData);

        const generalHeaders = [
            ["Cohorte", jsonData.cohorte],
            ["Resumen", jsonData.resumen],
            ["Fecha de Inicio", jsonData.fechaInicio],
            ["Fecha de Finalización", jsonData.fechaFinal],
           // ["Periodo", periodo], // Agregamos el periodo calculado
           

        ];

        let startRow = 3;
        generalHeaders.forEach(([label, value], index) => {
            const currentRow = startRow + index;

            const labelCell = worksheet.getCell(`A${currentRow}`);
            labelCell.value = label;
            labelCell.font = { bold: true };
            labelCell.alignment = { vertical: "middle", horizontal: "left" };

            worksheet.mergeCells(`B${currentRow}:O${currentRow}`);
            const valueCell = worksheet.getCell(`B${currentRow}`);
            valueCell.value = Array.isArray(value) ? value.join(", ") : value;
            valueCell.alignment = { vertical: "middle", horizontal: "left" };

            if (label === "Resumen") {
                worksheet.getRow(currentRow).height = Math.min(40, 10 + (value.length / 10));
                valueCell.alignment.wrapText = true;
            }
        });

        // **Espacio antes de la tabla de sesiones**
        let currentRow = startRow + generalHeaders.length + 2;

        // **Encabezado "Sesiones"**
        worksheet.mergeCells(`A${currentRow}:O${currentRow}`);
        const titleCellSesiones = worksheet.getCell(`A${currentRow}`);
        titleCellSesiones.value = "Sesiones";
        titleCellSesiones.font = { bold: true, size: 14, color: { argb: 'FFFFFFFF' } };
        titleCellSesiones.alignment = { vertical: "middle", horizontal: "center" };
        titleCellSesiones.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF50ADF3' } };

        // **Encabezado de la tabla**
        currentRow++;
        const headerRow = worksheet.addRow(worksheet.columns.map(col => col.header));
        headerRow.font = { bold: true };
        headerRow.eachCell(cell => {
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFD0D8E0' } };
        });

        // **Unificar datos de ambas tablas**
        const sesiones = jsonData.detalleSesiones.map(sesion => ({
            numRegistro: Number(sesion.numRegistro) || 0,  
            numSesion: Number(sesion.numSesion) || 0, 
            fechaSesion: sesion.fechaSesion,
            modalidad: sesion.modalidadSesion || "Presencial",  
            periodo: sesion.periodo,
            modulo: sesion.modulo || "No definido",  // Nueva propiedad agregada
            asistentes: Number(sesion.asistentes) || 0,
            mujeres: Number(sesion.mujeres) || 0,
            porcentajeMujeres: Number(sesion.porcentajeMujeres) || 0,
            hombres: Number(sesion.hombres) || 0,
            porcentajeHombres: Number(sesion.porcentajeHombres) || 0,
            subregion: sesion.subregion,
            municipio: sesion.municipio,
            pertenenciaEtnica: Number(sesion.pertenenciaEtnica) || 0,
            porcentajeEtnico: Number(sesion.porcentajeEtnico) || 0,
        }));
        

        // **Determinar el rango de filas para fusionar la columna "Periodo"**
        let firstPeriodoRow = currentRow + 1;
        let lastPeriodoRow = firstPeriodoRow + sesiones.length - 1;
       // const periodoCell = `E${firstPeriodoRow}:E${lastPeriodoRow}`;

        // **Agregar todas las sesiones a la tabla**
        // **Agregar todas las sesiones a la tabla con formato**
        sesiones.forEach((sesion) => {
            const row = worksheet.addRow([
                Number(sesion.numRegistro),
                Number(sesion.numSesion),
                sesion.modalidad, // Modalidad
                sesion.fechaSesion,
                Array.isArray(sesion.periodo) ? sesion.periodo.join(", ") : sesion.periodo, // Agregar la columna "Periodo"
                sesion.modulo,
                sesion.subregion.nombre,
                sesion.municipio.nombre,
                Number(sesion.asistentes),
                Number(sesion.mujeres),
                Number(sesion.porcentajeMujeres) / 100, // Convertir a porcentaje
                Number(sesion.hombres),
                Number(sesion.porcentajeHombres) / 100, // Convertir a porcentaje
                Number(sesion.pertenenciaEtnica),
                Number(sesion.porcentajeEtnico) / 100, // Convertir a porcentaje
            ]);

            // ✅ Aplicar formato numérico
            row.getCell(1).numFmt = '0'; // numRegistro
            row.getCell(2).numFmt = '0'; // numSesion
            row.getCell(8).numFmt = '0'; // asistentes
            row.getCell(9).numFmt = '0'; // mujeres
            row.getCell(11).numFmt = '0'; // hombres
            row.getCell(13).numFmt = '0'; // pertenenciaEtnica

            // ✅ Aplicar formato de porcentaje
            row.getCell(10).numFmt = '0.00%'; // porcentajeMujeres
            row.getCell(12).numFmt = '0.00%'; // porcentajeHombres
            row.getCell(14).numFmt = '0.00%'; // porcentajeEtnico
        });




        // **Fusionar la columna "Periodo"**
       // worksheet.mergeCells(periodoCell);
        worksheet.getCell(`E${firstPeriodoRow}`).alignment = { vertical: 'middle', horizontal: 'center' };

        // **Descargar el archivo Excel**
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        });
    };

    return <Button type="default" icon={<DownloadOutlined />} onClick={handleExport} style={{ color: "green", marginLeft: "10px" }} />;
};

export default ExcelDetalleCohorte;
