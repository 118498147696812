import axios from 'axios';
import config from '../../../config'; // Configuración de la URL base

const baseUrl = config.baseUrl;
const endpoint = '/api/enfoque_temas';
const endpoint2 = '/api/enfoque_temasEtnias';
const endpoint3 = '/api/enfoque_victima';
const EnfoqueGenero = {
  // Obtener todos los enfoques
  getAll: async () => {
    try {
      const response = await axios.get(`${baseUrl}${endpoint}`);
      return response.data;
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      throw error;
    }
  },

  // Obtener un enfoque por ID
  getById: async (id) => {
    try {
      const response = await axios.get(`${baseUrl}${endpoint}/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error al obtener el enfoque con ID ${id}:`, error);
      throw error;
    }
  },

  // Crear un nuevo enfoque
  create: async (data) => {
    try {
      const response = await axios.post(`${baseUrl}${endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error('Error al crear el enfoque:', error);
      throw error;
    }
  },

  // Actualizar un enfoque
  update: async (id, data) => {
    try {
      const response = await axios.put(`${baseUrl}${endpoint}/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error al actualizar el enfoque con ID ${id}:`, error);
      throw error;
    }
  },

  // Eliminar un enfoque
  delete: async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}${endpoint}/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error al eliminar el enfoque con ID ${id}:`, error);
      throw error;
    }
  },


    updateEtnia: async (id, data) => {
    try {
      const response = await axios.put(`${baseUrl}${endpoint2}/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error al actualizar el enfoque con ID ${id}:`, error);
      throw error; 
    }
  },

  updateEtniapoblacion_victima: async (id, data) => {
    try {
      const response = await axios.put(`${baseUrl}${endpoint3}/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(`Error al actualizar el enfoque con ID ${id}:`, error.response?.data || error);
      throw error;
    }
},

};

export default EnfoqueGenero;
