import React from "react";
import { Button } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

const ExportarExcelPoa = ({ dataSource, todosLosDatos }) => {
    const exportToExcel = async () => {

        if (!dataSource || dataSource.length === 0) {
            console.warn("No hay datos para exportar.");
            return;
        }

        const formatearFecha = (fecha) => {
            if (!fecha) return ""; // Manejo si la fecha es null o undefined
            const [ano, mes, dia] = fecha.split("-");
            return `${dia}-${mes}-${ano}`;
        };


        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Porcentajes de Avance POAs");

        // Definir encabezados
        const headers = [
            "Nombre resumido del proyecto", "Fecha Inicio POA", "Fecha Terminación POA",
            "Fecha de actualización de Avances", "Mes de Corte",
            "Avance Programado (P)", "Avance Ejecutado (E)"
        ];

        // Agregar encabezados a la hoja de cálculo
        worksheet.addRow(headers);

        // Aplicar estilos a las cabeceras
        worksheet.getRow(1).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: "FFFFFF" } };
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "0070C0" } };
            cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
            cell.border = {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } }
            };
        });

        // Agregar datos a la hoja de cálculo
        dataSource.forEach((row) => {
            const newRow = worksheet.addRow([

                `${row.codigo} - ${row.nombreProyecto}`,
                formatearFecha(row.fecha_inicio),  // Convertir formato
                formatearFecha(row.fecha_terminacion),
                formatearFecha(row.fecha_actualizacion),
                `${row.mes}-${parseInt(row.ano, 10)}`,  // Unificar Mes-Año
                0, // Placeholder para avanceProgramado
                0  // Placeholder para avanceEjecutado
            ]);

            // Alinear la columna "Proyecto" a la izquierda
            newRow.getCell(1).alignment = { horizontal: "left", vertical: "middle" };


            // Aplicar formato de porcentaje a las columnas P y E con redondeo
            newRow.getCell(6).value = Math.round(parseFloat(row.avanceProgramado)) / 100;
            newRow.getCell(6).numFmt = "0%"; // Sin decimales

            newRow.getCell(7).value = Math.round(parseFloat(row.avanceEjecutado)) / 100;
            newRow.getCell(7).numFmt = "0%"; // Sin decimales

        });

        // Aplicar bordes a todas las celdas
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) return; // Saltamos la cabecera

            row.eachCell((cell) => {
                cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                cell.border = {
                    top: { style: "thin", color: { argb: "000000" } },
                    left: { style: "thin", color: { argb: "000000" } },
                    bottom: { style: "thin", color: { argb: "000000" } },
                    right: { style: "thin", color: { argb: "000000" } }
                };
            });
        });

        // Ajustar automáticamente el ancho de las columnas
        worksheet.columns = headers.map(header => ({ width: header.length + 5 }));

        const columnWidths = [40, 15, 25, 30, 12, 20, 20];

        worksheet.columns = columnWidths.map((width) => ({ width }));

        // Aplicar bordes y estilos a todas las filas después de insertarlas
        worksheet.eachRow((row, rowNumber) => {
            if (rowNumber === 1) return; // Omitimos la cabecera

            row.eachCell((cell, colNumber) => {
                // Aplicar alineación solo si NO es la columna 2 (Proyecto)
                if (colNumber !== 1) {
                    cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                }

                if (colNumber == 1) {
                    cell.alignment = { horizontal: "left", vertical: "middle", wrapText: true };
                }
            });
        });


        /** ---- Hoja 2: detallePoas ---- */
        /** ---- Hoja 2: detallePoas ---- */
        if (todosLosDatos && todosLosDatos.length > 0) {
            const worksheet2 = workbook.addWorksheet("% de Avance POAs (Detalle)");
            const headersDetalle = [

                "Nombre resumido del proyecto",
                "Fecha Inicio POA",
                "Fecha Terminación POA",
                "Fecha de actualización de Avances",
                "Mes de Corte",
                "Año",
                "Avance Programado (P)",
                "Avance Ejecutado (E)",
            ];

            worksheet2.addRow(headersDetalle);
            // Estilos de encabezado
            worksheet2.getRow(1).eachCell((cell) => {
                cell.font = { bold: true, color: { argb: "FFFFFF" } };
                cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "0070C0" } };
                cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                cell.border = { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };
            });

            // Procesar cada proyecto
            todosLosDatos.forEach((proyecto) => {
                const datosPorMes = {};

                // Agregar datos de P
                proyecto.P.forEach((dato) => {
                    const key = `${dato.ano}-${dato.mes}`;
                    if (!datosPorMes[key]) {
                        datosPorMes[key] = {

                            codigo: proyecto.codigo,
                            nombreProyecto: proyecto.nombreProyecto,
                            fechaInicio: proyecto.fecha_inicio || "",
                            fechaTerminacion: proyecto.fecha_terminacion || "",
                            fechaActualizacion: proyecto.fecha_actualizacion || "",
                            mes: dato.mes,
                            ano: dato.ano,
                            avanceP: parseFloat(dato.Total_Avance) || 0,
                            avanceE: 0, // Valor inicial de E en caso de no existir
                        };

                    } else {
                        datosPorMes[key].avanceP = parseFloat(dato.Total_Avance) || 0;
                    }
                });

                // Agregar datos de E
                proyecto.E.forEach((dato) => {
                    const key = `${dato.ano}-${dato.mes}`;
                    if (!datosPorMes[key]) {
                        datosPorMes[key] = {

                            codigo: proyecto.codigo,
                            nombreProyecto: proyecto.nombreProyecto,
                            fechaInicio: proyecto.fecha_inicio || "",
                            fechaTerminacion: proyecto.fecha_terminacion || "",
                            fechaActualizacion: proyecto.fecha_actualizacion || "",
                            mes: dato.mes,
                            ano: dato.ano,
                            avanceP: 0, // Valor inicial de P en caso de no existir
                            avanceE: parseFloat(dato.Total_Avance) || 0,
                        };
                    } else {
                        datosPorMes[key].avanceE = parseFloat(dato.Total_Avance) || 0;
                    }
                });

                // Insertar filas en la segunda hoja de Excel
                Object.values(datosPorMes).forEach((fila) => {
                    const row = worksheet2.addRow([

                        `${fila.codigo} - ${fila.nombreProyecto}`,
                        formatearFecha(fila.fechaInicio),     // Convertir formato
                        formatearFecha(fila.fechaTerminacion),
                        formatearFecha(fila.fechaActualizacion),
                        fila.mes,
                        parseInt(fila.ano, 10),
                        fila.avanceP / 100, // Convertir a porcentaje
                        fila.avanceE / 100, // Convertir a porcentaje
                    ]);

                    // Aplicar formato de porcentaje a las columnas Avance P (5) y Avance E (6)
                    row.getCell(7).numFmt = "0%"; // Avance P sin decimales
                    row.getCell(8).numFmt = "0%"; // Avance E sin decimales

                });


            });




            // Aplicar bordes a todas las celdas
            worksheet2.eachRow((row, rowNumber) => {
                if (rowNumber === 1) return; // Saltamos la cabecera

                row.eachCell((cell) => {
                    cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                    cell.border = {
                        top: { style: "thin", color: { argb: "000000" } },
                        left: { style: "thin", color: { argb: "000000" } },
                        bottom: { style: "thin", color: { argb: "000000" } },
                        right: { style: "thin", color: { argb: "000000" } }
                    };
                });
            });


            // Ajustar automáticamente el ancho de las columnas


            worksheet2.columns = headers.map(header => ({ width: header.length + 5 }));


            const columnWidths = [40, 15, 25, 30, 12, 10, 20, 20];
            worksheet2.columns = columnWidths.map((width) => ({ width }));

            // Aplicar bordes y estilos a todas las filas después de insertarlas
            worksheet2.eachRow((row, rowNumber) => {
                if (rowNumber === 1) return; // Omitimos la cabecera

                row.eachCell((cell, colNumber) => {

                    row.height = 11; // Ajusta el valor según sea necesario

                    // Aplicar alineación solo si NO es la columna 2 (Proyecto)
                    if (colNumber !== 1) {
                        cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
                    }

                    if (colNumber == 1) {
                        cell.alignment = { horizontal: "left", vertical: "middle", wrapText: true };
                    }


                });
            });
        }

        // Guardar archivo Excel
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "Porcentajes_de_Avance_POAs.xlsx");
    };

    return (
        <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={exportToExcel}
            style={{ backgroundColor: "#28a745", borderColor: "#218838", color: "white" }}

            onMouseLeave={(e) => (e.target.style.backgroundColor = "#28a745")}
        >
            Exportar
        </Button>
    );
};

export default ExportarExcelPoa;
