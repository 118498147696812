import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint1 = '/api/headerDetallePO';
const endpoint2 = '/api/poaPO';
const endpoint3 = '/api/update-avancePO';
const endpoint4 = '/api/actualizar-fechaPO';
const endpoint5 = '/api/actualizar-pesotareaPO';
const endpoint6 = '/api/poaPOPeso';
const endpoint7 = '/api/poaPOExp';

const endpoint8 = '/api/poaPOPesoResumen';

const getHeader = async (oid_proyecto) => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint1}?oid_proyecto=${oid_proyecto}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    if (error.response?.status === 404) {
      console.error('El proyecto no existe o no se encontró información.');
    } else {
      console.error('Error al obtener los proyectos:', error);
    }
    throw error;
  }
};


const getPOA = async (fase_id, oid_proyecto) => {


  try {
    const response = await axios.get(`${baseUrl}${endpoint2}?fase_id=${fase_id}&oid_proyecto=${oid_proyecto}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener las actividades:', error);
    throw error;
  }
};


const getPOPeso = async (fase_id, oid_proyecto, modo) => {
  try {
    const response = await axios.post(`${baseUrl}${endpoint6}`, {
      fase_id,
      oid_proyecto,
      modo
    });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener las actividades:', error);
    throw error;
  }
};


const getPOPesoResumen = async () => {
  try {
    const response = await axios.post(`${baseUrl}${endpoint8}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener las resumen:', error);
    throw error;
  }
};

const getPOAExport = async (oid_proyecto) => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint7}?oid_proyecto=${oid_proyecto}`);



    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener las actividades:', error);
    throw error;
  }
};


const updateAvance = async (id, avance) => {
  try {
    const response = await axios.put(`${baseUrl}${endpoint3}`, { id, avance });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al actualizar el avance:', error);
    throw error;
  }
};

//Actualizar peso porcentual
const updatePeso = async (id, avance) => {
  try {
    const response = await axios.put(`${baseUrl}${endpoint5}`, { id, avance });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al actualizar el avance:', error);
    throw error;
  }
};

const updateFecha = async (oid, fecha, tipo) => {
  try {
    const response = await axios.put(`${baseUrl}${endpoint4}`, { oid, fecha, tipo });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al actualizar la fecha:', error);
    throw error;
  }
};

export { getHeader, getPOA, updateAvance, updateFecha, updatePeso, getPOPeso, getPOAExport, getPOPesoResumen };
