import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { login as loginService } from '../services/auth_service/auth_service';
import { logout as logoutService } from '../services/auth_service/logout_service';
import { jwtDecode } from 'jwt-decode';
import { setToken, setUsername, removeUsername, removeToken, removeApplications } from '../store/auth/cookieUtils';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [applications, setApplications] = useState([]);
  const [, setError] = useState(null);

  useEffect(() => {
    const token = Cookies.get('token');
    const username = Cookies.get('username');
    const appsCookie = Cookies.get('applications');
    let apps;

    try {
      // Asegúrate de que appsCookie no sea undefined antes de intentar analizarlo
      apps = appsCookie ? JSON.parse(appsCookie) : JSON.parse(localStorage.getItem('applications'));
    } catch (error) {
      console.error('Error parsing applications from cookie or localStorage:', error);
      apps = []; // Inicializa como un array vacío si hay un error
    }

    if (token && username && apps) {
      try {
        const decoded = jwtDecode(token);
        const isTokenValid = decoded && decoded.exp * 1000 > Date.now();

        if (isTokenValid) {
          setIsAuthenticated(true);
          setUser(username);
          setApplications(apps || []); // Cargar aplicaciones desde la cookie si están disponibles
        } else {
          logout(); // Invalidar sesión si el token no es válido
        }
      } catch (error) {
        console.error('Error decoding token:', error);
        logout();
      }
    }
  }, []);


  const login = async (username, password) => {
    try {
      const data = await loginService(username, password);

      setToken(data.token);
      setUsername(username);
      setApplications(data.applications);

      // Establecer el estado de autenticación
      setIsAuthenticated(true);
      setUser(username);
      return data;
    } catch (error) {
      console.error('Error logging in:', error);
      setIsAuthenticated(false);
      setApplications([]);
      setError(error);
      throw error; // Propaga el error
    }
  };


  const logout = async () => {
    try {
      await logoutService();
      Cookies.remove('token');
      removeUsername();
      removeToken();
      removeApplications();
      setIsAuthenticated(false);
      setUser(null);
      setApplications([]);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  return (
    <AuthContext.Provider value={{ isAuthenticated, user, applications, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;




