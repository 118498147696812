import React, { useEffect, useState } from 'react';
import { getListaDocumentos, updateDocumento, createDocumento, deleteDocumento } from '../../../../services/inicio/repositorio/repositorioDescargarVerService';
import { Modal, Table, Form, Button, Input, Popconfirm, Tooltip, message, Divider } from 'antd';
import { FilePdfOutlined, EditOutlined, PlusOutlined, SaveOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import "./ListaRepositorio.css";


const ListaRepositorio = () => {
    const [dataLista, setDataLista] = useState([]); // ✅
    const [count, setCount] = useState(0);
    const [editingKey, setEditingKey] = useState(null);
    const [editingRecord, setEditingRecord] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const [isNewRecord, setIsNewRecord] = useState(false);

    // 🚀 Traer datos desde el servicio y filtrarlos
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getListaDocumentos(); // res es { status, data }
                console.log("Data Obtenida: ", res)
                setDataLista(res.data); // ✅ usa solo el array
                setCount(res.data.length);
            } catch (err) {
                console.error("Error al obtener documentos:", err);
            }
        };

        fetchData();
    }, []);


    const isEditing = (record) => record.oid === editingKey;

    const edit = (record) => {
        setEditingRecord({ ...record }); // clona para edición aislada
        form.setFieldsValue({
            nombre: record.nombre,
            link: record.link,
        });
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setEditingRecord(null);
        setIsNewRecord(false);
        form.resetFields();
    };



    const cancelEdit = () => {
        setEditingKey(null);

    };

    // 🔧 Handlers
    const handleAdd = () => {
        setIsNewRecord(true);
        setEditingRecord({ nombre: '', link: '', origen: 1 });
        form.setFieldsValue({ nombre: '', link: '' });
        setIsModalOpen(true);
    };


    const handleDelete = async (oid) => {
        try {
            await deleteDocumento(oid);
            setDataLista(dataLista.filter(item => item.oid !== oid));
            message.success("Documento eliminado correctamente");
        } catch (error) {
            message.error("Error al eliminar el documento");
        }
    };




    const handleInputChange = (e, oid, field) => {
        const newData = [...dataLista];
        const index = newData.findIndex(item => item.oid === oid);
        if (index > -1) {
            newData[index] = {
                ...newData[index],
                [field]: e.target.value,
            };
            setDataLista(newData);
        }
    };


    const handleSave = async (record) => {
        try {
            // Validación rápida
            if (!record.nombre || !record.link) {
                message.warning('Debe completar el nombre y el link');
                return;
            }

            // Enviar al backend
            const response = await updateDocumento({
                oid: record.oid,         // asegúrate de que esté en cada record
                nombre: record.nombre,
                link: record.link,
                origen: record.origen    // por si es necesario
            });

            if (response.success) {
                message.success('Documento actualizado correctamente');
            } else {
                message.error('No se pudo actualizar el documento');
            }

        } catch (error) {
            message.error('Error al guardar el documento');
        }
    };






    const columns = [
        {
            title: 'Nombre del documento',
            dataIndex: 'nombre',
            key: 'nombre',
            align: 'left',
            render: (text, record) => {
                return isEditing(record) ? (

                    <Input.TextArea
                        value={record.nombre}
                        onChange={(e) => handleInputChange(e, record.oid, 'nombre')}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        style={{ backgroundColor: "#fffbe6", border: "1px solid #d9d9d9" }}
                    />
                ) : (

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            gap: '8px',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            maxHeight: '96px',
                            overflow: 'hidden',
                        }}
                    >
                        <FilePdfOutlined style={{ color: 'red', fontSize: 20, marginTop: 4 }} />
                        <span style={{ fontSize: '14px', lineHeight: '20px' }}>

                            <a
                                href={record.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: '#000',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    display: 'inline-block',
                                    maxWidth: '100%',
                                    wordBreak: 'break-all',
                                    whiteSpace: 'normal',
                                }}
                            >
                                {text}
                            </a>
                        </span>


                    </div>



                );
            },
        },

        {
            title: 'Acción',
            key: 'accion',
            width: 70,
            render: (_, record) => {
                const editable = isEditing(record);

                return editable ? (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                        <Tooltip title="Guardar cambios">
                            <Button
                                type="primary"
                                icon={<SaveOutlined />}
                                onClick={() => {
                                    handleSave(record);
                                    cancelEdit();
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="Cancelar edición">
                            <Button onClick={cancelEdit}>Cancelar</Button>
                        </Tooltip>
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', gap: '8px' }}>
                        <Tooltip title="Editar">
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                        </Tooltip>

                        <Tooltip title="Ver documento">
                            <Button
                                type="default"
                                icon={<EyeOutlined />}
                                onClick={() => window.open(record.link, '_blank')}
                            />
                        </Tooltip>


                        <Tooltip title="Eliminar documento">

                            <Popconfirm
                                title="¿Eliminar este registro?"
                                onConfirm={() => handleDelete(record.oid)}
                                okText="Aceptar"
                                cancelText="Cancelar"
                            >
                                <Button icon={<DeleteOutlined />} danger />
                            </Popconfirm>

                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    return (
        <div style={{ width: '100%' }}>


            <Divider style={{ borderColor: '#1a1a1a', borderWidth: 2 }} orientation="left">
                <strong>Documentos</strong>
            </Divider>

            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 16, marginRight: 10 }}>
                <Tooltip title="Agregar documento">
                    <Button
                        icon={<PlusOutlined />}
                        onClick={handleAdd}
                        style={{
                            background: "#1677ff",
                            color: "#FFF",
                            transition: "transform 0.2s ease-in-out",
                        }}
                        onMouseEnter={(e) => {
                            e.currentTarget.style.transform = "scale(1.1)";
                        }}
                        onMouseLeave={(e) => {
                            e.currentTarget.style.transform = "scale(1)";
                        }}
                    >
                        Crear
                    </Button>
                </Tooltip>
            </div>



            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Table
                    dataSource={[...dataLista].reverse()}
                    columns={columns}
                    pagination={false}
                    className="tabla-custom"
                    rowKey="oid"
                    style={{ width: '100%' }}
                />
            </div>



            <Modal
                title={isNewRecord ? 'Agregar nuevo documento' : 'Editar documento'}
                open={isModalOpen}
                onOk={() => form.submit()}
                onCancel={handleModalClose}
                okText="Guardar"
                cancelText="Cancelar"
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={async (values) => {
                        const newOrUpdatedDoc = {
                            ...editingRecord,
                            ...values,
                        };

                        try {
                            if (isNewRecord) {
                                // Llama al servicio que crea el documento en la BD
                                const res = await createDocumento({
                                    nombre: newOrUpdatedDoc.nombre,
                                    link: newOrUpdatedDoc.link,
                                    origen: newOrUpdatedDoc.origen,
                                });

                                // Agrega el nuevo registro a la tabla usando el ID generado por la BD
                                const newItem = {
                                    ...newOrUpdatedDoc,
                                    oid: res.insertId,
                                };

                                setDataLista([...dataLista, newItem]);
                                message.success('Documento agregado correctamente');
                            } else {
                                // Actualizar existente
                                await updateDocumento({
                                    oid: newOrUpdatedDoc.oid,
                                    nombre: newOrUpdatedDoc.nombre,
                                    link: newOrUpdatedDoc.link,
                                    origen: newOrUpdatedDoc.origen,
                                });

                                const newData = dataLista.map(item =>
                                    item.oid === newOrUpdatedDoc.oid ? newOrUpdatedDoc : item
                                );
                                setDataLista(newData);
                                message.success('Documento actualizado correctamente');
                            }

                            handleModalClose();
                        } catch (err) {
                            console.error(err);
                            message.error('Error al guardar el documento');
                        }
                    }}


                >
                    <Form.Item
                        label="Nombre del documento"
                        name="nombre"
                        rules={[{ required: true, message: 'Este campo es obligatorio' }]}
                    >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                    </Form.Item>

                    <Form.Item
                        label="Link del documento"
                        name="link"
                        rules={[
                            { required: true, message: 'Este campo es obligatorio' },
                            { type: 'url', message: 'Debe ingresar un enlace válido (ej. https://...)' },
                        ]}
                    >
                        <Input placeholder="https://bodecom.com/..." />
                    </Form.Item>
                </Form>
            </Modal>


        </div>
    );
};

export default ListaRepositorio;
