import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importar el plugin

// Registrar elementos de Chart.js
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels); // Registrar el plugin

const GraficaTorta = ({ titulo, datos, colores }) => {
    // Configuración del gráfico
    const total = datos.reduce((acc, item) => acc + item.valor, 0); // Calcular el total

    const data = {
        labels: datos.map((item) => item.name),
        datasets: [
            {
                data: datos.map((item) => item.valor),
                backgroundColor: colores, // Colores dinámicos
                hoverOffset: 4,
            },
        ],
    };

    // Opciones de configuración
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom", // Posición de la leyenda
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        let porcentaje = ((tooltipItem.raw / total) * 100).toFixed(1);
                        return `${tooltipItem.raw} (${porcentaje}%)`;
                    },
                },
            },
            datalabels: {
                color: "#fff", // Color del texto
                anchor: "center", // Centrar el porcentaje
                align: "center",
                textStrokeColor: "rgba(0, 0, 0, 0.6)", // ✅ Agregar un borde oscuro para mejorar visibilidad
                textStrokeWidth: 2, // ✅ Grosor del borde
                font: {
                    weight: "bold",
                    size: 14,
                },
                formatter: (value) => `${((value / total) * 100).toFixed(1)}%`, // Formatear a porcentaje
            },
        },
    };

    return (
        <div style={{ textAlign: "center" }}>
            <h3 style={{ fontWeight: "bold" }}>{titulo}</h3>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%", 
                    height: "400px",
                }}
            >
                <div style={{ width: "75%", maxWidth: "400px" }}>
                    <Doughnut data={data} options={options} />
                </div>
            </div>
        </div>
    );
    
};

export default GraficaTorta;
