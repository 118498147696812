import React from 'react';
import imgPorDefecto from '../../assets/img_lineas_productivas/pordefecto.png';
import img1 from '../../assets/fortalecimiento/ciclo_vida.png';
import img2 from '../../assets/fortalecimiento/dhha.png';
import img3 from '../../assets/fortalecimiento/organizacion.png';
import img4 from '../../assets/fortalecimiento/participacion.png';
const ImageComponent = ({ projectId, opacity = 0.4 }) => {  // Añadimos un parámetro opacity con un valor por defecto de 0.4
    const imageMapping = {
        1: img1,
        2: img2,
        3: img3,
        4: img4,
    };

    const imageSrc = imageMapping[projectId] || imgPorDefecto;

    return (
        <div
            style={{
                backgroundImage: `url(${imageSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                height: '100%',
                width: '100%',
                opacity,  // Aplica la opacidad a la imagen
            }}
        >
            {/* Puedes agregar un overlay aquí si deseas un efecto extra */}
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Fondo con opacidad
                    zIndex: 1,
                }}
            ></div>
        </div>
    );
};

export default ImageComponent;
