

import React, { useState } from 'react';
import { Button, Row, Col, Tabs, Modal } from 'antd';
import { ArrowLeftOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CursoCicloVidaT from './CursoCicloVidaTable';
import { fetchPoaData } from '../../../../services/poa/get_poa';
import FileManagerPOAN from '../../../../components/poaReporte/FileManagerPOAN';
const CursoCicloVida = () => {
    return <CursoCicloVidaT />;
}

const FortalecimientoCap = () => {
    const [activeTab, setActiveTab] = useState("1");
    const [itemParams, setItemParams] = useState(false);
    const [openModalUsuario, setOpenModalUsuario] = useState(false);
    const navigate = useNavigate();
    const handleTabChange = (key) => {
        setActiveTab(key);
    };
    const items = [
        {
            key: '1',
            label: '',
            children: <CursoCicloVida />,
        }
    ];

    const handleCardClick = () => {
        navigate(`/menu-curso-fortalecimiento`);
    };

    const hideModalUsuario = () => {
        setOpenModalUsuario(false);
    };

    const handleNoInfoClick = () => {
        procesarMpoa("3.2.3")
    };
    const procesarMpoa = async (Mpoa) => {
        if (Mpoa) {
            if (Mpoa === '2.2.1') {
                return null;
            }
            const manejarActividadEspecial = (actividad) => {
                switch (actividad) {
                    case '2.2.1.1':
                        return '2.2.1';
                    case '2.2.1.2':
                        return '2.2.1';
                    case '2.2.1.3':
                        return '2.2.1';
                    default:
                        return actividad;
                }
            };
            const actividadBase = manejarActividadEspecial(Mpoa);
            try {
                const dataCompleta = await fetchPoaData();
                const dataPoa = dataCompleta.dataPoa;
                const findNodePath = (node, path = []) => {
                    path.push(node);
                    if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        return path;
                    }
                    if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        if (node.children && node.children.length > 0) {
                            for (let child of node.children) {
                                if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                                    path.push(child);
                                    return path;
                                }
                                if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                                    path.push(child);
                                    return path;
                                }
                            }
                        }
                    }

                    if (node.children && node.children.length > 0) {
                        for (let child of node.children) {
                            const result = findNodePath(child, [...path]);
                            if (result) return result;
                        }
                    }
                    return null;
                };
                let matchedPath = null;

                for (let nodo of dataPoa) {
                    matchedPath = findNodePath(nodo);
                    if (matchedPath) break;
                }
                if (matchedPath) {
                    const initialData = extractData(matchedPath);
                    const actividadData = initialData[0].actividadData;
                    const tareaEncontrada = actividadData.find((item) => {
                        if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                            return (
                                item.codigo_tarea === actividadBase &&
                                ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                                    (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
                            );
                        }
                        return item.codigo_tarea === actividadBase;
                    });
                    if (tareaEncontrada) {
                        setOpenModalUsuario(true);
                        setItemParams(tareaEncontrada);
                    } else {
                        setOpenModalUsuario(false);
                        setItemParams(null);
                    }
                } else {
                    console.warn("No se encontró ningún nodo coincidente");
                }
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            }
        } else {
            setOpenModalUsuario(false);
        }
    };

    const extractData = (children) => {
        return children.flatMap(child => {
            if (child.children && child.tipo === 'Actividad') {
                const actividadData = child.children.flatMap(grandchild => {
                    if (grandchild.children && grandchild.tipo === 'Tarea') {
                        return grandchild.children.flatMap(greatGrandchild => {
                            if (greatGrandchild.tipo === 'Indicador') {
                                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                                const medioV = greatGrandchild.medioVerificacion || null;
                                return {
                                    key: greatGrandchild.key,
                                    codigo_tarea: grandchild.codigo_tarea,
                                    codActividad: grandchild.codActividad,
                                    nameActividad: child.actividad,
                                    tarea: grandchild.tarea,
                                    indicador: greatGrandchild.title,
                                    medioV: medioV,
                                    fechas: fechaFormateada,
                                    responsables: 'N/A',
                                    soportes: 'N/A',
                                    datoIndicador: greatGrandchild,
                                };
                            }
                            return [];
                        });
                    }
                    return [];
                });
                return [{
                    actividadTitle: child.title,
                    actividad: child.actividad,
                    actividadData: actividadData
                }];
            }
            return [];
        });
    };
    return (
        <div className="container">
            <div className="row mb-2 d-flex align-items-center">
                <div className="col-md-7 linea_separador mb-2 d-flex align-items-center">
                    <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                        <p>PROYECTO UTF <br /> COL 160 COL</p>
                    </div>
                    <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                        <p>
                            Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
                            institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
                        </p>
                    </div>
                </div>
                <div className="col-md-5 d-flex justify-content-center align-items-center flex-column">
                    <h4 className="text-center mb-5">Curso ciclo de vida de proyectos</h4>
                    <Row gutter={5}>
                        <Col>
                            <Button
                                onClick={() => handleCardClick()}
                                style={{ width: 125 }}
                                icon={<ArrowLeftOutlined />}
                            >
                                Regresar
                            </Button>

                            <Button
                                style={{ width: 125 }}
                                icon={<FolderOpenOutlined />}
                                onClick={() => handleNoInfoClick()}
                            >
                                Soportes
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <div>
                <Tabs activeKey={activeTab} onChange={handleTabChange} hideAdd items={items} />
            </div>
            {
                openModalUsuario && itemParams && (
                    <div className="d-flex justify-content-center">
                        <Modal
                            title={<span className="custom-modal-title">Medios de verificación</span>}
                            open={openModalUsuario}
                            onCancel={hideModalUsuario}
                            footer={null}
                            width="95%"
                            style={{ top: 20 }}
                        >
                            <FileManagerPOAN codigo_tarea={itemParams} />
                        </Modal>
                    </div>
                )
            }
        </div>
    );
};

export default FortalecimientoCap;
