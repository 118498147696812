import React, { useEffect, useState } from "react";
import { Card, Row, Col, Badge, Collapse, Statistic, List, Divider, Table, Progress } from 'antd';
import { UsergroupAddOutlined, EnvironmentOutlined, BarChartOutlined, UserOutlined, PieChartOutlined } from '@ant-design/icons';
import styles from './DashboardPlanOperativo.module.css';
import GraficaTorta from './Graficos/GraficaTorta';
import GraficaBarras from './Graficos/GraficaBarras';
import GraficaBarrasHorizontal from './Graficos/GraficaBarrasHorizontal';
import GraficaTorta3D from './Graficos/GraficaTorta3D';

import { dataIndicadores } from "../../services/plaOperativo/planOperativoProyectosServices";


const DashboardPlanOperativo = ({ dataProyecto, codigoProyecto }) => {
    const [dataParticipantes, setDataParticipantes] = useState(false);
    const participantesComponent = dataProyecto.find(item => item.sigla === 'LP');
    const resumenProyectos = participantesComponent?.resumenProyectos || {};
    const detalleParticipantes = participantesComponent?.detalleParticipantes || [];
    const componentes = dataProyecto;
    const [datosDashboard, setDatosDashboard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            if (!dataProyecto || !codigoProyecto) {
                console.warn("No hay un código de proyecto válido.");
                setLoading(false);
                return;
            }
            try {
                setLoading(true);
                const response = await dataIndicadores(codigoProyecto);

                if (response.status === 200) {
                    setDatosDashboard(response.data);
                } else {
                    console.error("Error en la respuesta:", response);
                    setError("No se pudieron obtener los datos.");
                }
            } catch (err) {
                console.error("Error al obtener los indicadores:", err);
                setError("Ocurrió un error al cargar los datos.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [codigoProyecto]);

    useEffect(() => {
        if (
            participantesComponent &&
            resumenProyectos.totalParticipante > 0 &&
            Array.isArray(detalleParticipantes) &&
            detalleParticipantes.length > 0
        ) {
            setDataParticipantes(true);
        } else {
            setDataParticipantes(false);
        }
    }, [participantesComponent, resumenProyectos, detalleParticipantes]);

    if (!datosDashboard) return <p>Cargando datos...</p>;
    const { indicadores, caracterizacionParticipantes, visitasAcompanamiento, ecas, actasEntrega, dataIPPTA, dataEDHA, componenteComercial } = datosDashboard;
    const {
        totalParticipante = 0,
        participantesPorDepartamento = [],
        participantesPorSexo = [],
        conteoPorCategorias = [],
        participantesPorNivelEscolaridad = [],
        participantesPorEstadoCivil = [],
        organizaciones = [],
        cabezaFamiliar = [],
        municipio = [],
        lineaEconomica = [],
        zona = []
    } = resumenProyectos;

    const department = participantesPorDepartamento && participantesPorDepartamento[0];
    const muni = (municipio && municipio.length > 0) ? municipio[0] : null;
    const maxTotal = Math.max(...organizaciones.map(item => item.total));
    const colors = ['#1890ff', '#52c41a', '#faad14'];
    const totalCabeza = cabezaFamiliar.reduce((acc, curr) => acc + curr.cantidad, 0);
    const columnsLineap = [
        {
            title: 'Línea productiva',
            dataIndex: 'linea_economica',
            key: 'lineaEconomica',
            render: text => <span style={{ color: '#1890ff', fontWeight: 'bold' }}>{text}</span>,
        },
        {
            title: 'Total participantes',
            dataIndex: 'total_por_linea_economica',
            key: 'total',
            align: 'center',
            render: total => {
                // Ajusta el umbral y color según tus necesidades
                const color = total > 5 ? '#52c41a' : '#faad14';
                return <span style={{ color, fontWeight: 'bold' }}>{total}</span>;
            },
        },
    ];

    const commonCardStyle = {
        borderRadius: "12px",
        textAlign: "center",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        cursor: "pointer", // O condicional, si fuera necesario
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        paddingTop: "15px"
    };

    const getContentForComponent = (componente) => {
        if (componente.sigla === "LP") {
            return (
                <>
                    {dataParticipantes ? (
                        <>
                            <Row gutter={16} align="stretch">
                                {/* Total Participantes */}
                                <Col xs={24} md={8} style={{ display: 'flex' }}>
                                    <Card
                                        variant="outlined"
                                        hoverable
                                        style={{
                                            ...commonCardStyle,
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: 16,
                                                paddingTop: '10px',
                                            }}
                                        >
                                            <UsergroupAddOutlined style={{ fontSize: 32 }} />
                                            <Progress
                                                type="circle"
                                                percent={totalParticipante} // Se asume que totalParticipante es un valor entre 0 y 100
                                                format={() => <span style={{ fontSize: 16 }}>{totalParticipante}</span>}
                                                size={80}
                                            />
                                        </div>
                                        <div style={{ marginTop: 8, fontSize: 16, fontWeight: 'bold' }}>
                                            Total Participantes
                                        </div>
                                    </Card>
                                </Col>
                                {/* Participantes por Departamento */}
                                <Col xs={24} md={8} style={{ display: 'flex' }}>
                                    <Card
                                        variant="outlined"
                                        hoverable
                                        style={{
                                            ...commonCardStyle,
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {department ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 16,
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                <BarChartOutlined style={{ fontSize: 32, color: '#1890ff' }} />
                                                <div>
                                                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                                                        {department.departamento}
                                                    </div>
                                                    <div style={{ fontSize: 14 }}>
                                                        Total: {department.total_por_departamento}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>No hay datos de departamento</div>
                                        )}
                                    </Card>
                                </Col>
                                {/* Municipio */}
                                <Col xs={24} md={8} style={{ display: 'flex' }}>
                                    <Card
                                        variant="outlined"
                                        hoverable
                                        style={{
                                            ...commonCardStyle,
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {muni ? (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 16,
                                                    paddingTop: '10px',
                                                }}
                                            >
                                                <EnvironmentOutlined style={{ fontSize: 32, color: '#52c41a' }} />
                                                <div>
                                                    <div style={{ fontSize: 16, fontWeight: 'bold' }}>
                                                        {muni.municipio}
                                                    </div>
                                                    <div style={{ fontSize: 14 }}>
                                                        Total: {muni.total_por_municipio}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>No hay datos de municipio</div>
                                        )}
                                    </Card>
                                </Col>
                            </Row>
                            {/* Sección: Participantes por Sexo */}
                            <Divider orientation="left">Participantes por Sexo</Divider>
                            <Card
                                variant="outlined"
                                style={{
                                    marginBottom: 16,
                                    padding: 20
                                }}
                            >
                                <Row gutter={16} align="stretch">
                                    {participantesPorSexo.map((item, index) => {
                                        // Define colores para cada sexo:
                                        const strokeColor = item.sexo === 'Hombre' ? '#1890ff' : '#da7b2b';
                                        const iconColor = strokeColor;
                                        return (
                                            <Col key={index} xs={24} md={12} style={{ display: 'flex' }}>
                                                <Card
                                                    hoverable
                                                    style={{
                                                        flex: 1,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        textAlign: 'center',
                                                        paddingTop: '20px',
                                                        border: 0,
                                                    }}
                                                >
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
                                                        <UserOutlined style={{ fontSize: 32, color: iconColor }} />
                                                        <Progress
                                                            type="circle"
                                                            percent={item.total_por_sexo}
                                                            format={() => <span style={{ fontSize: 16 }}>{item.total_por_sexo}</span>}
                                                            size={80}
                                                            strokeColor={strokeColor}
                                                        />
                                                    </div>
                                                    <div style={{ marginTop: 8, fontSize: 16 }}>
                                                        Sexo: {item.sexo}
                                                    </div>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Card>
                            {/* Sección: Conteo por Categorías */}
                            <Divider orientation="left">Participantes por Categorías</Divider>
                            {conteoPorCategorias.map((cat, index) => {
                                // Creamos un arreglo con las métricas a mostrar
                                const metrics = [
                                    { label: 'Campesino', value: cat.total_campesino, color: '#1890ff' },
                                    { label: 'Com. NARP', value: cat.total_comunidad_narp, color: '#fa541c' },
                                    { label: 'Víctimas', value: cat.total_victimas, color: '#f5222d' },
                                    { label: 'Mujer Rural', value: cat.total_mujer_rural, color: '#fa8c16' },
                                    { label: 'Joven Rural', value: cat.total_joven_rural, color: '#722ed1' },
                                    { label: 'Adulto Mayor', value: cat.total_adulto_mayor, color: '#13c2c2' },
                                ];
                                return (
                                    <Row key={index} gutter={24} style={{ marginBottom: 20, paddingTop: 10, paddingBottom: 10, paddingLeft: 40, paddingRight: 40 }}>
                                        {metrics.map((metric, idx) => (
                                            <Col key={idx} span={8}>
                                                <Badge.Ribbon text={metric.label} color={metric.color}>
                                                    <Card hoverable variant="outlined" style={{ ...commonCardStyle, textAlign: 'center', height: '100%', width: '100%', marginBottom: 10 }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 8, height: 70 }}>
                                                            <UserOutlined style={{ fontSize: 24, color: metric.color }} />
                                                            <span style={{ fontSize: 20, fontWeight: 'bold' }}>{metric.value}</span>
                                                        </div>
                                                    </Card>
                                                </Badge.Ribbon>
                                            </Col>
                                        ))}
                                    </Row>
                                );
                            })}
                            {/* Sección: Participantes por Nivel Escolaridad */}
                            <Divider orientation="left">Participantes por Nivel Escolaridad</Divider>
                            <Card hoverable variant="outlined" style={{ ...commonCardStyle, marginBottom: 16, padding: 15 }}>
                                <Row justify="space-around" gutter={[16, 16]}>
                                    {participantesPorNivelEscolaridad.map((item, index) => {
                                        // Calcula el porcentaje, asumiendo que totalParticipante es el total general.
                                        const porcentaje = totalParticipante
                                            ? ((item.total_por_nivel / totalParticipante) * 100).toFixed(1)
                                            : 0;
                                        return (
                                            <Col key={index} xs={24} sm={12} md={8} lg={6}>
                                                <Card
                                                    size="small"
                                                    title={item.nivel_escolaridad}
                                                    style={{ textAlign: 'center', height: '100%' }}
                                                    styles={{
                                                        header: {
                                                            textAlign: 'center',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#f0f2f5' // Personaliza este color
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                        }}
                                                    >
                                                        <PieChartOutlined style={{ fontSize: 24, color: '#52c41a' }} />
                                                        <Statistic
                                                            value={item.total_por_nivel}
                                                            valueStyle={{ fontSize: 20 }}
                                                            prefix={<span>Participantes: </span>}
                                                        />
                                                        <Statistic
                                                            value={porcentaje}
                                                            suffix="%"
                                                            valueStyle={{ fontSize: 20, color: '#1890ff' }}
                                                            prefix={<span></span>}
                                                        />
                                                    </div>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Card>
                            {/* Sección: Participantes por Estado Civil */}
                            <Divider orientation="left">Participantes por Estado Civil</Divider>
                            <Card variant="outlined" style={{ marginBottom: 16, padding: 15 }}>
                                <Row justify="space-around" gutter={[16, 16]}>
                                    {participantesPorEstadoCivil.map((item, index) => (
                                        <Col key={index} xs={24} sm={12} md={8} lg={6}>
                                            <Card
                                                size="small"
                                                title={item.estado_civil}
                                                style={{ textAlign: 'center', height: '100%' }}
                                                styles={{
                                                    header: {
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                        backgroundColor: '#f0f2f5' // Puedes ajustar este color
                                                    }
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 8
                                                    }}
                                                >
                                                    <UserOutlined style={{ fontSize: 24, color: '#faad14' }} />
                                                    <Statistic
                                                        value={item.total_por_estado}
                                                        valueStyle={{ fontSize: 20 }}
                                                        prefix={<span>N°: </span>}
                                                    />
                                                    <div style={{ fontSize: 16, color: '#595959' }}>
                                                        {`(${((item.total_por_estado / totalParticipante) * 100).toFixed(1)}%)`}
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                            {/* Sección: Organizaciones */}
                            <Divider orientation="left">Pertenecen a Organizaciones</Divider>
                            <Card variant="outlined" style={{ marginBottom: 16 }}>
                                <Row justify="space-around" align="end" gutter={16}>
                                    {organizaciones.map((item, index) => {
                                        const barHeight = (item.total / maxTotal) * 100; // altura en porcentaje
                                        const color = colors[index % colors.length];
                                        return (
                                            <Col key={index} xs={24} sm={8} md={8} lg={8} style={{ textAlign: 'center' }}>
                                                <Card size="small" variant="outlined">
                                                    <div
                                                        style={{
                                                            height: 120, // altura fija del contenedor de la barra
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center',
                                                            border: '1px solid #f0f0f0',
                                                            padding: 8,
                                                            marginBottom: 8
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: 30,
                                                                height: `${barHeight}%`,
                                                                backgroundColor: color,
                                                                transition: 'height 0.3s'
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div style={{ fontWeight: 'bold', marginBottom: 4 }}>
                                                        {`Organización: ${item.vinculado_alguna_organizacion || 'N/A'}`}
                                                    </div>
                                                    <div>{`Total: ${item.total}`}</div>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Card>
                            {/* Sección: Cabeza de Familia */}
                            <Divider orientation="left">Cabeza de Familia</Divider>
                            <Card variant="outlined" style={{ marginBottom: 16 }}>
                                <Row justify="space-around" align="middle" gutter={16}>
                                    {cabezaFamiliar.map((item, index) => {
                                        const percent = totalCabeza ? (item.cantidad / totalCabeza) * 100 : 0;
                                        // Asignamos un color distinto según el valor de "cabeza_familia"
                                        const color = item.cabeza_familia === 'SI' ? '#52c41a' : '#fa541c';
                                        return (
                                            <Col key={index} xs={24} sm={12} md={12} lg={12} style={{ textAlign: 'center' }}>
                                                <Card size="small" variant="outlined" style={{ textAlign: 'center' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8 }}>
                                                        <Progress
                                                            type="circle"
                                                            percent={Math.round(percent)}
                                                            format={() => <span style={{ fontSize: 20 }}>{item.cantidad}</span>}
                                                            size={80}
                                                            strokeColor={color}
                                                        />
                                                        <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                                                            {`Cabeza Familia: ${item.cabeza_familia}`}
                                                        </div>
                                                        <div style={{ fontSize: 14, color: '#888' }}>
                                                            {`(${Math.round(percent)}%)`}
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Card>
                            {/* Sección: Línea Económica */}
                            <Divider orientation="left">Línea productiva</Divider>
                            <Card variant="outlined" style={{ marginBottom: 16 }}>
                                <div style={{ width: '60%', margin: '0 auto' }}>
                                    <Table
                                        dataSource={lineaEconomica}
                                        columns={columnsLineap}
                                        pagination={false}
                                        rowKey="linea_economica"
                                        width={40}
                                        // También puedes personalizar el estilo de las filas si lo deseas:
                                        rowClassName={(record, index) => (index % 2 === 0 ? 'even-row' : 'odd-row')}
                                    />
                                </div>
                            </Card>
                            <div style={{ width: '80%', margin: '0 auto' }}>
                                <Divider orientation="left">Zonas</Divider>
                                <Card variant="outlined">
                                    <List
                                        grid={{ gutter: 16, column: 3 }}
                                        dataSource={zona}
                                        renderItem={item => (
                                            <List.Item>
                                                <Card>
                                                    <Card.Meta
                                                        title={item.nombre_zona}
                                                        description={`Total: ${item.total_por_zona}`}
                                                    />
                                                </Card>
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </div>
                        </>
                    ) : (
                        <p>No hay datos disponibles.</p>
                    )}
                </>
            )
        }
        // ✅ Contenido para otros componentes
        // ✅ Si no es "LP", se oculta el contenido
        return null;
    }
    const defaultActiveKey = dataProyecto.find(componente => componente.sigla === "LP")?.oid || null;

    return (
        <div style={{ padding: 16 }}>
            <h3>Indicadores del proyecto</h3>
            <Divider />
            <div className="container mt-4">
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-6 g-3">
                    {[
                        { titulo: "# de participantes", valor: indicadores?.numeroParticipantes ?? 0, icono: "bi-people-fill" },
                        { titulo: "Número de visitas", valor: indicadores?.numeroVisitas ?? 0, icono: "bi-eye-fill" },
                        { titulo: "Número de ECA´s", valor: indicadores?.numeroEcas ?? 0, icono: "bi-bar-chart-fill" },
                        { titulo: "Actas de entrega", valor: indicadores?.numeroActasEntrega ?? 0, icono: "bi-file-earmark-text-fill" },
                        { titulo: "IPPTA (Encuestas)", valor: indicadores?.ippta ?? 0, icono: "bi-clipboard-data-fill" },
                        { titulo: "EDAH (Encuestas)", valor: indicadores?.edah ?? 0, icono: "bi-person-badge-fill" },
                    ].map((indicador, index) => (
                        <div key={index} className="col">
                            <div className={`card shadow-sm text-center p-2 h-100 ${styles.bgCard}`}>
                                <div className={`card-body d-flex flex-column align-items-center justify-content-between ${styles.bgCardInterno}`}>
                                    <i className={`bi ${indicador.icono} fs-1 text-primary`}></i>
                                    <h5 className={`card-title mt-2 fw-bold ${styles.titleFont}`}>{indicador.titulo}</h5>
                                    <p className="card-text fs-4 text-success fw-bold">{indicador.valor}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <Divider />
                <h3 className={`${styles.titleSeccion}`}> CARACTERIZACIÓN DE PARTICIPANTES</h3>
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-6 g-3">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Participantes por sexo</h5>
                        <GraficaTorta
                            titulo=""
                            datos={participantesPorSexo.map(item => ({
                                name: item.sexo, // "Hombre" o "Mujer"
                                valor: item.total_por_sexo ?? 0 // Valor correspondiente
                            }))}
                            colores={["#1890ff", "#da7b2b"]} // Azul para Hombres, Rosa para Mujeres
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Nivel de escolaridad</h5>
                        <GraficaBarras
                            titulo=""
                            etiquetas={Object.keys(caracterizacionParticipantes?.nivelEscolaridad ?? {})}
                            datos={[{ label: "Nivel de Escolaridad", valores: Object.values(caracterizacionParticipantes?.nivelEscolaridad ?? {}) }]}
                            colores={["#1f77b4"]}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Tipo población</h5>
                        <GraficaBarras
                            titulo=""
                            etiquetas={Object.keys(caracterizacionParticipantes?.tipoPoblacion ?? {})}
                            datos={[{ label: "Tipo Población", valores: Object.values(caracterizacionParticipantes?.tipoPoblacion ?? {}) }]}
                            colores={["#1f77b4"]}
                        />
                    </div>
                </div>
                <Divider />
                <h3 className={`${styles.titleSeccion}`}> ACOMPAÑAMIENTO TÉCNICO</h3>
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-6 g-3 mt-4">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Visitas por periodo</h5>
                        <GraficaBarrasHorizontal
                            titulo=""
                            etiquetas={Object.keys(visitasAcompanamiento?.numeroVisitas ?? {}).filter(k => k !== "totalArchivos")}
                            datos={[{ label: "Visitas", valores: Object.values(visitasAcompanamiento?.numeroVisitas ?? {}).filter((_, i) => i !== 0) }]}
                            colores={["#66CCFF"]}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>ECA´s por periodo</h5>
                        <GraficaBarrasHorizontal
                            titulo=""
                            etiquetas={Object.keys(ecas?.numeroEcas ?? {}).filter(k => k !== "totalArchivos")}
                            datos={[{ label: "ECA's", valores: Object.values(ecas?.numeroEcas ?? {}).filter((_, i) => i !== 0) }]}
                            colores={["#66CCFF"]}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Actas por periodo</h5>
                        <GraficaBarrasHorizontal
                            titulo=""
                            etiquetas={Object.keys(actasEntrega?.numeroActasEntrega ?? {}).filter(k => k !== "totalArchivos")}
                            datos={[{ label: "Actas", valores: Object.values(actasEntrega?.numeroActasEntrega ?? {}).filter((_, i) => i !== 0) }]}
                            colores={["#66CCFF"]}
                        />
                    </div>
                </div>
                <Divider />
                <h3 className={`${styles.titleSeccion}`}> IPPTA</h3>
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-6 g-3">
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="d-flex flex-column align-items-center text-center">
                            <h5 className={`${styles.titleFont}`}>Nivel de transición agroecológica - medición inicial</h5>
                            <GraficaBarras
                                titulo=""
                                etiquetas={["Ambiental", "Socioeconómica", "Productiva"]}
                                datos={[
                                    {
                                        label: "Medición Inicial", valores: [
                                            Number(dataIPPTA?.ippta?.nivelTransicionAgroecologica?.medicionInicial?.dimensionAmbiental) || 0,
                                            Number(dataIPPTA?.ippta?.nivelTransicionAgroecologica?.medicionInicial?.dimensionSocioeconomica) || 0,
                                            Number(dataIPPTA?.ippta?.nivelTransicionAgroecologica?.medicionInicial?.dimensionProductiva) || 0
                                        ]
                                    }
                                ]}
                                colores={["#da7b2b", "#d8bfd9", "#66ccff"]}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="d-flex flex-column align-items-center text-center">
                            <h5 className={`${styles.titleFont}`}>Nivel de transición agroecológica - medición final</h5>
                            <GraficaBarras
                                titulo=""
                                etiquetas={["Ambiental", "Socioeconómica", "Productiva"]}
                                datos={[
                                    {
                                        label: "Medición Final", valores: [
                                            Number(dataIPPTA?.ippta?.nivelTransicionAgroecologica?.medicionFinal?.dimensionAmbiental) || 0,
                                            Number(dataIPPTA?.ippta?.nivelTransicionAgroecologica?.medicionFinal?.dimensionSocioeconomica) || 0,
                                            Number(dataIPPTA?.ippta?.nivelTransicionAgroecologica?.medicionFinal?.dimensionProductiva) || 0
                                        ]
                                    }
                                ]}
                                colores={["#da7b2b", "#d8bfd9", "#66ccff"]}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <div className="d-flex flex-column align-items-center text-center">
                            <h5 className={`${styles.titleFont}`}>Medición General</h5>
                            <GraficaTorta3D
                                titulo=""
                                datos={[
                                    { name: "Medición Final", valor: Number(dataIPPTA?.ippta?.medicionGeneral?.medicionFinal) || 0 },
                                    { name: "Medición Inicial", valor: Number(dataIPPTA?.ippta?.medicionGeneral?.medicionInicial) || 0 }
                                ]}
                                colores={["#408cc8", "#da7b2b"]}
                            />
                        </div>
                    </div>
                </div>
                <Divider />
                <h3 className={`${styles.titleSeccion}`}> EDHA</h3>
                <div className="row row-cols-1 row-cols-md-3 row-cols-lg-6 g-3">
                    {/* Participantes x Medición (Gráfica de Barras) */}
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Participantes x medición</h5>
                        <GraficaBarras
                            titulo=""
                            etiquetas={["Inicial", "Intermedia", "Final"]} // ✅ Etiquetas correctas
                            datos={[
                                {
                                    label: "Participantes",
                                    valores: [
                                        Number(dataEDHA?.dataEDHA?.edha?.participantesxmediccion?.inicial) || 0,
                                        Number(dataEDHA?.dataEDHA?.edha?.participantesxmediccion?.intermedia) || 0,
                                        Number(dataEDHA?.dataEDHA?.edha?.participantesxmediccion?.final) || 0
                                    ]
                                }
                            ]}
                            colores={["#408cc8", "#d8bfd9", "#da7b2b"]}
                        />
                    </div>
                    {/* Encuesta por Municipio (Gráfica de Barras Horizontal) */}
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Encuesta por municipio</h5>
                        <GraficaBarrasHorizontal
                            titulo=""
                            etiquetas={Object.keys(dataEDHA?.dataEDHA?.edha?.Encuestaxmunicipio ?? {})} // 🔹 Extrae municipios
                            datos={[
                                {
                                    label: "Encuestas",
                                    valores: Object.values(dataEDHA?.dataEDHA?.edha?.Encuestaxmunicipio ?? {}) // 🔹 Extrae valores
                                }
                            ]}
                            colores={["#66CCFF"]}
                        />
                    </div>
                    {/* Componente Comercial (Gráfica de Barras) */}
                    <div className="col-12 col-md-6 col-lg-4">
                        <h5 className={`${styles.titleFont}`}>Componente comercial</h5>
                        <GraficaBarras
                            titulo=""
                            etiquetas={dataEDHA?.componenteComercial?.dataCompComercial?.map(item => item.tipoAcuerdo) ?? []} // 🔹 Extrae nombres
                            datos={[
                                {
                                    label: "Tipo de Acuerdo",
                                    valores: dataEDHA?.componenteComercial?.dataCompComercial?.map(item => item.total) ?? []
                                }
                            ]}
                            colores={["#408cc8", "#da7b2b", "#fa8c16"]}
                        />
                    </div>
                </div>
            </div>
            <Divider />
            <h3 className={`${styles.titleSeccion}`}> Participantes</h3>
            <Collapse
                accordion
                defaultActiveKey={defaultActiveKey}
                items={dataProyecto
                    .filter(componente => componente.sigla === "LP")
                    .map(item => ({
                        key: item.oid,
                        label: "Detalle de participantes",
                        children: getContentForComponent(item),
                    }))}
            />
        </div >
    );
};

export default DashboardPlanOperativo;
