// 1. Librerías y dependencias externas
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Table, Collapse, List, Tooltip as AntdTooltip, Button, Form, Input, Modal, Col, Row, message, InputNumber } from 'antd'; // Renombrado Tooltip a AntdTooltip
import { TeamOutlined, AuditOutlined, HeartOutlined, SignatureOutlined, RightOutlined, EditOutlined } from "@ant-design/icons";
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, CartesianGrid, ResponsiveContainer, LabelList, PieChart, Pie, Cell, Legend } from 'recharts'; // Renombrado Tooltip a RechartsTooltip

// 2. Dependencias internas (tus servicios y archivos locales)
import { selectAppPermissions } from '../../../store/auth/selectors';
import EnfoqueGrafico from '../../../services/informacion_genero/enfoques/enfoqueGraficos';
import EnfoqueGeneralServicio from '../../../services/informacion_genero/enfoques/enfoqueGenero';
import logService from '../../../services/log/logService';

// 3. Estilos
import "./enfoqueGenero.css";

const { Panel } = Collapse;

const EnfoqueGeneral = () => {
  const [, setDatos] = useState([]);
  const [dataOrgAgrupada, setDataOrgAgrupada] = useState([]);
  const [dataProyectMujer, setDataProyectMujer] = useState([]);
  const [dataGenero, setDataGenero] = useState([]);
  const [dataHombMujeProy, setDataHombMujeProy] = useState([]);
  const [dataGeneral, setDataGeneral] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [dataOrgAgrupadas, setDataOrgAgrupadas] = useState([]);
  const [datapoblacionEtnica, setDataPoblacionEtnica] = useState([]);
  const [datatipo_organizacion, setDataTipoOrganizacion] = useState([]);
  const [dataPoblacionEdad, setDataPoblacionEdad] = useState([]);
  const [dataEdadMH, setDataEdadMH] = useState([]);
  const [dataVictimas, setDataVictimas] = useState([]);
  const [dataVictimasReportadas, setDataVictimasReportadas] = useState([]);
  const appName = 'enfoqueGenero';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionUpdate = permissions.priv_update === 'Y';
  const user = useSelector((state) => state.auth.user);
  const userLogin = user.username;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [calificaciones, setCalificaciones] = useState({});
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await EnfoqueGeneralServicio.getAll();
        setDataGeneral(data);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await EnfoqueGrafico.getData();

        const formattedData = data.organizacionesMujeres.map(item => ({
          nombre: item.sigla,
          valor: item.n_registros
        }));

        const dataHombMujeProy = data.proyectosGenerales.map((p) => ({
          nombre_completo_proyecto: p.proyecto,
          total_hombres: parseInt(p.n_hombres, 10),
          total_mujeres: parseInt(p.n_mujeres, 10),
        }));

        const transformedData = Object.entries(data.poblacionRangoEdad[0]).map(([name, value]) => ({
          name: `Entre ${name}`,
          Edades: Number(value) // Aseguramos que el valor sea un número
        }));
        const transformedDataHM = Object.entries(data.poblacionRangoEdadHM[0]).reduce((acc, [name, value]) => {
          let gender, ageRange;
          if (name.includes("No Reporta Edad")) {
            [gender] = name.split(" ").slice(-1);
            ageRange = "No Reporta Edad";
          } else {
            [gender, ageRange] = name.split(" ");
          }
          let existingEntry = acc.find(entry => entry.name === `Entre ${ageRange} años`);

          if (!existingEntry) {
            existingEntry = { name: `Entre ${ageRange} años`, Hombres: 0, Mujeres: 0 };
            acc.push(existingEntry);
          }
          existingEntry[gender] = Number(value);
          return acc;
        }, []);

        const transformedDataReparador = Object.entries(data.reparador[0]).map(([key, value]) => {
          if (key.startsWith("Porcentaje")) {
            const name = key.replace("Porcentaje", "").trim(); // Remueve "Porcentaje" y ajusta el nombre
            return {
              name: name,  // Nombre de la categoría
              porcentaje: parseFloat(value),  // Valor del porcentaje
            };
          }
        }).filter(item => item !== undefined);

        setDataOrgAgrupada(formattedData);
        setDataProyectMujer(data.proyectosMujeres);
        setDataGenero(data.genero);
        setDataHombMujeProy(dataHombMujeProy);
        setDataOrgAgrupadas(data.organizacionesAgrupadas);
        setDataPoblacionEtnica(data.poblacion);
        setDataTipoOrganizacion(data.tipoOrganizacion);

        setDataPoblacionEdad(transformedData);
        setDataEdadMH(transformedDataHM);

        setDataVictimas(data.textVictima);
        setDataVictimasReportadas(transformedDataReparador);
        setDatos(data);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };

    fetchData();
  }, []);

  const setInitialFormValues = () => {
    if (datapoblacionEtnica.length > 0) {
      form.setFieldsValue({
        oid: datapoblacionEtnica.map(item => ({ oid: item.oid })),
        poblacion: datapoblacionEtnica.map(item => ({ poblacion: item.poblacion })),
        porcentaje: datapoblacionEtnica.map(item => ({ porcentaje: item.porcentaje })),
        valor: datapoblacionEtnica.map(item => ({ valor: item.valor })),
      });
    }
  };

  const setInitialFormValues2 = () => {
    if (dataVictimas.length > 0) {
      form2.setFieldsValue({
        oid: dataVictimas.map(item => ({ oid: item.oid })),
        poblacion: dataVictimas.map(item => ({ poblacion: item.poblacion })),
        descripcion: dataVictimas.map(item => ({ descripcion: item.descripcion })),
      });
    }
  };

  useEffect(() => {
    setInitialFormValues();
    setInitialFormValues2();
  }, [datapoblacionEtnica, dataVictimas, form, form2]);

  const handleEditClick = (option) => {
    if (option === 1) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible2(true);
    }
  };

  const handleCancel = (option) => {
    if (option === 1) {
      setIsModalVisible(false);
    } else {
      setIsModalVisible2(false);
    }
  };

  const handleOk = async (option) => {
    if (option === 1) {
      try {
        const values = await form.validateFields();
        const updatedData = datapoblacionEtnica.map((item, index) => ({
          oid: values.oid[index]?.oid ?? item.oid,
          poblacion: values.poblacion[index]?.poblacion ?? item.poblacion,
          porcentaje: parseFloat(values.porcentaje[index]?.porcentaje ?? item.porcentaje),
          valor: parseInt(values.valor[index]?.valor ?? item.valor, 10),
          user: userLogin,
        }));

        await Promise.all(updatedData.map(data => EnfoqueGeneralServicio.updateEtnia(data.oid, data)));
        setDataPoblacionEtnica(updatedData);
        setInitialFormValues();
        message.success("Datos actualizados con éxito");
        handleCancel(1);

        // Registrar el log
        await logService.createLog({
          action: "update",
          description: JSON.stringify(values),
          username: userLogin,
          application: "pa_participantes_poblacion_etnica"
        });

      } catch (error) {
        console.error("Error al actualizar:", error);
        message.error("Error al actualizar los datos");
      }
    } else {
      try {
        const values = await form2.validateFields();
        const updatedData = dataVictimas.map((item, index) => ({
          oid: values.oid?.[index]?.oid ?? item.oid,
          poblacion: values.poblacion?.[index]?.poblacion ?? item.poblacion,
          descripcion: values.descripcion?.[index]?.descripcion ?? item.descripcion,
          user: userLogin,
        }));

        await Promise.all(updatedData.map(data => EnfoqueGeneralServicio.updateEtniapoblacion_victima(data.oid, data)));
        setDataVictimas(updatedData);
        setInitialFormValues2();
        message.success("Datos actualizados con éxito");
        handleCancel(2);

        // Registrar el log
        await logService.createLog({
          action: "update",
          description: JSON.stringify(values),
          username: userLogin,
          application: "pa_participante_poblacion_victima"
        });

      } catch (error) {
        console.error("Error al actualizar:", error);
        message.error("Error al actualizar los datos");
      }
    }
  };


  const columns = [
    { title: "Proyectos", dataIndex: "proyecto", className: "custom-header-enfoques", key: "proyecto", width: 250 },
    { title: "Número de Mujeres", dataIndex: "n_mujeres", className: "custom-header-enfoques", key: "n_mujeres", align: "center", width: 180 }
  ];

  //Grafico de barras de organizaciones de mujeres
  const renderBarChart = (
    <div className="chart-container">
      <div className="chart-header">Organizaciones de Mujeres</div>
      <div className="chart-content">
        {dataOrgAgrupada.length > 0 ? (
          <ResponsiveContainer width="100%" height={350} style={{ textAlign: "center" }}>
            <BarChart data={dataOrgAgrupada} margin={{ bottom: 20, top: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="nombre"
                tick={{ fill: "black" }}
                angle={-55}
                textAnchor="end"
                height={80}
                tickFormatter={(nombre) =>
                  (nombre?.length > 10 ? nombre.substring(0, 10).toUpperCase() + "..." : nombre?.toUpperCase()) || ""
                }
                style={{ fontSize: 10 }}
                label={{
                  value: "Número de Mujeres x Organización",
                  position: "insideBottom",
                  offset: -10,
                  style: { fontSize: 15, fontWeight: "bold" }
                }}
              />
              <YAxis tick={{ fill: "black" }} />
              <RechartsTooltip />
              <Bar dataKey="valor" fill="#b39ddb">
                <LabelList dataKey="valor" position="top" fill="#000" fontSize={13} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  //Tabla de proyectos de mujeres
  const renderTable = (
    <div style={{ marginBottom: "20px" }}>
      <div className="chart-container">
        <div className="chart-header">Proyecto de Mujeres</div>
        <Table
          columns={columns}
          dataSource={dataProyectMujer}
          rowKey="proyecto"
          pagination={false}
          className="custom-table-enfoque"
          bordered={true}
        />
      </div>
    </div>);

  //Grafico de pastel de genero
  const COLORS = ["#a5d6a7", "#b39ddb"];
  const renderPieChart = (
    <div className="pie-chart-container">
      <div className="pie-chart-header">% de Hombres y Mujeres General</div>
      <div className="pie-chart-content">
        {dataGenero.length > 0 ? (
          <ResponsiveContainer width="100%" height={180}>
            <PieChart>
              <Pie
                data={dataGenero}
                dataKey="cantidad" // Sigue usando cantidad para el tamaño del sector
                nameKey="sexo"
                cx="50%"
                cy="50%"
                outerRadius={60}
                innerRadius={30}
                label={({ name, percent, x, y }) => {
                  const labelText = `${name}: ${(percent * 100).toFixed(2)}%`;
                  const textWidth = labelText.length * 6;
                  const textHeight = 16;
                  return (
                    <>
                      <rect
                        x={x - textWidth / 1.6 - 4}
                        y={y - textHeight / 2 - 2}
                        width={textWidth + 30}
                        height={textHeight + 4}
                        fill="#797272"
                        rx={4}
                      />
                      <text
                        x={x}
                        y={y}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{ fontSize: '12px', fontWeight: 'bold', fill: '#fff' }}
                      >
                        {labelText}
                      </text>
                    </>
                  );
                }}

                labelPosition="outside"
                labelStyle={{ fontSize: "12px", fontWeight: "bold", fill: "#555" }}
              >
                {dataGenero.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <RechartsTooltip formatter={(value, name, props) => [`${props.payload.porcentaje}%`, name]} /> {/* Mostrar % en Tooltip */}
              <Legend verticalAlign="middle" align="right" layout="vertical" iconType="circle" />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  //Grafica Proyectos de Hombres y Mujeres
  const renderBarChartProy = (
    <div className="chart-container">
      <div className="chart-header">Hombre y Mujeres por Proyecto</div>
      <div className="chart-content">
        {dataHombMujeProy.length > 0 ? (
          <ResponsiveContainer width="100%" height={600}>
            <BarChart
              data={dataHombMujeProy}
              layout="vertical"
              margin={{ left: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis
                dataKey="nombre_completo_proyecto"
                type="category"
                width={180}
                tick={{ fontSize: 12 }}
                tickFormatter={(nombre) =>
                  nombre?.slice(0, 20).toUpperCase() + (nombre?.length > 0 ? "..." : "")
                }
                label={{
                  value: "Hombres y Mujeres por Proyecto",
                  angle: -90, // Hace que el texto sea vertical
                  position: "insideLeft",
                  dy: 10, // Ajusta la distancia del texto al eje
                  style: { fontSize: 14, fontWeight: "bold", textAnchor: "middle" }
                }}
              />

              <XAxis type="number" />
              <RechartsTooltip />
              <Legend verticalAlign="top" align="center" />
              <Bar dataKey="total_hombres" fill="#a5d6a7" name="Hombres" stackId="a">
                <LabelList dataKey="total_hombres" position="insideRight" fill="white" />
              </Bar>
              <Bar dataKey="total_mujeres" fill="#b39ddb" name="Mujeres" stackId="a">
                <LabelList dataKey="total_mujeres" position="insideRight" fill="white" />
              </Bar>

            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>);

  const handleCalificacionChange = (value, key) => {
    setCalificaciones((prev) => ({
      ...prev,
      [key]: value, // Guardar la nueva calificación en el estado
    }));
  };

  const handleBlur = async (record, value) => {
    try {
      const data = {
        calificacion: value,
        user: userLogin
      };

      await EnfoqueGeneralServicio.update(record.key, data);
      message.success("Calificación registrada correctamente.");

      // Registrar el log con más contexto
      await logService.createLog({
        action: "update",
        description: `Calificación actualizada: Anterior ${JSON.stringify(record)}: Actual: ${JSON.stringify(data)}`, // Mejor descripción
        username: userLogin,
        application: "enfoque_tema",
      });

    } catch (error) {
      console.error("Error al actualizar la calificación:", error);
      message.error("Error al registrar la calificación. Inténtalo de nuevo.");
    }
  };

  const filteredData = dataGeneral.find((item) => item.oid === Number(selectedTab));

  const tablasGeneral = (
    <div>
      {filteredData ? (
        filteredData.categorias.length > 0 ? (
          filteredData.categorias.map((categoria) => {
            const columns = [
              {
                title: "Descripción",
                dataIndex: "nombre",
                className: "custom-header-enfoques",
                key: "nombre",
                width: 350,
                ellipsis: false,
                render: (text) => (
                  <div style={{ whiteSpace: "normal", wordWrap: "break-word", textAlign: "justify" }}>
                    {text}
                  </div>
                ),
              },
              {
                title: "Cantidad",
                dataIndex: "calificacion",
                className: "custom-header-enfoques",
                key: "calificacion",
                width: 100,
                align: "center",
                render: (_, record) => {
                  return (
                    <InputNumber
                      min={0}
                      value={calificaciones[record.key] ?? record.calificacion ?? 0}
                      onChange={(value) => handleCalificacionChange(value, record.key)}
                      onBlur={() => handleBlur(record, calificaciones[record.key] ?? record.calificacion ?? 0)}
                      style={{ width: "100%" }}
                      disabled={!permissionUpdate} // Deshabilitar si no tiene permisos
                    />
                  );
                },
              }
            ];

            const dataSource = categoria.temas.map((tema) => ({
              key: tema.oid,
              nombre: tema.nombre,
              calificacion: tema.calificacion,
            }));

            return (
              <div key={categoria.oid} style={{ marginBottom: "20px" }}>
                <div className="chart-container">
                  <div className="chart-header">{categoria.nombre}</div>
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    locale={{ emptyText: "No hay temas disponibles" }}
                    bordered={true}
                    style={{ wordWrap: "break-word" }}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <p>No hay información disponible</p>
        )
      ) : (
        <p>No hay información disponible</p>
      )}
    </div>
  );

  // Organizaciones agrupadas
  const renderOrgAgrupadas = (
    <div className="chart-container">
      <div className="chart-header">Organizaciones</div>
      <div>
        {dataOrgAgrupadas.length > 0 ? (
          <Collapse

            accordion={false} // Permite que varios paneles estén abiertos al mismo tiempo
            defaultActiveKey={dataOrgAgrupadas.map(grupo => grupo.oid)} // Hace que todos los paneles estén abiertos al principio
            style={{ color: "white", borderRadius: "8px" }}
            expandIcon={({ isActive }) => (
              <RightOutlined style={{ color: "white" }} rotate={isActive ? 90 : 0} />
            )}
          >
            {dataOrgAgrupadas.map((grupo) => (
              <Panel
                key={grupo.oid} // La clave única de cada panel
                header={<span style={{ color: "white", fontWeight: "bold" }}>{grupo.tipo_organizacion}</span>} // Texto con color blanco
                style={{ background: "#49b6f6", color: "white" }}
              >
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                  <List
                    dataSource={grupo.organizaciones}
                    renderItem={(org) => (
                      <List.Item key={org.NombreOrganizacion}> {/* Asignar un key único aquí */}
                        <strong>{org.NombreOrganizacion}</strong>
                      </List.Item>
                    )}
                  />
                </div>
              </Panel>
            ))}
          </Collapse>) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  // Población étnica
  const COLORS2 = ["#a5d6a7", "#81d4fa"];
  const renderPoblacionEtnica = (
    <div className="pie-chart-container">
      <div className="pie-chart-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 1, textAlign: 'center' }}>% de Población Étnica</div>
        {permissionUpdate && (
          <AntdTooltip title="Editar">
            <Button shape="circle" icon={<EditOutlined />} onClick={() => handleEditClick(1)} />
          </AntdTooltip>
        )}
      </div>
      <div className="pie-chart-content">
        {datapoblacionEtnica.length > 0 ? (
          <ResponsiveContainer width="100%" height={360}>
            <PieChart>
              <Pie
                data={datapoblacionEtnica}
                dataKey="porcentaje"
                nameKey="poblacion"
                cx="50%"
                cy="50%"
                outerRadius={130}
                innerRadius={70}
                labelPosition="outside"
                labelStyle={{ fontSize: "12px", fontWeight: "bold", fill: "#333" }} // Cambié el color del label a un tono oscuro (#333)
                label={(props) => {
                  const { name, percent, value, valor, x, y } = props;
                  const labelText = `${valor} - ${value}%`;
                  const formattedText = labelText.length > 20 ? labelText.replace(/ /g, '\n') : labelText;

                  // Estimación del tamaño del rectángulo según la longitud del texto
                  const textWidth = formattedText.length * 6; // Aproximadamente 6px por caracter
                  const textHeight = 16; // Altura del texto

                  return (
                    <>
                      {/* Rectángulo de fondo */}
                      <rect
                        x={x - textWidth / 2 - 4} // Centrar el rect
                        y={y - textHeight / 2 - 2}
                        width={textWidth + 8} // Ajustar el ancho según el texto
                        height={textHeight + 4}
                        fill="#797272"
                        rx={4} // Bordes redondeados opcionales
                      />
                      {/* Texto encima del rectángulo */}
                      <text
                        x={x}
                        y={y}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{ fontSize: '12px', fontWeight: 'bold', fill: '#fff', whiteSpace: 'pre' }}
                      >
                        {formattedText}
                      </text>
                    </>
                  );
                }}
              >
                {datapoblacionEtnica.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS2[index % COLORS2.length]} />
                ))}
              </Pie>
              <RechartsTooltip formatter={(value, name, props) => [`${props.payload.porcentaje}%`, name]} />
              <Legend verticalAlign="bottom" align="center" layout="horizontal" iconType="circle" />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  //Grafica de población
  const COLORS3 = ["#a5d6a7", "#81d4fa", "#b39ddb"];
  const renderEtnico = (
    <div className="pie-chart-container" style={{ position: 'relative' }}>
      <div className="pie-chart-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 1, textAlign: 'center' }}>Enfoque Étnico</div>
      </div>
      <div className="pie-chart-content">
        {datatipo_organizacion.length > 0 ? (
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={datatipo_organizacion}
                dataKey="t_registros"
                nameKey="tipo_organizacion"
                cx="50%"
                cy="45%"
                outerRadius={120}
                innerRadius={0}
                labelPosition="outside"
                label={(props) => {
                  const { name, percent, value, x, y } = props;
                  const labelText = `${name}: ${value}`;
                  const formattedText = labelText.length > 20 ? labelText.replace(/ /g, '\n') : labelText;

                  // Estimación del tamaño del rectángulo según la longitud del texto
                  const textWidth = formattedText.length * 6; // Aproximadamente 6px por caracter
                  const textHeight = 16; // Altura del texto

                  return (
                    <>
                      {/* Rectángulo de fondo */}
                      <rect
                        x={x - textWidth / 2 - 4} // Centrar el rect
                        y={y - textHeight / 2 - 2}
                        width={textWidth + 8} // Ajustar el ancho según el texto
                        height={textHeight + 4}
                        fill="#797272"
                        rx={4} // Bordes redondeados opcionales
                      />
                      {/* Texto encima del rectángulo */}
                      <text
                        x={x}
                        y={y}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{ fontSize: '12px', fontWeight: 'bold', fill: '#fff', whiteSpace: 'pre' }}
                      >
                        {formattedText}
                      </text>
                    </>
                  );
                }}

              >
                {datatipo_organizacion.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS3[index % COLORS3.length]} />
                ))}
              </Pie>
              <RechartsTooltip formatter={(value, name) => [`${value} registros`, name]} />
              <Legend verticalAlign="bottom" align="center" layout="horizontal" iconType="circle" />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  ///Grafica de edades
  const renderPoblacionRangoEdad = (
    <div className="chart-container">
      <div className="chart-header">Población segun rangos de edad</div>
      <div className="chart-content">
        {dataPoblacionEdad.length > 0 ? (
          <ResponsiveContainer width="100%" height={360}>
            <BarChart data={dataPoblacionEdad}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name"
                tick={{ fill: "black" }}
                angle={-30}
                textAnchor="end"
                height={100}
                tickFormatter={(nombre) =>
                  (nombre?.length > 25 ? nombre.substring(0, 25).toUpperCase() + "..." : nombre?.toUpperCase()) || ""
                }
                style={{ fontSize: 10 }}
                label={{
                  value: "Rangos de edades",
                  position: "insideBottom",
                  offset: 0,
                  style: { fontSize: 15, fontWeight: "bold" }
                }}
              />
              <YAxis tick={{ fill: "black" }} />
              <RechartsTooltip />
              <Bar dataKey="Edades" fill="#b39ddb">
                <LabelList dataKey="Edades" position="top" fill="#797272" fontSize={13} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  //Grafica de edades HM
  const renderPoblacionRangoEdadHM = (
    <div className="chart-container">
      <div className="chart-header">Población según rangos de edad</div>
      <div className="chart-content">
        {dataEdadMH.length > 0 ? (
          <ResponsiveContainer width="100%" height={490}>
            <BarChart data={dataEdadMH}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                tick={{ fill: "black" }}
                angle={-30}
                textAnchor="end"
                height={100}

                tickFormatter={(nombre) =>
                  (nombre?.length > 25 ? nombre.substring(0, 25).toUpperCase() + "..." : nombre?.toUpperCase()) || ""
                }
                style={{ fontSize: 10 }}
              />
              <YAxis tick={{ fill: "black" }} />
              <RechartsTooltip />
              <Legend />
              <Bar dataKey="Hombres" fill="#a5d6a7">
                <LabelList dataKey="Hombres" position="top" fill="#000" fontSize={13} />
              </Bar>
              <Bar dataKey="Mujeres" fill="#b39ddb">
                <LabelList dataKey="Mujeres" position="top" fill="#000" fontSize={13} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  ///Grafica reparador
  const COLORS4 = ["#a5d6a7", "#81d4fa", "#b39ddb"];
  const renderReparador = (
    <div className="pie-chart-container">
      <div className="pie-chart-header">Población víctima reportada en los proyectos</div>
      <div className="pie-chart-content">
        {dataVictimasReportadas.length > 0 ? (
          <ResponsiveContainer width="100%" height={360}>
            <PieChart>
              <Pie
                data={dataVictimasReportadas}
                dataKey="porcentaje"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={130}
                innerRadius={70}
                labelPosition="outside"
                labelStyle={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  fill: "#333"
                }}
                label={(props) => {
                  const { name, percent, value, x, y } = props;
                  const labelText = `${name}: ${value} (${(percent * 100).toFixed(1)}%)`;
                  const formattedText = labelText.length > 20 ? labelText.replace(/ /g, '\n') : labelText;
                  const textWidth = formattedText.length * 7;
                  const textHeight = 13;
                  return (
                    <>
                      <rect
                        x={x - textWidth / 2 - 6}
                        y={y - textHeight / 2 - 4}
                        width={textWidth + 12}
                        height={textHeight + 8}
                        fill="#797272"
                        rx={4}
                      />
                      <text
                        x={x}
                        y={y}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{ fontSize: '14px', fontWeight: 'bold', fill: 'white', whiteSpace: 'pre' }}
                      >
                        {formattedText}
                      </text>
                    </>
                  );
                }}
              >
                {dataVictimasReportadas.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS4[index % COLORS4.length]} />
                ))}
              </Pie>
              <RechartsTooltip formatter={(value, name, props) => [`${props.payload.porcentaje}%`, name]} />
              <Legend verticalAlign="bottom" align="center" layout="horizontal" iconType="circle" />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </div>
  );

  //Texto Victimas
  const renderPorcentajeVictimas = (
    <div className="pie-chart-container" style={{ position: 'relative' }}>
      <div className="pie-chart-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ flex: 1, textAlign: 'center' }}>
          {dataVictimas[0]?.poblacion}
        </div>
        {permissionUpdate && (
          <AntdTooltip title="Editar">
            <Button shape="circle" icon={<EditOutlined />} onClick={() => handleEditClick(2)} />
          </AntdTooltip>
        )}

      </div>
      {dataVictimas.length > 0 ? (
        <div className="pie-chart-content" style={{ textAlign: 'justify' }}>
          <p>{dataVictimas[0]?.descripcion}</p> {/* Muestra la descripción del texto */}
        </div>
      ) : (
        <p>Cargando datos...</p>
      )}
    </div>
  );

  const items = [
    {
      key: "1",
      label: (
        <span className="tab-icon">
          <TeamOutlined /> ENFOQUE DE GÉNERO
        </span>
      ),
      children: (
        <div className="dashboard-container">
          <div className="right-section" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <div className="chart-box" style={{ flex: 1 }}>
              <div style={{ marginBottom: "20px" }}>{tablasGeneral}</div>
              <div style={{ marginBottom: "20px" }}>{renderBarChart}</div>
            </div>

            <div className="chart-box" style={{ flex: 1 }}>
              <div style={{ marginBottom: "20px" }}>{renderPieChart}</div>
              <div style={{ marginBottom: "20px" }}>{renderBarChartProy}</div>
              <div style={{ marginBottom: "20px" }}>{renderTable}</div>
            </div>

          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span className="tab-icon">
          <AuditOutlined /> ENFOQUE ÉTNICO
        </span>
      ),
      children: (<div className="dashboard-container">
        <div className="right-section" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <div className="chart-box" style={{ flex: 1 }}>
            <div style={{ marginBottom: "20px" }}>{tablasGeneral}</div>
            <div style={{ marginBottom: "20px" }}>{renderEtnico}</div>
          </div>
          <div className="chart-box" style={{ flex: 1 }}>
            <div style={{ marginBottom: "20px" }}>{renderOrgAgrupadas}</div>
            <div style={{ marginBottom: "20px" }}>{renderPoblacionEtnica}</div>
          </div>
        </div>
      </div>),
    },
    {
      key: "3",
      label: (
        <span className="tab-icon">
          <HeartOutlined /> ENFOQUE CURSO DE VIDA
        </span>
      ),
      children: (<div className="dashboard-container">
        <div className="right-section" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <div className="chart-box" style={{ flex: 1 }}>
            <div style={{ marginBottom: "20px" }}>{tablasGeneral}</div>
            <div style={{ marginBottom: "20px" }}>{renderPoblacionRangoEdad}</div>
          </div>

          <div className="chart-box" style={{ flex: 1 }}>
            <div style={{ marginBottom: "20px" }}>{renderPoblacionRangoEdadHM}</div>

          </div>

        </div>
      </div>),
    },
    {
      key: "4",
      label: (
        <span className="tab-icon">
          <SignatureOutlined /> ENFOQUE REPARADOR
        </span>
      ),
      children: (<div className="dashboard-container">
        <div className="right-section" style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <div className="chart-box" style={{ flex: 1 }}>
            <div style={{ marginBottom: "20px" }}>{tablasGeneral}</div>
            <div style={{ marginBottom: "20px" }}>{renderPorcentajeVictimas}</div>
          </div>
          <div className="chart-box" style={{ flex: 1 }}>
            <div style={{ marginBottom: "20px" }}>{renderReparador}</div>
          </div>
        </div>
      </div>),
    },
  ];

  return (
    <div className="container">
      <React.Fragment>
        <div className="row mb-2 d-flex align-items-center" >
          <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
            <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
              <p>PROYECTO UTF <br /> COL 160 COL</p>
            </div>
            <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
              <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
            <h2 className="text-center mb-2">ENFOQUES DIFERENCIALES</h2>
          </div>
          <div className="col-md-12">
            <Tabs value={selectedTab} onChange={setSelectedTab} defaultActiveKey="1" centered items={items} className="mi-tabs-container" />
          </div>
        </div>
      </React.Fragment>
      <Modal
        title="Editar Etnico"
        open={isModalVisible}
        onOk={() => handleOk(1)}
        onCancel={() => handleCancel(1)}
        okText="Actualizar"
        cancelText="Cancelar"
        width={1000}
      >
        <Form form={form} layout="vertical" >
          <Row gutter={16}>
            {datapoblacionEtnica.map((item, index) => (
              <React.Fragment key={index}>
                <Col span={0}>
                  <Form.Item
                    label="oid"
                    name={['oid', index, 'oid']}
                  >
                    <Input type="number" defaultValue={item.oid} />
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    label="Etnia"
                    name={['poblacion', index, 'poblacion']}
                  >
                    <Input type="text" defaultValue={item.poblacion} style={{ height: 80, lineHeight: "80px", padding: "0 12px", fontSize: "16px" }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item
                    label="Valor"
                    name={['valor', index, 'valor']}
                  >
                    <Input type="text" defaultValue={item.valor} style={{ height: 80, lineHeight: "80px", padding: "0 12px", fontSize: "16px" }} />
                  </Form.Item>
                </Col>
                <Col span={2}>
                  <Form.Item
                    label="Porcentaje"
                    name={['porcentaje', index, 'porcentaje']}
                  >
                    <Input type="number" defaultValue={item.porcentaje} style={{ height: 80 }} />
                  </Form.Item>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Enfoque Reparador"
        open={isModalVisible2}
        onOk={() => handleOk(2)}
        onCancel={() => handleCancel(2)}
        okText="Actualizar"
        cancelText="Cancelar"
        width={800}
      >
        <Form form={form2} layout="vertical" >
          <Row gutter={[16, 16]}>
            {dataVictimas.map((item, index) => (
              <React.Fragment key={index}>
                <Col span={0}>
                  <Form.Item label="oid" name={['oid', index, 'oid']}>
                    <Input type="number" defaultValue={item.oid} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Población" name={['poblacion', index, 'poblacion']}>
                    <Input type="text" defaultValue={item.poblacion} style={{ height: 80, fontSize: "16px" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Descripción" name={['descripcion', index, 'descripcion']}>
                    <Input.TextArea
                      defaultValue={item.descripcion}
                      style={{ height: 200, fontSize: "16px", resize: "none", whiteSpace: "pre-wrap" }}
                    />
                  </Form.Item>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default EnfoqueGeneral;