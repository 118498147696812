import React, { useEffect, useState } from "react";
import { Button } from "antd"; // Importa el botón de Ant Design
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { getHeader, getPOAExport } from "../../services/plaOperativo/headerPlanOperativoServices";
import { FileExcelOutlined } from "@ant-design/icons";

const ExportPoa = ({ selectedProject, valoresJsonPeso }) => {
    const [headerPO, setHeaderPO] = useState(null);
    const [fasesResult, setFaseResult] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!selectedProject) return;
        const fetchProjects = async () => {
            try {
                const { data } = await getHeader(selectedProject);
                setHeaderPO(data?.encabezado[0] || null);
            } catch (error) {
                console.error("Error fetching projects:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProjects();
    }, [selectedProject]);

    useEffect(() => {
        if (!selectedProject) return;
        const fetchPOA = async () => {
            setLoading(true);
            try {
                const { data } = await getPOAExport(selectedProject);
                setFaseResult(Array.isArray(data) ? data : []);
            } catch (error) {
                console.error("Error fetching POA:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchPOA();
    }, [selectedProject]);


    function logTotPesoPorMesAnioPorFase(dataJSONPeso) {
        // Objeto que guardará los acumuladores por fase, subdivididos en P y E.
        // Ej: acumuladoresPorFase[faseId] = { P: {}, E: {} }
        const acumuladoresPorFase = {};

        // Recorremos las "fases" (elementos del array dataJSONPeso)
        dataJSONPeso.forEach((fase) => {
            const faseId = fase.fase_id || "fase-sin-id";

            // Si no existe, inicializamos la estructura con P y E vacíos
            if (!acumuladoresPorFase[faseId]) {
                acumuladoresPorFase[faseId] = {
                    P: {},
                    E: {},
                };
            }

            if (!fase.children) return; // Sin actividades, skip

            fase.children.forEach((tarea) => {
                // Ajusta si tu "pesoPorcentual" es realmente un factor o un %.
                // Aquí supongo que lo usas "tal cual":
                const pesoEntero = parseFloat(tarea.pesoPorcentual) || 0;
                const peso = pesoEntero / 1; // o /100 si en BD 3 significa 3%

                if (!tarea.children) return; // Sin indicadores, skip

                tarea.children.forEach((indicador) => {
                    const meta = parseFloat(indicador.meta) || 0;
                    if (meta <= 0 || peso <= 0) return; // Evitar /0

                    // --- PROYECTADO (P) ---
                    if (indicador.cronogramasP) {
                        indicador.cronogramasP.forEach((crono) => {
                            const p = parseFloat(crono.avance) || 0;
                            const mes = crono.mes;
                            const ano = crono.ano;
                            const keyMesAnio = `${mes}-${ano}`;

                            const parcial = (p / meta) * peso;

                            if (!acumuladoresPorFase[faseId].P[keyMesAnio]) {
                                acumuladoresPorFase[faseId].P[keyMesAnio] = 0;
                            }
                            acumuladoresPorFase[faseId].P[keyMesAnio] += parcial;
                        });
                    }

                    // --- EJECUTADO (E) ---
                    if (indicador.cronogramasE) {
                        indicador.cronogramasE.forEach((crono) => {
                            const e = parseFloat(crono.avance) || 0;
                            const mes = crono.mes;
                            const ano = crono.ano;
                            const keyMesAnio = `${mes}-${ano}`;

                            const parcial = (e / meta) * peso;

                            if (!acumuladoresPorFase[faseId].E[keyMesAnio]) {
                                acumuladoresPorFase[faseId].E[keyMesAnio] = 0;
                            }
                            acumuladoresPorFase[faseId].E[keyMesAnio] += parcial;
                        });
                    }
                });
            });
        });
        // *** REDONDEO AL FINAL ***
        // Recorremos cada fase, y dentro de cada fase, cada tipo (P, E) y cada "mes-año"
        for (const faseId in acumuladoresPorFase) {
            for (const tipo of ["P", "E"]) {
                for (const keyMesAnio in acumuladoresPorFase[faseId][tipo]) {
                    const valorAcumulado = acumuladoresPorFase[faseId][tipo][keyMesAnio];
                    // Redondear a 2 decimales
                    acumuladoresPorFase[faseId][tipo][keyMesAnio] = Number(
                        valorAcumulado.toFixed(2)
                    );
                }
            }
        }

        // Ahora construimos un objeto JSON con el formato deseado
        // (ej. "fase" y luego "p" y "e" separadas)
        const resultado = { fases: [] };

        for (const [faseId, objPE] of Object.entries(acumuladoresPorFase)) {
            // objPE = { P: {...}, E: {...} }
            resultado.fases.push({
                fase: faseId,
                p: objPE.P,
                e: objPE.E
            });
        }
        // Devolver el objeto JSON para usarlo en otra parte
        return resultado;
    }

    // Función para obtener los encabezados de cronograma
    const getCronogramaHeaders = (fase) => {
        let totalPesoPorcentual = 0;
        const actividadesArray = Array.isArray(fase.actividades) ? fase.actividades : [fase.actividades];
        const JsonPeso = logTotPesoPorMesAnioPorFase(actividadesArray);

        const headerRow1 = ["ACTIVIDAD", "TAREA", "INDICADORES", "META", "FECHA INICIO", "FECHA FIN", "P/E"];
        const headerRow2 = Array(7).fill(""); // Segunda fila con espacios vacíos para alineación
        const yearMonthMap = new Map();
        const mesesPorAnio = {};
        // Recopilar años y meses en un Map
        fase.actividades?.forEach(act => {
            act.children?.forEach(tarea => {
                totalPesoPorcentual += tarea.pesoPorcentual;
                tarea.children?.forEach(indicador => {
                    indicador.cronogramaconfig?.forEach(({ anio, fechas }) => {

                        if (!yearMonthMap.has(anio)) {
                            yearMonthMap.set(anio, new Set());
                        }
                        fechas.forEach(mes => yearMonthMap.get(anio).add(mes));
                        mesesPorAnio[anio] = fechas;

                    });
                });
            });
        });


        const sortedYears = Array.from(yearMonthMap.keys()).sort();
        const monthOrder = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
        sortedYears.forEach(anio => {
            const mesesOrdenados = Array.from(yearMonthMap.get(anio)).sort((a, b) => monthOrder.indexOf(a) - monthOrder.indexOf(b));

            // Agregar el prefijo "Año: " al año
            headerRow1.push(`CRONOGRAMA : ${anio}`, ...Array(mesesOrdenados.length * 2 - 1).fill(""));
            // Insertamos los meses y sus respectivos %Avance
            mesesOrdenados.forEach(mes => {

                const fases = JsonPeso.fases || [];
                if (!fases.some(fase => fase.fase === '3')) {
                    const PData = (fases.find(fase => fase.p?.[`${mes}-${anio}`] !== undefined)?.p[`${mes}-${anio}`] ?? 0) + "%";
                    const EData = (fases.find(fase => fase.e?.[`${mes}-${anio}`] !== undefined)?.e[`${mes}-${anio}`] ?? 0) + "%";

                    headerRow2.push(`${mes}\n${PData}`, `%Avance\n${EData}`);
                } else {
                    headerRow2.push(`${mes}`, `%Avance`);
                }
                //const dato = JsonPeso(fase.actividades);

            });
        });
        // Determinar el texto de la fase de manera más compacta
        const faseText = (fase.cod_Fase === 1) ? "FASE DE ALISTAMIENTO" : "FASE DE EJECUCIÓN";

        // Mostrar el JSON en la consola para depuración


        // Definir encabezados adicionales
        headerRow1.push("SEGUIMIENTO ACUMULADO", "");
        headerRow2.push("META Y AVANCE ACUMULADO", "% DE AVANCE ACUMULADO");

        // Agregar columnas según la fase
        if (fase.cod_Fase !== 3) {
            headerRow1.push("PESO PORCENTUAL");
            headerRow2.push(`PESO PORCENTUAL ${faseText}\n${totalPesoPorcentual}%`);
        }

        // Agregar columnas fijas al final
        headerRow1.push("FECHA FIN REAL", "MEDIO DE VERIFICACIÓN", "RUTA SOPORTES");
        headerRow2.push("", "", "");

        return { headerRow1, headerRow2, mesesPorAnio };
    };



    const exportToExcel = async () => {

        if (!headerPO) {
            console.error("No hay datos de encabezado para exportar.");
            alert("No hay datos disponibles para exportar.");
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Plan Operativo Anual");

        // **Estilos globales**
        const titleStyle = { font: { size: 14, bold: true }, alignment: { horizontal: "center" } };

        // **Estilos de borde**
        const borderStyle = { top: { style: "thin" }, left: { style: "thin" }, bottom: { style: "thin" }, right: { style: "thin" } };


        // **Aplicar estilos de celda**
        const applyCellStyle = (cell, style, border = true) => {
            cell.font = style.font;
            cell.alignment = style.alignment;
            if (border) cell.border = borderStyle;
        };

        // **Funciones para fusionar celdas y establecer valores**  
        const mergeAndSetCellTitle = (range, value, style = titleStyle, border = true) => {
            worksheet.mergeCells(range);
            const cell = worksheet.getCell(range.split(":")[0]);
            cell.value = value;
            applyCellStyle(cell, style, border);
            return cell;
        };

        // **Funciones para fusionar celdas y establecer valores**
        const mergeAndSetCell = (range, value, style = titleStyle, border = true, wrapText = true) => {
            worksheet.mergeCells(range);
            const cell = worksheet.getCell(range.split(":")[0]);
            cell.value = value;
            applyCellStyle(cell, style, border);
            cell.alignment = { horizontal: "center", vertical: "middle", wrapText };
            const columns = range.split(":").map(cellRef => cellRef.replace(/\d+/g, ""));
            const totalColumns = columns.length > 1 ? columns[1].charCodeAt(0) - columns[0].charCodeAt(0) + 1 : 1;
            const contentLength = value ? value.toString().length : 10;
            const columnWidth = Math.max(contentLength / totalColumns + 5, 15);
            columns.forEach(col => worksheet.getColumn(col).width = columnWidth);
            return cell;
        };


        // **Función para aplicar estilos generales**
        const applyStyle = (cell, bgColor, fontColor = "000000", bold = false, fontSize = 12, align = "center") => {
            cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: bgColor } };
            cell.font = { color: { argb: fontColor }, bold: bold, size: fontSize };
            cell.alignment = { horizontal: align, vertical: "middle", wrapText: true };
            cell.border = {
                top: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                bottom: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
            };
        };

        // **Encabezado principal (Título y convenio)**
        // **Combinar y aplicar estilos en A1:F1**
        worksheet.mergeCells("A1:F1");

        const mergedCell = worksheet.getCell("A1");

        // **Asignar el texto nuevamente después de fusionar**
        mergedCell.value = headerPO?.titulo ?? "Título no disponible";

        // **Establecer alineación para centrar completamente**
        mergedCell.alignment = {
            horizontal: "center",
            vertical: "middle",
            wrapText: true
        };

        // **Ajustar la altura de la fila para evitar que se recorte el texto**
        worksheet.getRow(1).height = 30; // Ajusta según necesidad

        // **Aplicar formato de fuente y fondo**
        mergedCell.font = {
            size: 20,
            bold: true,
            color: { argb: "FFFFFF" }
        };

        mergedCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "4472C4" }
        };


        worksheet.getCell("A5").alignment = { horizontal: "center", vertical: "middle", wrapText: true };

        mergeAndSetCellTitle("A2:F2", "ESTRUCTURACIÓN Y EJECUCIÓN DE PROYECTOS");
        applyStyle(worksheet.getCell("A2"), "4472C4", "FFFFFF", true, 14);

        // Aumentar la altura de la fila 2
        worksheet.getRow(2).height = 20; // Ajusta el valor según lo necesario

        worksheet.getCell("A2").alignment = { horizontal: "center", vertical: "middle", wrapText: true };


        mergeAndSetCellTitle("A3:F3", headerPO?.convenio ?? "Convenio no disponible");
        applyStyle(worksheet.getCell("A3"), "F2F2F2", "000000", true, 12);
        worksheet.getCell("A3").alignment = { horizontal: "center", vertical: "middle", wrapText: true };

        mergeAndSetCellTitle("A4:F4", headerPO?.factibilidad ?? "Factibilidad no disponible");
        applyStyle(worksheet.getCell("A4"), "F2F2F2", "000000", false, 12);
        worksheet.getCell("A4").alignment = { horizontal: "center", vertical: "middle", wrapText: true };

        // Aumentar la altura de la fila 4
        worksheet.getRow(4).height = 40; // Ajusta este valor según lo necesites

        // Asegurar que el texto se ajuste dentro del espacio disponible
        worksheet.getCell("A4").alignment = { horizontal: "left", vertical: "middle", wrapText: true };

        // **Información lateral (Código, versión y fecha)**
        mergeAndSetCell("AJ1:AK1", "Código: FM-EEP-22");
        applyStyle(worksheet.getCell("AJ1"), "DAEEF3", "000000", true);

        mergeAndSetCell("AJ2:AK2", "Versión: 04");
        applyStyle(worksheet.getCell("AJ2"), "DAEEF3", "000000", true);

        mergeAndSetCell("AJ3:AK3", "Fecha de publicación: 28-06-2024");
        applyStyle(worksheet.getCell("AJ3"), "DAEEF3", "000000", true);

        mergeAndSetCell("AJ4:AK4", "");
        applyStyle(worksheet.getCell("AJ4"), "DAEEF3");

        // **Segunda parte del encabezado**
        mergeAndSetCell("A5:A5", "TERRITORIO DE INTERVENCIÓN / ZONA PDET:");
        applyStyle(worksheet.getCell("A5"), "DAEEF3", "000000", true);
        worksheet.getCell("A5").alignment = { horizontal: "center", vertical: "middle", wrapText: true };


        mergeAndSetCell("B5:B5", headerPO?.territorio_zona?.replace("TERRITORIO DE INTERVENCION / ZONA PDET: ", "") ?? "No disponible");
        applyStyle(worksheet.getCell("B5"), "F2F2F2");



        mergeAndSetCell("C5:C5", "DURACIÓN:");
        applyStyle(worksheet.getCell("C5"), "DAEEF3", "000000", true);

        mergeAndSetCell("D5:D5", headerPO?.duracion ?? "");
        applyStyle(worksheet.getCell("D5"), "F2F2F2");

        mergeAndSetCell("E5:E5", "FECHA APROBACIÓN POA:");
        applyStyle(worksheet.getCell("E5"), "DAEEF3", "000000", true);

        mergeAndSetCell("F5:F5", headerPO?.children?.[0]?.fecha_aprobacion ?? "");
        applyStyle(worksheet.getCell("F5"), "F2F2F2");

        // **Tercera parte del encabezado**
        mergeAndSetCell("A6:A6", "No. CONTRATO / CONVENIO:");
        applyStyle(worksheet.getCell("A6"), "DAEEF3", "000000", true);
        worksheet.getCell("A6").alignment = { horizontal: "center", vertical: "middle", wrapText: true };


        mergeAndSetCell("B6:B6", headerPO?.children?.[0]?.contrato_convenio ?? "");
        applyStyle(worksheet.getCell("B6"), "F2F2F2");

        mergeAndSetCell("C6:C6", "FECHA DE INICIO:");
        applyStyle(worksheet.getCell("C6"), "DAEEF3", "000000", true);

        mergeAndSetCell("D6:D6", headerPO?.fecha_inicio ?? "");
        applyStyle(worksheet.getCell("D6"), "F2F2F2");

        mergeAndSetCell("E6:E6", "INFORME No.:");
        applyStyle(worksheet.getCell("E6"), "DAEEF3", "000000", true);

        mergeAndSetCell("F6:F6", headerPO?.children?.[0]?.informe_no ?? "");
        applyStyle(worksheet.getCell("F6"), "F2F2F2");

        // **Cuarta parte del encabezado**
        mergeAndSetCell("A7:A7", "EJECUTOR:");
        applyStyle(worksheet.getCell("A7"), "DAEEF3", "000000", true);
        worksheet.getCell("A7").alignment = { horizontal: "center", vertical: "middle", wrapText: true };

        mergeAndSetCell("B7:B7", headerPO?.children?.[0]?.ejecutor ?? "");
        applyStyle(worksheet.getCell("B7"), "F2F2F2");

        mergeAndSetCell("C7:C7", "FECHA DE TERMINACIÓN:");
        applyStyle(worksheet.getCell("C7"), "DAEEF3", "000000", true);

        mergeAndSetCell("D7:D7", headerPO?.fecha_terminacion ?? "");
        applyStyle(worksheet.getCell("D7"), "F2F2F2");

        mergeAndSetCell("E7:E7", "FECHA DE ACTUALIZACIÓN:");
        applyStyle(worksheet.getCell("E7"), "DAEEF3", "000000", true);

        mergeAndSetCell("F7:F7", headerPO?.children?.[0]?.fecha_actualizacion ?? "");
        applyStyle(worksheet.getCell("F7"), "F2F2F2");


        mergeAndSetCell("A8:F8", "");



        // Aplicar negrita a los encabezados
        // worksheet.getRow(1).font = { bold: true };

        // Definir ancho máximo para las columnas A, B, C, D, E, F
        worksheet.getColumn("A").width = 40; // Ajusta según necesidad
        worksheet.getColumn("A").alignment = { horizontal: "center", vertical: "top", wrapText: true }; // Ajustar texto automáticamente
        worksheet.getColumn("B").width = 40;
        worksheet.getColumn("B").alignment = { horizontal: "left", vertical: "top", wrapText: true }; // Ajustar texto automáticamente


        worksheet.getColumn("C").width = 25;
        worksheet.getColumn("D").width = 25;
        worksheet.getColumn("E").width = 25;
        worksheet.getColumn("F").width = 25;

        let rowIndex = 9; // Índice de fila para comenzar a insertar datos


        // **Recorrer las fases**
        fasesResult.forEach((fase, index) => {

            const { headerRow1, headerRow2, mesesPorAnio } = getCronogramaHeaders(fase);
            const totalColumns = headerRow1.length;
            worksheet.getColumn(totalColumns - 2).width = 15; // FECHA FIN REAL
            worksheet.getColumn(totalColumns - 1).width = 30; // MEDIO DE VERIFICACIÓN
            worksheet.getColumn(totalColumns).width = 40; // RUTA SOPORTES
            const lastColumnLetter = worksheet.getColumn(totalColumns).letter;

            // Agregar una fila para la Fase
            const faseRow = worksheet.addRow([`${fase.nombre_corto}`]);
            faseRow.getCell(1).font = { bold: true, size: 14 }; // Resaltar fase
            faseRow.getCell(1).alignment = { horizontal: "left", vertical: "middle" };
            faseRow.getCell(1).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "A6A6A6" } };

            // Unir todas las columnas en la fila de la fase
            worksheet.mergeCells(`A${rowIndex}:${lastColumnLetter}${rowIndex}`);
            const mergedCell = worksheet.getCell(`A${rowIndex}`);
            mergedCell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "A6A6A6" } };
            rowIndex++;

            // **Agregar encabezados dinámicos (headerRow1 y headerRow2)**
            const headerRowMain = worksheet.addRow(headerRow1);

            worksheet.getRow(headerRowMain.number).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "4472C4" } // Azul oscuro para encabezado principal
            };
            worksheet.getRow(headerRowMain.number).font = { color: { argb: "FFFFFF" }, bold: true };


            const headerRowSub = worksheet.addRow(headerRow2);

            // **Aplicar estilos a headerRow1**
            headerRowMain.font = { bold: true };
            // Aplicar fondo azul
            headerRowMain.eachCell((cell) => {
                cell.alignment = { wrapText: true, horizontal: "center", vertical: "middle" };
                cell.fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "4472C4" } // Azul oscuro
                };
                cell.font = { color: { argb: "FFFFFF" }, bold: true }; // Texto blanco
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });

            headerRowSub.font = { bold: true };
            headerRowSub.eachCell((cell) => {
                cell.alignment = { wrapText: true, horizontal: "center", vertical: "middle" };
                cell.fill = { type: "pattern", pattern: "solid", fgColor: { argb: "BFBFBF" } }; // Color gris claro
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });

            let colIndex = 1;
            let currentYear = null;
            // let startCol = 1;

            // **Fusionar celdas de los años**

            let startCol = null;  // Columna donde comienza cada sección
            let currentHeader = null;  // Nombre del encabezado actual

            headerRow1.forEach((value, colIndex) => {
                // Detectar el inicio de un nuevo encabezado
                if (value.startsWith("CRONOGRAMA")) {
                    // Si ya había un bloque previo, fusionar antes de actualizar
                    if (currentHeader !== null && startCol !== null) {
                        worksheet.mergeCells(headerRowMain.number, startCol + 1, headerRowMain.number, (colIndex + 1) - 1);
                    }
                    // Actualizar valores para el nuevo bloque
                    currentHeader = value;
                    startCol = colIndex;
                }
            });
            headerRow1.forEach((_, colIndex) => {
                const columnIndex = colIndex + 7; // Ajuste del índice de la columna
                worksheet.getColumn(columnIndex).width = (columnIndex >= 7 && columnIndex <= 31) ? 10 : 30;
            });

            // Asegurar la última fusión para el último bloque
            if (currentHeader !== null && startCol !== null) {
                worksheet.mergeCells(headerRowMain.number, startCol + 1, headerRowMain.number, (headerRow1.length - 5) - 1);
                worksheet.mergeCells(headerRowMain.number, headerRow1.length - 5, headerRowMain.number, (headerRow1.length - 3) - 1);

            }
            // Fusionar la última sección del año
            worksheet.getRow(rowIndex).height = 40; // Ajustar altura de la fila principal de encabezado
            worksheet.getRow(rowIndex).height = 35; // Ajustar altura de la segunda fila del encabezado
            rowIndex += 2; // Avanzar 2 filas porque agregamos dos encabezados
            let maxAlturaActividad = 20; // Altura base mínima

            // **Recorrer actividades dentro de la fase**
            fase.actividades.forEach((actividad) => {
                const actividadStartRow = rowIndex;
                let actividadWritten = false;
                actividad.children?.forEach((tarea) => {
                    const tareaStartRow = rowIndex;
                    let tareaWritten = false;
                    let indicadorWritten = false;

                    // **Recorrer indicadores dentro de la tarea**
                    tarea.children?.forEach((indicador) => {
                        const indicadorStartRow = rowIndex;
                        const valoresPorcentaje = {};
                        let totalE = 0;
                        let totalP = 0;
                        let progresoEPTotal = 0;

                        // Calcular el porcentaje de avance para cada mes
                        for (const anio in mesesPorAnio) {
                            if (mesesPorAnio.hasOwnProperty(anio)) {
                                const meses = mesesPorAnio[anio]; // Array de meses para el año actual
                                meses.forEach(mes => {
                                    const registroP = indicador.cronogramasP.find(item => item.ano === anio && item.mes === mes);
                                    const registroE = indicador.cronogramasE.find(item => item.ano === anio && item.mes === mes);
                                    const avanceP = Math.floor(Number(registroP?.avance) || 0);
                                    const avanceE = Math.floor(Number(registroE?.avance) || 0);
                                    const progresoPE = avanceP > 0 ? (avanceE / avanceP) * 100 : 0;
                                    valoresPorcentaje[`${anio}-${mes}`] = `${Math.round(progresoPE)}%`;
                                });
                            }
                        }
                        // Recorrer los indicadores para obtener los valores de avance
                        ["P", "E"].forEach((tipoPE) => {
                            const valoresPorAnio = {};
                            indicador[`cronogramas${tipoPE}`]?.forEach((item) => {
                                if (!valoresPorAnio[item.ano]) {
                                    valoresPorAnio[item.ano] = {};
                                }
                                valoresPorAnio[item.ano][item.mes] = Math.round(item.avance);
                            });
                            const rowValues = [];
                            const porcentajeCells = [];
                            let control = 1;
                            Object.keys(mesesPorAnio).sort().forEach((anio) => {
                                mesesPorAnio[anio].forEach((mes) => {
                                    const valor = valoresPorAnio[anio]?.[mes] || 0;
                                    const porcentaje = valoresPorcentaje[`${anio}-${mes}`] || 0;
                                    if (tipoPE === "P") {
                                        totalP += parseFloat(valor);
                                        rowValues.push(valor);
                                        rowValues.push("");
                                    } else {
                                        totalE += parseFloat(valor);
                                        rowValues.push(valor);
                                        rowValues.push(porcentaje);
                                        porcentajeCells.push(rowValues.length);
                                        control++;
                                    }
                                });
                            });
                            if (tipoPE === "E") {
                                rowValues.push(totalE);
                                progresoEPTotal = totalP > 0 ? `${Math.floor((totalE / totalP) * 100)}%` : "0%";
                                rowValues.push(progresoEPTotal);
                                porcentajeCells.push(rowValues.length);
                            } else {
                                rowValues.push(totalP);
                                rowValues.push("");
                            }
                            const medioVerificacion = indicador.medios_verificacion[0].medio_verificacion;
                            const row = worksheet.addRow([
                                !actividadWritten ? `${actividad.codigo_actividad} - ${actividad.descripcion}` : "",
                                !tareaWritten ? `${tarea.codigo_tarea} - ${tarea.descripcion}` : "",
                                !indicadorWritten ? `${indicador.indicador}` : "",
                                indicador.meta,
                                indicador.fecha_inicio,
                                indicador.fecha_fin,
                                tipoPE,
                                ...rowValues
                            ]);

                            // Fusionar celdas de la actividad
                            if (tipoPE === "E") {
                                porcentajeCells.forEach((colIndexProgres) => {
                                    const colLetter = worksheet.getColumn(colIndexProgres + 7).letter;
                                    const mergeRange = `${colLetter}${rowIndex - 1}:${colLetter}${(rowIndex + 1) - 1}`;
                                    worksheet.mergeCells(mergeRange);
                                    const valor = rowValues[colIndexProgres - 1]; // Obtenemos el valor de la celda correspondiente
                                    worksheet.getCell(`${colLetter}${rowIndex - 1}`).value = valor;  // Asignar el valor a la celda fusionada
                                    worksheet.getCell(`${colLetter}${rowIndex - 1}`).alignment = {
                                        vertical: "middle",
                                        horizontal: "center"
                                    };
                                });
                            }
                            const lastColIndexProgres = porcentajeCells.at(-1); // Obtener el último elemento
                            if (tipoPE === "E" && lastColIndexProgres !== undefined) {
                                let valores = fase.cod_Fase !== 3
                                    ? [tarea.pesoPorcentual + "%", indicador.fecha_f_real, medioVerificacion, indicador.ruta]
                                    : [indicador.fecha_f_real, medioVerificacion, indicador.ruta];

                                asignarValoresEnExcel(worksheet, valores, lastColIndexProgres, rowIndex);
                            }

                            function asignarValoresEnExcel(worksheet, valores, lastColIndexProgres, rowIndex) {
                                valores.forEach((valor, i) => {
                                    const colLetter = worksheet.getColumn(lastColIndexProgres + 8 + i).letter;
                                    const mergeRange = `${colLetter}${rowIndex - 1}:${colLetter}${rowIndex}`;
                                    worksheet.mergeCells(mergeRange);
                                    worksheet.getCell(`${colLetter}${rowIndex - 1}`).value = valor;
                                });
                            }
                            row.eachCell((cell, colNumber) => {
                                const alignment = colNumber <= 3 ? "left" : "center";
                                cell.alignment = { wrapText: true, vertical: "middle", horizontal: alignment };
                                if (colNumber === 2) {
                                    cell.fill = {
                                        type: "pattern",
                                        pattern: "solid",
                                        fgColor: { argb: "DAEEF3" } // Azul claro
                                    };
                                }
                                cell.border = {
                                    top: { style: "thin" },
                                    left: { style: "thin" },
                                    bottom: { style: "thin" },
                                    right: { style: "thin" }
                                };
                            });

                            actividadWritten = true;
                            tareaWritten = true;
                            indicadorWritten = true;
                            rowIndex++;
                        });

                        // Fusionar celdas de la actividad
                        worksheet.mergeCells(`B${indicadorStartRow}:B${rowIndex - 1}`); // Tarea
                        worksheet.mergeCells(`C${indicadorStartRow}:C${rowIndex - 1}`); // Indicador
                        worksheet.mergeCells(`D${indicadorStartRow}:D${rowIndex - 1}`); // Meta
                        worksheet.mergeCells(`E${indicadorStartRow}:E${rowIndex - 1}`); // Fecha Inicio
                        worksheet.mergeCells(`F${indicadorStartRow}:F${rowIndex - 1}`); // Fecha Fin
                        ["B", "C"].forEach(col => {
                            worksheet.getCell(`${col}${indicadorStartRow}`).alignment = {
                                vertical: "middle", horizontal: "left", wrapText: true
                            };
                        });
                    });

                    if (tarea.children.length > 1) {
                        //worksheet.mergeCells(`B(${tareaStartRow}):B${rowIndex - 1}`);
                        worksheet.getCell(`B${tareaStartRow}`).alignment = { vertical: "middle", horizontal: "center", wrapText: true };
                    }
                });

                if (actividad.children.length > 1) {
                    worksheet.mergeCells(`A${actividadStartRow}:A${rowIndex - 1}`);
                    const cellActividad = worksheet.getCell(`A${actividadStartRow}`);
                    cellActividad.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
                    cellActividad.font = { bold: true };
                    for (let i = actividadStartRow; i < rowIndex; i++) {
                        worksheet.getRow(i).height = maxAlturaActividad;
                    }
                } else {
                    worksheet.getCell(`A${actividadStartRow}`).font = { bold: true };
                }
            });
        });

        //Porcentajes de avance
        let headers = [];
        if (valoresJsonPeso && valoresJsonPeso.fases && valoresJsonPeso.fases.length > 0) {
            headers = Object.keys(valoresJsonPeso.fases[0].p);
        }

        const data = {
            "Fase de Alistamiento": { p: {}, e: {} },
            "Fase de ejecución": { p: {}, e: {} },
            "Total Avance Proyecto": { p: {}, e: {} }
        };

        valoresJsonPeso.fases.forEach(fase => {
            const faseName = fase.fase === "1" ? "Fase de Alistamiento" : "Fase de ejecución";
            headers.forEach(header => {
                data[faseName].p[header] = fase.p[header] || 0;
                data[faseName].e[header] = fase.e[header] || 0;
            });
        });

        headers.forEach(header => {
            data["Total Avance Proyecto"].p[header] = (data["Fase de Alistamiento"].p[header] || 0) + (data["Fase de ejecución"].p[header] || 0);
            data["Total Avance Proyecto"].e[header] = (data["Fase de Alistamiento"].e[header] || 0) + (data["Fase de ejecución"].e[header] || 0);
        });

        let row = rowIndex + 2; // Espacio entre la tabla de cronograma y la tabla de avance

        Object.keys(data).forEach(phase => {
            // Desplazar el nombre de la fase a la columna F (6)
            worksheet.getCell(row, 6).value = phase;
            worksheet.getCell(row, 6).font = { bold: true, size: 12 };
            worksheet.getCell(row, 6).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "A6A6A6" } };
            worksheet.getCell(row, 6).alignment = { horizontal: "center", vertical: "middle" };
            row++;

            // **Encabezados de mes/año combinados**
            headers.forEach((header, index) => {
                worksheet.mergeCells(row, index * 2 + 7, row, index * 2 + 8);
                worksheet.getCell(row, index * 2 + 7).value = header;
                worksheet.getCell(row, index * 2 + 7).alignment = { horizontal: "center", vertical: "middle" };
                worksheet.getCell(row, index * 2 + 7).font = { bold: true, color: { argb: "FFFFFF" } };
                worksheet.getCell(row, index * 2 + 7).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "4472C4" } };
                worksheet.getCell(row, index * 2 + 7).border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
            row++;

            // **Encabezados P/E**
            headers.forEach((_, index) => {
                worksheet.getCell(row, index * 2 + 7).value = "P";
                worksheet.getCell(row, index * 2 + 8).value = "E";
                worksheet.getCell(row, index * 2 + 7).alignment = { horizontal: "center" };
                worksheet.getCell(row, index * 2 + 8).alignment = { horizontal: "center" };
                worksheet.getCell(row, index * 2 + 7).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "808080" } }; // Gris oscuro
                worksheet.getCell(row, index * 2 + 8).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "808080" } }; // Gris oscuro
                worksheet.getCell(row, index * 2 + 7).border = worksheet.getCell(row, index * 2 + 8).border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
            row++;

            // **Datos**
            headers.forEach((header, index) => {
                const faseName = phase === "Fase de Alistamiento" || phase === "Fase de ejecución" ? phase : "Total Avance Proyecto";
                worksheet.getCell(row, index * 2 + 7).value = (data[faseName].p[header] || 0).toFixed(0) + "%";
                worksheet.getCell(row, index * 2 + 8).value = (data[faseName].e[header] || 0).toFixed(0) + "%";
                worksheet.getCell(row, index * 2 + 7).alignment = { horizontal: "center" };
                worksheet.getCell(row, index * 2 + 8).alignment = { horizontal: "center" };
                worksheet.getCell(row, index * 2 + 7).border = worksheet.getCell(row, index * 2 + 8).border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
            row++;

            // **Total Avance**
            worksheet.getCell(row, 6).value = "TOTAL AVANCE";
            worksheet.getCell(row, 6).font = { bold: true, size: 12 };
            worksheet.getCell(row, 6).fill = { type: "pattern", pattern: "solid", fgColor: { argb: "A6A6A6" } };
            worksheet.getCell(row, 6).alignment = { horizontal: "center", vertical: "middle" };

            const acumuladosP = headers.map(header => {
                const faseName = phase === "Fase de Alistamiento" || phase === "Fase de ejecución" ? phase : "Total Avance Proyecto";
                return data[faseName].p[header];
            }).reduce((acc, val) => {
                acc.push((acc.length > 0 ? acc[acc.length - 1] : 0) + (val || 0));
                return acc;
            }, []);

            const acumuladosE = headers.map(header => {
                const faseName = phase === "Fase de Alistamiento" || phase === "Fase de ejecución" ? phase : "Total Avance Proyecto";
                return data[faseName].e[header];
            }).reduce((acc, val) => {
                acc.push((acc.length > 0 ? acc[acc.length - 1] : 0) + (val || 0));
                return acc;
            }, []);

            headers.forEach((header, index) => {
                worksheet.getCell(row, index * 2 + 7).value = acumuladosP[index].toFixed(0) + "%";
                worksheet.getCell(row, index * 2 + 8).value = acumuladosE[index].toFixed(0) + "%";
                worksheet.getCell(row, index * 2 + 7).alignment = { horizontal: "center" };
                worksheet.getCell(row, index * 2 + 8).alignment = { horizontal: "center" };
                worksheet.getCell(row, index * 2 + 7).border = worksheet.getCell(row, index * 2 + 8).border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
            row++;

            row++; // Espacio entre fases
        });



        // **Generar archivo**
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, "Plan_Operativo_Anual.xlsx");

    };


    return (
        <div>
            <Button
                type="primary"
                onClick={exportToExcel}
                disabled={loading || fasesResult.length === 0}
                icon={<FileExcelOutlined />} // Agrega el icono de Excel
            >
                Descargar
            </Button>
        </div>
    );
};

export default ExportPoa;
