import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { EnvironmentOutlined, DashboardOutlined, FolderOpenOutlined } from '@ant-design/icons';
import ColombiaMapComponent from '../Maps/MapsInicial';
import DashboardInicial from '../DashboardInicial/DashboardInicial';
import RepositorioDocumento from './Repositorio/RepositorioDocumento_old2';


function Inicio() {
    const [selectedComponent, setSelectedComponent] = useState('map');
    const [openDrawer, setOpenDrawer] = useState(false);


    const handleOpenRepositorio = () => {
        setOpenDrawer(true);
    };

    const onCloseDrawable = () => {
        setOpenDrawer(false);
      };

    return (
        <>
            <div style={{ textAlign: 'center', padding: '10px' }}>
                <Space style={{ marginBottom: '20px' }}>
                    <Button
                        type="primary"
                        icon={<EnvironmentOutlined style={{ fontSize: '20px' }} />}
                        onClick={() => setSelectedComponent('map')}
                        style={{ width: '120px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        Mapa
                    </Button>
                    <Button
                        type="primary"
                        icon={<DashboardOutlined style={{ fontSize: '20px' }} />}
                        onClick={() => setSelectedComponent('dashboard')}
                        style={{ width: '120px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        Dashboard
                    </Button>
                    <Button
                        type="primary"
                        icon={<FolderOpenOutlined style={{ fontSize: '20px' }} />}
                        onClick={handleOpenRepositorio}
                        style={{ display: 'none !important', width: 'auto', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        Repositorio documental
                    </Button>

                </Space>
                <div id="exportable-section">
                    {selectedComponent === 'map' && <ColombiaMapComponent />}
                    {selectedComponent === 'dashboard' && <DashboardInicial />}
                    {<RepositorioDocumento open={openDrawer} onCloseMetodo={onCloseDrawable}  />}
                </div>
            </div>
        </>
    );
}

export default Inicio;
