import axios from 'axios';
import config from '../../../../../config'; // Configuración para obtener la URL base

const baseUrl = config?.baseUrl || ''; // Validación de URL base
const endpoint = '/api/zonas-proyectos-poa';
const API_URL = `${baseUrl}${endpoint}`;

// Obtener las zonas-proyectos filtradas por `proyecto`
export const getZonasProyectosPOA = async (proyecto) => {
  try {
    const response = await axios.get(API_URL, {
      params: { proyecto }, // Envía `proyecto` como parámetro de consulta (query)
      timeout: 5000, // Evita que la petición quede colgada
    });
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error(`Error en la petición: ${error.response?.status} - ${error.message}`);
      throw new Error(error.response?.data?.message || 'Error al obtener los datos');
    }
    console.error('Error desconocido:', error);
    throw new Error('Ocurrió un error inesperado');
  }
};
