import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import {
    HomeOutlined,
    CloudSyncOutlined,
    TeamOutlined,
    BarChartOutlined,
    GlobalOutlined,
    AppstoreAddOutlined,
    FilePdfOutlined,
    LogoutOutlined,
    ApartmentOutlined, BorderInnerOutlined,
    ShopOutlined,
    SolutionOutlined,
    ShoppingCartOutlined,
    LineChartOutlined,
    UsergroupAddOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { logout, fetchApplications } from '../../store/auth/authSlice';
import { removeToken, removeUsername, removeApplications } from '../../store/auth/cookieUtils';

import './NavBodecom.css';

const NavBodecom = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { applications, isAuthenticated } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isAuthenticated && applications.length === 0) {
            dispatch(fetchApplications());
        }
    }, [isAuthenticated, applications, dispatch]);

    const handleLogout = async () => {
        try {
            removeToken();
            removeUsername();
            removeApplications();
            await dispatch(logout());
            navigate('/');
        } catch (error) {
            console.error('Error durante el logout:', error);
        }
    };

    const menuItems = [
        { path: '/inicio', icon: <HomeOutlined />, label: 'Inicio', appName: 'inicio' },
        {
            label: 'Producto 1', appName: 'producto_1',
            children: [
                { path: '/buscar-proyecto', icon: <CloudSyncOutlined />, label: 'Actualización', appName: 'buscar-proyecto' },
                { path: '/es_proyectos', icon: <ApartmentOutlined />, label: 'Estructuración', appName: 'es_proyectos' },
            ],
        },
        {
            label: 'Producto 2', appName: 'producto_2',
            children: [
                {
                    label: 'Implementación',
                    children: [
                        { path: '/planOperativo', icon: <TeamOutlined />, label: 'FAO', appName: 'planOperativo' },
                        { path: '#', icon: <TeamOutlined />, label: 'Organizaciones', appName: '', disabled: true },

                    ],
                },
            ],
        },
        {
            label: 'Producto 3', appName: 'producto_3',
            children: [
                { path: '/menu-curso-fortalecimiento', icon: <BarChartOutlined />, label: 'Fort. Capacidades', appName: 'menu-curso-fortalecimiento', disabled: false },


            ],
        },
        {
            label: 'Información General', appName: 'info_general',
            children: [
                { path: '/participantes', icon: <UsergroupAddOutlined />, label: 'Participantes', appName: 'participantes' }, 
                { path: '/organizaciones', icon: <ShopOutlined />, label: 'Socioempresarial (Organizacional)', appName: 'organizaciones' }, 
                { path: '/acuerdos-comerciales', icon: <ShoppingCartOutlined />, label: 'Comercial', appName: 'acuerdos-comerciales' },
                { path: '/caracterizacion', icon: <SolutionOutlined />, label: 'Caracterización', appName: 'caracterizacion' }, 
                { path: '/sistematizacionIppta', icon: <BarChartOutlined />, label: 'IPPTA', appName: 'sistematizacionIppta' }, 
                { path: '/encuestaEdah', icon: <LineChartOutlined />, label: 'Encuesta EDAH', appName: 'encuesta_EDAH' }, 
                { path: '/enfoqueGenero', icon: <TeamOutlined />, label: 'Enfoques Diferenciales', appName: 'enfoqueGenero' }, 
            ],
        },
        {
            label: 'Seguimiento', appName: 'seguimiento',
            children: [
                { path: '/poa', icon: <AppstoreAddOutlined />, label: 'POA', appName: 'poa' },
                { path: '/reportes', icon: <FilePdfOutlined />, label: 'Informes', appName: 'reportes' },
            ],
        },
        { path: '/app-slider', icon: <GlobalOutlined />, label: 'Configuración', appName: 'app-slider' },
    ];

    const userApplications = applications.map((app) => app.app_name);
    const renderMenuItems = (items) =>
        items.map((item) => {
            if (item.children) {
                return (
                    <NavDropdown
                        key={item.label}
                        title={item.label}
                        id={`dropdown-${item.label}`}
                        className="nav-dropdown"
                    >
                        {item.children.map((child) => {
                            if (child.children) {
                                return (
                                    <NavDropdown
                                        key={child.label}
                                        title={child.label}
                                        id={`nested-dropdown-${child.label}`}
                                        className="dropdown-menu-end nested-dropdown"
                                    >
                                        {child.children.map((nestedChild) => (
                                            <NavDropdown.Item
                                                key={nestedChild.path}
                                                as={Link}
                                                to={nestedChild.path}
                                                disabled={!userApplications.includes(nestedChild.appName)}
                                                className={!userApplications.includes(nestedChild.appName) ? 'disabled-link' : ''}
                                            >
                                                {nestedChild.icon} {nestedChild.label}
                                            </NavDropdown.Item>
                                        ))}
                                    </NavDropdown>
                                );
                            }
                            return (
                                <NavDropdown.Item
                                    key={child.path}
                                    as={Link}
                                    to={child.path}
                                    disabled={!userApplications.includes(child.appName)}
                                    className={!userApplications.includes(child.appName) ? 'disabled-link' : ''}
                                >
                                    {child.icon} {child.label}
                                </NavDropdown.Item>
                            );
                        })}
                    </NavDropdown>
                );
            }
            return (
                <Nav.Link
                    key={item.path}
                    as={!userApplications.includes(item.appName) || item.disabled ? 'span' : Link} // Cambia a <span> si está deshabilitado
                    to={
                        !userApplications.includes(item.appName) || item.disabled
                            ? undefined // No asigna un destino si está deshabilitado
                            : item.path
                    }
                    className={`nav-link ${!userApplications.includes(item.appName) || item.disabled ? 'disabled-link' : ''
                        }`} // Aplica la clase deshabilitada si corresponde
                >
                    {item.icon} {item.label}
                </Nav.Link>
            );

        });

    return (
        <Navbar bg="light" expand="lg" className="mb-4 full-width-navbar pl-5">
            <div className="container-fluid">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {applications.length === 0 ? (
                            <div>Loading applications...</div>
                        ) : (
                            renderMenuItems(menuItems)
                        )}
                        <Nav.Link onClick={handleLogout} className="nav-link">
                            <LogoutOutlined /> Salir
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};

export default NavBodecom;
