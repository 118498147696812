import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Modal, Popconfirm, message, Card, Tooltip, Statistic, Row, Col, Progress, Divider } from "antd";
import { PlusOutlined, EditOutlined, ArrowLeftOutlined, DiffOutlined, InfoOutlined, DeleteOutlined } from "@ant-design/icons";
import "bootstrap/dist/css/bootstrap.min.css";
import Header160 from "../../header/header";
import FormularioCohorte from "./FormularioCohorte";
import { getCohorte, deleteCohorte } from "../../../services/Producto_3/Uniminuto/uniminutosServices"
import ExportToExcelCohortes from "./ExcelCohortes"
import ExcelDetalleCohorte from "./ExcelDetalleCohorte"
import "./Uniminuto.css"
import RadarChart from "../../poaReporte/Graficos/RadarChart";
import { useNavigate } from "react-router-dom";



function AnimatedProgress({
    type = "circle",
    finalValue = 0,
    format,
    strokeColor,
    progressInfoPosition
}) {
    const [currentValue, setCurrentValue] = React.useState(0);

    React.useEffect(() => {
        // Reiniciamos a 0 cada vez que 'finalValue' cambie
        setCurrentValue(0);

        let tempValue = 0;
        const interval = setInterval(() => {
            tempValue += 1;         // Incrementa de 1 en 1
            if (tempValue >= finalValue) {
                tempValue = finalValue;
                clearInterval(interval);
            }
            setCurrentValue(tempValue);
        }, 15); // ~15 ms para ~66fps

        return () => clearInterval(interval);
    }, [finalValue]); // Cuando cambie finalValue, reinicia y re-aniima

    return (
        <Progress
            type={type}
            percent={currentValue}
            format={format}
            strokeColor={strokeColor}
            progressInfoPosition={progressInfoPosition}
        />
    );
}


const Uniminuto = () => {

    const navigate = useNavigate();
    const modalRef = useRef();
    // Estado para la tabla
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [fullText, setFullText] = useState("");
    const [loading, setLoading] = useState(false);
    const [resumen, setResumen] = useState(false);

    // Estado para almacenar los indicadores de sesiones y participación
    const [indicadores, setIndicadores] = useState({

        // Número total de cohortes registradas
        numCohortes: 0,

        // Conteo de sesiones por modalidad
        numSesionesVirtuales: 0,
        numSesionesAutonomas: 0,
        numSesionesPresenciales: 0,

        // Total de participantes por modalidad
        totalParticipantesPresenciales: 0,
        totalParticipantesVirtuales: 0,
        totalParticipantesAutonomas: 0,

        // Porcentaje de participación total de hombres y mujeres en todas las sesiones
        porc_Htot: 0,
        porc_Mtot: 0,

        // Participación de mujeres y hombres en sesiones presenciales
        participacionMujeresPresenciales: 0,
        participacionHombresPresenciales: 0,

        // Participación de mujeres y hombres en sesiones virtuales
        participacionMujeresVirtuales: 0,
        participacionHombresVirtuales: 0,

        // Participación de mujeres y hombres en sesiones autónomas
        participacionMujeresAutonomas: 0,
        participacionHombresAutonomas: 0,

        // Total de sesiones realizadas
        numSesiones: 0,

        // Total de asistentes por género en todas las modalidades
        asistentesHombres: 0,
        asistentesMujeres: 0,

        // Asistentes en sesiones virtuales por género
        asistentesHombresV: 0,
        asistentesMujeresV: 0,

        // Porcentaje de asistentes en sesiones virtuales por género
        porc_asistentesHombresV: 0,
        porc_asistentesMujeresV: 0,

        // Asistentes en sesiones presenciales por género
        asistentesHombresP: 0,
        asistentesMujeresP: 0,

        // Porcentaje de asistentes en sesiones presenciales por género
        porc_asistentesHombresP: 0,
        porc_asistentesMujeresP: 0,

        // Asistentes en sesiones autónomas por género
        asistentesHombresA: 0,
        asistentesMujeresA: 0,

        // Porcentaje de asistentes en sesiones autónomas por género
        porc_asistentesHombresA: 0,
        porc_asistentesMujeresA: 0,
    });


    // Carga de Datos en la tabla
    const fetchData = async () => {
        setLoading(true); // Inicia el loading

        try {
            const response = await getCohorte();
            console.log("response",response)
            if (response.status === 200 && response.success) {

                // Transformamos los datos para la tabla
                const transformedData = response.data.map((reg) => {
                    const jsonData = reg.JSONDataCohorte;

                    const sesionesVirtuales = jsonData.detalleSesiones.filter(sesion => sesion.modalidadSesion === "Virtual") || [];
                    const sesionesAutonomas = jsonData.detalleSesiones.filter(sesion => sesion.modalidadSesion === "Autónomo") || [];
                    const sesionesPresenciales = jsonData.detalleSesiones.filter(sesion => sesion.modalidadSesion === "Presencial") || [];


                    const numSesiones = sesionesPresenciales.length + sesionesVirtuales.length + sesionesAutonomas.length;

                    const totalAsistentes =
                        sesionesVirtuales.reduce((acc, ses) => acc + Number(ses.asistentes || 0), 0) +
                        sesionesAutonomas.reduce((acc, ses) => acc + Number(ses.asistentes || 0), 0) +
                        sesionesPresenciales.reduce((acc, ses) => acc + Number(ses.asistentes || 0), 0);



                    const asistentesHombres = sesionesVirtuales.reduce(
                        (acc, ses) => acc + Number(ses.hombres || 0),
                        0
                    ) + sesionesAutonomas.reduce(
                        (acc, ses) => acc + Number(ses.hombres || 0),
                        0
                    ) + sesionesPresenciales.reduce(
                        (acc, ses) => acc + Number(ses.hombres || 0),
                        0
                    );



                    const asistentesMujeres = sesionesVirtuales.reduce(
                        (acc, ses) => acc + Number(ses.mujeres || 0),
                        0
                    ) + sesionesAutonomas.reduce(
                        (acc, ses) => acc + Number(ses.mujeres || 0),
                        0
                    ) + sesionesPresenciales.reduce(
                        (acc, ses) => acc + Number(ses.mujeres || 0),
                        0
                    );

                    // Calculamos los totales específicos para cada tipo de sesión
                    const asistentesHombresP = sesionesPresenciales.reduce((acc, ses) => acc + Number(ses.hombres || 0), 0);
                    const asistentesMujeresP = sesionesPresenciales.reduce((acc, ses) => acc + Number(ses.mujeres || 0), 0);

                    const asistentesHombresV = sesionesVirtuales.reduce((acc, ses) => acc + Number(ses.hombres || 0), 0);
                    const asistentesMujeresV = sesionesVirtuales.reduce((acc, ses) => acc + Number(ses.mujeres || 0), 0);
                    const asistentesHombresA = sesionesAutonomas.reduce((acc, ses) => acc + Number(ses.hombres || 0), 0);
                    const asistentesMujeresA = sesionesAutonomas.reduce((acc, ses) => acc + Number(ses.mujeres || 0), 0);

                    const totalPV = asistentesHombresV + asistentesMujeresV;
                    const porcentajeHombresV = totalPV > 0 ? ((asistentesHombresV / totalPV) * 100).toFixed(2) : "0.00";
                    const porcentajeMujeresV = totalPV > 0 ? ((asistentesMujeresV / totalPV) * 100).toFixed(2) : "0.00";

                    const totalPA = asistentesHombresA + asistentesMujeresA;
                    const porcentajeHombresA = totalPA > 0 ? ((asistentesHombresA / totalPA) * 100).toFixed(2) : "0.00";
                    const porcentajeMujeresA = totalPA > 0 ? ((asistentesMujeresA / totalPA) * 100).toFixed(2) : "0.00";

                    const porcentajeasistentesHombres =
                        totalAsistentes > 0
                            ? ((asistentesHombres / totalAsistentes) * 100).toFixed(2)
                            : "0.00";
                    const porcentajeasistentesMujeres =
                        totalAsistentes > 0
                            ? ((asistentesMujeres / totalAsistentes) * 100).toFixed(2)
                            : "0.00";



                    // Porcentajes por tipo de sesión
                    const calcularPorcentaje = (total, valor) => (total > 0 ? ((valor / total) * 100).toFixed(2) : "0.00");

                    const porcentajeHombresP = calcularPorcentaje(asistentesHombresP + asistentesMujeresP, asistentesHombresP);
                    const porcentajeMujeresP = calcularPorcentaje(asistentesHombresP + asistentesMujeresP, asistentesMujeresP);


                    return {
                        key: reg.oid.toString(),
                        cohorte: jsonData.cohorte,
                        resumen: jsonData.resumen,
                        numSesiones,
                        totalAsistentes,
                        asistentesHombres,
                        asistentesMujeres,
                        porcentajeasistentesHombres,
                        porcentajeasistentesMujeres,

                        numSesionesVirtuales: sesionesVirtuales.length,
                        numSesionesAutonomas: sesionesAutonomas.length,
                        numSesionesPresenciales: sesionesPresenciales.length,  // ✅ Agregar esto

                        // Agregar datos de sesiones presenciales
                        asistentesHombresP,
                        asistentesMujeresP,
                        porc_asistentesHombresP: porcentajeHombresP,
                        porc_asistentesMujeresP: porcentajeMujeresP,


                        // Agregamos los nuevos campos:
                        asistentesHombresV,
                        asistentesMujeresV,
                        porc_asistentesHombresV: porcentajeHombresV,
                        porc_asistentesMujeresV: porcentajeMujeresV,
                        asistentesHombresA,
                        asistentesMujeresA,

                        porc_asistentesHombresA: porcentajeHombresA,
                        porc_asistentesMujeresA: porcentajeMujeresA,

                        rawData: reg,

                    };
                });
                console.log("transformedData", transformedData)
                setData(transformedData);


                // Calculamos los indicadores globales a partir de la respuesta original
                let global = {
                    numCohortes: 0,

                    // Número de sesiones por tipo
                    numSesionesVirtuales: 0,
                    numSesionesAutonomas: 0,
                    numSesionesPresenciales: 0,
                    numSesiones: 0,

                    // Total de participantes por tipo de sesión
                    totalParticipantesVirtuales: 0,
                    totalParticipantesAutonomas: 0,
                    totalParticipantesPresenciales: 0,

                    // Totales de asistentes por género
                    asistentesHombres: 0,
                    asistentesMujeres: 0,

                    // Totales de asistentes por género en cada tipo de sesión
                    sumaHombresVirtuales: 0,
                    sumaMujeresVirtuales: 0,

                    sumaHombresAutonomas: 0,
                    sumaMujeresAutonomas: 0,

                    sumaHombresPresenciales: 0,
                    sumaMujeresPresenciales: 0,

                    // Porcentajes de asistentes por género
                    porcentajeasistentesHombres: 0,
                    porcentajeasistentesMujeres: 0,

                    porcentajeasistentesHombresV: 0,
                    porcentajeasistentesMujeresV: 0,

                    porcentajeasistentesHombresA: 0,
                    porcentajeasistentesMujeresA: 0,

                    porcentajeasistentesHombresP: 0,
                    porcentajeasistentesMujeresP: 0,
                };


                response.data.forEach((reg) => {
                    global.numCohortes += 1;
                    const jsonData = reg.JSONDataCohorte;

                    // Filtrar sesiones por tipo
                    const sesionesVirtuales = jsonData.detalleSesiones.filter(sesion => sesion.modalidadSesion === "Virtual") || [];
                    const sesionesAutonomas = jsonData.detalleSesiones.filter(sesion => sesion.modalidadSesion === "Autónomo") || [];
                    const sesionesPresenciales = jsonData.detalleSesiones.filter(sesion => sesion.modalidadSesion === "Presencial") || [];

                    // Sumar número de sesiones por tipo
                    global.numSesionesVirtuales += sesionesVirtuales.length;
                    global.numSesionesAutonomas += sesionesAutonomas.length;
                    global.numSesionesPresenciales += sesionesPresenciales.length;

                    // Sumar total de asistentes por tipo de sesión
                    global.totalParticipantesVirtuales += sesionesVirtuales.reduce((acc, ses) => acc + Number(ses.asistentes || 0), 0);
                    global.totalParticipantesAutonomas += sesionesAutonomas.reduce((acc, ses) => acc + Number(ses.asistentes || 0), 0);
                    global.totalParticipantesPresenciales += sesionesPresenciales.reduce((acc, ses) => acc + Number(ses.asistentes || 0), 0);

                    // Sumar asistentes hombres y mujeres en cada tipo de sesión
                    global.sumaHombresVirtuales += sesionesVirtuales.reduce((acc, ses) => acc + Number(ses.hombres || 0), 0);
                    global.sumaMujeresVirtuales += sesionesVirtuales.reduce((acc, ses) => acc + Number(ses.mujeres || 0), 0);

                    global.sumaHombresAutonomas += sesionesAutonomas.reduce((acc, ses) => acc + Number(ses.hombres || 0), 0);
                    global.sumaMujeresAutonomas += sesionesAutonomas.reduce((acc, ses) => acc + Number(ses.mujeres || 0), 0);

                    global.sumaHombresPresenciales += sesionesPresenciales.reduce((acc, ses) => acc + Number(ses.hombres || 0), 0);
                    global.sumaMujeresPresenciales += sesionesPresenciales.reduce((acc, ses) => acc + Number(ses.mujeres || 0), 0);
                });

                // Calcula totales adicionales
                global.numSesiones = global.numSesionesVirtuales + global.numSesionesAutonomas + global.numSesionesPresenciales;
                global.asistentesHombres = global.sumaHombresVirtuales + global.sumaHombresAutonomas + global.sumaHombresPresenciales;
                global.asistentesMujeres = global.sumaMujeresVirtuales + global.sumaMujeresAutonomas + global.sumaMujeresPresenciales;

                // Calcula totales y porcentajes
                const totalVirtuales = global.totalParticipantesVirtuales;
                const totalAutonomas = global.totalParticipantesAutonomas;
                const totalPresenciales = global.totalParticipantesPresenciales;

                const totalParticipantes = totalVirtuales + totalAutonomas + totalPresenciales;

                const calcularPorcentaje = (total, valor) => (total > 0 ? ((valor / total) * 100).toFixed(2) : "0.00");

                // Participación en sesiones virtuales
                const participacionHombresVirtuales = calcularPorcentaje(totalVirtuales, global.sumaHombresVirtuales);
                const participacionMujeresVirtuales = calcularPorcentaje(totalVirtuales, global.sumaMujeresVirtuales);

                // Participación en sesiones autónomas
                const participacionHombresAutonomas = calcularPorcentaje(totalAutonomas, global.sumaHombresAutonomas);
                const participacionMujeresAutonomas = calcularPorcentaje(totalAutonomas, global.sumaMujeresAutonomas);

                // Participación en sesiones presenciales
                const participacionHombresPresenciales = calcularPorcentaje(totalPresenciales, global.sumaHombresPresenciales);
                const participacionMujeresPresenciales = calcularPorcentaje(totalPresenciales, global.sumaMujeresPresenciales);

                // Porcentaje total de asistentes hombres y mujeres en todas las sesiones
                const porcentajeasistentesHombres = calcularPorcentaje(totalParticipantes, global.asistentesHombres);
                const porcentajeasistentesMujeres = calcularPorcentaje(totalParticipantes, global.asistentesMujeres);

                // Asignar valores al estado
                setIndicadores({
                    numCohortes: global.numCohortes,
                    numSesionesVirtuales: global.numSesionesVirtuales,
                    numSesionesAutonomas: global.numSesionesAutonomas,
                    numSesionesPresenciales: global.numSesionesPresenciales,
                    numSesiones: global.numSesiones,

                    totalParticipantesVirtuales: totalVirtuales,
                    totalParticipantesAutonomas: totalAutonomas,
                    totalParticipantesPresenciales: totalPresenciales,

                    asistentesHombres: global.asistentesHombres,
                    asistentesMujeres: global.asistentesMujeres,

                    participacionHombresVirtuales: Number(participacionHombresVirtuales),
                    participacionMujeresVirtuales: Number(participacionMujeresVirtuales),

                    participacionHombresAutonomas: Number(participacionHombresAutonomas),
                    participacionMujeresAutonomas: Number(participacionMujeresAutonomas),

                    participacionHombresPresenciales: Number(participacionHombresPresenciales),
                    participacionMujeresPresenciales: Number(participacionMujeresPresenciales),

                    asistentesHombresV: global.sumaHombresVirtuales,
                    asistentesMujeresV: global.sumaMujeresVirtuales,

                    asistentesHombresA: global.sumaHombresAutonomas,
                    asistentesMujeresA: global.sumaMujeresAutonomas,

                    asistentesHombresP: global.sumaHombresPresenciales,
                    asistentesMujeresP: global.sumaMujeresPresenciales,

                    porc_asistentesHombresV: participacionHombresVirtuales,
                    porc_asistentesMujeresV: participacionMujeresVirtuales,

                    porc_asistentesHombresA: participacionHombresAutonomas,
                    porc_asistentesMujeresA: participacionMujeresAutonomas,

                    porc_asistentesHombresP: participacionHombresPresenciales,
                    porc_asistentesMujeresP: participacionMujeresPresenciales,

                    porc_Htot: porcentajeasistentesHombres,
                    porc_Mtot: porcentajeasistentesMujeres,
                });
            } else {
                message.error("Error en la consulta de cohortes");
            }
        } catch (error) {
            console.error("Error fetching cohortes:", error);
            message.error("Error al obtener cohortes");
        } finally {
            setLoading(false); // Finaliza el loading
        }
    };

    useEffect(() => {
        fetchData();
    }, []);


    const handleDelete = async (record, userLogin) => {
        try {
            setLoading(true); // Mostrar indicador de carga

            const userName = modalRef.current?.userLogin;

            if (userName === undefined) {
                message.warning("Debe iniciar sesión nuevamente.");
                return;
            }
            const oid = record.key; // Suponiendo que "key" es el oid
            const registroActual = record.rawData; // Guardamos los datos actuales antes de eliminar
            await deleteCohorte(userName, oid, registroActual);
            message.success("Cohorte eliminado correctamente");
            // Refrescamos los datos después de eliminar
            fetchData();
        } catch (error) {
            message.error("Error al eliminar el cohorte");
        } finally {
            setLoading(false);
        }
    };
    // Columnas de la tabla
    const columns = [
        {
            title: "Acciones",
            key: "acciones",
            align: "center",
            fixed: 'left',
            className: 'ajustecolumna',
            render: (text, record) => (
                <>
                    {modalRef.current?.permissionUpdate && (

                        <Button type="primary" icon={<EditOutlined />} onClick={() => handleEdit(record)}>

                        </Button>

                    )}

                    {modalRef.current?.permissionDelete && (

                        <Popconfirm
                            title="¿Estás seguro de eliminar el registro de cohorte?"
                            onConfirm={() => handleDelete(record)}
                            okText="Sí"
                            cancelText="No"
                            placement="topRight"
                        >
                            <Button
                                danger
                                icon={<DeleteOutlined />}
                                style={{ marginLeft: '10px' }} >

                            </Button>
                        </Popconfirm>
                    )}

                    {modalRef.current?.permissionPrint && (
                        <ExcelDetalleCohorte cohorte={record} />
                    )}
                </>
            ),
        },
        {
            title: "Cohorte",
            dataIndex: "cohorte",
            key: "cohorte",
            render: (text) => (
                <Tooltip title={text}>
                    <span>{text && text.length > 20 ? text.slice(0, 20) + "..." : text}</span>
                </Tooltip>
            ),
        },

        {
            title: "Resumen",
            dataIndex: "resumen",
            key: "resumen",
            render: (text) => (
                <>
                    <DiffOutlined style={{ color: "#1890ff", fontSize: "16px", marginRight: "8px", cursor: "pointer" }} onClick={() => openModal(text)} />
                    {text && text.length > 30 ? text.slice(0, 30) + "..." : text}
                </>
            ),
        },

        {
            title: "Sesiones",
            className: "custom-header-sesiones",
            children: [
                {
                    title: "Total sesiones",
                    dataIndex: "numSesiones",
                    key: "numSesiones",
                    align: "center",
                    className: "ajustecolumna"
                },
                {
                    title: "Presenciales",
                    dataIndex: "numSesionesPresenciales",
                    key: "numSesionesPresenciales",
                    align: "center",
                    className: "ajustecolumna"
                },
                {
                    title: "Virtuales",
                    dataIndex: "numSesionesVirtuales",
                    key: "numSesionesVirtuales",
                    align: "center",
                    className: "ajustecolumna"
                },
                {
                    title: "Autonomas",
                    dataIndex: "numSesionesAutonomas",
                    key: "numSesionesAutonomas",
                    align: "center",
                    className: "ajustecolumna"
                },
            ],
        },
        {
            title: "Consolidado participación",
            className: "custom-header-participacion",
            children: [
                {
                    title: "Total Asistentes",
                    dataIndex: "totalAsistentes",
                    key: "totalAsistentes",
                    align: "center",
                    className: "ajustecolumna",
                },
                {
                    title: "Hombres",
                    dataIndex: "asistentesHombres",
                    key: "asistentesHombres",
                    align: "center",
                    className: "ajustecolumna",
                },
                {
                    title: "Mujeres",
                    dataIndex: "asistentesMujeres",
                    key: "asistentesMujeres",
                    align: "center",
                    className: "ajustecolumna",
                },
                {
                    title: "% Hombres",
                    dataIndex: "porcentajeasistentesHombres",
                    key: "porcentajeasistentesHombres",
                    align: "center",
                    className: "ajustecolumna",
                },
                {
                    title: "% Mujeres",
                    dataIndex: "porcentajeasistentesMujeres",
                    key: "porcentajeasistentesMujeres",
                    align: "center",
                    className: "ajustecolumna",
                },
            ],
        },

        {
            title: "Participación sesiones presenciales",
            className: "custom-header-participacion-sesionesP",
            children: [

                {
                    title: "Asistentes Hombres",
                    dataIndex: "asistentesHombresP",
                    key: "asistentesHombresP",
                    align: "center",
                    className: "ajustecolumna"
                },
                {
                    title: "% Hombres",
                    dataIndex: "porc_asistentesHombresP",
                    key: "porc_asistentesHombresP",
                    align: "center",
                    className: "ajustecolumna"
                },

                {
                    title: "Asistentes Mujeres",
                    dataIndex: "asistentesMujeresP",
                    key: "asistentesMujeresP",
                    align: "center",
                    className: "ajustecolumna"
                },

                {
                    title: "% Mujeres",
                    dataIndex: "porc_asistentesMujeresP",
                    key: "porc_asistentesMujeresV",
                    align: "center",
                    className: "ajustecolumna"
                },

            ],
        },

        {
            title: "Participación sesiones virtuales",
            className: "custom-header-participacion-sesionesV",
            children: [

                {
                    title: "Asistentes Hombres",
                    dataIndex: "asistentesHombresV",
                    key: "asistentesHombresV",
                    align: "center",
                    className: "ajustecolumna"
                },
                {
                    title: "% Hombres",
                    dataIndex: "porc_asistentesHombresV",
                    key: "porc_asistentesHombresV",
                    align: "center",
                    className: "ajustecolumna"
                },

                {
                    title: "Asistentes Mujeres",
                    dataIndex: "asistentesMujeresV",
                    key: "asistentesMujeresV",
                    align: "center",
                    className: "ajustecolumna"
                },

                {
                    title: "% Mujeres",
                    dataIndex: "porc_asistentesMujeresV",
                    key: "porc_asistentesMujeresV",
                    align: "center",
                    className: "ajustecolumna"
                },

            ],
        },

        {
            title: "Participación sesiones Autonomas",
            className: "custom-header-participacion-sesionesA",
            children: [

                {
                    title: "Asistentes Hombres",
                    dataIndex: "asistentesHombresA",
                    key: "asistentesHombresA",
                    align: "center",
                    className: "ajustecolumna"
                },
                {
                    title: "% Hombres",
                    dataIndex: "porc_asistentesHombresA",
                    key: "porc_asistentesHombresA",
                    align: "center",
                    className: "ajustecolumna"
                },
                {
                    title: "Asistentes Mujeres",
                    dataIndex: "asistentesMujeresA",
                    key: "asistentesMujeresA",
                    align: "center",
                    className: "ajustecolumna"
                },

                {
                    title: "% Mujeres",
                    dataIndex: "porc_asistentesMujeresA",
                    key: "porc_asistentesMujeresA",
                    align: "center",
                    className: "ajustecolumna"
                },

            ],
        },



    ];


    // Función para abrir el modal y guardar el texto completo
    const openModal = (text) => {
        setFullText(text);
        setModalVisible(true);
    };

    const openResumen = () => {
        setResumen(!resumen);
    };

    // Función para manejar la edición
    const handleEdit = (record) => {
        // Llamamos a la función editRecord del formulario hijo
        modalRef.current.editRecord(record.rawData);
    };

    // Función para abrir en modo crear: llamada desde un botón
    const handleCreate = () => {
        // Usa optional chaining para evitar error si modalRef.current es null.
        modalRef.current?.showModal();
    };

    const maxCohortes = indicadores.numCohortes;
    const numCohortes = indicadores.numCohortes ?? 0;
    const percentCohortes = Math.round((numCohortes / maxCohortes) * 100);

    // Ejemplo 1: gradiente simple (2 colores)
    const twoColors = {
        '0%': '#108ee9',
        '100%': '#87d068',
    };

    // Ejemplo 2: gradiente "conic" (3 colores)
    const conicColors = {
        '0%': '#87d068',
        '50%': '#ffe58f',
        '100%': '#ffccc7',
    };

    // Obtén los valores asegurándote de que no sean undefined
    const totalAsistentes = (indicadores.totalParticipantesPresenciales + indicadores.totalParticipantesVirtuales + indicadores.totalParticipantesAutonomas) ?? 0;

    const totalVirtuales = indicadores.totalParticipantesVirtuales ?? 0;
    const totalAutonomas = indicadores.totalParticipantesAutonomas ?? 0;
    const totalPresenciales = indicadores.totalParticipantesPresenciales ?? 0;

    // Definir el total máximo (suma de todas las categorías)
    const max = totalAsistentes || 1; // Evita dividir por 0

    // Función para calcular los porcentajes de manera más precisa
    const calcularPorcentajes = (valores) => {
        let sumaPorcentajes = 0;
        let porcentajes = valores.map((val) => {
            let porcentaje = (val / max) * 100; // Deja los decimales para ajuste final
            sumaPorcentajes += porcentaje;
            return porcentaje;
        });

        // Ajuste final para que la suma sea exactamente 100%
        let diferencia = 100 - sumaPorcentajes;

        // Agregar la diferencia al valor con el mayor porcentaje para corregir el error de redondeo
        let indexMax = porcentajes.indexOf(Math.max(...porcentajes));
        porcentajes[indexMax] += diferencia;

        // Redondeamos todos los valores una vez corregido el total
        return porcentajes.map(val => Math.round(val));
    };

    // Calcula los porcentajes correctamente
    const [percentVirtuales, percentAutonomas, percentPrecenciales] = calcularPorcentajes([totalVirtuales, totalAutonomas, totalPresenciales]);

    // Total de asistentes siempre debe ser 100%
    const percentAsistentes = 100;


    // Asumiendo que cada valor ya es un porcentaje de 0 a 100 (ej. 45.5).
    const pmv = indicadores.participacionMujeresVirtuales ?? 0; // Participación Mujeres Virtuales
    const phv = indicadores.participacionHombresVirtuales ?? 0; // Participación Hombres Virtuales
    const pma = indicadores.participacionMujeresAutonomas ?? 0; // Participación Mujeres Autónomas
    const pha = indicadores.participacionHombresAutonomas ?? 0; // Participación Hombres Autónomas

    const pmp = indicadores.participacionMujeresPresenciales ?? 0; // Participación Mujeres Presenciales
    const php = indicadores.participacionHombresPresenciales ?? 0; // Participación Hombres Presenciales


    return (
        <div className="container">

            <FormularioCohorte ref={modalRef} onRegistroGuardado={fetchData} />

            <div className="row mb-2 d-flex align-items-center">
                {/* Título */}
                <Header160 />

                {/* Contenedor de los botones con mejor distribución */}
                <div className="col-md-4 d-flex justify-content-between align-items-center gap-2">
                    {/* Botón de regresar */}
                    <Button
                        type="default"
                        onClick={() => navigate(-1)}
                        icon={<ArrowLeftOutlined />}
                        style={{ color: "#6c757d", borderColor: "#6c757d", flexGrow: 1 }}
                    >
                        Regresar
                    </Button>

                    {/* Botón para mostrar/ocultar resumen */}
                    <Button
                        type={resumen ? "primary" : "default"}
                        onClick={openResumen}
                        icon={<InfoOutlined />}
                        style={{ flexGrow: 1 }}
                    >
                        {resumen ? 'Ocultar' : 'Mostrar'} Resumen
                    </Button>

                    {/* Botón para registrar cohorte (si tiene permisos) */}
                    {modalRef.current?.permissionInsert && (
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={handleCreate}
                            style={{ flexGrow: 1 }}
                        >
                            Registrar
                        </Button>
                    )}
                </div>
            </div>


            {/* Indicadores */}

            {resumen && (

                <div>
                    <div className="mb-4 d-flex justify-content-center">
                        <h4>Resumen de cohortes y sesiones</h4>
                    </div>
                    <Row gutter={[16, 16]} className="mb-4 justify-content-center" >
                        <Col span={6}>
                            <Card style={{ textAlign: 'center', paddingTop: '15px' }}>
                                <AnimatedProgress
                                    type="circle"
                                    finalValue={percentCohortes}
                                    format={() => `${numCohortes}`}
                                />
                                <p style={{ marginTop: 16 }}>Número de Cohortes</p>
                            </Card>
                        </Col>

                        <Col span={6}>
                            <Card style={{ textAlign: 'center', paddingTop: '15px' }}>
                                {(() => {
                                    // Calculamos el total de sesiones antes de renderizar
                                    const totalSesiones = indicadores.numSesionesPresenciales + indicadores.numSesionesVirtuales + indicadores.numSesionesAutonomas;

                                    return (
                                        <AnimatedProgress
                                            type="circle"
                                            finalValue={totalSesiones}
                                            format={() => `${totalSesiones}`} // ✅ Corrige la interpolación
                                        />
                                    );
                                })()}
                                <p style={{ marginTop: 16 }}>Número de sesiones</p>
                            </Card>
                        </Col>

                    </Row>
                    <Row gutter={[16, 16]} className="mb-4 justify-content-center" >

                        <Col span={6}>
                            <Card style={{ textAlign: 'center', paddingTop: '15px' }}>
                                <AnimatedProgress
                                    type="circle"
                                    finalValue={indicadores.numSesionesPresenciales ?? 0}
                                    format={(percent) => `${percent}`}
                                />
                                <p style={{ marginTop: 16 }}>Sesiones Presenciales</p>
                            </Card>
                        </Col>


                        <Col span={6}>
                            <Card style={{ textAlign: 'center', paddingTop: '15px' }}>
                                <AnimatedProgress
                                    type="circle"
                                    finalValue={indicadores.numSesionesVirtuales ?? 0}
                                    format={(percent) => `${percent}`}
                                />



                                <p style={{ marginTop: 16 }}>Sesiones Virtuales</p>
                            </Card>
                        </Col>

                        <Col span={6}>
                            <Card style={{ textAlign: 'center', paddingTop: '15px' }}>
                                <AnimatedProgress
                                    type="circle"
                                    finalValue={indicadores.numSesionesAutonomas ?? 0}
                                    format={(percent) => `${percent}`}
                                />
                                <p style={{ marginTop: 16 }}>Sesiones Autónomas</p>
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} className=" justify-content-center">
                        <Col span={6} style={{ borderRight: "1px solid #d9d9d9", paddingRight: "16px" }}>
                            <Card style={{ textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                                <Statistic
                                    title="Total Asistentes"
                                    value={totalAsistentes}
                                />
                                <AnimatedProgress
                                    type="line"
                                    finalValue={percentAsistentes}
                                    strokeColor={twoColors}
                                    format={(p) => `${p}%`}
                                />
                            </Card>
                        </Col>

                        <Col span={6} style={{ borderRight: "1px solid #d9d9d9", paddingRight: "16px" }}>
                            <Card style={{ textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                                <Statistic
                                    title="Total Asistencias Presenciales"
                                    value={totalPresenciales}
                                />
                                <AnimatedProgress
                                    type="line" // linea
                                    finalValue={percentPrecenciales}
                                    strokeColor={conicColors}
                                    format={(p) => `${p}%`}
                                />

                            </Card>

                            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <Statistic
                                        title="Participación Mujeres Presenciales"
                                        value={indicadores.asistentesMujeresP}
                                    />
                                    <AnimatedProgress
                                        type="line"
                                        finalValue={pmp}                    // Valor final para la animación (0 a 100)
                                        strokeColor={twoColors}             // Gradiente de color
                                        progressInfoPosition="center"       // Texto en el centro
                                        format={(val) => `${val.toFixed(2)}%`}
                                    />
                                </Card>
                            </Col>

                            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <Statistic
                                        title="Participación Hombres Presenciales"
                                        value={indicadores.asistentesHombresP}
                                    />
                                    <AnimatedProgress
                                        type="line"
                                        finalValue={php}
                                        strokeColor={twoColors}
                                        progressInfoPosition="center"
                                        format={(val) => `${val.toFixed(2)}%`}
                                    />
                                </Card>
                            </Col>
                        </Col>

                        <Col span={6} style={{ borderRight: "1px solid #d9d9d9", paddingRight: "16px" }}>
                            <Card style={{ textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                                <Statistic
                                    title="Total Asistencias Virtuales"
                                    value={totalVirtuales}
                                />
                                <AnimatedProgress
                                    type="line" // linea
                                    finalValue={percentVirtuales}
                                    strokeColor={conicColors}
                                    format={(p) => `${p}%`}
                                />
                            </Card>


                            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <Statistic
                                        title="Participación Mujeres Virtuales"
                                        value={indicadores.asistentesMujeresV}
                                    />
                                    <AnimatedProgress
                                        type="line"
                                        finalValue={pmv}                    // Valor final para la animación (0 a 100)
                                        strokeColor={twoColors}             // Gradiente de color
                                        progressInfoPosition="center"       // Texto en el centro
                                        format={(val) => `${val.toFixed(2)}%`}
                                    />
                                </Card>
                            </Col>

                            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <Statistic
                                        title="Participación Hombres Virtuales"
                                        value={indicadores.asistentesHombresV}
                                    />
                                    <AnimatedProgress
                                        type="line"
                                        finalValue={phv}
                                        strokeColor={twoColors}
                                        progressInfoPosition="center"
                                        format={(val) => `${val.toFixed(2)}%`}
                                    />
                                </Card>
                            </Col>

                        </Col>

                        <Col span={6}>
                            <Card style={{ textAlign: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                                <Statistic
                                    title="Total Asistencias Autónomas"
                                    value={totalAutonomas}
                                />
                                <AnimatedProgress
                                    type="line"
                                    finalValue={percentAutonomas}
                                    strokeColor={twoColors}
                                    format={(p) => `${p}%`}
                                />
                            </Card>

                            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <Statistic
                                        title="Participación Mujeres Autónomas"
                                        value={indicadores.asistentesMujeresA}
                                    />
                                    <AnimatedProgress
                                        type="line"
                                        finalValue={pma}
                                        strokeColor={twoColors}
                                        progressInfoPosition="center"
                                        format={(val) => `${val.toFixed(2)}%`}
                                    />
                                </Card>
                            </Col>

                            <Col span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                <Card style={{ textAlign: 'center', marginTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                    <Statistic
                                        title="Participación Hombres Autónomas"
                                        value={indicadores.asistentesHombresA}
                                    />
                                    <AnimatedProgress
                                        type="line"
                                        finalValue={pha}
                                        strokeColor={twoColors}
                                        progressInfoPosition="center"
                                        format={(val) => `${val.toFixed(2)}%`}
                                    />
                                </Card>
                            </Col>

                        </Col>
                    </Row>

                    <Divider />
                    <Row gutter={[16, 16]} className="mt-4 justify-content-center">
                        <Col span={24} style={{ textAlign: "center" }}>
                            <h2>Participación de hombres y mujeres</h2>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "500px" }}>
                                <RadarChart
                                    data={{
                                        asistentesMujeresV: indicadores.asistentesMujeresV,
                                        asistentesHombresV: indicadores.asistentesHombresV,
                                        asistentesMujeresA: indicadores.asistentesMujeresA,
                                        asistentesHombresA: indicadores.asistentesHombresA,
                                        asistentesMujeresP: indicadores.asistentesMujeresP,
                                        asistentesHombresP: indicadores.asistentesHombresP,
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>


                </div>

            )}

            {/* Tabla de registros */}
            <div className="d-flex justify-content-start mb-3">
                <ExportToExcelCohortes data={data} indicadores={indicadores} />
            </div>
            <Table
                columns={columns}
                dataSource={data}  // data es el state donde se guardó transformedData
                pagination={{ pageSize: 15 }}
                bordered
                align="middle"
                tableLayout="fixed"
                scroll={{ x: "max-content" }}
            />

            <Modal
                title="Resumen del cohorte"
                open={modalVisible}
                onCancel={() => setModalVisible(false)}
                footer={null}
            >


                <div style={{ whiteSpace: "pre-line" }}>
                    {fullText}
                </div>
            </Modal>


        </div>


    );
};

export default Uniminuto;
