import React, { useState } from 'react';
import { Button, Form, Input, Modal, message, Tooltip } from 'antd';
import { EditOutlined } from "@ant-design/icons";
import { renameFolder } from '../../../../services/inicio/repositorio/repositorioDescargarVerService';

const RenombrarCarpeta = ({ visible, folder, onFolderRenamed, onClose, username }) => {
    const [form] = Form.useForm();

    const handleRename = async () => {
        try {
            // Valida los campos del formulario
            const values = await form.validateFields();
            const newName = values.newName.trim();
            // Guarda la ruta antigua antes de llamar al servicio
            const oldPath = folder.path;
            // Llama al servicio para renombrar la carpeta
            const response = await renameFolder({ folderId: folder.id, newName, username });

            message.success(response.message || "Carpeta renombrada con éxito");

            // Llama al callback pasándole el objeto actualizado y el oldPath
            if (onFolderRenamed) {
                const updatedFolder = { ...folder, name: newName, path: response.newFolder.path };

                onFolderRenamed(updatedFolder, oldPath);
            }
            onClose();
        } catch (error) {
            console.error("Error en handleRename:", error);
            message.error("Error al renombrar la carpeta");
        }
    };



    return (
        <Modal
            title="Renombrar Carpeta"
            open={visible}
            onCancel={onClose}
            onOk={handleRename}
            okText="Renombrar"
            cancelText="Cancelar"
            destroyOnClose
        >
            <Form form={form} layout="vertical" initialValues={{ newName: folder.name }}>
                <Form.Item
                    label="Nuevo nombre"
                    name="newName"
                    rules={[
                        { required: true, message: "Por favor ingresa un nuevo nombre." },
                        {
                            pattern: /^[^\\\/:*?"<>|]+$/,
                            message: 'No se pueden usar los caracteres / \\ : * ? " < > |',
                        },
                    ]}
                >
                    <Input placeholder="Ingresa el nuevo nombre" prefix={<EditOutlined />} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default RenombrarCarpeta;


